import Vue from 'vue'
import https from '@/https.js'
const initState = {
  /* enterpriseSerialNumber:'', */
}

const state = {
  checkDisplay: false, //签到 
  public_name: '',
}

const mutations = {
  SET_CHECK_DISPLAY(state, data) {
    state.checkDisplay = data
  },
  PUBLIC_NAME(state, data) {
    state.public_name = data
  }


}

const actions = {

  //退出账户
  user_logout({
    commit
  }, params = {}) {
    return https.fetchGet('user-logout', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}