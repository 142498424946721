<template>
  <div class="banxin shoppingCart">
    <template>
      <div class="order_success">
        <img src="@/assets/images/cart/success.png">
        <h3>订单支付成功</h3>
        <p class="one" style="display:block;text-align:center">我们将尽快为您发货，收货期间请保持手机畅通</p>
        <p class="success">
          订单号：<i>{{params.tradeNo}}</i><br>
          支付方式：<i>支付宝</i><br>
          支付金额：<i class="err">¥{{params.totalFee}}</i>
        </p>
        <div class="btn">
          <router-link to="/index" class="submit">返回首页</router-link>
          <router-link to="/home/order/list" class="back">查看订单</router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
//import QRCode from 'qrcodejs2'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '订单支付-合晶芯城',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  components: {
  },
  data () {
    return {
      params: {
        tradeNo: '',
        totalFee: ''
      }
    }
  },

  created () {
    this.params.tradeNo = this.$router.currentRoute.query.no
    let m = this.$router.currentRoute.query.m
    if (m) {
      this.params.totalFee = m.replace('_', '.')
    }
  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {

  },
  mounted () {

  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/cart-pay.scss";
.shoppingCart {
  padding-top: 1px;
  min-height: calc(100vh - 80px - 312px);
}
</style>