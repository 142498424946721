<template>
  <div class="newMain bgf5f5f5">
    <div class="sample-wrap auto clearfix">
      <div class="banner"></div>
      <div class="rule clearfix">
        <div class="fl">
          <h3>样品申请步骤</h3>
          <div class="rule-img">
            <img src="@/assets/images/sample/rule.png">
            <div class="text">
              <span>选择样品</span>
              <span>提交申请</span>
              <span>原厂登记</span>
              <span>发货成功</span>
            </div>
          </div>
        </div>
        <div class="fr">
          <h3>样品申请规则</h3>
          <div class="rule-text">
            <p>1、任何合晶芯城注册用户都可直接申请；欢迎会员申请；</p>
            <p>2、您的申请经平台与原厂审核通过后，将在5个工作日内寄出；</p>
            <p>3、同一样品每个用户最多可领取一次，如有特殊情况请联系客服；</p>
            <p>4、如遇到有任何问题，请联系在线客服。</p>
            <p>5、快递以平台默认快递发出。</p>
          </div>
        </div>
      </div>
      <div class="pfilter-wrap clearfix">
        <div class="ass ass2 ass3">
          <div class="atitle">样品列表</div>
          <ul class="ass_ul" v-if="sampleCagetory">
            <li class="ass_list" v-for="(item, index) in sampleCagetory" :key="index" v-show="index < 10">
              <a class="nav_menu_title">
                <img class="icon" :src="item.image">
                {{ item.nameCh }}
                <i class="ass_list_right"></i>
              </a>
              <div class="nav_menu_box">
                <div class="l">
                  <div class="nav_menu">
                    <div class="nav_menu_item clear">
                      <!-- <a href="javascript:;">
                        <h3 class="clear">
                          <img :src="item.image" />
                          {{ item.nameCh }}
                        </h3>
                      </a> -->
                      <ul class="clear" v-if="item.subCategory">
                        <li v-for="(i2, d2) in item.subCategory" :key="d2">
                          <a href="javascript:;" @click="getSampleList(i2.id)">{{ i2.nameCh }}</a>
                          <i></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </li>
          </ul>
        </div>

        <div class="select-wrap" v-if="sampleList">
          <div class="param_search clear">
            <div class="clearfix">
              <i class="l">参数选型：</i>
              <p class="l" v-for="(item, index) in s_attr" :key="index">
                <a class="item" href="javascript:;" v-for="(i,d) in item" :key="d"><span>{{i.name}}{{i.val}}</span><i @click="delHtmlItem(i.idx,i.valIdx,index,d)"></i></a>
              </p>
            </div>
          </div>
          <div class="param_select">
            <div class="brand_select_box">
              <template v-if="sampleList.object.QueryItems&&sampleList.object.QueryItems.length>0">
                <div class="brand_select" v-for="(av,aindex) in sampleList.object.QueryItems" :key="aindex">
                  <h4>{{av.name}}</h4>
                  <ul>
                    <template v-if="av.attrs.length >0&&s_attr_falg[aindex]">
                      <li v-for="(av2,aindex2) in av.attrs" :key="aindex2" @click="attrSelect(av.name,aindex,av2,aindex2,av.attrs.length)" :class="{'attrclass':s_attr_falg[aindex].arr[aindex2]}">
                        {{av2}}</li>
                    </template>
                  </ul>
                </div>
              </template>

            </div>
          </div>
          <a href="javascript:;" class="pBtn" @click="paramsSearch">立即查询</a>
        </div>
      </div>
      <div class="primary_search_product w960 fr">
        <div class="search_product_title">
          <!-- <div id="selectSort" class="product-price"><span>默认排序</span><i></i>
            <div id="price-selct" class="price-selct" style="display:none;">
              <p class="selcted">默认排序</p>
              <p class="">价格从低到高</p>
              <p class="">价格从高到低</p>
            </div>
          </div> -->
          <!-- <div id="checkbox" class="product-stock">
            <i class=""></i><span>有库存的</span>
          </div> -->
          <div class="padding20" v-if="sampleList">
            符合条件的商品：<span>{{sampleList.total}}</span></div>
        </div>
        <div class="s-wbox" v-loading="isloading">
          <template v-if="sampleList && sampleList.records.length>0">
            <ul class="new-product-ul">
              <template>
                <li class="new-product-li clearfix" v-for="(v,index) in sampleList.records" :key="index">
                  <div class="pl-col-1 fl">
                    <a href="javascript:;" class="ibox">
                      <img v-if="v.goodsImg" :src="v.goodsImg" :alt="v.name+' '+v.packageSpecification">
                      <img v-else src="@/assets/images/goods/default_img.png" :alt="v.name+' '+v.packageSpecification">
                    </a>
                  </div>
                  <div class="pl-col-2 fl">
                    <p class="p_name">
                      <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.name"></router-link>
                    </p>
                    <div class="clear">
                      <div class="c1">
                        <p>
                          <span class="tag">编码：</span>
                          <router-link class="detail" :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.ksn"></router-link>
                        </p>
                        <p>
                          <span class="tag">品牌：</span>
                          <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.brandName"></router-link>
                        </p>
                        <p class="ms-wrap">
                          <span class="tag">描述：</span>
                          <router-link class="ms" :title="v.goodRemark" :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.goodRemark"></router-link>
                        </p>
                      </div>
                      <div class="c2" style="margin-left:120px;">
                        <p>
                          <span class="tag">型号：<i v-html="v.materialSn"></i></span>
                        </p>
                        <p>
                          <span class="tag">封装：<i v-html="v.packageSpecification"></i></span>
                        </p>

                        <p v-if="v.goodsManual">
                          <span class="tag">数据手册：<i><a :href="v.goodsManual" target="_blank" class="pdf">&nbsp;</a></i></span>
                        </p>
                        <p v-else>
                          <a href="javascript:;" class="pdf">数据手册：暂无</a>
                        </p>
                      </div>
                      <!-- <div class="c3">
                        <p><span class="tag">库存：<i>现货</i></span></p>
                        <p><span class="tag">发货时间：<i>2~7 天发货</i></span></p>
                        <p><span class="tag">包装：「卷装」</span></p>
                      </div> -->
                    </div>
                  </div>
                  <!-- <div class="pl-col-3 fl">
                  <div class="price_list">
                    <div class="tab">
                      <table v-if="v.priceTemplate&&v.priceTemplate.length >0">
                        <tbody>
                          <tr v-for="(vp,index2) in v.priceTemplate" :key="index2">
                            <td class="num">{{vp.num}}+</td>
                            <td class="price">¥{{vp.price}}/{{v.storeCompany}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> -->
                  <div class="pl-col-4 fr" style="margin-right:25px;">
                    <div class="opera_box">
                      <!-- <div class="number-box">
                        <input class="buy-num" value="100"><span class="add">+</span><span class="sub">-</span>
                      </div> -->
                      <router-link class="add_cart J_addToCart" target='_blank' :to="{path:'/sample/detail',query:{id:v.id}}">申请样品</router-link>
                      <p class="mfNum">可免费领取：{{v.stepSample}}个</p>

                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div class="page-wrap" v-if="sampleList">
              <el-pagination background hideOnSinglePage layout="prev, pager, next" :total="sampleList.total" @current-change="currentChange"></el-pagination>
            </div>
          </template>
          <div class="no_product_box" v-if="sampleList&&sampleList.records.length==0">
            <img src="@/assets/images/goods/noshop_1.png">
            <p>很抱歉，暂无相关商品，请查询其他的看看</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '合晶芯城 - 厂家直营 - 二极管 - 晶体管 - 电阻电容 - 集成电路 - 电子元器件交易平台',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二极管,晶体管,电阻,电容,集成电路,电子元器件网上商城'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内厂家直营二极管、晶体管、电阻、电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  data () {
    return {
      isloading: false,
      params: {
        categoryId: '',
        pageSize: 10,
        pageNum: 1,
        attrs: [],
      },
      params2: {
        pageNum: 1,
        pageSize: 10,
        queryValue: '',
        categoryId: '',
        attrs: [],
        seoName: this.$route.query.name || ''
      },

      s_attr: [], //选中属性列表集合 ---  展示
      s_attr_id: [], //选中属性列表集合 --  标识
      s_attr_falg: [],
      currentId: '',
    }
  },
  async asyncData ({ store }) {
    let getSampleCagetory = await store.dispatch('lxr/get_sample_cagetory');
    let getSampleList = await store.dispatch('lxr/get_sample_list_2', {
      categoryId: '',
      pageSize: 10,
      pageNum: 1,
    });
    return getSampleCagetory, getSampleList
  },
  created () {
    this.getSampleList()
  },
  mounted () {
    if (!this.sampleCagetory) {
      this.getSampleCagetory()
    }
  },
  computed: {
    ...mapState('lxr', ['sampleList', 'sampleCagetory'
    ]),
  },
  methods: {
    ...mapActions('lxr', ['get_sample_list_2', 'get_product_list', 'get_sample_cagetory', 'get_product_list_sample'
    ]),
    currentChange (e) {
      this.params.pageNum = e
      this.getSampleList('')
    },
    paramsSearch () {
      this.params.attrs = []
      this.s_attr_id.forEach((item, index) => {
        if (item.length > 0) {
          let val = ''
          item.forEach(i => {
            val += this.sampleList.object.QueryItems[index].attrs[i] + '&'
          });
          this.params.attrs.push({ attrValue: val, attrId: this.sampleList.object.QueryItems[index].name })
        }
      });
      this.params.pageNum = 1
      this.getSampleList('')
    },
    // async getProductList () {
    //   this.isloading = true
    //   let res = await this.get_product_list_sample(this.params2)
    //   if (res.httpCode == 200) {
    //     this.isloading = false
    //     if (res.data.object.QueryItems && res.data.object.QueryItems.length > 0) {
    //       res.data.object.QueryItems.forEach((item, index) => {
    //         this.s_attr_id.push([])
    //         this.s_attr.push([])
    //         this.s_attr_falg.push({ arr: [] })
    //         item.attrs.forEach((i, d) => {
    //           this.s_attr_falg[index].arr.push(false)
    //         })
    //       });
    //     }
    //   }
    // },
    async getSampleCagetory () {
      let res = await this.get_sample_cagetory({})
    },
    async getSampleList (id) {
      this.isloading = true
      if (id) {
        this.currentId = id
        this.s_attr_id = []
        this.s_attr = []
        this.s_attr_falg = []
        this.params.attrs = []
        this.params.pageNum = 1
        this.params.categoryId = id
      }
      let res = await this.get_sample_list_2(this.params)
      if (res.httpCode == 200) {
        this.isloading = false
        if (res.data.object.QueryItems && res.data.object.QueryItems.length > 0) {
          res.data.object.QueryItems.forEach((item, index) => {
            this.s_attr_id.push([])
            this.s_attr.push([])
            this.s_attr_falg.push({ arr: [] })
            item.attrs.forEach((i, d) => {
              this.s_attr_falg[index].arr.push(false)
            })
          });
        }
      }
    },
    delHtmlItem (idx, valIdx, i, id) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      this.s_attr[i].splice(id, 1)
      this.s_attr_id[i].splice(id, 1)
    },
    //参数属性单击
    attrSelect (name, idx, val, valIdx, length) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      if (this.s_attr_id[idx].indexOf(valIdx) >= 0) {
        let delIdx = this.s_attr_id[idx].indexOf(valIdx)
        this.s_attr_id[idx].splice(delIdx, 1)
        this.s_attr[idx].splice(delIdx, 1)
      } else {
        this.s_attr_id[idx].push(valIdx)
        this.s_attr[idx].push({ name: name, idx: idx, val: val, valIdx: valIdx })
      }
    },
  },
}
</script>
<style lang="scss">
</style>