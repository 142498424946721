<template>
  <div class="banxin shoppingCart">
    <template>
      <div class="order_success">
        <img src="@/assets/images/cart/success2.png">
        <h3>库存锁仓中...</h3>
        <p class="one" style="display:block;text-align:center">您的订单已经提交成功，商家会确认您的订单后给您发货，请保持电话通畅，如有紧急订单，请<a href="javascript:;" @click="clickPopBox">联系客服</a>，我们会第一时间处理您的订单</p>
        <div class="btn">
          <router-link to="/product/level" class="submit">继续购物</router-link>
          <router-link to="/home/order/list" class="back">查看订单</router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
//import QRCode from 'qrcodejs2'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '订单支付-合晶芯城',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  components: {
  },
  data () {
    return {
      params: {
        tradeNo: '',
        totalFee: ''
      }
    }
  },

  created () {
    this.params.tradeNo = this.$router.currentRoute.query.no
    let m = this.$router.currentRoute.query.m
    if (m) {
      this.params.totalFee = m.replace('_', '.')
    }
  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    clickPopBox () { window.open("https://hm.baidu.com/hm.js?30d5f0cd8e30265492389905f69cf896", "", "height=600px,width=800px") },
  },
  mounted () {

  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/cart-pay.scss";
.shoppingCart {
  padding-top: 1px;
  min-height: calc(100vh - 80px - 312px);
}
</style>