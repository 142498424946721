<template>
  <div class="userMain">
    <!-- 头部组件 -->
    <keepAlive>
      <Header></Header>
    </keepAlive>
    <div class="banxin clear userCenter">
      <!-- <template v-if="userInfo"> -->
      <!-- 左侧导航 -->
      <keepAlive>
        <LeftNav></LeftNav>
      </keepAlive>
      <router-view />
      <!-- </template> -->
    </div>
    <!-- 底部组件 -->
    <keepAlive>
      <Footer></Footer>
    </keepAlive>

    <!-- 右侧边栏浮动 -->
    <!-- <keepAlive>
      <rightBar></rightBar>
    </keepAlive> -->
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Header from '@/components/public/userHeader.vue'
import LeftNav from '@/components/public/userNavLeft.vue'
import Footer from '@/components/public/pBottom.vue'
export default {
  name: 'app',
  // async asyncData ({ store }) {
  //   let getFriendLink = await store.dispatch('lxr/get_friend_link');
  //   return getFriendLink
  // },
  components: {
    Header,
    LeftNav,
    Footer
  },
  data () {
    return {
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['token'])
  },
  methods: {
    ...mapMutations('lxr', ['SET_TOKEN', 'SET_USERINFO']),
  },
  mounted () {
    if (!this.token) {
      this.SET_TOKEN(sessionStorage.getItem('access-token'))
    }
    if (!this.userInfo) {
      let userInfo = window.localStorage.getItem("userInfo")
      this.SET_USERINFO(JSON.parse(userInfo))
    }
  },
  watch: {
  }
}
</script>
<style lang="scss">
@import "~@/assets/css/member-left.scss";
.userMain {
  min-height: 100vh;
  background: #f5f5f5;
}
</style>


