<template>
  <div class="r userCenter_container_box">
    <h2 class="ptitle">兑换详情<router-link :to="{path:'/home/user/dh'}" class="return">返回</router-link>
    </h2>
    <div class="userCenter_container integral-dh-d" v-loading='loading'>
      <template v-if="datas">
        <img :src="datas.image">
        <table>
          <tbody>
            <tr>
              <td>物品名称：</td>
              <td>{{datas.name}}</td>
            </tr>
            <tr>
              <td>物品面值：</td>
              <td>￥{{datas.amount}}</td>
            </tr>
            <tr>
              <td>消耗积分：</td>
              <td>{{datas.exchangePoint}}</td>
            </tr>
            <tr>
              <td>兑换时间：</td>
              <td>{{datas.createTime | setTime}}</td>
            </tr>
            <tr>
              <td>物品卡号：</td>
              <td>{{datas.cartNo}}</td>
            </tr>
            <tr>
              <td>物品卡密：</td>
              <td>{{datas.cartKey}}</td>
            </tr>
          </tbody>
        </table>
      </template>

    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-积分兑换详情',
    }
  },
  data () {
    return {
      params: {
        id: this.$route.query.id
      },
      datas: null,
      loading: true
    }
  },
  created () {
    if (this.params.id) {
      this.getCardD()
    } else {
      this.$message.error('读取详情异常，已为您返回列表')
      this.$router.push({
        path: '/home/user/dh'
      })
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('lxr', ['get_card_d']),
    async getCardD () {
      this.loading = true
      let res = await this.get_card_d(this.params)
      if (res.httpCode == 200) {
        this.datas = res.data
      }
      this.loading = false
    }
  },
  mounted () {
  },
  filters: {
    setTime: function (val) {
      val = val.substring(0, val.length - 2)
      return val
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-points.scss";
.current {
  color: #0067ed;
}
</style>


