<template>
  <div class="login-wrap" v-if="ifLoginFalg">
    <div class="info_top_bg">
      <div class="info_top clearfix">
        <h1 class="fl">
          <router-link to="/">
            <img height="44" src="@/assets/images/login/logo.svg">
          </router-link>
        </h1>
        <div class="fl bt">
          <span class="name" id="top-name">用户登录</span>
        </div>
        <router-link class="r" to="/index">商城首页</router-link>
      </div>
    </div>
    <div class="login-con">
      <img class="login-bg" src="@/assets/images/login/login-bg.png" alt="">
      <div class="con-info">
        <div class="l-nav">
          <a href="javascript:;" :class="{current:navIdx==2}" @click="navClickFun(2)">账户登录</a>
          <a href="javascript:;" :class="{current:navIdx==1}" @click="navClickFun(1)">免密登录</a>
          <a href="javascript:;" @click="wxLogin">扫码登录</a>
        </div>
        <div class="nav-con">
          <div class="inp-wrap">
            <input class="inp-phone" autocomplete="off" comfirm="2" type="text" maxlength="11" placeholder="请输入手机号码" v-model.trim="params.mobile" @blur="phoneBlur()" @focus="phoneErr=''">
            <div class="err">{{phoneErr}}</div>
          </div>
          <div class="inp-wrap" v-if="navIdx==1">
            <input class="inp-code" type="text" autocomplete="off" placeholder="请输入短信验证码" maxlength="6" v-model.trim="params.code" @keyup.enter="loginFun" @blur="codeBlur()" @focus="codeErr=''">
            <div class="err">{{codeErr}}</div>
            <span id="send" v-show="!sendFalg" @click="sendSms">发送验证码</span>
            <span id="send" v-show="sendFalg">重新发送(<i ref="timeNum">60</i>)</span>
          </div>
          <div class="inp-wrap" v-if="navIdx==2">
            <input name="password" type="password" class="inp-password" autocomplete="off" placeholder="请输入密码" maxlength="20" v-model.trim="params.password" @keyup.enter="loginFun" @blur="passBlur()" @focus="passErr=''">
            <div class="err">{{passErr}}</div>
          </div>
          <div class="inp-wrap">
            <button id="login" @click="loginFun">登录</button>
          </div>
          <div class="other-wrap">
            <router-link to="/reset">忘记密码</router-link>
            <router-link to="/register">免费注册</router-link>
          </div>
        </div>
      </div>
    </div>
    <loginFooter></loginFooter>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import loginFooter from '@/views/login/loginFooter.vue'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '登录',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二极管,晶体管,电阻,电容,集成电路,电子元器件网上商城'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内厂家直营二极管、晶体管、电阻、电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  components: {
    loginFooter
  },
  data () {
    return {
      phoneErr: '',
      codeErr: '',
      passErr: '',
      navIdx: 2,
      params: {
        mobile: '',
        password: '',
        code: ''
      },
      sendFalg: false,
      mobileStatus: false,
      passwordStatus: false,
      codeStatus: false,
      status: false,
      time: '',
      ifLoginFalg: false
    }
  },

  created () {
    this.isLogin()
  },
  computed: {
    // ...mapState('lxr', ['token'])
  },
  methods: {
    ...mapActions('lxr', ['password_login', 'phoneCode_login', 'send_sms', 'wx_login', 'is_login', 'get_cart_list']),
    ...mapMutations('lxr', ['SET_TOKEN']),
    phoneBlur () {
      if (this.params.mobile.length == 11) {
        this.mobileStatus = true
        this.phoneErr = ''
      } else {
        this.mobileStatus = false
        if (this.params.mobile) {
          this.phoneErr = '手机号码填写错误'
        }
      }
      this.check()
    },
    codeBlur () {
      if (this.params.code.length == 6) {
        this.codeStatus = true
        this.codeErr = ''
      } else {
        this.codeStatus = false
        if (this.params.code) {
          this.codeErr = '短信验证码填写错误'
        }
      }
      this.check()
    },
    passBlur () {
      if (this.params.password.length >= 6 && this.params.password.length <= 20) {
        this.passwordStatus = true
        this.passErr = ''
      } else {
        this.passwordStatus = false
        if (this.params.password) {
          this.passErr = '登录密码填写错误'
        }
      }
      this.check()
    },

    async getCartList () {
      let res = await this.get_cart_list()
    },
    navClickFun (idx) {
      this.navIdx = idx
      this.mobileStatus = false
      this.passwordStatus = false
      this.codeStatus = false
      this.phoneErr = ''
      this.codeErr = ''
      this.passErr = ''
    },

    //查询用户是否登录
    async isLogin () {
      let res = await this.is_login()
      this.ifLoginFalg = true
      if (res.httpCode == 200) {
        this.$router.push({ path: '/home/user' })
      }
    },

    //发送短信
    async sendSms () {
      let sendCode = 60

      if (!this.mobileStatus) {
        return this.phoneErr = '手机号码填写错误'
      }
      let res = await this.send_sms({ mobile: this.params.mobile })
      if (res.httpCode == 200) {
        this.sendFalg = true
        this.time = setInterval(() => {
          sendCode--
          this.$refs.timeNum.innerText = sendCode
          if (sendCode == 0) {
            clearInterval(this.time)
            this.sendFalg = false
            this.$refs.timeNum.innerText = '60'
          }
        }, 1000);
      } else {
        this.$message.error(res.message)
      }
    },

    //登录
    async loginFun () {
      let res;
      this.phoneBlur()
      this.codeBlur()
      this.passBlur()
      if (!this.status) {
        if (!this.mobileStatus) {
          return this.phoneErr = '手机号码填写错误'
        }
        if (this.navIdx == 1) {
          if (!this.codeStatus) {
            return this.codeErr = '短信验证码填写错误'
          }
        } else {
          if (!this.passwordStatus) {
            return this.passErr = '登录密码填写错误'
          }
        }
      }
      if (this.navIdx == 1) {
        res = await this.phoneCode_login(this.params)
      } else {
        res = await this.password_login(this.params)
      }

      if (res.httpCode == 200) {
        let token = res.data.modifyTime.replace(/access-token:/g, '')
        window.localStorage.setItem("userInfo", JSON.stringify(res.data))
        if (token) {
          window.localStorage.setItem("access-token", token)
          this.SET_TOKEN(token)
        }

        if (this.$route.query.redirect) {
          this.$router.push(decodeURIComponent(this.$route.query.redirect));
        } else {
          this.$router.push('/home/user');
        }
        this.getCartList()
      } else {
        if (this.navIdx == 1) {
          this.codeErr = res.message
        } else {
          this.passErr = res.message
        }
      }
    },

    //微信授权登录
    async wxLogin () {
      let res = await this.wx_login()
      if (res.httpCode == 200) {
        sessionStorage.removeItem("code")
        var a = document.createElement('a')
        a.target = '_blank'
        a.href = res.data
        a.click()
        // window.open(res.data)
      } else {
        this.$message.error(res.message)
      }
    },
    check () {
      if (this.navIdx == 1) {
        if (this.mobileStatus && this.params.code == 6) {
          this.status = true
        } else {
          this.status = false
        }
      } else {
        if (this.mobileStatus && this.params.code >= 6 && this.params.code <= 20) {
          this.status = true
        } else {
          this.status = false
        }
      }
    }
  },
  mounted () {

  },
  watch: {
    // 'params.mobile' (val) {
    //   if (val.length == 11) {
    //     this.mobileStatus = true
    //   } else {
    //     this.mobileStatus = false
    //   }
    //   this.check()
    // },
    // 'params.password' (val) {
    //   if (val.length >= 6 && val.length <= 20) {
    //     this.passwordStatus = true
    //   } else {
    //     this.passwordStatus = false
    //   }
    //   this.check()
    // },
    // 'params.code' (val) {
    //   if (val.length == 6) {
    //     this.codeStatus = true
    //   } else {
    //     this.codeStatus = false
    //   }
    //   this.check()
    // }
  }, beforeDestroy () {
    clearInterval(this.time)
  },
}
</script>
<style lang="scss">
</style>
<style lang="scss">
</style>


