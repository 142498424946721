<template>
  <div class="r userCenter_container_box">
    <h2 class="text_r">
      <span class="l">订单详情</span>
      <router-link class="r" to="/home/order/list">返回</router-link>
    </h2>
    <div class="userCenter_container orderDetail" v-loading="isloading">
      <template v-if="info">

        <table class="base_info">
          <tbody>
            <tr>
              <th colspan="5">基本信息</th>
            </tr>
            <tr class="first">
              <td><i>订单号</i><br><span id="order_sn">{{info.orderSn}}</span></td>
              <td><i>用户名</i><br>{{userInfo.nickname}}</td>
              <td><i>支付状态</i><br>
                {{info.payStatus==0?'未支付':info.payStatus==1?'已付款':info.payStatus==2?'已付款':'付款失败'}}
              </td>
              <td><i>支付方式</i><br>{{info.payName!=0?info.payName:'-'}}</td>
              <td><i>下单时间：</i><br>{{info.createTime | calcTime}}</td>
            </tr>
            <tr>
              <td><i>提货时间or发货时间</i><br>{{info.shippingTime!=0?info.shippingTime:'-'}}</td>
              <td><i>订单状态</i><br>
                <template v-if="info.orderStatus==-2">
                  审核不通过
                </template>
                <template v-if="info.orderStatus==-1">
                  待审核
                </template>
                <template v-if="info.orderStatus==0">
                  待付款
                </template>
                <template v-if="info.orderStatus==1">
                  待发货
                </template>
                <template v-if="info.orderStatus==2">
                  已发货
                </template>
                <template v-if="info.orderStatus==3">
                  已完成
                </template>
                <template v-if="info.orderStatus==4">
                  <i>已取消</i>
                </template>
              </td>
              <td><i>配送方式</i><br>
                <template v-if="info.isSample==1">
                  邮寄到付
                </template>
                <template v-else-if="info.giveType==1">
                  {{info.shippingName}}
                </template>
                <template v-else>
                  -
                </template>
              </td>
              <td><i>付款时间</i><br>{{info.payTime!=0?info.payTime:'-'}}</td>
              <td>
                <i>提货码or发货单号</i><br>{{info.invoiceNo || '-'}}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="product-item">
          <div class="orderM_title clear">
            <span class="one">商品信息</span>
            <span class="two">我的料号</span>
            <span class="three">单价(含增值税)</span>
            <span class="three">数量(pcs)</span>
            <span class="four">金额(含增值税)</span>
          </div>

          <div class="order_body clear">
            <ul class="clear" v-if="info.hktOrderGoods&&info.hktOrderGoods.length>0">
              <li class="clear" v-for="(i, idx) in info.hktOrderGoods" :key="idx">
                <img v-if="i.goodsImg" :src="i.goodsImg">
                <img v-else src="@/assets/images/goods/default_img.png">
                <div class="order-list l">
                  <p class="title">
                    <router-link :to="{path:'/product/detail',query:{id:i.goodsId}}">{{i.goodsName}}</router-link>
                  </p>
                  <p class="lh">制造商料号：{{i.materialSn}}</p>
                  <div class="xx">
                    <span>{{i.goodsCategoryName}}</span>
                    <i class="brand_name_u">更多信息</i>
                    <div class="brand_details">
                      <em></em>
                      <div>品牌: {{i.brandName}} &nbsp;&nbsp;参数规格: {{i.specParam}}</div>
                    </div>
                  </div>
                </div>
                <p class="order-wl l"><i></i>{{i.customerSn}}</p>
                <p class="order-dj l">￥{{i.goodsPrice}}</p>
                <p class="order-num l">
                  <span>×{{i.goodsNum}} pcs</span>
                  <router-link :to="{path:'/product/detail',query:{id:i.goodsId}}" class="getonce confirmReceipt">再次购买</router-link>
                </p>
                <p class="order-litotal l">
                  <!--<span class="unline">￥286.00</span>-->
                  <span>￥{{i.goodsAmount}}</span>
                </p>
              </li>
            </ul>
            <div class="order-body-total clear">
              <span class="">原价:<em>￥{{info.totalAmount | calcPriceFun}}</em>订单总金额（运费：<i>¥{{info.shippingPrice}}</i>）：</span>
              <span class="order-total-price">￥{{info.totalAmount | calcPriceFun}}</span>
            </div>
            <div class="order-body-status clear" v-if="info.orderStatus!=4">
              <p class="l">
                <span class="wfh">
                  <template v-if="info.orderStatus==-2">
                    审核不通过
                  </template>
                  <template v-if="info.orderStatus==-1">
                    待审核
                  </template>
                  <template v-if="info.orderStatus==0">
                    待付款
                  </template>
                  <template v-if="info.orderStatus==1">
                    待发货
                  </template>
                  <template v-if="info.orderStatus==2">
                    已发货
                  </template>
                  <template v-if="info.orderStatus==3">
                    已完成
                  </template>
                  <template v-if="info.orderStatus==4">
                    <i>已取消</i>
                  </template>
                </span>
              </p>
              <p class="r">
                <router-link v-if="info.orderStatus==0" to="/order/pay" class="btn gopay">去付款</router-link>
                <a href="javascript:;" v-if="info.orderStatus!=4&&info.orderStatus!=5&&info.orderStatus<1" @click="cancelOrder(info.id)" class="btn cancel">取消订单</a>
              </p>
            </div>
          </div>
        </div>

        <table v-if="info.giveType==2&&md">
          <tr>
            <th colspan="4">门店信息</th>
          </tr>
          <tr class="first">
            <td><i>门店名称</i><br>{{md.name}}</td>
            <td><i>门店号码</i><br>{{md.mobile}}</td>
            <td colspan="2"><i>提货地址</i><br>{{md.province}}{{md.city}}{{md.district}}{{md.addr}}</td>
          </tr>
        </table>
        <table v-if="info.giveType==1">
          <tbody>
            <tr>
              <th colspan="4">收货人信息</th>
            </tr>
            <tr class="first">
              <td><i>收货人</i><br>{{info.contacts}}</td>
              <td><i>手机号码</i><br>{{info.contactsMobile}}</td>
              <td colspan="2"><i>收货地址</i><br>{{info.province}}{{info.city}}{{info.district}}{{info.address}}</td>
            </tr>
          </tbody>
        </table>

        <!--发票信息-->
        <table v-if="invoice&&invoice.category!=0">
          <tbody>
            <tr>
              <th colspan="5">发票信息</th>
            </tr>
            <tr class="first">
              <td><i>发票类型</i><br>{{invoice.category==1?'普通发票':'增值税专用发票'}}（{{invoice.type==1&&invoice.category!=2?'电子发票':'纸质发票'}}）</td>
              <td><i>发票抬头</i><br>{{invoice.title}}</td>
              <td><i>收票人电话</i><br>{{invoice.collectMobile}}</td>
              <td><i>发票内容</i><br>电子元器件</td>
            </tr>
            <tr>
              <td><i>开票状态</i><br>
                {{invoice.status==1?'已开票':'未开票'}}
              </td>
              <td><i>纳税人识别号</i><br>
                {{invoice.openTaxpayer}}
              </td>
              <td v-if="invoice.type==2&&invoice.category!=2">
                <i>收票人邮箱</i><br>
                <template v-if="invoice.collectEmail">
                  {{invoice.collectEmail}}
                </template>
                <template else>&nbsp;</template>
              </td>
              <!-- <td v-if="invoice.type==1"><i>操作</i><br>
                <template v-if="invoice.status==1">
                  <router-link :to="invoice.invoiceUrl" class="lookInvoice">查看电子发票</router-link>
                </template>
                <template else>&nbsp;</template>
              </td> -->
              <td v-if="invoice.type==2||invoice.category==2">
                <i>收票地址</i><br>
                {{invoice.collectAddress}}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>备注信息</th>
            </tr>
            <tr class="remark">
              <td>{{info.userNote}}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '订单详情',
    }
  },
  data () {
    return {
      info: null,
      isloading: true,
      params: {
        orderSn: ''
      },
      md: null,
      invoice: null,
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['userInfo'])
  },
  methods: {
    ...mapActions('lxr', ['get_order_detail', 'get_id_md', 'get_order_invoice', 'cancel_order']),
    async getIdMd (id) {
      let res = await this.get_id_md({ id: id })
      if (res.httpCode == 200) {
        this.md = res.data
      }
    },
    async getOrderInvoice (id) {
      let res = await this.get_order_invoice({ orderId: id })
      if (res.httpCode == 200) {
        this.invoice = res.data
      }
    },
    async getOrderDetail () {
      let res = await this.get_order_detail(this.params)
      if (res.httpCode == 200) {
        this.info = res.data
        this.getOrderInvoice(res.data.id)
        this.getIdMd(res.data.warehouseId)
        this.isloading = false
      }
    },
    cancelOrder (id) {
      this.$confirm("确定取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.cancel(id)
        })
        .catch(() => { });
    },

    async cancel (id) {
      let res = await this.cancel_order({ id: id })
      if (res.httpCode == 200) {
        this.getOrderDetail()
      }
    },
  },
  mounted () {
    this.params.orderSn = this.$route.query.id
    this.getOrderDetail()
  },
  filters: {
    calcTime (val) {
      return val.substr(0, val.length - 5)
    },
    calcPriceFun (val) {
      return parseFloat(val).toFixed(2);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-orderDetail2.scss";
.brand_details > em {
  left: 105px;
}
</style>


