<template>
  <div class="hkt_container clear">
    <template v-if="productDetail">

      <!--小球购物车-->
      <div class="ball-container">
        <!--小球-->
        <div v-for="(ball,index) in balls" :key="index">
          <transition name="drop" @before-enter="beforeDrop" @enter="dropping" @after-enter="afterDrop">
            <div class="ball" v-show="ball.show">
              <div class="inner inner-hook"></div>
            </div>
          </transition>
        </div>
      </div>
      <!--小球购物车-->

      <div class="primary_search_title clear">
        <span class="l first">
          <router-link class="first" to="/product/level">全部商品</router-link>&gt;
        </span>
        <span class="l">
          <router-link :to="{path:'/product/level',query:{cname:productDetail.parentCategoryName,cid:productDetail.parentCategoryId}}">{{productDetail.parentCategoryName}}</router-link>&gt;
        </span>
        <span class="l">
          <router-link :to="{path:'/product/level',query:{cname:productDetail.parentCategoryName,cid:productDetail.categoryId,fcid:productDetail.parentCategoryId,name:productDetail.categoryName}}">{{productDetail.categoryName}}</router-link>&gt;
        </span>
        <span class="l last">
          <i>{{productDetail.name}}</i>
        </span>
      </div>

      <div class="product_detail_contont">
        <div class="product_detail_mainCon">
          <div class="p_logo">
            <img v-if="productDetail.goodsImg" :src="productDetail.goodsImg" />
            <img v-else src="@/assets/images/brand/default.png" :alt="productDetail.name" />
            <div class="collection" :class="{active:productDetail.collect==1}" @click="getFavorites">
              <!-- collect -->
              <a href="javascript:;">{{productDetail.collect==1?'取消收藏':'收藏商品'}}</a>
            </div>
          </div>

          <div class="p_info">
            <h3 class="p_title">{{productDetail.name}}</h3>
            <div class="info_d clearfix">
              <table>
                <tbody>
                  <tr>
                    <td>
                      商品型号：{{productDetail.materialSn}}
                    </td>
                    <td>
                      商品编码：{{productDetail.ksn}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      商品品牌：{{productDetail.brandName}}
                    </td>
                    <td>
                      起&nbsp;&nbsp;订&nbsp;&nbsp;量：{{productDetail.packCount}} {{productDetail.storeCompany}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      封装规格：{{productDetail.packageSpecification}}
                    </td>
                    <td>
                      商品库存：有货
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      规格参数：
                      <template v-if="productDetail.attrs&&productDetail.attrs.length>0">
                        <span v-for="(item, index) in productDetail.attrs" :key="index">
                          {{item.attrId}}:{{item.attrValue}}&nbsp;&nbsp;
                        </span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="info_buy clearfix">
              <div class="fl">
                <div class="line">
                  <span class="title fl">交货周期：</span>{{productDetail.deliveryNo}}
                </div>
                <div class="line">
                  <span class="title fl">交货仓库：</span>深圳仓
                </div>
                <div class="line clearfix">
                  <span class="title fl">购买数量：</span>
                  <div class="input_box fl">
                    <input type="text" min="1" @blur="dis" maxlength="7" placeholder="购买数量" id="pcsNum" onkeyup="value=value.replace(/[^\d]/ig,'')" />
                    <i>pcs</i>
                  </div>
                  <span class="cf fl">×</span>
                  <span class="error fl">￥{{pri}}/pcs</span>
                </div>
                <div class="line">
                  <span class="title fl">总计金额：</span><span class="error">￥{{xPrice}}</span>
                </div>
              </div>
              <div class="priceTemplate fr">
                <table>
                  <tbody>
                    <tr>
                      <th>采购数量</th>
                      <th>单价 (含13%增值税)</th>
                    </tr>
                    <template v-if="productDetail.priceTemplate.length >0">
                      <tr v-for="(v,indexp) in productDetail.priceTemplate" :key="indexp">
                        <td>{{v.num}}+</td>
                        <td>￥{{v.price }}/pcs</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="buy_btn">
              <span class="cart_btn" @click="addShopCart($event,'')">加入购物车</span>
              <span class="link_cart_btn" data-url="/cart/order/index.html" @click="addShopCart($event,'1')">立即购买</span>
              <span v-if="productDetail.isSample==1" class="sample_btn" @click="goSample(productDetail.id)">申请样品</span>
            </div>
          </div>
        </div>
        <div class="product_detail_other">
          <div class="nav_tab">
            <span :class="{current:navIdx==0}" @click="navIdxFun(0)">商品详情</span>
            <span :class="{current:navIdx==1}" @click="navIdxFun(1)">PDF数据手册</span>
          </div>
          <div class="nav_con" v-if="navIdx==0">
            <table class="params">
              <tbody>
                <tr>
                  <td>商品类别</td>
                  <td>{{productDetail.categoryName}}</td>
                </tr>
                <tr>
                  <td>包装数量</td>
                  <td>{{productDetail.packCount}} {{productDetail.storeCompany}}</td>
                </tr>
                <tr>
                  <td>商品毛重</td>
                  <td>--</td>
                </tr>
                <template v-if="productDetail.attrs&&productDetail.attrs.length>0">
                  <tr v-for="(item, index) in productDetail.attrs" :key="index">
                    <td>{{item.attrId}}</td>
                    <td>{{item.attrValue}}</td>
                  </tr>
                </template>
                <tr>
                  <td>产品系列</td>
                  <td>--</td>
                </tr>
                <tr>
                  <td>行业应用</td>
                  <td>{{productDetail.industryId || '--'}}</td>
                </tr>
                <tr>
                  <td>技术应用</td>
                  <td>{{productDetail.technologyId || '--'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="nav_con" v-else style="background:#000">
            <!-- <vuePdf :src="productDetail.goodsManual"></vuePdf> -->
            <!-- <no-ssr> -->
            <!-- {{productDetail.goodsManual}} -->
            <vuePdf :src="productDetail.goodsManual" v-for="i in numPages" :key="i" :page="i"></vuePdf>
            <!-- {{currentPage}} / {{pageCount}}
              <vuePdf :src="productDetail.goodsManual" @num-pages="pageCount = $event" @page-loaded="currentPage = $event"></vuePdf> -->
            <!-- </no-ssr> -->
            <!-- <div>
                <input type="checkbox" v-model="show">
                <select v-model="src" style="width: 30em">
                  <option v-for="item in pdfList" :value="item" v-text="item"></option>
                </select>
                <input v-model.number="page" type="number" style="width: 5em"> /{{numPages}}
                <button @click="rotate += 90">&#x27F3;</button>
                <button @click="rotate -= 90">&#x27F2;</button>
                <button @click="$refs.pdf.print()">print</button>
                <div style="width: 50%">
                  <div v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>
                  <vuePdf v-if="show" ref="pdf" style="border: 1px solid red" :src="src" :page="page" :rotate="rotate" @password="password" @progress="loadedRatio = $event" @error="error" @num-pages="numPages = $event" @link-clicked="page = $event"></vuePdf>
                </div>
              </div> -->
          </div>
        </div>
      </div>

      <div class="goods_desc clear" v-if="false">
        <div class="goods_info l">
          <div class="l product-box-div">
            <p>
              <em>型&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号：</em>
              <i>{{productDetail.materialSn}}</i>
            </p>
            <p>
              <em>编&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码：</em>
              <i>{{productDetail.ksn}}</i>
            </p>
            <p>
              <em>封 装 规 格：</em>
              <i>{{productDetail.packageSpecification}}</i>
            </p>
            <p>
              <em>包 装 数 量：</em>
              <i>{{productDetail.packCount}} {{productDetail.storeCompany}}</i>
            </p>
            <p>
              <em>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌：</em>
              <i>{{productDetail.brandName}}</i>
            </p>
            <p class="pdf">
              <em>数 据 手 册：</em>
              <template v-if="productDetail.goodsManual">
                <a class="openpdf" :href="productDetail.goodsManual" target="_blank">在线查看</a> |
                <a :href="productDetail.goodsManual" target="_blank">下载</a>
              </template>
              <a v-else href="javascript:;">无</a>
            </p>
          </div>
          <div class="l product-box-div">
            <p>
              <em>商 品 类 别：</em>
              <i>{{productDetail.categoryName}}</i>
            </p>
            <p>
              <em>库 存 数 量：</em>
              <!-- <i class="strong">{{productDetail.storeCount}} {{productDetail.storeCompany}}</i> -->
              <i class="strong">现货</i>
            </p>
            <p>
              <em>交 货 周 期：</em>
              <i class="strong" style="color:#E0212F">{{productDetail.deliveryNo}}</i>
            </p>
            <p>
              <em>最小起订量：</em>
              <i class="strong">{{productDetail.packCount}} {{productDetail.storeCompany}}</i>
            </p>
            <p>
              <em>商品毛重量：</em>
              <i class="strong">-</i>
            </p>
            <p class="db">
              <span v-if="favorites!=null" id="collection" class="collection" :class="{active:favorites}" @click="getFavorites">
                <i></i>
                <a href="javascript:;">{{favorites?'已收藏':'收藏商品'}}</a>
              </span>
            </p>
          </div>
        </div>
        <div class="inventory_price r" v-if="productDetail.priceTemplate&&productDetail.priceTemplate.length>0">
          <table>
            <tbody>
              <tr>
                <th>采购数量</th>
                <th>单价(含13%增值税)</th>
                <th v-if="productDetail.priceTemplate[0].oldPrice">原价</th>
              </tr>
              <tr v-for="(item, index) in productDetail.priceTemplate" :key="index">
                <td>{{item.num}}+</td>
                <td class="error">￥{{item.price}}/pcs</td>
                <td class="priceOld" v-if="item.oldPrice">￥{{item.oldPrice}}/{{productDetail.storeCompany}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="purchase_box" v-if="false">
        <div class="clear purchase_fill">
          <div class="l">
            <!-- <div class="l inpu_box">
              <input type="text" v-model.trim="panNum" @blur="discNum" min="1" placeholder="整盘购" id="discNum" data-goods="30516" style="width: 120px;" />
              <i>盘</i>
            </div> -->
            <div class="l inpu_box inpu_two_box">
              <input type="text" min="1" @blur="dis" maxlength="7" placeholder="输入散装购买数量" id="pcsNum" onkeyup="value=value.replace(/[^\d]/ig,'')" />
              <i>pcs</i>
              <div class="remind_box" v-show="know">
                <div class="remind_box_top">
                  <p id="total_pcs" data-_type>
                    您购买该产品的数量：
                    <span>
                      {{panNum}}✖{{productDetail.packCount}}pcs={{panNum*productDetail.packCount}} pcs
                      <br />数量自动计为包装数量的整数倍
                    </span>
                  </p>
                  <span class="know" @click="close">我知道了</span>
                </div>
                <div class="remind_box_btm">
                  <em></em>
                </div>
              </div>
            </div>
            <div class="l total_box">
              <p id="goods_price_id">
                <span class="cf">×</span>阶梯单价：
                <span class="error">￥{{pri}}/pcs</span>
              </p>
            </div>
          </div>
          <div class="r">
            <!-- <span class="oldtotal l" id="old_goods_price" data="15.602000">
              原价：
              <i>￥{{oldPrice}}</i>
            </span> -->
            <span class="total l" id="new_goods_price" data="14.98">
              合计：
              <i>￥{{xPrice}}</i>
            </span>
            <span class="submit l custom_add_cart" id="custom_add_cart_u_event" @click="addShopCart($event,'')">加入购物车</span>
            <span class="createOrder l custom_add_cart_order" data-url="/cart/order/index.html" @click="addShopCart($event,'1')">立即购买</span>
            <span v-if="productDetail.isSample==1" class="submit2 l custom_add_cart" id="custom_add_cart_u_event" @click="goSample(productDetail.id)">申请样品</span>
          </div>
        </div>
      </div>

      <div class="table_manual_box" v-if="false">
        <div class="clear table_manual_body">
          <div class="specification_table">
            <div class="t_title clear">
              <h3 class="l">规格参数</h3>
            </div>
            <table>
              <tbody>
                <tr class="t_tr">
                  <td class="t_tds" width="180">商品类别</td>
                  <td class="t_td">{{productDetail.categoryName}}</td>
                  <td class="t_tds" width="180">品牌</td>
                  <td class="t_td">{{productDetail.brandName}}</td>
                </tr>
                <tr class="t_tr">
                  <td class="t_tds" width="180">技术应用</td>
                  <td class="t_td">{{productDetail.technologyId || '-'}}</td>
                  <td class="t_tds" width="180">物料编号</td>
                  <td class="t_td">{{productDetail.materialSn}}</td>
                </tr>
                <template v-if="productDetail.attrs&&productDetail.attrs.length>0">
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=1">
                      <td class="t_tds" width="180">{{productDetail.attrs[0].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[0].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=2">
                      <td class="t_tds" width="180">{{productDetail.attrs[1].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[1].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=3">
                      <td class="t_tds" width="180">{{productDetail.attrs[2].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[2].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=4">
                      <td class="t_tds" width="180">{{productDetail.attrs[3].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[3].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=5">
                      <td class="t_tds" width="180">{{productDetail.attrs[4].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[4].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=6">
                      <td class="t_tds" width="180">{{productDetail.attrs[5].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[5].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=7">
                      <td class="t_tds" width="180">{{productDetail.attrs[6].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[6].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=8">
                      <td class="t_tds" width="180">{{productDetail.attrs[7].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[7].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=9">
                      <td class="t_tds" width="180">{{productDetail.attrs[8].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[8].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=10">
                      <td class="t_tds" width="180">{{productDetail.attrs[9].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[9].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=11">
                      <td class="t_tds" width="180">{{productDetail.attrs[10].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[10].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=12">
                      <td class="t_tds" width="180">{{productDetail.attrs[11].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[11].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=13">
                      <td class="t_tds" width="180">{{productDetail.attrs[12].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[12].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=14">
                      <td class="t_tds" width="180">{{productDetail.attrs[13].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[13].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=15">
                      <td class="t_tds" width="180">{{productDetail.attrs[14].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[14].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=16">
                      <td class="t_tds" width="180">{{productDetail.attrs[15].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[15].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=17">
                      <td class="t_tds" width="180">{{productDetail.attrs[16].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[16].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=18">
                      <td class="t_tds" width="180">{{productDetail.attrs[17].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[17].attrValue}}</td>
                    </template>
                  </tr>
                  <tr class="t_tr">
                    <template v-if="productDetail.attrs.length>=19">
                      <td class="t_tds" width="180">{{productDetail.attrs[18].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[18].attrValue}}</td>
                    </template>
                    <template v-if="productDetail.attrs.length>=20">
                      <td class="t_tds" width="180">{{productDetail.attrs[19].attrId}}</td>
                      <td class="t_td">{{productDetail.attrs[19].attrValue}}</td>
                    </template>
                  </tr>
                </template>

                <tr class="t_tr">
                  <td class="t_tds" width="180">产品系列</td>
                  <td class="t_td">-</td>
                  <td class="t_tds" width="180">行业应用</td>
                  <td class="t_td">{{productDetail.industryId || '-'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="same_goods_box">
            <div class="same_goods_topBg">
              <div class="same_goods_top">
                <span>相似的/相同规格的商品</span>
                <em>
                  共找到：
                  <i id="getAlikeTotal">{{productResembleTotal}}</i>
                </em>
                <p class="r">
                  <router-link :to="{path:'/search',query:{cid:productDetail.categoryId,spe:productDetail.specParam,v:productDetail.specParam,type:1}}">搜索相同规格商品 &gt;&gt;</router-link>
                  <span class="r">
                    <i class="pre" v-if="productResembleParams.pageNum>1" @click="resembleTurn(productResembleParams.pageNum-1)"></i>
                    <i class="pre" v-else></i>
                    <i class="next" v-if="productResembleParams.pageNum<productResembleTotalPage" @click="resembleTurn(productResembleParams.pageNum+1)"></i>
                    <i class="next" v-else></i>
                  </span>
                </p>
              </div>
            </div>
            <div class="same_goods_body" v-loading="isloading">
              <ul id="getAlike" class="clear" v-if="productResemble.length>0">
                <li v-for="(item, index) in productResemble" :key="index" :class="{l:index%2==0?true:false,r:index%2==0?false:true}">
                  <router-link :to="{path:'/product/detail',query:{id:item.id}}">
                    <img v-if="item.image" :src="item.image" :alt="item.name" />
                    <img v-else src="@/assets/images/brand/default.png" :alt="item.name" />
                    <p>
                      {{item.name}}
                      <span class="r">{{item.brandName}}</span>
                    </p>
                    <p class="last">
                      现货库存
                      <!-- :{{item.storeCount}} -->
                      <i>{{item.compare}}</i>
                      <i class="price">
                        ¥{{item.price}}/pcs
                        <span>起</span>
                      </i>
                    </p>
                  </router-link>
                </li>
              </ul>
            </div>
            <!-- 参数组值匹配为空 -->
            <div class="param_null_box" v-if="!isloading&&productResemble.length==0">
              <img src="@/assets/images/goods/noshop_1.png" />
              <p>很遗憾，这颗物料很特别，也许是定制物料，我们还没有找到可以替代的商品</p>
              <router-link :to="{path:'/index'}">&lt;返回首页浏览其他商品</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="others_recommend_box clear" v-if="false">
        <h3>买它的客户还买了</h3>
        <ul class="r other_goods_box" id="goodsRecommend" v-if="productOther.length>0">
          <li class="clear other_goods" v-for="(item, index) in productOther" :key="index">
            <img class="l" v-if="item.image" :src="item.image" />
            <img class="l" v-else src="@/assets/images/brand/default.png" :alt="item.name" />
            <div>
              <router-link :to="{path:'/product/detail',query:{id:item.id}}">
                <h4>{{item.name}}</h4>
              </router-link>
              <p>
                现货库存
                <!-- ：<i>{{item.storeCount}}</i> -->
              </p>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { priceFormat } from "@/plugins/ibox/utils";
var vuePdf;
if (typeof window !== 'undefined') {
  vuePdf = require('vue-pdf').default
}
// let pdf;
// if (typeof window !== 'undefined') {
//   import pdf from 'vue-pdf'
// }
// import jqueryFly from '@/assets/jquery.fly.min.js';
export default {
  name: "app",
  metaInfo () {
    return {
      title: this.productDetail ? this.productDetail.name : '',
      meta: [
        {
          name: 'keywords',
          content: this.productDetail ? this.productDetail.brandName + ' ' + this.productDetail.packageSpecification : ''
        },
        {
          name: 'description',
          content: '合晶芯城是电子元器件采购首选的一站式电商平台。拥有10000多平方米现代化元器件仓库，自营现货库存超100000种。满足各品牌原装正品电子元器件采购需求，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器等。'
        }
      ]
    }
  },
  asyncData ({ store, route }) {
    console.log(route.query.id)
    let getProductDetail = store.dispatch("lxr/get_product_detail", {
      id: route.query.id
    });
    return getProductDetail;
  },
  components: {
    vuePdf
  },
  data () {
    return {
      // show: true,
      // pdfList: [
      //   '',
      //   'https://cdn.mozilla.net/pdfjs/tracemonkey.pdf',
      //   'https://cdn.rawgit.com/mozilla/pdf.js/c6e8ca86/test/pdfs/freeculture.pdf',
      //   'https://cdn.rawgit.com/mozilla/pdf.js/c6e8ca86/test/pdfs/annotation-link-text-popup.pdf',
      //   'https://cdn.rawgit.com/mozilla/pdf.js/c6e8ca86/test/pdfs/calrgb.pdf',
      //   'https://cdn.rawgit.com/sayanee/angularjs-pdf/68066e85/example/pdf/relativity.protected.pdf',
      //   'data:application/pdf;base64,JVBERi0xLjUKJbXtrvsKMyAwIG9iago8PCAvTGVuZ3RoIDQgMCBSCiAgIC9GaWx0ZXIgL0ZsYXRlRGVjb2RlCj4+CnN0cmVhbQp4nE2NuwoCQQxF+/mK+wMbk5lkHl+wIFislmIhPhYEi10Lf9/MVgZCAufmZAkMppJ6+ZLUuFWsM3ZXxvzpFNaMYjEriqpCtbZSBOsDzw0zjqPHZYtTrEmz4eto7/0K54t7GfegOGCBbBdDH3+y2zsMsVERc9SoRkXORqKGJupS6/9OmMIUfgypJL4KZW5kc3RyZWFtCmVuZG9iago0IDAgb2JqCiAgIDEzOAplbmRvYmoKMiAwIG9iago8PAogICAvRXh0R1N0YXRlIDw8CiAgICAgIC9hMCA8PCAvQ0EgMC42MTE5ODcgL2NhIDAuNjExOTg3ID4+CiAgICAgIC9hMSA8PCAvQ0EgMSAvY2EgMSA+PgogICA+Pgo+PgplbmRvYmoKNSAwIG9iago8PCAvVHlwZSAvUGFnZQogICAvUGFyZW50IDEgMCBSCiAgIC9NZWRpYUJveCBbIDAgMCA1OTUuMjc1NTc0IDg0MS44ODk3NzEgXQogICAvQ29udGVudHMgMyAwIFIKICAgL0dyb3VwIDw8CiAgICAgIC9UeXBlIC9Hcm91cAogICAgICAvUyAvVHJhbnNwYXJlbmN5CiAgICAgIC9DUyAvRGV2aWNlUkdCCiAgID4+CiAgIC9SZXNvdXJjZXMgMiAwIFIKPj4KZW5kb2JqCjEgMCBvYmoKPDwgL1R5cGUgL1BhZ2VzCiAgIC9LaWRzIFsgNSAwIFIgXQogICAvQ291bnQgMQo+PgplbmRvYmoKNiAwIG9iago8PCAvQ3JlYXRvciAoY2Fpcm8gMS4xMS4yIChodHRwOi8vY2Fpcm9ncmFwaGljcy5vcmcpKQogICAvUHJvZHVjZXIgKGNhaXJvIDEuMTEuMiAoaHR0cDovL2NhaXJvZ3JhcGhpY3Mub3JnKSkKPj4KZW5kb2JqCjcgMCBvYmoKPDwgL1R5cGUgL0NhdGFsb2cKICAgL1BhZ2VzIDEgMCBSCj4+CmVuZG9iagp4cmVmCjAgOAowMDAwMDAwMDAwIDY1NTM1IGYgCjAwMDAwMDA1ODAgMDAwMDAgbiAKMDAwMDAwMDI1MiAwMDAwMCBuIAowMDAwMDAwMDE1IDAwMDAwIG4gCjAwMDAwMDAyMzAgMDAwMDAgbiAKMDAwMDAwMDM2NiAwMDAwMCBuIAowMDAwMDAwNjQ1IDAwMDAwIG4gCjAwMDAwMDA3NzIgMDAwMDAgbiAKdHJhaWxlcgo8PCAvU2l6ZSA4CiAgIC9Sb290IDcgMCBSCiAgIC9JbmZvIDYgMCBSCj4+CnN0YXJ0eHJlZgo4MjQKJSVFT0YK',
      // ],
      // src: '',
      // loadedRatio: 0,
      // page: 1,
      // numPages: 0,
      // rotate: 0,
      // currentPage: 0,
      // pageCount: 0,
      numPages: 0,
      src: '',
      navIdx: 0,
      isloading: true,
      params: {
        id: this.$route.query.id
      },
      productResemble: [],
      productResembleParams: {
        categoryId: '',
        pageNum: 1,
        pageSize: 6,
        specParam: "", //商品规格
        id: '', //商品id
        price: "" //商品价格
      },
      productResembleTotal: 0,
      productResembleTotalPage: 1,
      productOther: [],
      productOtherParams: {
        categoryId: '',
        pageNum: 1,
        pageSize: 9
      },
      favorites: null,
      token: null,
      know: false, // 数量提示语
      panNum: "",
      pri: "",
      oldPrice: "",
      oldPrice1: "",
      yPrice: "", // 原总计
      xPrice: "", // 现总计
      smallNum: "", // 最小包装数
      shopNumber: "", // 数量
      minNumber: "", // 数量最小值
      maxNumber: "", // 数量最大值
      maxOldPrice: "", // 最大值原单价
      maxPrice: "", // 最大值现单价
      productNew: "",
      count: 0,
      balls: [
        //小球 设为3个
        {
          show: false
        },
        {
          show: false
        },
        {
          show: false
        }
      ],
      dropBalls: []
    };
  },

  created () {
  },
  computed: {
    ...mapState("lxr", ["productDetail"])
  },
  methods: {
    ...mapActions("lxr", [
      "get_product_detail",
      "get_product_resemble",
      "get_product_other",
      "select_favorites_status",
      "get_favorites",
      "get_favorites_del", "get_cart_header", 'get_cart_list'
    ]),
    ...mapMutations("lxr", ["GET_PRODUCT_DETAIL", 'SET_CART_LENGTH', 'SET_PRODUCT_COLLECT']),
    ...mapActions("lw", ["addCart"]),
    password: function (updatePassword, reason) {

      updatePassword(prompt('password is "test"'));
    },
    error: function (err) {

      console.log(err);
    },
    pdfLoadTask () {
      this.src = vuePdf.createLoadingTask(this.productDetail.goodsManual)
      this.src.promise.then(pdf => {
        this.numPages = pdf.numPages;
      });
    },
    navIdxFun (idx) {
      this.navIdx = idx
      if (idx == 1) {
        this.pdfLoadTask()
      }
    },
    goSample (id) {
      this.$router.push({ path: '/sample/detail', query: { id: id } })
    },
    /*小球飞入 */
    additem (event) {
      //this.drop(event.target);
      this.count++;
    },
    drop (el) {
      //抛物
      for (let i = 0; i < this.balls.length; i++) {
        let ball = this.balls[i];
        if (!ball.show) {
          ball.show = true;
          ball.el = el;
          this.dropBalls.push(ball);
          return;
        }
      }
    },
    beforeDrop (el) {
      /* 购物车小球动画实现 */
      let count = this.balls.length;
      while (count--) {
        let ball = this.balls[count];
        if (ball.show) {
          let rect = ball.el.getBoundingClientRect(); // 获取小球的相对于视口的位移(小球高度)
          let x = -(window.innerWidth - rect.left - 500);
          let y = rect.top - 150; // 负数是从左上角往下的的方向, 正数是往上
          el.style.display = ""; // 清空display
          el.style.webkitTransform = `translate3d(0, ${y}px, 0)`;
          el.style.transform = `translate3d(0, ${y}px, 0)`;
          // 处理内层动画
          let inner = el.getElementsByClassName("inner-hook")[0]; // 使用inner-hook类来单纯被js操作
          inner.style.webkitTransform = `translate3d(${x}px, 0, 0)`;
          inner.style.transform = `translate3d(${x}px, 0, 0)`;
        }
      }
    },
    dropping (el, done) {
      /*重置小球数量  样式重置*/
      let rf = el.offsetHeight;
      el.style.webkitTransform = "translate3d(0,0,0)";
      el.style.transform = "translate3d(0,0,0)";
      let inner = el.getElementsByClassName("inner-hook")[0];
      inner.style.webkitTransform = "translate3d(0,0,0)";
      inner.style.transform = "translate3d(0,0,0)";
      el.addEventListener("transitionend", done);
    },
    afterDrop (el) {
      /*初始化小球*/
      let ball = this.dropBalls.shift();
      if (ball) {
        ball.show = false;
        el.style.display = "none";
      }
    },
    /**小球飞入 */
    async getCartList () {
      let res = await this.get_cart_list()
    },
    // 购物车
    addShopCart (e, type) {
      // if (this.productDetail.storeCount == 0 || this.productDetail.storeCount < this.productDetail.setUp) {
      //   this.$message.error("库存不够了，请联系管理员");
      //   return
      // }
      let params = {
        customerSn: this.productDetail.materialSn,
        goodsId: this.productDetail.id,
        goodsName: this.productDetail.name,
        goodsNum: this.shopNumber,
        goodsImage: this.productDetail.goodsImg,
        goodsCategoryName: this.productDetail.categoryName,
        price: this.productDetail.price,
        priceTemplate: JSON.stringify(this.productDetail.priceTemplate),
        setUp: this.productDetail.setUp,
        storeCount: this.productDetail.storeCount,
        categoryId: this.productDetail.categoryId,
        stepSample: this.productDetail.stepSample,
        packCount: this.productDetail.packCount,
        brandName: this.productDetail.brandName
      };
      if (type == '3' && this.productDetail.isSample == 1) {
        params.isSample = 1
      } else {
        params.isSample = 0
      }
      this.addCart(params)
        .then(res => {
          if (res.httpCode == 200) {
            if (type == 1 || type == 3) {
              this.$router.push({ path: '/cart' })
            } else {
              this.$message.success('添加成功');
              this.additem(e);
            }
            //this.SET_CART_LENGTH()
            this.getCartList()
          } else {
            if (type == 3) {
              this.$message.error(res.message);
            }
          }
        })
        .catch(err => {
        });
    },
    // discNum () {
    //   this.know = true;
    //   this.shopNumber = this.panNum * this.productDetail.packCount;
    //   if (this.shopNumber > this.productDetail.storeCount) {
    //     this.know = false;
    //     return this.$message.error("库存不够了，请联系管理员");
    //   }
    //   let priceTpl = this.productDetail.priceTemplate

    //   // 判断数量
    //   if (this.panNum * this.productDetail.packCount < this.minNumber) {
    //     this.pri = this.productDetail.price;
    //   } else {
    //     for (let i = 0; i < priceTpl.length; i++) {
    //       if (this.shopNumber > this.maxNumber) {
    //         this.oldPrice = priceFormat(this.maxOldPrice * this.shopNumber);
    //         this.xPrice = priceFormat(this.maxPrice * this.shopNumber);
    //         this.pri = this.maxPrice;
    //       }
    //       if (this.shopNumber >= priceTpl[i].num) {
    //         this.pri = priceTpl[i].price;
    //         this.oldPrice = priceFormat(priceTpl[i].oldPrice * this.shopNumber);
    //         this.xPrice = priceFormat(priceTpl[i].price * this.shopNumber);
    //       }
    //     }
    //   }
    // },
    dis (e) {
      let a = parseInt(e.target.value)
      let packCount = parseInt(this.productDetail.packCount)
      let b = a % packCount
      if (a > packCount) {
        if (b != 0) {
          a += packCount - b
        }
        if (a > 1000000) {
          let c = 1000000 % packCount
          if (c != 0) {
            a = 1000000 - c
          } else {
            a = 1000000
          }
        }
      } else {
        a = packCount
      }
      this.shopNumber = a
      $('#pcsNum').val(a)
      let priceTpl = this.productDetail.priceTemplate;
      if (priceTpl && priceTpl.length > 0) {
        if (typeof (priceTpl) == 'string') {
          priceTpl = JSON.parse(priceTpl)
        }
        var length = priceTpl.length
        if (this.shopNumber < priceTpl[0].num) {
          this.pri = item.price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else if (length >= 2 && ((this.shopNumber >= priceTpl[0].num) && (this.shopNumber < priceTpl[1].num))) {
          this.pri = priceTpl[0].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else if (length >= 3 && ((this.shopNumber >= priceTpl[1].num) && (this.shopNumber < priceTpl[2].num))) {
          this.pri = priceTpl[1].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else if (length >= 4 && ((this.shopNumber >= priceTpl[2].num) && (this.shopNumber < priceTpl[3].num))) {
          this.pri = priceTpl[2].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else if (length >= 5 && ((this.shopNumber >= priceTpl[3].num) && (this.shopNumber < priceTpl[4].num))) {
          this.pri = priceTpl[3].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else if (length >= 6 && ((this.shopNumber >= priceTpl[4].num) && (this.shopNumber < priceTpl[5].num))) {
          this.pri = priceTpl[4].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else if (length >= 7 && ((this.shopNumber >= priceTpl[5].num) && (this.shopNumber < priceTpl[6].num))) {
          this.pri = priceTpl[5].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else if (length >= 7 && ((this.shopNumber >= priceTpl[6].num) && (this.shopNumber < priceTpl[7].num))) {
          this.pri = priceTpl[6].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        } else {
          this.pri = priceTpl[priceTpl.length - 1].price
          this.xPrice = priceFormat(this.pri * this.shopNumber);
        }
      } else {
        this.pri = this.productDetail.price
      }
    },
    // 获取区间值
    // priceNumber (arr, num) {
    //   let _this = this;
    //   let priceTpl = _this.productDetail.priceTemplate;
    //   let length = priceTpl.length;
    //   let i = 0, sum = 0, len = arr.length;
    //   if (!(len > 0)) {
    //     return;
    //   }
    //   for (i; i < len; i++) {
    //     sum += arr[i];
    //     if (sum >= num) {
    //       if (priceTpl[i].num <= arr[i]) {
    //         _this.pri = priceTpl[i].price
    //         _this.xPrice = priceFormat(priceTpl[i].price * _this.shopNumber);
    //       }
    //       return;
    //     }
    //   }

    // },
    // 关闭提示框
    close () {
      this.know = false;
    },
    getDateInit () {
      /*获取调价模板的最小值 */
      let priceTpl = this.productNew.priceTemplate;
      let arr = [],
        ne = [],
        min = [];
      if (priceTpl) {
        for (let i = 0; i < priceTpl.length; i++) {
          arr.push(priceTpl[i].num);
          ne.push(priceTpl[i]);
          min.push(priceTpl[i]);
        }
      }

      this.minNumber = Math.min.apply(null, arr);
      this.maxNumber = Math.max.apply(null, arr);
      // 查找调价模板的最大的单价
      ne.forEach(e => {
        if (e.num == this.maxNumber) {
          this.maxOldPrice = e.oldPrice;
          this.maxPrice = e.price;
        }
      });
      min.forEach(e => {
        if (e.num == this.productDetail.setUp) {
          this.pri = e.price
        }
      });
      // /* / 获取调价模板的最小值 */
      this.shopNumber = this.productNew.setUp;
      if (this.panNum * this.productNew.packCount < this.minNumber) {
        for (let i = 0; i < priceTpl.length; i++) {
          if (this.shopNumber >= priceTpl[i].num) {
            this.pri = priceTpl[i].price;
            // this.oldPrice = priceFormat(priceTpl[i].oldPrice * this.shopNumber);
            this.xPrice = priceFormat(priceTpl[0].price * this.shopNumber);
          }
        }
        // this.oldPrice = priceFormat(this.productNew.price * this.shopNumber);
        this.xPrice = priceFormat(priceTpl[0].price * this.shopNumber);
      }
    },
    async getProductDetail () {
      let res = await this.get_product_detail({ id: this.$route.query.id });
      if (res.httpCode == 200) {
        $('#pcsNum').val(this.productDetail.packCount)
        this.productNew = Object.assign({}, this.productDetail); // 深拷贝
        this.getDateInit(); // 初始化计算数据
        // if (this.token) {
        //   this.selectFavoritesStatus();
        // } else {
        //   this.favorites = false;
        // }
        // this.getProductResemble(1);
        // this.getProductOther();
      }
    },
    async getProductResemble (page) {
      this.productResembleParams.pageNum = page;
      this.productResembleParams.categoryId = this.productDetail.categoryId;
      this.productResembleParams.specParam = this.productDetail.specParam; //商品规格
      this.productResembleParams.id = this.productDetail.id; //商品id
      this.productResembleParams.price = this.productDetail.price; //商品价格
      let res = await this.get_product_resemble(this.productResembleParams);
      if (res.httpCode == 200) {
        this.isloading = false;
        // this.GET_PRODUCT_RESEMBLE(res)
        this.productResemble = res.data.records;
        this.productResembleTotal = res.data.total;
        let num = this.productResembleTotal / 10;
        if (parseInt(num) >= 1) {
          if (this.productResembleTotal % 10 > 0) {
            this.productResembleTotalPage = parseInt(num) + 1;
          } else {
            this.productResembleTotalPage = parseInt(num);
          }
        } else {
          this.productResembleTotalPage = 1;
        }
      }
    },
    async getProductOther () {
      this.productOtherParams.categoryId = this.productDetail.categoryId;
      let res = await this.get_product_other(this.productOtherParams);
      if (res.httpCode == 200) {
        // this.GET_PRODUCT_RESEMBLE(res)
        this.productOther = res.data.records;
      }
    },
    //获取收藏状态
    async selectFavoritesStatus () {
      let res = await this.select_favorites_status({
        goodId: this.productDetail.id
      });
      if (res.httpCode == 200) {
        if (res.data) {
          this.favorites = true;
        } else {
          this.favorites = false;
        }
      }
    },

    //收藏
    async getFavorites () {
      let res;
      if (this.productDetail.collect == 1) {
        res = await this.get_favorites_del({ goodsId: this.productDetail.id });
        if (res.httpCode == 200) {
          this.SET_PRODUCT_COLLECT(0)
          this.$message.success("取消成功");
        }
      } else {
        let params = {
          goodsImg: this.productDetail.goodsImg,
          brandName: this.productDetail.brandName,
          categoryName: this.productDetail.categoryName,
          deliveryDate: this.productDetail.deliveryNo,
          favoriteType: 1,
          goodsId: this.productDetail.id,
          goodsName: this.productDetail.name,
          materialSn: this.productDetail.materialSn,
          packCount: this.productDetail.packCount,
          packageSpecification: this.productDetail.packageSpecification,
          setUp: this.productDetail.setUp,
          specifications: this.productDetail.specParam,
          status: 1, //状态(1已收藏|2已取消) ,
          storeCount: this.productDetail.storeCount,
          priceTemplate: JSON.stringify(this.productDetail.priceTemplate),
        };
        res = await this.get_favorites(params);
        if (res.httpCode == 200) {
          this.SET_PRODUCT_COLLECT(1)
          this.$message.success("收藏成功");
        } else {
          this.$message.error("操作出现异常");
        }
      }

    },

    resembleTurn (page) {
      this.getProductResemble(page);
    },
  },
  mounted () {
    this.token = localStorage.getItem("access-token");
    this.getProductDetail();
    if (!this.productDetail) {
      this.getProductDetail();
    } else {
      $('#pcsNum').val(this.productDetail.packCount)
      this.productNew = Object.assign({}, this.productDetail); // 深拷贝
      this.getDateInit(); // 初始化计算数据
      // if (this.token) {
      //   this.selectFavoritesStatus();
      // } else {
      //   this.favorites = false;
      // }
      // this.getProductResemble(1);
      //this.getProductOther();
    }
  },
  watch: {
    $route () {
      this.params.id = this.$route.query.id;
      this.getProductDetail();
    }
  }, beforeDestroy () {
    this.GET_PRODUCT_DETAIL('')
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/home-product-view.scss";
.ball {
  position: fixed;
  left: 78%;
  z-index: 200;
  transition: all 0.6s cubic-bezier(0.34, 0.42, 0.52, 0.63);
}
.inner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: red;
  transition: all 0.6s linear;
}
</style>