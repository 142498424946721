<template>
  <div class="userCenter_nav l">
    <div class="name">
      <template v-if="userInfo">
        <img v-if="userInfo.headImg" :src="userInfo.headImg" class="headImg">
        <img v-else class="headImg" src="@/assets/images/member/user_default.png">
        <div class="name-zt">
          <router-link class="nn" to="/home/user">{{userInfo.nickname}}</router-link>
          <p class="rank2 clear">
            <i class="l">
              <img :src="'http://doc.hotking.com/hkt/ui/img/level/rank_'+userInfo.level+'.png'">
            </i>
            <span>{{userInfo.level==1?'铜牌会员':userInfo.level==2?'银牌会员':userInfo.level==3?'黄金会员':userInfo.level==4?'白金会员':userInfo.level==5?'钻石会员':'皇冠会员'}}</span>
          </p>
        </div>
      </template>
    </div>
    <div class="list">
      <div v-for="(item,index) in nav" :key="index">
        <h3><i :class="{account:item.type=='帐户中心',service:item.type=='服务中心'}"></i>{{item.type}}</h3>
        <p v-for="(it,id) in item.val" :key="id" @click="clickNav(it.path,it.name)">
          <a href="javascript:;" :class="{active:currentPath.indexOf(it.path)>-1}">{{it.name}}<i></i></a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      currentPath: '',
      parentIdx: 0,
      idx: 0,
      nav: [
        {
          type: '交易管理',
          val: [
            {
              path: '/home/order/list',
              name: '订单管理'
            }, {
              path: '/home/order/buy-history',
              name: '购买历史'
            }, {
              path: '/home/order/bom',
              name: 'BOM'
            }, {
              path: '/home/sample/order',
              name: '样品订单'
            }
          ]
        }, {
          type: '帐户中心',
          val: [{
            path: '/home/user/info',
            name: '编辑账户信息'
          }, {
            path: '/home/user/address',
            name: '管理收货地址'
          }, {
            path: '/home/user/invoice',
            name: '增值税开票资料'
          }, {
            path: '/home/user/edit-ps',
            name: '更改登录密码'
          }, {
            path: '/home/user/integral',
            name: '积分明细'
          }, {
            path: '/home/user/dh',
            name: '我的兑换'
          }, {
            path: '/home/rules',
            name: '会员规则'
          }]
        }, {
          type: '服务中心',
          val: [
            {
              path: '/home/message',
              name: '系统消息'
            }, {
              path: '/home/collection',
              name: '我的收藏'
            }
          ]
        }]

    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['userTitle', 'userInfo'])
  },
  methods: {
    ...mapMutations('lxr', ['SET_USERTITLE']),
    clickNav (link, name) {
      this.currentPath = link
      this.$router.push({ path: link })
      this.SET_USERTITLE(name)
    }
  },
  mounted () {
    this.currentPath = this.$route.path
  },
  watch: {
    '$route.path' (val, old) {
      this.currentPath = val
    }
  }
}
</script>
<style lang="scss" scoped>
.nn {
  min-height: 16px;
  display: block;
}
</style>


