<template>
  <div class="newMain">
    <div class="bom-wrap">
      <div class="bom-con clearfix">
        <div class="bom-ms fl">
          <h3>BOM专家，一键下单</h3>
          <ul>
            <li>海量现货库存，一站式配单</li>
            <li>智能推荐，优先保证现货与低价，省时省力</li>
            <li>随意切换搜索条件，快速同步匹配结果</li>
            <li>参数智能选型，AI深度学习IDPE/ISPE引擎</li>
          </ul>
          <router-link to="/home/order/bom" class="pBtn">我的历史配单</router-link>
        </div>
        <div class="bom-info fr clearfix" v-loading="isloading">
          <div class="inpBtn fl" id="bomCopy">
            <h3>手动输入型号或参数</h3>
            <textarea placeholder="可手动输入型号或粘贴到此处示例：
RI0603L103JT     100
LL4148     20
SMAJ5.0A     300" id="textare"></textarea>
            <a href="javascript:;" class="pBtn" @click="bomBtn">立即配单</a>
          </div>
          <div class="tip-wrap fr">
            <h3>BOM表格上传</h3>
            <div class="fileUpload">
              <div class="btn">
                <input ref='file' type="file" id="file" class="fileExcel" name="file" @change="upfiles" accept="" style="visibility: hidden; width: 0px; height: 0px;">
                <label for="file">{{text}}</label>
              </div>
              <a class="down" href="javascript:;" @click="download">下载BOM标准模板 提升选型准确率</a>
            </div>
          </div>
          <div class="bom-tip">
            <h3>说明：</h3>
            <p>1、为保证准确性，请下载BOM模板；</p>
            <p>2、复制并粘贴数据到模板中，并将文件保存为xls/xlsx/csv格式；</p>
            <p>3、点击上侧的“上传BOM文件”按钮上传文件；</p>
            <p>4、文件大小必须在5M以内，上传数据不能超过20条；</p>
          </div>
          <div class="other">
            或
          </div>
        </div>
      </div>
    </div>
    <div class="bom-b">
      <h3>智能BOM 您的一站式配单平台</h3>
      <p>合晶芯城依托千万SKU大数据、运用人工智能、IDPE/ISPE引擎等技术手段，为工程师提供智能、精准选型服务，
        更支持参数实时修改并提供多种选型策略。使研发阶段的BOM处理更高效、便捷，有效提升配单效率！</p>
      <img src="@/assets/images/bom/bom-5.png">
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { exportMethod } from '@/plugins/ibox/utils'
import axios from 'axios'
export default {
  metaInfo () {
    return {
      title: '秒配BOM表，一键下单',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  data () {
    return {
      isActive: 1,
      file: '',
      params: [],
      text: '上传BOM文件',
      isloading: false,
    }
  },
  methods: {
    ...mapActions('lw', ['bomQuery', 'downloadTpl']),
    // 上传
    upfiles: function (e) {
      this.isloading = true
      this.file = e.target.files[0]
      if (this.file == '' || !this.file) {
        return this.$message.error("请上传模板文件");
      } else {
        this.$message({
          showClose: true,
          message: '文件上传解析中...',
          type: 'warning',
          duration: 0
        });
        this.text = '文件上传解析中...'
        var formData = new FormData();
        formData.append('file', this.file);
        formData.append('language', 1);
        let config = {
          //添加请求头
          headers: { "Content-Type": "multipart/form-data" },
          // responseType: 'blob',          
        };
        axios.post('/shop/bom/importBomExcel', formData, config).then(res => {
          if (res.data.httpCode == 200) {
            $('.el-message--warning').remove()
            this.text = '上传BOM文件'
            if (res.data.data) {
              localStorage.setItem('excelList', JSON.stringify(res.data.data))
              this.$router.push({
                name: 'match',
                query: {
                  type: 2
                }
              })
            } else {
              this.$alert('上传成功，识别到BOM不是标准模板，需人工配单！为提升选型准确率，建议您下载页面BOM标准模板', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  // this.$message({
                  //   type: 'info',
                  //   message: `action: ${action}`
                  // });
                }
              });
            }
          } else {
            $('.el-message--warning').remove()
            this.text = '上传BOM文件'
            this.$message.error(res.data.message);
          }
          this.isloading = false
          this.$refs.file.value = ''
        })
          .catch(function (error) {
          });
      }
    },

    // 下载模板
    async download () {
      let obj = {
        method: 'get',
        url: '/bom/downloadExcel',
        fileName: `bom查询模板导出`,
        params: ''
      }
      exportMethod(obj) // 模板导出
    },
    bomBtn () {
      this.params = []
      var textare = $("#textare").val();
      if ($("#bomCopy").is(":visible")) {   //黏贴功能
        if (textare) {
          let attrs = textare.replace(/[\r\n]/g, ",")
          let attr = attrs.split(",");

          for (var i = 0; i < attr.length; i++) {
            if (attr[i].trim().length > 0) {
              let idx = attr[i].trim().lastIndexOf(' ')
              let num = attr[i].trim().substr(idx + 1);
              let materialSn = attr[i].substr(0, idx)
              // let materialSn = attr[i]
              let re = /^[0-9]+.?[0-9]*$/;
              // if (idx < 0) {
              //   this.$message.error("第" + (i + 1) + "行内容格式不符合");
              //   return false;
              //   break;
              // }
              // if (!num) {
              //   this.$message.error("第" + (i + 1) + "行数量内容格式不符合");
              //   return false;
              //   break;
              // }
              // if (!re.test(num)) {
              //   this.$message.error("第" + (i + 1) + "行数量请输入数字");
              //   return false;
              //   break;
              // }
              this.params.push({
                materialSn: materialSn,
                num: num,
                language: "1"
              })
            }
          };
          this.bomCopyAjax(this.params)
          // var arr = [];
          // var index = 0;
          // for (var i = 0; i < attr.length; i++) {
          //   var items = attr[i].replace(/[\s\n]/g, ',');
          //   var item = items.split(",")
          //   var regEn = /[`~!@#$%^&*()_<>?:"{},\/;'[\]]/im,
          //     regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
          //   var regNum = /^[0-9]*$/;
          //   for (var j = 0; j < item.length; j++) {
          //     if (regEn.test(item[0]) || regCn.test(item[0])) {
          //       $(".bomc-pp").html("");
          //       return this.$message.error("你黏贴的内容格式不符合");
          //       break;
          //     }
          //     if (item.length > 2 && item[j] == "") {
          //       item.splice(j, 1)
          //       j = j - 1;
          //     }
          //   }
          //   if (item.length > 2) {
          //     $(".bomc-pp").html("");
          //     return this.$message.error("你黏贴的内容格式不符合");
          //     break;
          //   }
          //   if (!regNum.test(item[1]) && item[1] != undefined) {
          //     $(".bomc-pp").html("");
          //     return this.$message.error("你黏贴的内容—数量格式不符合");
          //     break;
          //   }
          //   if (item[0] != "") {
          //     index++;
          //     arr.push(this.menuArrToObj(item))
          //   }
          // }
          // $(".bomc-pp").html('<span>当前有'+index+'个待匹配型号</span><span class="bomc-del" >删除</span>');
          // this.bomCopyAjax(arr)
        } else {
          return this.$message.error("请输入对应的格式");
        }
      } else if ($("#bomExcel").is(":visible")) {   //excel
        if (this.file == '') {
          return this.$message.error("请上传模板文件");
        } else {
          var formData = new FormData();
          formData.append('file', this.file);
          formData.append('language', 1);
          let config = {
            //添加请求头
            headers: { "Content-Type": "multipart/form-data" },
            // responseType: 'blob',          
          };
          axios.post('/shop/bom/importBomExcel', formData, config).then(res => {
            if (res.data.httpCode == 200) {
              this.$router.push({
                name: 'match',
                query: {
                  type: 2
                }
              })
              localStorage.setItem('excelList', JSON.stringify(res.data.data))
            }
          })
            .catch(function (error) {
            });
        }
      } else {          // 图片
        if (img) {
        } else {
          return this.$message.error("请先上传图片");
        }
      }
    },
    // 多型号提交
    async bomCopyAjax (arry) {
      localStorage.setItem('bomparams', JSON.stringify(arry));
      let res = await this.bomQuery(arry);
      if (res.httpCode == 200) {
        localStorage.setItem('excelList', JSON.stringify(res.data))
        this.$router.push({
          name: 'match',
          query: {
            type: 1
          }
        })
      } else if (res.httpCode == 201) {
        return this.$message.error(res.message)
      }
    },
    // 数组转对象
    menuArrToObj (arr) {
      var obj = {};
      for (var i = 0; i < arr.length; i++) {
        obj["materialSn"] = arr[0];
        obj["num"] = arr[1];
        obj["language"] = '1'
      }
      return obj;
    },
  },
  created () {
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
</style>