<template>
  <div class="r userCenter_container_box">
    <h2 class="ptitle">我的兑换</h2>
    <!-- <div class="filter-wrap">
      <el-select class="mr20" v-model="params.data.alterType" placeholder="请选择" @change="scoreType()">
        <el-option v-for="(item,index) in type" :key="index" :label="item.name" :value="item.type">
        </el-option>
      </el-select>
      <el-date-picker v-model="params.data.startTime" value-format='yyyy-MM-dd' type="date" placeholder="选择日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <span class="ctext">至</span>
      <el-date-picker v-model="params.data.endTime" value-format='yyyy-MM-dd' type="date" placeholder="选择日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <button class="search-btn" @click="searchInput">查询</button>
    </div> -->
    <div class="userCenter_container integral-dh">
      <table class="tab-1">
        <tbody>
          <tr>
            <th>兑换时间</th>
            <th>兑换产品</th>
            <th>消耗积分</th>
            <th>操作</th>
          </tr>
          <tr v-for="(item,index) in lists" :key="index">
            <td>{{item.createTime | setTime}}</td>
            <td>{{item.name}}</td>
            <td>{{item.exchangePoint}}</td>
            <td class="operation">
              <router-link class="o-btn" :to="{path:'/home/user/dh/detail',query:{id:item.id}}">查看详情</router-link>
              <a class="o-btn" href="javascript:;" @click="del(item.id)">删除</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="page-wrap">
        <el-pagination background hide-on-single-page layout="prev, pager, next" :total="total" :page-size="params.pageSize" @current-change="currentChange">
        </el-pagination>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-积分明细',
    }
  },
  data () {
    return {
      isloading: true,
      total: 0,
      params: {
        "pageNum": 1,
        "pageSize": 10
      },
      lists: [],
    }
  },
  created () {
    this.getCardList()
  },
  computed: {
  },
  methods: {
    ...mapActions('lxr', ['get_card_list', 'del_Card']),
    currentChange (e) {
      this.params.pageNum = e
      this.getCardList()
    },
    del (id) {
      this.$confirm('确定删除此记录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delCard(id)
      }).catch(() => {
      })
    },
    async delCard (id) {
      let res = await this.del_Card({ id: id })
      if (res.httpCode == 200) {
        this.getCardList()
      } else {
        this.$message.error(res.message)
      }
    },
    async getCardList () {
      let res = await this.get_card_list(this.params)
      if (res.httpCode == 200) {
        this.lists = res.data.records
        this.total = res.data.total
      }
    }
  },
  mounted () {
  },
  filters: {
    setTime: function (val) {
      val = val.substring(0, val.length - 2)
      return val
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-points.scss";
.current {
  color: #0067ed;
}
</style>


