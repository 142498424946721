<template>
  <div>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 主体路由 -->
    <router-view />

    <!-- 底部组件 -->
    <keepAlive>
      <Pbottom></Pbottom>
    </keepAlive>

    <!-- 右侧边栏浮动 -->
    <!-- <keepAlive>
      <rightBar></rightBar>
    </keepAlive> -->
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Header from '@/components/public/header.vue'
import Pbottom from '@/components/public/pBottom.vue'
export default {
  name: 'app',
  async asyncData ({ store }) {
    let getFriendLink = await store.dispatch('lxr/get_friend_link');
    return getFriendLink
  },
  components: {
    Header,
    Pbottom
  },
  data () {
    return {
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['token'])
  },
  methods: {
    ...mapActions('lxr', ['get_friend_link']),
    ...mapMutations('lxr', ['SET_TOKEN']),
    async getFriendLink () {
      let res = this.get_friend_link()
    },
  },
  mounted () {
    if (!this.token) {
      this.SET_TOKEN(sessionStorage.getItem('access-token'))
    }
    if (!this.friendLink) {
      this.getFriendLink()
    }
  },
  watch: {
  }
}
</script>
<style lang="scss">
</style>


