import { render, staticRenderFns } from "./pBottom.vue?vue&type=template&id=060d8146&"
import script from "./pBottom.vue?vue&type=script&lang=js&"
export * from "./pBottom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "7ef9fcde"
  
)

export default component.exports