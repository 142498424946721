<template>
  <div class="newMain bgf5f5f5">
    <div class="news-wrap">
      <div class="new-list-wrap">
        <h3 class="type-title">
          {{params.cid==35?'商城资讯':'行业新闻'}}
          <div class="search-wrap">
            <input type="text" placeholder="请输入文章关键词" v-model="params.title" @keyup.enter="searchBut">
            <i @click="searchBut"></i>
          </div>
        </h3>
        <div class="list-wrap" v-loading="isloading">
          <template v-if="params.cid==35&&newList">
            <router-link class="list" v-for="(item, index) in newList.records" :key="index" :to="{path:'/news/detail',query:{id:item.id}}" target="_blank">
              <img class="new-img" v-if="item.img" :src="item.img" :alt="item.title">
              <img class="new-img" v-else src="@/assets/images/default.png" :alt="item.title">
              <div class="info">
                <h4 class="new-title">{{item.title}}</h4>
                <div class="new-describe">{{item.description}}</div>
                <div class="tag-time clearfix">
                  <div class="tag fl">
                    <span v-for="(v, index) in item.keywords" :key="index" :class="{I:index==0,C:index==1,J:index==2,K:index==3,E:index==4,F:index==5}">{{v.tag}}</span>
                  </div>
                  <div class="time fr">{{item.updateTime.substr(0,10)}}</div>
                </div>
              </div>
            </router-link>
            <div class="pagination" v-if="newList" style="text-align:right;">
              <el-pagination background hide-on-single-page layout="prev, pager, next" :total="newList.total" :page-size="params.pageSize" :current-page="params.pageNum" @current-change="currentChange">
              </el-pagination>
            </div>
          </template>
          <template v-if="params.cid==38&&newList2">
            <router-link class="list" v-for="(item, index) in newList2.records" :key="index" :to="{path:'/news/detail',query:{id:item.id}}" target="_blank">
              <img class="new-img" v-if="item.img" :src="item.img" :alt="item.title">
              <img class="new-img" v-else src="@/assets/images/default.png">
              <div class="info">
                <h4 class="new-title">{{item.title}}</h4>
                <div class="new-describe">{{item.description}}</div>
                <div class="tag-time clearfix">
                  <div class="tag fl">
                    <span v-for="(v, index) in item.keywords" :key="index" :class="{I:index==0,C:index==1,J:index==2,K:index==3,E:index==4,F:index==5}">{{v.tag}}</span>
                  </div>
                  <div class="time fr">{{item.updateTime.substr(0,10)}}</div>
                </div>
              </div>
            </router-link>
            <div class="pagination" v-if="newList2" style="text-align:right;">
              <el-pagination background hide-on-single-page layout="prev, pager, next" :total="newList2.total" :page-size="params.pageSize" :current-page="params.pageNum" @current-change="currentChange">
              </el-pagination>
            </div>
          </template>
        </div>
      </div>
      <div class="new-other">
        <div class="other-title">
          <i></i>
          <span>热门阅读</span>
        </div>
        <ul class="other-li" v-if="newList3">
          <li v-for="(item, index) in newList3.records" :key="index">
            <router-link :to="{path:'/news/detail',query:{id:item.id}}" target="_blank"><i class="sort">{{index+1}}</i>{{item.title}}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  metaInfo () {
    return {
      title: '服务中心',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  async asyncData ({ store, route }) {
    let getNewsList = await store.dispatch('lxr/get_news_list', {
      "cid": route.query.cid || 35,
      "pageNum": parseInt(route.query.page) || 1,
      "pageSize": 10
    });
    let getNewsHot = await store.dispatch('lxr/get_news_list', {
      "cid": 37,
      "pageNum": 1,
      "pageSize": 10
    });
    return getNewsList, getNewsHot;
  },
  data () {
    return {
      params: {
        "cid": this.$route.query.cid || 35,
        "pageNum": parseInt(this.$route.query.page) || 1,
        "pageSize": 10,
        title: ''
      },
      isloading: true,
    }
  },
  created () {
    // console.log(this.newList)
  },
  computed: {
    ...mapState('lxr', ['newList', 'newList2', 'newList3']),
  },
  methods: {
    ...mapActions('lxr', ['get_news_list']),
    async getNewsList () {
      this.isloading = true
      let res = await this.get_news_list(this.params)
      if (res.httpCode == 200) {
        this.isloading = false
      }
    },
    async getNewsList2 () {
      let res = await this.get_news_list({
        "cid": 37,
        "pageNum": 1,
        "pageSize": 10
      })
    },
    searchBut () {
      this.$router.push({
        path: '/industry',
        query: { cid: this.params.cid, page: 1 }
      })
      this.params.pageNum = 1
      this.getNewsList()
    },
    currentChange (e) {
      this.params.pageNum = e
      this.$router.push({
        path: '/industry',
        query: { cid: this.params.cid, page: this.params.pageNum }
      })
      //this.$router.go(0)
      this.getNewsList()
    }
  },
  mounted () {
    if (!this.newList || !this.newList2) {
      this.getNewsList()
    } else {
      this.isloading = false
    }
    if (!this.newList3) {
      this.getNewsList2()
    }
  },
}
</script>
<style lang="scss">
</style>