<template>
  <div class="hkt_container clear" id="goods_list">
    <div class="primary_search_title clear">
      <router-link to="/product/level" class="">全部商品</router-link>&gt;<i data-v-a0fd5576="">“{{params.queryValue}}” 的搜索结果</i>
    </div>

    <div class="primary_search_classify clear" v-if="params.type!=2&&productList">
      <div id="cate_div" class="clear">
        <p class="l">商品类目</p>
        <div class="secondary_classify_box l " id="cate_box" :class="{current:categoryNameFalg}">
          <template v-if="categoryNameFalg">
            <a v-for="(item, index) in productList.object.categoryName" :key="index" href="javascript:;" class="threeLevel_classify" @click="categorySelect('')">{{item}}</a>
          </template>
          <template v-else>
            <a v-for="(item, index) in productList.object.categoryName" :key="index" href="javascript:;" class="threeLevel_classify" @click="categorySelect(item)">{{item}}</a>
          </template>
        </div>
        <!-- <p class="mores" id="more"><i class="more"></i>更多</p> -->
      </div>

      <div id="cate_div" class="clear">
        <p class="l">商品品牌</p>
        <div class="secondary_classify_box l " id="cate_box" :class="{current:brandNameFalg}">
          <template v-if="brandNameFalg">
            <a v-for="(item, index) in productList.object.brandName" :key="index" href="javascript:;" @click="brandSelect('')">{{item}}</a>
          </template>
          <template v-else>
            <a v-for="(item, index) in productList.object.brandName" :key="index" href="javascript:;" @click="brandSelect(item)">{{item}}</a>
          </template>
        </div>
      </div>
    </div>
    <div class="searchMain">
      <div class="primary_search_product">
        <div class="source">
          <a class="active" href="">自营</a>
          <!-- <router-link v-if="!bname" :to="{path:'/search/china-1',query:{v:$route.query.v}}">国内现货</router-link> -->
        </div>
        <div class="search_product_title">
          <div class="product-price" id="selectSort">
            <span @click.stop="priceSortShowFun" v-clickoutside="handleClose">{{sortNavText}}</span>
            <i></i>
            <div class="price-selct" id="price-selct" v-show="priceSortShow">
              <p v-for="(item, index) in sortNav" :key="index" :class="{selcted:sortNavIdx==index}" @click="sortFun(item.sort)">{{item.text}}</p>
            </div>
          </div>
          <div class="product-stock" id="checkbox" @click="stockFun">
            <i :class="{active:stockFalg}"></i>
            <span>有库存的</span>
          </div>
          <div class="padding20">
            符合条件的商品：<span v-if="productList">{{productList.total}}</span><span v-else>0</span>
          </div>
        </div>
        <div class="s-wbox" v-loading="isloading">
          <template v-if="productList && productList.records.length>0">
            <ul class="new-product-ul">
              <li class="new-product-li clearfix" v-for="(v,index) in productList.records" :key="index">
                <div class="pl-col-1 fl">
                  <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" class="ibox">
                    <img v-if="v.goodsImg" :src="v.goodsImg" :alt="v.name+' '+v.packageSpecification">
                    <img v-else src="@/assets/images/goods/default_img.png" :alt="v.name+' '+v.packageSpecification">
                  </router-link>
                </div>
                <div class="pl-col-2 fl">
                  <p class="p_name">
                    <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.name"></router-link>
                  </p>
                  <div class="clear">
                    <div class="c1">
                      <p>
                        <span class="tag">编码：</span>
                        <router-link class="detail" :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.ksn"></router-link>
                      </p>
                      <p>
                        <span class="tag">品牌：</span>
                        <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.brandName"></router-link>
                      </p>
                      <p class="ms-wrap">
                        <span class="tag">描述：</span>
                        <router-link class="ms" :title="v.goodRemark" :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.goodRemark"></router-link>
                      </p>
                    </div>
                    <div class="c2">
                      <p>
                        <span class="tag">型号：<i v-html="v.materialSn"></i></span>
                      </p>
                      <p>
                        <span class="tag">封装：<i v-html="v.packageSpecification"></i></span>
                      </p>

                      <p v-if="v.goodsManual">
                        <a :href="v.goodsManual" target="_blank" class="pdf">数据手册</a>
                      </p>
                      <p v-else>
                        <a href="javascript:;" class="pdf">数据手册：暂无</a>
                      </p>
                    </div>
                    <div class="c3">
                      <p><span class="tag">库存：<i>现货</i></span></p>
                      <p><span class="tag">发货时间：<i>2~7 天发货</i></span></p>
                      <p><span class="tag">包装数量：{{v.packCount}} {{v.storeCompany}}</span></p>
                    </div>
                  </div>
                </div>
                <div class="pl-col-3 fl">
                  <div class="price_list">
                    <div class="tab">
                      <table v-if="v.priceTemplate&&v.priceTemplate.length >0">
                        <tbody>
                          <tr v-for="(vp,index2) in v.priceTemplate" :key="index2">
                            <td class="num">{{vp.num}}+</td>
                            <td class="price">¥{{vp.price}}/{{v.storeCompany}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="pl-col-4 fr">
                  <div class="opera_box">
                    <div class="number-box">
                      <input class="buy-num" :value="v.packCount" maxlength="7" onkeyup="value=value.replace(/[^\d]/ig,'')" @blur="blurFun($event,v.packCount,index)"><span class="add" @click="add($event,v.packCount,index)">+</span><span class="sub" @click="sub($event,v.packCount,index)">-</span>
                      <div class="tip">
                        <em></em>
                        需整盘数量购买，已为您修正
                      </div>
                    </div>
                    <span class="add_cart" @click="addShopCart(v,index)">加入购物车</span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="page-wrap">
              <el-pagination background hideOnSinglePage layout="prev, pager, next" :total="productList.total" @current-change="currentChange" :current-page="params.pageNum"></el-pagination>
            </div>
          </template>
          <div class="no_product_box" v-if="(productList&&productList.records.length==0&&!isloading) || !productList&&!isloading">
            <img src="@/assets/images/goods/noshop_1.png">
            <p>很抱歉，暂无相关商品，请查询其他的看看</p>
          </div>
          <!-- <div class="no_product_box" v-if="(productList&&productList.records.length==0&&!isloading) || !productList&&!isloading">
            <img src="@/assets/images/goods/noshop_1.png">
            <p>很抱歉，暂无相关商品，请查询其他的看看</p>
            <router-link class="otherLink" :to="{path:'/search/china-1',query:{v:$route.query.v}}">看看国内现货</router-link>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import https from '@/https.js'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '合晶芯城_电子元器件产品大全_IC交易网_电子元件采购',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城是电子元器件采购首选的一站式电商平台。拥有10000多平方米现代化元器件仓库，自营现货库存超100000种。满足各品牌原装正品电子元器件采购需求，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器等。'
        }
      ]
    }
  },
  components: {
  },
  async asyncData ({ store, route }) {
    var get_brand_category = null
    if (route.query.type == 2 && route.query.bid != '') {
      get_brand_category = await store.dispatch('lxr/get_brand_category', {
        brandId: route.query.bid,
        pageNum: 1, pageSize: 100
      })
    }
    let getProductList = await store.dispatch('lxr/get_product_list', {
      pageNum: parseInt(route.query.page) || 1,
      pageSize: 10,
      categoryId: route.query.cid || '',
      categoryName: route.query.caname || '',
      attrs: [],
      queryValue: route.query.v || '',
      brandId: route.query.bid || '',
      goodsType: route.query.gt || '',
      type: route.query.type || '',
      specParam: route.query.spe || '',
    })
    return get_brand_category, getProductList
  },
  data () {
    return {
      brandCategoryIdIdx: -1,
      brandCategoryIdx: -1,
      sortNav: [
        {
          sort: 0,
          text: '默认排序'
        },
        {
          sort: 1,
          text: '价格升序'
        },
        {
          sort: 2,
          text: '价格降序'
        }
      ],
      sortNavIdx: 0,
      sortNavText: '默认排序',
      stockFalg: false,
      priceSortShow: false,
      total: 0, //总数
      params: {
        pageNum: parseInt(this.$route.query.page) || 1,
        pageSize: 10,
        queryValue: this.$route.query.v || '',
        categoryId: this.$route.query.cid || '',
        categoryName: this.$route.query.caname || '',
        brandName: this.$route.query.bname || '',
        attrs: [],
        brandId: this.$route.query.bid || '',
        goodsType: this.$route.query.gt || '',
        type: this.$route.query.type || '',
        specParam: this.$route.query.spe || '',
      },
      s_attr: [], //选中属性列表集合 ---  展示
      s_attr_id: [], //选中属性列表集合 --  标识
      s_attr_falg: [],

      img_dis: false, //图片放大显示
      imgCurrent: 0, //图片显示标识
      isloading: true,
      categoryNameFalg: false,
      brandNameFalg: false,

      bname: this.$route.query.bname || '',
    }
  },
  created () {
  },
  computed: {
    ...mapState('lxr', ['productList', 'brandCategory']),
  },

  methods: {
    ...mapActions('lxr', ['get_product_list', 'get_favorites', 'get_brand_category', 'get_favorites_del', 'get_cart_list']),
    ...mapActions("lw", ["addCart"]),
    ...mapMutations('lxr', ['GET_PRODUCT_LIST', 'GET_BRAND_CATEGORY', 'SET_PRODUCT_LIST']),
    async getCartList () {
      let res = await this.get_cart_list()
    },
    async addShopCart (v, index) {
      let params = {
        customerSn: v.materialSn,
        goodsId: v.id,
        goodsName: v.name.replace(/<SPAN STYLE='COLOR:#0067ED'>/g, "").replace(/<\/SPAN>/g, ""),
        goodsNum: $('.buy-num').eq(index).val(),
        goodsImage: v.goodsImg,
        goodsCategoryName: v.categoryName.replace(/<SPAN STYLE='COLOR:#0067ED'>/g, "").replace(/<\/SPAN>/g, ""),
        price: v.price,
        priceTemplate: JSON.stringify(v.priceTemplate),
        setUp: v.setUp,
        storeCount: v.storeCount,
        categoryId: v.categoryId,
        stepSample: v.stepSample,
        packCount: v.packCount,
        brandName: v.brandName
      };
      let res = await this.addCart(params)
      if (res.httpCode == 200) {
        // this.$router.push({ path: '/cart' })
        this.getCartList()
        this.$message.success('添加成功');
      } else {
        this.$message.error(res.message);
      }
    },
    blurFun (e, packCount, idx) {
      let num = parseInt(e.target.value)
      let a = num
      let b = a % parseInt(packCount)

      if (a > parseInt(packCount)) {
        $('.number-box').eq(idx).find('.sub').attr('style', '')
        if (b != 0) {
          a += parseInt(packCount) - b
          $('.buy-num').eq(idx).next().next().next().show()
          setTimeout(() => {
            $('.buy-num').eq(idx).next().next().next().hide()
          }, 2000);
        }
        if (a > 1000000) {
          let c = 1000000 % parseInt(packCount)
          if (c != 0) {
            a = 1000000 - c
          } else {
            a = 1000000
          }
        }
      } else {
        a = parseInt(packCount)
        $('.number-box').eq(idx).find('.sub').attr('style', 'color:#999')
        $('.buy-num').eq(idx).next().next().next().show()
        setTimeout(() => {
          $('.buy-num').eq(idx).next().next().next().hide()
        }, 2000);
      }
      $('.buy-num').eq(idx).val(a)
    },
    add (e, packCount, idx) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num + parseInt(packCount)
      if (a > 1000000) {
        let b = 1000000 % parseInt(packCount)
        if (b != 0) {
          a = 1000000 - b
        } else {
          a = 1000000
        }
      }
      $('.number-box').eq(idx).find('.sub').attr('style', '')
      $('.buy-num').eq(idx).val(a)
    },
    sub (e, packCount, idx) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num - parseInt(packCount)
      if (a < parseInt(packCount)) {
        a = parseInt(packCount)
        $('.number-box').eq(idx).find('.sub').attr('style', 'color:#999')
      } else {
        $('.number-box').eq(idx).find('.sub').attr('style', '')
      }
      $('.buy-num').eq(idx).val(a)
    },
    brandCategoryClick (idx, item) {
      this.brandCategoryIdx = idx
      this.brandCategoryIdIdx = 0
      this.params.categoryId = item.sub[0].id
      this.getProductList()
    },
    categoryIdClick (idx, id) {
      this.brandCategoryIdIdx = idx
      this.params.categoryId = id
      this.getProductList()
    },
    priceSortShowFun () {
      this.priceSortShow = !this.priceSortShow
    },
    stockFun () {
      this.stockFalg = !this.stockFalg
      if (this.stockFalg) {
        this.params.storeCount = 1
      } else {
        delete this.params.storeCount
      }
      this.getProductList()
    },
    sortFun (sort) {
      if (sort == 1) { //升序
        this.params.sort = 1
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else if (sort == 2) { //降序
        this.params.sort = 2
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else {
        delete this.params.sort
      }
      this.getProductList()
    },
    async getBrandCategory () {
      let res = await this.get_brand_category({ brandId: this.params.brandId, pageNum: 1, pageSize: 100 })
      // if (res.httpCode == 200) {
      //   this.GET_BRAND_CATEGORY(res)
      // } else {
      // }
    },

    //选择分类搜索
    categorySelect (name) {
      // this.GET_PRODUCT_LIST('')
      this.params.categoryName = name
      setTimeout(() => {
        if (name) {
          this.categoryNameFalg = true
        } else {
          this.categoryNameFalg = false
        }
      }, 500);

      this.params.pageNum = 1
      // this.getProductList()
      this.$router.push({ path: 'search', query: { v: this.params.queryValue, caname: this.params.categoryName || null, bname: this.params.brandName || null, page: this.params.pageNum } })
    },

    //选择品牌搜索
    brandSelect (name) {
      // this.GET_PRODUCT_LIST('')
      this.params.brandName = name
      setTimeout(() => {
        if (name) {
          this.brandNameFalg = true
        } else {
          this.brandNameFalg = false
        }
      }, 500);
      this.params.pageNum = 1
      this.$router.push({ path: 'search', query: { v: this.params.queryValue, caname: this.params.categoryName || null, bname: this.params.brandName || null, page: this.params.pageNum } })
      // this.getProductList()
    },

    //参数属性单击
    attrSelect (name, idx, val, valIdx, length) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      if (this.s_attr_id[idx].indexOf(valIdx) >= 0) {
        let delIdx = this.s_attr_id[idx].indexOf(valIdx)
        this.s_attr_id[idx].splice(delIdx, 1)
        this.s_attr[idx].splice(delIdx, 1)
      } else {
        this.s_attr_id[idx].push(valIdx)
        this.s_attr[idx].push({ name: name, idx: idx, val: val, valIdx: valIdx })
      }
    },
    currentChange (e) {
      this.params.pageNum = e
      //this.getProductList()
    },
    async getProductList () {
      this.isloading = true
      let res = await this.get_product_list(this.params)
      if (!res) {
        this.isloading = false
        this.GET_PRODUCT_LIST('')
        return
      }
      if (res.httpCode == 200) {
        this.isloading = false
        this.GET_PRODUCT_LIST(res)
        if (!this.productList) {
          this.$router.push({ path: '/search/china-1', query: { v: this.$route.query.v } })
        }
      } else {
        this.$message.error('搜索出现异常')
        this.isloading = false
      }
    },
    handleClose () {
      this.priceSortShow = false
    }
  },
  mounted () {
    //初始化商品列表
    if (this.params.categoryName) {
      this.categoryNameFalg = true
    }
    if (this.params.brandName) {
      this.brandNameFalg = true
    }
    if (!this.brandCategory) {
      this.getBrandCategory()
    }
    console.log(this.productList)
    if (!this.productList) {
      this.getProductList()
    } else {
      this.isloading = false
      if (this.productList.object.QueryItems && this.productList.object.QueryItems.length > 0) {
        this.productList.object.QueryItems.forEach((item, index) => {
          this.s_attr_id.push([])
          this.s_attr.push([])
          this.s_attr_falg.push({ arr: [] })
          item.attrs.forEach((i, d) => {
            this.s_attr_falg[index].arr.push(false)
          })
        });
      }
    }
  },
  filters: {
    macSlice: function (val) {
      if (val) {
        return val.length > 18 ? val.slice(0, 18) + '...' : val;
      }
      return '';
    },
    pdfUrl (url) {
      return url.replace('http://doc.hotking.com/', '')
    },
  }, watch: {
    'params.pageNum' () {
      this.$router.push({ path: 'search', query: { v: this.params.queryValue, caname: this.params.categoryName || null, bname: this.params.brandName || null, page: this.params.pageNum } })
    },
    '$route' () {
      this.brandNameFalg = false
      this.categoryNameFalg = false
      this.params = {
        pageNum: parseInt(this.$route.query.page) || 1,
        pageSize: 10,
        queryValue: this.$route.query.v || '',
        categoryId: this.$route.query.cid || '',
        categoryName: this.$route.query.caname || '',
        brandName: this.$route.query.bname || '',
        attrs: [],
        brandId: this.$route.query.bid || '',
        goodsType: this.$route.query.gt || '',
        type: this.$route.query.type || '',
        specParam: this.$route.query.spe || '',
      }
      this.bname = this.$route.query.bname || ''
      if (this.params.categoryName) {
        this.categoryNameFalg = true
      }
      if (this.params.brandName) {
        this.brandNameFalg = true
      }
      this.getProductList()
    }
  }
  , beforeDestroy () {
    this.GET_PRODUCT_LIST('')
    this.GET_BRAND_CATEGORY('')
  }

}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/home-product-cate.scss";
.brand-search {
  width: 100%;
  height: 56px;
  line-height: 56px;
  p.l {
    font-size: 14px;
    font-weight: bold;
    color: #1e1f30;
    span {
      color: #0067ed;
    }
  }
}
.brand_search_r {
  position: relative;
  margin-top: 10px;
  input {
    height: 36px;
    width: 480px;
    line-height: 36px;
    font-size: 12px;
    border: 1px solid #f1f3f7;
    border-radius: 4px;
    text-indent: 12px;
    outline: none;
  }
  i {
    position: absolute;
    width: 34px;
    height: 34px;
    top: 1px;
    right: 1px;
    background: #f1f3f7 url(~@/assets/images/brand/search_d.png) no-repeat
      center center;
    background-size: 30%;
    cursor: pointer;
    border-radius: 0 2px 2px 0;
  }
}
.search_content {
  width: 100%;
  min-height: 120px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-bottom: 25px;
}
.brand_secondnary {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #f7f7f8;
  padding: 0 20px;
  margin-bottom: 0;
  a {
    color: #1e1f30;
    font-weight: bold;
    margin-right: 40px;
    font-size: 12px;
    &:hover {
      color: #0067ed;
    }
    &.active {
      color: #0067ed;
    }
  }
}
.brand_thirdnary {
  padding: 10px 20px;
  li {
    width: 20%;
    line-height: 40px;
    a {
      color: #373636;
      font-size: 12px;
      &:hover {
        color: #0067ed;
      }
      &.active {
        color: #0067ed;
      }
    }
  }
}
</style>
