<template>
  <div class="newMain bgf5f5f5">
    <div class="sampleD-wrap auto">
      <div class="sd-box">
        <h3>样品信息</h3>
        <table>
          <tbody>
            <tr>
              <th>图片</th>
              <th width="100">品牌</th>
              <th>名称</th>
              <th>规格</th>
              <th>型号</th>
              <th>免费领取数量</th>
              <th width="100">申请数量</th>
            </tr>
            <template v-if="productDetail">
              <tr>
                <td>
                  <img v-if="productDetail.goodsImg" :src="productDetail.goodsImg" />
                  <img v-else src="@/assets/images/goods/default_img.png" />
                </td>
                <td>{{productDetail.brandName}}</td>
                <td>{{productDetail.name}}</td>
                <td>{{productDetail.specParam}}</td>
                <td>{{productDetail.materialSn}}</td>
                <td>{{productDetail.stepSample}}pcs</td>
                <td>
                  <!-- <div class="number-box">
                    <input class="buy-num" v-model="params.num"><span class="add">+</span><span class="sub">-</span>
                  </div> -->
                  <el-input-number v-model="num" :min="1" :max="parseInt(productDetail.stepSample)" label="描述文字"></el-input-number>
                  <p class="tip" v-if="tipFalg">已超出免费数量，会收取部分费用</p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="sd-box">
        <h3>项目信息</h3>
        <div class="lab-wrap">
          <div class="item">
            <span class="lab">所属行业：</span>
            <el-input placeholder="如手机、安防、汽车电子" v-model.trim="params.order.industry"></el-input>
          </div>
          <div class="item">
            <span class="lab">产品应用：</span>
            <el-input type="textarea" placeholder="样品用于什么项目，用于什么地方" v-model.trim="params.order.occupation"></el-input>
          </div>
        </div>
      </div>
      <div class="sd-box">
        <h3>收货信息</h3>
        <div class="lab-wrap">
          <div class="item">
            <span class="lab">收件公司：</span>
            <el-input placeholder="工程师填写个人" v-model.trim="params.order.company"></el-input>
          </div>
          <div class="item">
            <span class="lab">收件人：</span>
            <el-input placeholder="请填写收件人" v-model.trim="params.order.contacts"></el-input>
          </div>
          <div class="item">
            <span class="lab">联系电话：</span>
            <el-input placeholder="收件人手机号码" v-model.trim="params.order.contactsMobile"></el-input>
          </div>
          <div class="item">
            <span class="lab">收件地址：</span>
            <el-select :value="params.order.province" placeholder="请选择省份" @change="provinceSelect">
              <el-option v-for="(item,index) in province_ul" :key="index" :label="item.provinceName" :value="{code:item.provinceCode,text:item.provinceName}">
              </el-option>
            </el-select>
            <el-select :value="params.order.city" placeholder="请选择市区" @change="citySelect">
              <el-option v-for="(item,index) in city_ul" :key="index" :label="item.cityName" :value="{code:item.cityCode,text:item.cityName}">
              </el-option>
            </el-select>
            <el-select :value="params.order.district" placeholder="请选择区" @change="areaSelect">
              <el-option v-for="(item,index) in area_ul" :key="index" :label="item.areaName" :value="{code:item.areaCode,text:item.areaName}">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="lab">详细地址：</span>
            <el-input placeholder="详细地址" v-model.trim="params.order.address"></el-input>
          </div>
          <div class="item">
            <span class="lab">运费方式：</span>
            到付
          </div>
          <div class="item">
            <a href="javascript:;" class="pBtn" @click="saveSapmleOrder">立即提交</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '合晶芯城 - 厂家直营 - 二极管 - 晶体管 - 电阻电容 - 集成电路 - 电子元器件交易平台',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二极管,晶体管,电阻,电容,集成电路,电子元器件网上商城'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内厂家直营二极管、晶体管、电阻、电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  data () {
    return {
      province_ul: [],
      city_ul: [],
      area_ul: [],
      params: {
        "order": {
          "language": 1,
          "province": "",
          "city": "",
          "district": "",
          "address": "",
          "isSample": 1,
          "contacts": "",//联系人
          "contactsMobile": "",//联系人号码
          industry: '',//行业
          company: '',//公司
          occupation: '',//产品应用
          "hktOrderGoods": [{
            "goodsId": "",
            "goodsNum": 1
          }]
        }
      },
      num: 1,
      tipFalg: false,
    }
  },
  asyncData ({ store, route }) {
    let getProductDetail = store.dispatch("lxr/get_product_detail", {
      id: route.query.id
    });
    return getProductDetail;
  },
  created () {
    this.getProvince()
  },
  mounted () {

  },
  computed: {
    ...mapState("lxr", ["productDetail"])
  },
  mounted () {
    if (!this.productDetail) {
      this.getProductDetail()
    }
  },
  methods: {
    ...mapActions("lxr", [
      "get_address_info", 'get_product_detail', 'save_sapmle_order', 'save_order'
    ]),
    async saveSapmleOrder () {
      this.params.order.hktOrderGoods[0].goodsId = this.productDetail.id
      this.params.order.hktOrderGoods[0].goodsNum = this.num
      if (isNaN(this.params.order.contactsMobile) || this.params.order.contactsMobile.length != 11) {
        return this.$message.error('请输入正确的手机号码');
      }

      for (const key in this.params.order) {
        if (!this.params.order[key]) {
          return this.$message.error('请把信息填写完整');
        }
        if (this.params.order[key] == 'hktOrderGoods') {
          if (!this.params.order[key].goodsId) {
            return this.$message.error('样品ID获取错误，请刷新页面');
          } else if (!this.params.order[key].goodsNum) {
            return this.$message.error('请输入样品数量');
          }
        }
      }
      var res = await this.save_order(this.params);
      if (res.httpCode == 200) {
        this.$router.push({
          path: '/sample/success'
        })
      } else {
        this.$message.error(res.message);
      }
    },
    async getProductDetail () {
      var res = await this.get_product_detail({
        id: this.$route.query.id
      });
    },
    //获取省份
    async getProvince () {
      var res = await this.get_address_info({
        level: 0,
        parentCode: 0
      });
      if (res.httpCode == 200) {
        this.province_ul = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    //选择省份
    provinceSelect (obj) {
      this.getCity(obj);
      this.params.order.city = ''
      this.params.order.district = ''
      this.params.order.province = obj.text
    },
    //获取城市
    async getCity (obj) {
      var res = await this.get_address_info({
        level: 1,
        parentCode: obj.code
      });
      if (res.httpCode == 200) {
        this.city_ul = res.data;

      } else {
        this.$message.error(res.message);
      }
    },
    //选择城市
    citySelect (obj) {
      this.getArea(obj);
      this.params.order.district = ''
      this.params.order.city = obj.text
    },
    //获取区
    async getArea (obj) {
      var res = await this.get_address_info({
        level: 2,
        parentCode: obj.code
      });
      if (res.httpCode == 200) {
        this.cityShow = 2;
        this.area_ul = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    //选择区
    areaSelect (obj) {
      this.params.order.district = obj.text
    },
  },
  watch: {
    'num' (val) {
      if (val > this.productDetail.stepSample) {
        this.tipFalg = true
      } else {
        this.tipFalg = false
      }
    }
  }
}
</script>
<style lang="scss">
</style>