// API
var date = new Date()
var timer = date.getTime().toString()
const API = {
  //all
  'send-sms': '/aliyun/sms/sendSMS', //发送验证码
  'user-logout': '/user/userLogin/userLogout', //退出登录
  'is-login': '/user/userLogin/isLogin', //查询用户是否登录
  //'areainfo': 'http://www.online-service.vip/areainfo',http://api.online-service.vip/2018/cnareainfo/l1 //城市接口
  'get-address-info': '/shop/address/getAddressInfo', //城市接口
  'check-mobile-code': '/user/userLogin/checkMobileCode', //城市接口


  //lxr
  'password-login': '/user/userLogin/passwordLogin', //用手机和密码登录
  'phoneCode-login': '/user/userLogin/phoneCodeLogin', //用手机和短信登录
  'register-user': '/user/userLogin/registerUser', //用户注册
  'wx-login': '/user/userLogin/wxLogin', //微信授权登录
  'wx-callBack': '/user/userLogin/wxCallBack', //回调微信用户信息
  'wx-bindPhone': '/user/userLogin/bindingPhoneByWechat', //微信登录绑定手机
  'get-address': '/shop/address/getUserAddress', //获取用户地址
  'save-address': '/shop/address/saveUserAddress', //获取用户地址
  'del-address': '/shop/address/deleteUserAddress', //删除用户地址
  'edit-address': '/shop/address/updateUserAddress', //更新用户地址
  'set-def-address': '/shop/address/setDefaultAddress', //设置默认用户地址
  'get-userInfo': '/shop/userInfo/getUserInfo', //获取用户信息
  'save-userInfo': '/shop/userInfo/updateUser', //获取用户信息
  'send-email': '/shop/mail/sendMail', //发送邮件
  'bind-email': '/shop/mail/confirmBinding', //绑定邮箱
  'get-favorites': '/shop/favorite/saveHktFavorites', //新增收藏
  'get-favorites-type': '/shop/favorite/getCategoryInfo', //收藏种类
  'get-favorites-list': '/shop/favorite/favoritePage', //收藏列表
  'get-favorites-del': '/shop/favorite/deleteFavorites', //取消收藏
  'select-favorites-status': '/shop/favorite/getFavoritesByGoodsId', //查询商品收藏状态
  'set-favorites-remarks': '/shop/favorite/updateHktFavorites', //设置备注
  'get-order': '/async/order/getOrdersByCondition', //获取订单列表
  'get-news-type': '/user/news/queryNewsCategoryList', //获取新闻类型 
  'get-news-all': '/user/news/queryAllNews', //获取新闻类型
  'get-news-list': '/user/news/queryNewsArticlePage', //获取对应的新闻列表
  'get-news-detail': '/user/news/queryNewsArticle', //获取对应的新闻详情
  'get-friend-link': '/comm/system/queryFriendLink', //友情链接
  'update-password': '/user/userLogin/updatePassword',
  'get-product-menu': '/product/selectProductMenu', //商品目录
  'get-product-detail': '/product/findProduct', //商品详情
  'get-product-resemble': 'product/resemble', //相似商品
  'get-product-other': '/product/other', //买了该商品得还买了
  'get-product-list': '/product/selectProductLike', //商品列表
  'get-china-product-list-1': '/portal/positive/queryList', //国内现货
  'get-product-count': '/product/statistics', //获取商品总数
  'get-brand': '/brand/selectBrand', //商品品牌列表
  'get-brand-hot': '/brand/selectHotBrand', //热门品牌
  'get-brand-detail': '/brand/findBrand', //品牌详情
  'hot-keyword': 'product/selectTitle', //头部热门关键字
  'get-product-special': 'product/selectSpecialProduct', //首页特价
  'get-product-hot': 'product/selectHotProduct', //首页热门
  'get-product-purchase': 'product/selectPurchaseProduct', //首页采购专区

  'get-cart-list': '/cart/getCartList', //获取购物车列表
  'update-cart-status': '/cart/updateCart', //更新购物车状态
  'del-cart-ids': '/cart/deleteCartByGoodId', //删除购物车列表
  'get-cart-header': '/cart/getCartHeader', // 获取购物车header
  'check-cart': '/cart/checkCart', //验证选中的商品是否是同一类型
  'update-invoice': '/shop/userInvoice/updateUserInvoice', //更新个人中心发票
  'save-invoice': '/shop/userInvoice/saveUserInvoice', //新增个人中心发票
  'get-invoice': '/shop/userInvoice/getUserInvoice', //获取个人中心发票

  'get-kd': '/shop/expressCompany/getHktExpressCompanyList', //获取配送快递
  'get-md': '/shop/warehouseMap/getWarehouseMapList', //获取门店地址
  'get-id-md': '/shop/warehouseMap/getHktWarehouseMapById', //根据ID查询门店信息
  'link-confirm-order': '/shop/order/calculateGoodsPrice', //跳转到确认订单
  'save-order': '/shop/order/saveOrder', //提交订单
  'get-user-contacts': '/shop/userContacts/getUserContacts', //获取用户联系人信息
  'save-user-contacts': '/shop/userContacts/saveUserContacts', //新增用户联系人
  'del-user-contacts': '/shop/userContacts/deleteUserContacts', //删除用户联系人
  'get-pay-order': '/shop/order/getUserUnPayHktOrder', //获取用户未支付的订单
  'wxpayqrcode': '/wxpay/qrcode', //微信支付
  'alipay-pay': '/alipay/pay', //支付宝支付
  'get-order-list': '/shop/order/getOrdersByCondition', //订单列表
  'get-order-detail': '/shop/order/getHktOrderOderSn', //订单列表
  'get-order-history': '/shop/orderGoods/getHrderGoodsHistory', //历史购买记录
  'get-order-invoice': '/shop/orderInvoice/getUserInvoice', //订单详情订单发票
  'get-brand-category': '/product/category', //根据品牌ID查品牌分类子分类

  'get-message-list': '/shop/message/messagePage', //获取消息列表
  'update-message': '/shop/message/batchMessageIsRead', //更新消息
  'del-message': '/shop/message/batchDeleteHktMessage', //删除消息
  'get-index-order': '/order/getOrderStatistics', //首页订单统计
  'del-order': '/shop/order/deleteOrder', //删除用户订单
  'cancel-order': '/shop/order/cancelOrder', //取消用户订单
  'get-guesgs-list': 'product/guesgs', //购物车猜你喜欢
  'fc-fz': '/encapsulation/findPage', //封测 封装
  'fc-cl': '/material/findPage', //封测 材料
  'fc-cl2': '/encapsulation/getEncapsulation', //封测 材料
  'fc-type': '/material/getType', //封测 类型
  'fc-brand': '/fcProduct/getBrandNames', //封测 品牌
  'fc-cp': '/fcProduct/findPage', //封测 产品
  'fc-apply': '/fcApply/findPage', //封测 应用
  'fc-save': '/shop/fcOrder/save', //封测 提交订单
  'fc-cp-default': '/fcProduct/getDefaultBom', //封测 获取初始
  'fc-order': '/shop/fcOrder/findPage', //封测 获取个人订单
  'get-search-key': '/searchRemind/queryList', //搜索联想
  'update-sample-info': '/shop/orderSample/saveOrderSample', //样品资料上传





  //allen
  'getCateTwo': '/async/goods/getInfo',
  'addScore': '/shop/usersScore/saveUsersScore', //新增积分
  'queryScore': '/shop/usersScore/UserScorePage', //查询积分
  'querySignInPage': '/shop/UserSignIn/getHktUsersSignInPage', //用户签到列表
  'querySignInfo': '/shop/UserSignIn/getSignInListByMonth', //查询签到信息
  'addSignInfo': '/shop/UserSignIn/saveUserSignIn', // 新增签到信息
  'getSign': '/shop/UserSignIn/signInSatistics', // 签到统计
  'addCart': '/cart/saveCart', // 加入购物车
  'getNewsBanner': '/ad/queryAdList', // 获取新闻广告图
  'getHelpInfo': '/user/help/queryCategory', // 帮助中心信息
  'bomQuery': '/shop/bom/matchBom', // bom匹配查询 
  'downloadTpl': '/bom/downloadExcel', // 模板下载
  'exportBom': '/shop/bom/importBomExcel', // excel匹配
  'batchCart': '/cart/batchCart', // 商品批量加入购物车
  'getUserInfoCount': '/shop/userInfo/getUserStatistics', // 用户统计
  'checkUserOrderStatus': '/shop/order/CheckUserOrderStatus', // 判断是否操作可以下单的数量


  //2020-12-07
  'get-sample-hotList': '/prod/sample/hotSample', //首页热门样品
  'get-sample-list': '/prod/sample/queryList', //首页样品
  'save-sapmle-order': '/shop/sampleOrder/addSapmleOrder', //提交样品
  'get-sample-order': '/shop/sampleOrder/queryList', //样品订单
  'del-Sapmle-order': '/shop/sampleOrder/delSapmleOrder', //删除样品订单
  'get-sample-cagetory': '/prod/sample/sampleCagetory', //获取样品分类
  'get-brand-list': '/prod/brand/queryList', //品牌专区
  'get-sample-order-detail': '/shop/sampleOrder/findSapmleOrder', //样品订单详情
  'save-bom-order': '/shop/bom/saveBomOrder', //保存bom
  'del-bom-order': '/shop/bom/delBomOrder', //删除bom订单
  'get-bom-order': '/shop/bom/queryPage', //查询bom订单
  'get-bom-detail': '/shop/bom/findBomOrder', //查询bom详情
  'add-bom': '/shop/bom/addBomDetail', //添加bom
  'select-kd': '/shop/kuaidiniao/express', //快递查询
  'level-list': '/level/selectScoreLevel', //会员等级列表
  'bom-updata': '/detail/updateDetail', //bom更新
  'bom-add-list': '/detail/productListByBomParam', //bom新增列表
  'bom-exp': '/bom/exportBomExcel', //bom导出
  'bom-del': '/detail/delDetail', //bom删除
  'bom-updata-name': '/shop/bom/updateBomOrder', //修改bom名称
  'get-message-num': '/message/getUnReadMsg', //获取未读消息
  'filter-list': '/product/filterList', //条件帅选
  'certificate-list': '/certificate/selectCertificate', //授权证书列表

  'get-Card': '/card/selectShoppingCard', //积分兑换商品
  'dh-Card': '/shop/card/exchangePoints', //积分兑换
  'get-card-list': '/shop/card/selectUserCard', //积分兑换明细
  'del-Card': '/shop/card/delUserCard', //删除兑换
  'get-card-d': '/shop/card/findUserCardDetail', //卡券详情
  'get-cuser-info': '/shop/card/userInfo', //刷新用户信息
}

export default API