<template>
  <div class="info_top_bg clear">
    <div class="info_top">
      <h1 class="l">
        <router-link to="/">
          <img height="44" src="@/assets/images/login/logo.svg">
        </router-link>
      </h1>
      <div class="l bt">
        <span class="name" id="top-name">{{public_name}}</span>
      </div>
      <template v-if="$route.path=='/cart/order'">
        <router-link class="r" to="/cart">返回购物车</router-link>
      </template>
      <template v-else>
        <router-link class="r" to="/index">商城首页</router-link>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
    }
  },

  created () {

  },
  computed: {
    ...mapState('cwq', ['public_name'])
  },
  methods: {
    ...mapMutations('cwq', ['PUBLIC_NAME']),
  },
  mounted () {
    this.PUBLIC_NAME(this.$route.meta.title)
  }
}
</script>



