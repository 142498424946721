<template>
  <div class="r userCenter_container_box">
    <h2>修改密码</h2>
    <div class="userCenter_container modify_password">
      <div style="position:relative;">
        <table>
          <tbody>
            <tr>
              <td class="left"><i>*</i>手机号码：</td>
              <td class="phone">
                <input :value="params.mobile" readonly type="text">
                <i class="empty_input"></i>
                <span class="verify_right"></span>
                <p class="error"></p>
              </td>
            </tr>
            <tr>
              <td class="left"><i>*</i>验证码：</td>
              <td class="clear vertify">
                <input class="l" id="smscode" maxlength="6" placeholder="请输入验证码" name="smscode" type="text" v-model.trim="code">
                <i class="empty_input smscode"></i>
                <span class="l" id="send" v-show="!sendFalgPhone" @click="sendSms">发送验证码</span>
                <span class="l" id="send" v-show="sendFalgPhone">重新发送(<i ref="timeNumPhone">60</i>)</span>
                <p class="error"></p>
              </td>
            </tr>
            <tr>
              <td class="left"><i>*</i>输入新密码：</td>
              <td>
                <input id="password" name="password" type="password" placeholder="字母与数字组合至少6位" v-model.trim="pswd1">
                <i class="empty_input"></i>
                <span class="verify_right"></span>
                <p class="error"></p>
              </td>
            </tr>
            <tr>
              <td class="left"><i>*</i>确认新密码：</td>
              <td>
                <input id="repassword" name="repassword" type="password" placeholder="字母与数字组合至少6位" v-model.trim="pswd2">
                <i class="empty_input"></i>
                <span class="verify_right"></span>
                <p class="error"></p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td><span class="submit" id="confirm" @click="submit">确认</span></td>
            </tr>
          </tbody>
        </table>
        <div id="error" class="login_tips" style="display:none;">请先输入验证码</div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-修改密码',
    }
  },
  data () {
    return {
      params: {
        mobile: '',
        password: ''
      },
      code: '',
      sendFalgPhone: false,
      phoneTime: '',
      pswd1: '',
      pswd2: '',
    }
  },

  created () {

  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['send_sms', 'check_mobile_code', 'save_userInfo']),
    //发送短信
    async sendSms () {
      let sendCode = 60
      if (!this.params.mobile || this.params.mobile.length != 11) {
        this.$message.error('手机号码有误')
        return
      }
      let res = await this.send_sms({ mobile: this.params.mobile })
      if (res.httpCode == 200) {
        this.sendFalgPhone = true
        this.phoneTime = setInterval(() => {
          sendCode--
          this.$refs.timeNumPhone.innerText = sendCode
          if (sendCode == 0) {
            clearInterval(this.phoneTime)
            this.sendFalgPhone = false
            this.$refs.timeNumPhone.innerText = '60'
          }
        }, 1000);
      }
    },

    async submit () {
      if (!this.params.mobile || this.params.mobile.length != 11) {
        this.$message.error('手机号码有误')
        return
      }
      else if (!this.code) {
        this.$message.error('请输入验证码')
        return
      } else if (this.code.length != 6) {
        this.$message.error('请输入6位数验证码')
        return
      }
      if (!(this.pswd1.length >= 6 && this.pswd1.length <= 20)) {
        this.$message.error('请输入正确的新密码')
        return
      } else if (!(this.pswd2.length >= 6 && this.pswd2.length <= 20)) {
        this.$message.error('请输入正确的重复密码')
        return
      }
      let res = await this.check_mobile_code({
        mobile: this.params.mobile,
        code: this.code
      })
      if (res.httpCode == 200) {
        this.updatePs()
      } else {
        this.$message.error(res.message)
      }
    },

    //更新手机
    async updatePs () {
      let res = await this.save_userInfo({
        password: this.params.password,
      })
      if (res.httpCode == 200) {
        this.$message.success('修改成功')
        this.params.password = ''
        this.pswd1 = ''
        this.pswd2 = ''
        this.code = ''
        clearInterval(this.phoneTime)
        this.sendFalgPhone = false
        this.$refs.timeNumPhone.innerText = '60'
      } else {
        this.$message.error(res.message)
      }
    },
  },
  mounted () {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      this.params.mobile = JSON.parse(userInfo).mobile
    }
  }, watch: {
    'pswd1' (val) {
      if (val.length >= 6 && val.length <= 20) {
        if (val == this.pswd2) {
          this.params.password = val
        }
      } else {
        this.params.password = ''
      }
    },
    'pswd2' (val) {
      if (val.length >= 6 && val.length <= 20) {
        if (val == this.pswd1) {
          this.params.password = val
        }
      } else {
        this.params.password = ''
      }
    },
  },
  beforeDestroy () {
    clearInterval(this.phoneTime)
  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-modifyPassword.scss";
</style>


