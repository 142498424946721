import Vue from 'vue'
import Vuex from 'vuex'
import lxr from './modules/lxr'
import lw from './modules/lw'
import cwq from './modules/cwq'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export function createStore() {
  return new Vuex.Store({
    modules: {
      lw,
      lxr,
      cwq
    },
    strict: false,
  })
}