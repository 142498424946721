<template>
  <div class="r userCenter_container_box">
    <h2>收货地址</h2>
    <div class="userCenter_container shipping_address" v-loading="!addressListFlag">
      <!-- 有收货地址 -->
      <div v-if="addressList.length>0">
        <ul class="list">
          <li class="active" v-for="(item, index) in addressList" :key="index">
            <div class>
              <i class="tiaowen"></i>
              <p class="l">{{item.consignee}}</p>
              <p class="l">18038017694</p>
              <p class="l address_detail" :title="item.address">{{item.province}}{{item.city}}{{item.district}}{{item.address}}</p>
              <p v-if="item.isDefault==1" class="l default">默认</p>
              <p class="r editor_ship" @click="editAddressShowFun(item.id,index)">修改</p>
              <p v-if="item.isDefault!=1" class="setDefault r" @click="setDefAddress(item.id)">设为默认</p>
              <i class="del" @click="delAddress(item.id)"></i>
            </div>
          </li>
        </ul>
        <div class="top" v-if="addressListFlag">
          <span id="add" class="submit" @click="addressShowFun">添加地址</span>
        </div>
      </div>
      <!-- 无收货地址 -->
      <div class="shippingA_none_box" v-if="addressListFlag&&addressList.length<1">
        <img src="@/assets/images/userCenter/shipping_none.png" />
        <p>暂无收货地址</p>
        <p>
          <a id="add_address" href="javascript:;" @click="addressShowFun">新增收获地址</a>
        </p>
      </div>
    </div>

    <!-- 添加 -->
    <!--新增收获地址-->
    <transition name="el-fade-in">
      <div class="mask_layout1" v-if="addressShow">
        <!-- 新增收货地址  -->
        <div id="new_shipping_address" class="pop_up_box new_shipping_address">
          <div class="pop_up_title clear">
            <h3 class="l">新增/修改收货地址</h3>
            <span class="r" @click="addressShow=false"></span>
          </div>
          <div class="new_shipping_body">
            <table>
              <tbody>
                <tr style="display: none">
                  <input name="address_id" type="hidden" value="0" />
                </tr>
                <tr>
                  <td>
                    <i class="error">*</i>收货人
                  </td>
                  <td>
                    <input name="consignee" maxlength="10" type="text" v-model="params.consignee " />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i>*</i>手机号
                  </td>
                  <td>
                    <input name="mobile" maxlength="11" type="text" v-model="params.mobile" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i>*</i>所在地区：
                  </td>
                  <td class="clear select_area_box">
                    <div>
                      <span id="province_click" @click="getProvince">
                        <input name="province_id" type="hidden" placeholder="请选择省" value="0" />
                        <input name="province" type="text" placeholder="请选择省" v-model="params.province" readonly />
                      </span>
                      <ul class="province_ul" v-show="cityShow==0">
                        <li v-for="(item, index) in province_ul" :key="index" :code="item.provinceCode">
                          <span @click="provinceSelect(index,item.provinceName,item.provinceCode)" :class="{active:provinceSelectIdx==index}">{{item.provinceName}}</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span id="city_click" @click="getCity">
                        <input name="city_id" type="hidden" placeholder="请选择市" value="0" />
                        <input name="city" type="text" placeholder="请选择市" v-model="params.city" readonly />
                      </span>
                      <ul class="city_ul" v-show="cityShow==1">
                        <li v-for="(item, index) in city_ul" :key="index" :code="item.cityCode">
                          <span @click="citySelect(index,item.cityName,item.cityCode)" :class="{active:citySelectIdx==index}">{{item.cityName}}</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span id="district_click" @click="getArea">
                        <input name="district_id" type="hidden" placeholder="请选择区" value="0" />
                        <input name="district" type="text" placeholder="请选择区" v-model="params.district" readonly />
                      </span>
                      <ul class="district_ul" v-show="cityShow==2">
                        <li v-for="(item, index) in area_ul" :key="index" :code="item.areaCode">
                          <span @click="areaSelect(index,item.areaName,item.areaCode)" :class="{active:areaSelectIdx==index}">{{item.areaName}}</span>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i>*</i>详细地址：
                  </td>
                  <td>
                    <textarea id="address_detail" maxlength="120" placeholder="街道、小区、楼牌号等" v-model="params.address"></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <span id="shippingSave" v-if="!params.id" class="submit" @click="saveAddress">保存</span>
            <span id="shippingSave" v-else class="submit" @click="editAddress">保存</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import https from "@/https.js";
export default {
  name: "app",
  components: {},
  metaInfo () {
    return {
      title: '个人中心-收货地址',
    }
  },
  data () {
    return {
      addressShow: false,
      province_ul: [],
      cityShow: -1,
      provinceSelectIdx: -1,
      city_ul: [],
      citySelectIdx: -1,
      area_ul: [],
      areaSelectIdx: -1,
      curProvinceCode: -1,
      curCityCode: -1,
      params: {
        address: "", //详细地址
        city: "", //城市
        district: "", //区
        mobile: "", //手机
        province: "", //省份
        consignee: ""
      },
      addressList: [],
      addressListFlag: false
    };
  },

  created () { },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions("lxr", [
      "get_address",
      "save_address",
      "del_address",
      "edit_address",
      "set_def_address",
      "get_address_info"
    ]),
    addressShowFun () {
      this.opt();
      this.addressShow = !this.addressShow;
    },
    editAddressShowFun (id, idx) {
      if (id) {
        this.params.address = this.addressList[idx].address;
        this.params.city = this.addressList[idx].city;
        this.params.district = this.addressList[idx].district;
        this.params.mobile = this.addressList[idx].mobile;
        this.params.province = this.addressList[idx].province;
        this.params.consignee = this.addressList[idx].consignee;
        this.params.id = id;
      }
      this.addressShow = !this.addressShow;
    },
    async getAddress () {
      var res = await this.get_address();
      if (res.httpCode == 200) {
        this.addressList = res.data;
        this.addressListFlag = true;
      } else {
        //this.$message.error(res.message)
      }
    },

    //获取省份
    async getProvince () {
      let _this = this;
      var res = await this.get_address_info({
        level: 0,
        parentCode: 0
      });
      if (res.httpCode == 200) {
        _this.cityShow = 0;
        _this.province_ul = res.data;
      } else {
        this.$message.error(res.message);
      }
    },

    //选择省份
    provinceSelect (idx, val, code) {
      this.provinceSelectIdx = idx;
      this.params.province = val;
      this.getCity(code);
    },

    //获取城市
    async getCity (code) {
      let _this = this;
      if (code > 0) {
        _this.curProvinceCode = code;
      }
      if (_this.curProvinceCode < 0) {
        return;
      }
      var res = await this.get_address_info({
        level: 1,
        parentCode: _this.curProvinceCode
      });
      if (res.httpCode == 200) {
        _this.cityShow = 1;
        _this.city_ul = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    //选择城市
    citySelect (idx, val, code) {
      this.citySelectIdx = idx;
      this.params.city = val;
      this.getArea(code);
    },

    //获取区
    async getArea (code) {
      let _this = this;
      if (code > 0) {
        _this.curCityCode = code;
      }
      if (_this.curCityCode < 0) {
        return;
      }

      var res = await this.get_address_info({
        level: 2,
        parentCode: _this.curCityCode
      });
      if (res.httpCode == 200) {
        _this.cityShow = 2;
        _this.area_ul = res.data;
      } else {
        _this.$message.error(res.message);
      }
    },
    //选择城市
    areaSelect (idx, val, code) {
      this.cityShow = -1;
      this.areaSelectIdx = idx;
      this.params.district = val;
    },

    //新增用户地址
    async saveAddress () {
      let falg = this.checkAddVal();
      if (!falg) {
        return;
      }
      var res = await this.save_address(this.params);
      if (res.httpCode == 200) {
        this.getAddress();
        this.addressShowFun();
        this.opt();
      } else {
        this.$message.error(res.message);
      }
    },

    //更新用户地址
    async editAddress (id) {
      let falg = this.checkAddVal();
      if (!falg) {
        return;
      }
      var res = await this.edit_address(this.params);
      if (res.httpCode == 200) {
        this.getAddress();
        this.addressShowFun();
        this.opt();
      } else {
        this.$message.error(res.message);
      }
    },

    //设置默认
    async setDefAddress (id) {
      var res = await this.set_def_address({ id: id });
      if (res.httpCode == 200) {
        this.getAddress();
      } else {
        this.$message.error(res.message);
      }
    },

    checkAddVal () {
      let falg;
      if (!this.params.consignee) {
        this.$message.error("请填写收货人");
        falg = false;
      } else if (!this.params.mobile) {
        this.$message.error("请填写手机号");
        falg = false;
      } else if (!this.params.province) {
        this.$message.error("请选择省");
        falg = false;
      } else if (!this.params.city) {
        this.$message.error("请选择市");
        falg = false;
      } else if (!this.params.district) {
        this.$message.error("请选择区");
        falg = false;
      } else if (!this.params.address) {
        this.$message.error("请填写详细地址");
        falg = false;
      } else {
        falg = true;
      }
      return falg;
    },

    opt () {
      this.params = {
        address: "", //详细地址
        city: "", //城市
        district: "", //街道
        mobile: "", //手机
        province: "", //省份
        consignee: ""
      };
      this.provinceSelectIdx = -1;
      this.citySelectIdx = -1;
      this.areaSelectIdx = -1;
    },

    //删除用户地址
    delAddress (id) {
      this.$confirm("确认删除该地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.delFun(id);
        })
        .catch(() => { });
    },

    async delFun (id) {
      var res = await this.del_address({ id: id });
      if (res.httpCode == 200) {
        this.getAddress();
      } else {
        this.$message.error(res.message);
      }
    }
  },
  mounted () {
    // let token = sessionStorage.getItem('access-token');
    // this.$axios.defaults.headers['access-token'] = token
    this.getAddress();
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-addrManage.scss";
</style>


