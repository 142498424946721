import Vue from 'vue'
import https from '@/https.js'
const initState = {
  /* enterpriseSerialNumber:'', */
}

const state = {
  userTitle: '', //个人中心标题
  cartFlag: false, //购物车打开标志
  userInfo: '', //用户信息保存
  token: '',
  newType: null,
  newList: null,
  newList2: null,
  newList3: null,
  newAll: null,
  newDetail: null,
  next: null, // 上一篇
  last: null, // 下一篇
  hot: null, // 热门
  dayNews: null, // 每日行情
  newKeyword: '', //新闻关键字
  newsNavIdx: null,
  newTypeName: null,
  friendLink: null,
  brandList: null,
  brandHotList: null,
  brandDetail: null,
  brandCategory: null,
  productList: null,
  productListHot: null, //首页热门
  productListSpecial: null, //特价
  productListPurchase: null, //采购专区
  productMenu: null,
  productMenuIndex: null,
  hotKeywordList: null,
  indexList: null,
  productCount: 0,
  productDetail: null,
  productResemble: null,
  cartListX: [],
  cartLength: 0,
  sumPriceX: 0,
  indexOrderList: null,
  bar1: '',
  bar2: '',
  bar3: 'vuex的数据',
  a: '',
  seoName: '', //搜索SEO
  chinaProductList1: null,
  fcClList: null,
  fcFzList: null,
  sampleListIndex: null,
  sampleList: null,
  sampleCagetory: null,
  newBrandList: null,
  SQlist: null,
}

const mutations = {
  //首页订单统计
  GET_INDEX_ORDER(state, data) {
    if (data.data) {
      state.indexOrderList = data.data
    } else {
      state.indexOrderList = null
    }
  },

  SET_CART_LENGTH(state) {
    state.cartLength += 1
  },
  GET_CART_LENGTH(state, data) {
    if (data.httpCode == 200) {
      state.cartLength = data.data.length
    }
  },
  GET_CART_LIST(state, data) {
    if (data.httpCode == 200) {
      state.cartListX = data.data
      state.sumPriceX = 0
      state.cartLength = state.cartListX.length
      if (state.cartListX.length > 0) {
        state.cartListX.forEach(item => {
          var length

          if (item.priceTemplate && item.priceTemplate.length > 0) {
            if (typeof (item.priceTemplate) == 'string') {
              item.priceTemplate = JSON.parse(item.priceTemplate)
            }
            length = item.priceTemplate.length
            item.goodsNum = parseInt(item.goodsNum)
            // 获取最大值的价格
            let arr = [],
              ne = [];
            if (item.priceTemplate) {
              for (let i = 0; i < item.priceTemplate.length; i++) {
                arr.push(item.priceTemplate[i].num);
                ne.push(item.priceTemplate[i]);
              }
            }
            var bigPrice;
            var maxNumber = Math.max.apply(null, arr);
            // // 查找调价模板的最大的单价
            ne.forEach(e => {
              if (e.num == maxNumber) {
                bigPrice = e.price;
              }
            });
            if (item.goodsNum < item.priceTemplate[0].num) {
              item.calcPrice = item.price
            } else {
              for (let i = 0; i < item.priceTemplate.length; i++) {
                if (item.goodsNum > maxNumber) {
                  item.calcPrice = item.goodsNum * bigPrice
                }
                if (item.goodsNum >= item.priceTemplate[i].num) {
                  item.calcPrice = item.priceTemplate[i].price
                }
              }
            }
            state.sumPriceX += (parseFloat((item.goodsNum * item.calcPrice).toFixed(2)))
          }
        });
        state.sumPriceX = state.sumPriceX.toFixed(2)
      }
    }
  },
  SET_NEWS_NAV_IDX(state, data) {
    state.newsNavIdx = data
  },

  SET_USERINFO(state, data) {
    state.userInfo = data
  },
  SET_USERTITLE(state, data) {
    state.userTitle = data
  },
  //购物车状态
  setCartFalg(state) {
    state.cartFlag = !state.cartFlag
  },
  SET_TOKEN(state, data) {
    state.token = data
  },

  //新闻类型
  GET_NEWS_TYPE(state, data) {
    state.newType = data.data
  },

  //新闻列表
  GET_NEWS_LIST(state, data) {
    if (data.data) {
      if (data.data.records.length > 0) {
        state.newList = data.data
      } else {
        state.newList = null
      }
    } else {
      state.newList = null
    }
  },
  //新闻列表
  GET_NEWS_LIST2(state, data) {
    if (data.data) {
      if (data.data.records.length > 0) {
        state.newList2 = data.data
      } else {
        state.newList2 = null
      }
    } else {
      state.newList2 = null
    }
  },
  //新闻列表
  GET_NEWS_LIST3(state, data) {
    if (data.data) {
      if (data.data.records.length > 0) {
        state.newList3 = data.data
      } else {
        state.newList3 = null
      }
    } else {
      state.newList3 = null
    }
  },

  SET_NEWS_NAV_NAME(state, data) {
    state.newTypeName = data
  },

  //新闻列表
  GET_NEWS_ALL(state, data) {
    state.newAll = data.data
  },

  //新闻详情
  GET_NEWS_DETAIL(state, data) {
    state.newDetail = null
    if (data) {
      state.newDetail = data.data.news
      state.next = data.data.next // 上一篇
      state.last = data.data.last // 下一篇
      state.hot = data.data.hot // 热门
      state.dayNews = data.data.dayNews // 每日行情
      if (state.newDetail.keywords) {
        state.newDetail.keywords.forEach(item => {
          state.newKeyword += item.tag + ' '
        });
      }
    } else {
      state.newDetail = null
    }
  },

  //友情链接
  GET_FRIEND_LINK(state, data) {
    state.friendLink = data.data
  },

  //品牌列表
  GET_BRAND(state, data) {
    state.brandList = data.data
  },

  //热门品牌列表
  GET_BRAND_HOT(state, data) {
    if (data.data) {
      state.brandHotList = data.data.records
    }
  },

  //品牌详情
  GET_BRAND_DETAIL(state, data) {
    if (data) {
      state.brandDetail = data.data
      state.brandDetail.brandDesc_ch.info = Vue.iBox.returnHtml(state.brandDetail.brandDesc_ch.info)
      state.brandDetail.brandDesc_ch.content = Vue.iBox.returnHtml(state.brandDetail.brandDesc_ch.content)
    } else {
      state.brandDetail = null
    }
  },

  //商品列表
  GET_PRODUCT_LIST(state, data) {
    if (data) {
      if (data.data) {
        if (data.data.seoName) {
          state.seoName = data.data.seoName
        }
        if (data.data.records.length > 0) {
          data.data.records.forEach((item, index) => {
            if (typeof (item.priceTemplate) == 'string') {
              if (item.priceTemplate.length > 1) {
                item.priceTemplate = JSON.parse(item.priceTemplate)
              }
            }
          });
          state.productList = data.data
        } else if (data.data.object.QueryItems.length > 0) {
          state.productList = data.data
        } else if (data.data.object.categoryItem.length > 0) {
          state.productList = data.data
        } else {
          state.productList = null
        }
      } else {
        state.productList = null
      }
    } else {
      state.productList = null
    }
  },

  //国内商品列表
  GET_CHINA_PRODUCT_LIST_1(state, data) {
    if (data) {
      if (data.data) {
        if (data.data.ResultList.length > 0) {
          data.data.ResultList.forEach((item, index) => {
            if (typeof (item.StepPrices) == 'string') {
              if (item.StepPrices.length > 1) {
                item.StepPrices = JSON.parse(item.StepPrices)
              }
            }
          });
          state.chinaProductList1 = data.data
        } else {
          state.chinaProductList1 = null
        }
      } else {
        state.chinaProductList1 = null
      }
    } else {
      state.chinaProductList1 = null
    }
  },

  SET_PRODUCT_LIST(state, data) {
    state.productList.records[data.index].collect = data.collect
  },
  //首页专区
  GET_PRODUCT_HOT(state, data) {
    if (data.data) {
      if (data.data.records.length > 0) {
        state.productListHot = data.data.records
      } else {
        state.productListHot = null
      }
    } else {
      state.productListHot = null
    }
  },
  GET_PRODUCT_SPECIAL(state, data) {
    if (data.data) {
      if (data.data.records.length > 0) {
        state.productListSpecial = data.data.records
      } else {
        state.productListSpecial = null
      }
    } else {
      state.productListSpecial = null
    }
  },
  GET_PRODUCT_PURCHASE(state, data) {
    if (data.data) {
      if (data.data.records.length > 0) {
        state.productListPurchase = data.data.records
      } else {
        state.productListPurchase = null
      }
    } else {
      state.productListPurchase = null
    }
  },

  //商品目录
  GET_PRODUCT_MENU(state, data) {
    state.productMenu = data.data
  },
  GET_PRODUCT_MENU_INDEX(state, data) {
    if (data.data) {
      data.data.forEach(item => {
        if (item.nameCh == '二极管') {
          item.image2 = require('@/assets/images/type/t1.png')
        } else if (item.nameCh == '晶体管') {
          item.image2 = require('@/assets/images/type/t2.png')
        } else if (item.nameCh == '电阻') {
          item.image2 = require('@/assets/images/type/t3.png')
        } else if (item.nameCh == '电容') {
          item.image2 = require('@/assets/images/type/t4.png')
        } else if (item.nameCh == '电源IC') {
          item.image2 = require('@/assets/images/type/t5.png')
        } else if (item.nameCh == '整流桥') {
          item.image2 = require('@/assets/images/type/t6.png')
        } else if (item.nameCh == 'MOS场效应管') {
          item.image2 = require('@/assets/images/type/t7.png')
        }
      });
    }
    state.productMenuIndex = data.data
  },

  //头部热门关键字
  HOT_KEYWORD(state, data) {
    state.hotKeywordList = data.records
  },

  //首页数据
  GET_INDEX(state, data) {
    state.indexList = data.data
  },

  //获取商品总数
  GET_PRODUCT_COUNT(state, data) {
    state.productCount = data.data.count
  },

  SET_PRODUCT_COLLECT(state, data) {
    state.productDetail.collect = data
  },
  //获取商品详情
  GET_PRODUCT_DETAIL(state, data) {
    if (data) {
      if (data.data && data.data.priceTemplate) {
        if (data.data.priceTemplate.length > 0) {
          if (typeof (data.data.priceTemplate) == 'string') {
            if (data.data.priceTemplate.length > 1) {
              data.data.priceTemplate = JSON.parse(data.data.priceTemplate)
            }
          }
        }
        state.productDetail = data.data
      } else {
        state.productDetail = null
      }
    } else {
      state.productDetail = null
    }
  },

  //根据品牌分类查询分类
  GET_BRAND_CATEGORY(state, data) {
    console.log(data)
    if (data) {
      state.brandCategory = data.data
    } else {
      state.brandCategory = null
    }
  },

  //获取相似产品
  // GET_PRODUCT_RESEMBLE(state, data) {
  //   state.productResemble = data.data
  // },

  setItem1(state, data) {
    // Vue.set(state, 'bar1', data.message)
    state.bar2 = data.data
  },

  FC_CL(state, data) {
    state.fcClList = data
  },
  FC_FZ(state, data) {
    state.fcFzList = data
  },
  SET_SAMPLE(state, data) {
    state.sampleListIndex = data
  },
  SET_SAMPLE_2(state, data) {
    if (data) {
      if (data.data) {
        if (data.data.seoName) {
          state.seoName = data.data.seoName
        }
        if (data.data.records.length > 0) {
          data.data.records.forEach((item, index) => {
            if (typeof (item.priceTemplate) == 'string') {
              if (item.priceTemplate.length > 1) {
                item.priceTemplate = JSON.parse(item.priceTemplate)
              }
            }
          });
          state.sampleList = data.data
        } else if (data.data.object.QueryItems.length > 0) {
          state.sampleList = data.data
        } else {
          state.sampleList = null
        }
      } else {
        state.sampleList = null
      }
    } else {
      state.sampleList = null
    }
  },
  GET_SAMPLE_CAGETORY(state, data) {
    state.sampleCagetory = data.data
  },
  GET_BRAND_LIST(state, data) {
    if (data) {
      state.newBrandList = data.data
    } else {
      state.newBrandList = null
    }
  },
  GET_SQ_LIST(state, data) {
    state.SQlist = data.data
  },

}

const actions = {
  //兑换后获取积分
  get_cuser_info({
    commit
  }, params = {}) {
    return https.fetchPost('get-cuser-info', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //积分明细详情
  get_card_d({
    commit
  }, params = {}) {
    return https.fetchPost('get-card-d', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //积分明细删除
  del_Card({
    commit
  }, params = {}) {
    return https.fetchPost('del-Card', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //积分明细
  get_card_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-card-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //积分兑换
  dh_Card({
    commit
  }, params = {}) {
    return https.fetchPost('dh-Card', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //积分兑换商品
  get_Card({
    commit
  }, params = {}) {
    return https.fetchPost('get-Card', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //授权证书列表
  certificate_list({
    commit
  }, params = {}) {
    return https.fetchPost('certificate-list', params)
      .then(data => {
        commit('GET_SQ_LIST', data.data)
        return data.data
      }).catch(err => {})
  },


  //获取消息数量
  'filter_list'({
    commit
  }, params = {}) {
    return https.fetchPost('filter-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取消息数量
  get_message_num({
    commit
  }, params = {}) {
    return https.fetchPost('get-message-num', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //bom修改名称
  bom_updata_name({
    commit
  }, params = {}) {
    return https.fetchPost('bom-updata-name', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //bom列表删除
  bom_del({
    commit
  }, params = {}) {
    return https.fetchPost('bom-del', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //bom新增列表
  bom_add_list({
    commit
  }, params = {}) {
    return https.fetchPost('bom-add-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //bom更新
  bom_updata({
    commit
  }, params = {}) {
    return https.fetchPost('bom-updata', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //会员等级列表
  level_list({
    commit
  }, params = {}) {
    return https.fetchPost('level-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //添加
  select_kd({
    commit
  }, params = {}) {
    return https.fetchGet('select-kd', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //添加
  add_bom({
    commit
  }, params = {}) {
    return https.fetchPost('add-bom', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //查询bom列表
  get_bom_detail({
    commit
  }, params = {}) {
    return https.fetchPost('get-bom-detail', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //查询bom列表
  get_bom_order({
    commit
  }, params = {}) {
    return https.fetchPost('get-bom-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //删除bom
  del_bom_order({
    commit
  }, params = {}) {
    return https.fetchPost('del-bom-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //保存bom
  save_bom_order({
    commit
  }, params = {}) {
    return https.fetchPost('save-bom-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //样品订单详情
  get_sample_order_detail({
    commit
  }, params = {}) {
    return https.fetchPost('get-sample-order-detail', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //品牌专区
  get_brand_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-brand-list', params)
      .then(data => {
        commit('GET_BRAND_LIST', data.data)
        return data.data
      }).catch(err => {})
  },
  //样品列表搜索
  get_product_list_sample({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-list', params)
      .then(data => {
        if (params.seoName) {
          data.data.data.seoName = params.seoName
        }
        commit('SET_SAMPLE_2', data.data)
        return data.data
      }).catch(err => {})
  },

  //获取样品分类
  get_sample_cagetory({
    commit
  }, params = {}) {
    return https.fetchPost('get-sample-cagetory', params)
      .then(data => {
        commit('GET_SAMPLE_CAGETORY', data.data)
        return data.data
      }).catch(err => {})
  },

  //样品订单删除
  del_sample_order({
    commit
  }, params = {}) {
    return https.fetchPost('del-Sapmle-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //样品订单
  get_sample_order({
    commit
  }, params = {}) {
    return https.fetchPost('get-sample-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //样品保存
  save_sapmle_order({
    commit
  }, params = {}) {
    return https.fetchPost('save-sapmle-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //样品页面样品列表
  get_sample_list_2({
    commit
  }, params = {}) {
    return https.fetchPost('get-sample-list', params)
      .then(data => {
        commit('SET_SAMPLE_2', data.data)
        return data.data
      }).catch(err => {})
  },
  //首页样品列表
  get_sample_hotList({
    commit
  }, params = {}) {
    return https.fetchPost('get-sample-hotList', params)
      .then(data => {
        commit('SET_SAMPLE', data.data.data)
        return data.data
      }).catch(err => {})
  },

  // 判断是否操作可以下单的数量
  check_user_order_status({
    commit
  }, params = {}) {
    return https.fetchGet('checkUserOrderStatus', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //验证选中的商品是否是同一类型
  check_cart({
    commit
  }, params = {}) {
    return https.fetchPost('check-cart', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //样品资料上传
  update_sample_info({
    commit
  }, params = {}) {
    return https.fetchPost('update-sample-info', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //搜索联想
  get_search_key({
    commit
  }, params = {}) {
    return https.fetchPost('get-search-key', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //封测 提交
  fc_save({
    commit
  }, params = {}) {
    return https.fetchPost('fc-save', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //封测 应用
  fc_apply({
    commit
  }, params = {}) {
    return https.fetchPost('fc-apply', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //封测 订单
  fc_order({
    commit
  }, params = {}) {
    return https.fetchPost('fc-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //封测 产品
  fc_cp({
    commit
  }, params = {}) {
    return https.fetchPost('fc-cp', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //封测 默认产品
  fc_cp_default({
    commit
  }, params = {}) {
    return https.fetchGet('fc-cp-default', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //封测 品牌
  fc_brand({
    commit
  }, params = {}) {
    return https.fetchGet('fc-brand', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //封测 材料
  fc_cl2({
    commit
  }, params = {}) {
    return https.fetchPost('fc-cl2', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //封测 材料
  fc_cl({
    commit
  }, params = {}) {
    return https.fetchPost('fc-cl', params)
      .then(data => {
        commit('FC_CL', data.data.data)
        return data.data
      }).catch(err => {})
  },
  //封测 封装
  fc_fz({
    commit
  }, params = {}) {
    return https.fetchPost('fc-fz', params)
      .then(data => {
        commit('FC_FZ', data.data.data)
        return data.data
      }).catch(err => {})
  },
  //封测 根据材料获取类型
  fc_type({
    commit
  }, params = {}) {
    return https.fetchGet('fc-type', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //退出账户
  user_logout({
    commit
  }, params = {}) {
    return https.fetchGet('user-logout', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //查询用户是否登录
  is_login({
    commit
  }, params = {}) {
    return https.fetchGet('is-login', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //用手机和密码登录
  password_login({
    commit
  }, params = {}) {
    return https.fetchGet('password-login', params)
      .then(data => {
        commit('SET_USERINFO', data.data.data)
        return data.data
      }).catch(err => {})
  },


  //用手机和短信登录
  phoneCode_login({
    commit
  }, params = {}) {
    return https.fetchGet('phoneCode-login', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //用户注册
  register_user({
    commit
  }, params = {}) {
    return https.fetchPost('register-user', params)
      .then(data => {
        // commit('setItem1', data)
        return data.data
      }).catch(err => {})
  },

  //发送短信
  send_sms({
    commit
  }, params = {}) {
    return https.fetchGet('send-sms', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //微信授权登录
  wx_login({
    commit
  }, params = {}) {
    return https.fetchGet('wx-login', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //回调微信用户信息
  wx_callBack({
    commit
  }, params = {}) {
    return https.fetchGet('wx-callBack', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //微信登录绑定手机
  wx_bindPhone({
    commit
  }, params = {}) {
    return https.fetchPost('wx-bindPhone', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取用户地址
  get_address({
    commit
  }, params = {}) {
    return https.fetchGet('get-address', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //保存用户地址
  save_address({
    commit
  }, params = {}) {
    return https.fetchPost('edit-address', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //删除用户地址
  del_address({
    commit
  }, params = {}) {
    return https.fetchGet('del-address', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //更新用户地址
  edit_address({
    commit
  }, params = {}) {
    return https.fetchPost('edit-address', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //设置默认用户地址
  set_def_address({
    commit
  }, params = {}) {
    return https.fetchGet('set-def-address', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取用户信息
  get_userInfo({
    commit
  }, params = {}) {
    return https.fetchGet('get-userInfo', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //更新用户信息
  save_userInfo({
    commit
  }, params = {}) {
    return https.fetchPost('save-userInfo', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取城市
  get_address_info({
    commit
  }, params = {}) {
    return https.fetchGet('get-address-info', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取新闻类型
  get_news_type({
    commit
  }, params = {
    language: 1
  }) {
    return https.fetchGet('get-news-type', params)
      .then(data => {
        commit('GET_NEWS_TYPE', data.data)
        return data.data
      }).catch(err => {})
  },

  //获取新闻列表
  get_news_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-news-list', params)
      .then(data => {
        if (params.cid == 35) {
          commit('GET_NEWS_LIST', data.data)
        } else if (params.cid == 38) {
          commit('GET_NEWS_LIST2', data.data)
        } else {
          commit('GET_NEWS_LIST3', data.data)
        }
        return data.data
      }).catch(err => {})
  },


  //获取新闻
  get_news_all({
    commit
  }, params = {
    language: 1
  }) {
    return https.fetchGet('get-news-all', params)
      .then(data => {
        commit('GET_NEWS_ALL', data.data)
        return data.data
      }).catch(err => {})
  },

  //获取详情
  get_news_detail({
    commit
  }, params = {}) {
    return https.fetchPost('get-news-detail', params)
      .then(data => {
        commit('GET_NEWS_DETAIL', data.data)
        return data.data
      }).catch(err => {})
  },

  //发送邮件
  send_email({
    commit
  }, params = {}) {
    return https.fetchGet('send-email', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //绑定邮箱
  bind_email({
    commit
  }, params = {}) {
    return https.fetchGet('bind-email', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //手机和验证码绑定校验
  check_mobile_code({
    commit
  }, params = {}) {
    return https.fetchGet('check-mobile-code', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //友情链接
  get_friend_link({
    commit
  }, params = {}) {
    return https.fetchGet('get-friend-link', params)
      .then(data => {
        commit('GET_FRIEND_LINK', data.data)
        return data.data
      }).catch(err => {})
  },

  //重置密码
  update_password({
    commit
  }, params = {}) {
    return https.fetchGet('update-password', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //商品目录
  get_product_menu({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-menu', params)
      .then(data => {
        commit('GET_PRODUCT_MENU', data.data)
        return data.data
      }).catch(err => {})
  },

  //商品目录-index 
  get_product_menu_index({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-menu', params)
      .then(data => {
        commit('GET_PRODUCT_MENU_INDEX', data.data)
        return data.data
      }).catch(err => {})
  },

  //商品详情
  get_product_detail({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-detail', params)
      .then(data => {
        commit('GET_PRODUCT_DETAIL', data.data)
        return data.data
      }).catch(err => {})
  },

  //商品列表
  get_product_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-list', params)
      .then(data => {
        if (params.seoName) {
          data.data.data.seoName = params.seoName
        }
        commit('GET_PRODUCT_LIST', data.data)
        return data.data
      }).catch(err => {})
  },

  //国内商品列表
  get_china_product_list_1({
    commit
  }, params = {}) {
    return https.fetchPost('get-china-product-list-1', params)
      .then(data => {
        commit('GET_CHINA_PRODUCT_LIST_1', data.data)
        return data.data
      }).catch(err => {})
  },

  //首页特价
  get_product_special({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-special', params)
      .then(data => {
        commit('GET_PRODUCT_SPECIAL', data.data)
        return data.data
      }).catch(err => {})
  },

  //首页热门
  get_product_hot({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-hot', params)
      .then(data => {
        commit('GET_PRODUCT_HOT', data.data)
        return data.data
      }).catch(err => {})
  },
  //首页采购专区
  get_product_purchase({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-purchase', params)
      .then(data => {
        commit('GET_PRODUCT_PURCHASE', data.data)
        return data.data
      }).catch(err => {})
  },

  //商品品牌列表
  get_brand({
    commit
  }, params = {}) {
    return https.fetchPost('get-brand', params)
      .then(data => {
        commit('GET_BRAND', data.data)
        return data.data
      }).catch(err => {})
  },

  //商品热门品牌列表
  get_brand_hot({
    commit
  }, params = {}) {
    return https.fetchPost('get-brand-hot', params)
      .then(data => {
        commit('GET_BRAND_HOT', data.data)
        return data.data
      }).catch(err => {})
  },

  //商品品牌详情
  get_brand_detail({
    commit
  }, params = {}) {
    return https.fetchPost('get-brand-detail', params)
      .then(data => {
        commit('GET_BRAND_DETAIL', data.data)
        return data.data
      }).catch(err => {})
  },

  //新增收藏
  get_favorites({
    commit
  }, params = {}) {
    return https.fetchPost('get-favorites', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  //收藏分类
  get_favorites_type({
    commit
  }, params = {}) {
    return https.fetchGet('get-favorites-type', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //收藏列表
  get_favorites_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-favorites-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //删除收藏
  get_favorites_del({
    commit
  }, params = {}) {
    return https.fetchPost('get-favorites-del', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //设置备注
  set_favorites_remarks({
    commit
  }, params = {}) {
    return https.fetchPost('set-favorites-remarks', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //头部热门关键字
  hot_keyword({
    commit
  }, params = {}) {
    return https.fetchPost('hot-keyword', params)
      .then(data => {
        commit('HOT_KEYWORD', data.data.data)
        return data.data
      }).catch(err => {})
  },

  //首页数据
  get_index({
    commit
  }, params = {}) {
    return https.fetchPost('get-index', params)
      .then(data => {
        commit('GET_INDEX', data.data)
        return data.data
      }).catch(err => {})
  },

  //获取商品总数
  get_product_count({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-count', params)
      .then(data => {
        commit('GET_PRODUCT_COUNT', data.data)
        return data.data
      }).catch(err => {})
  },

  //获取相似产品
  get_product_resemble({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-resemble', params)
      .then(data => {
        // commit('GET_PRODUCT_RESEMBLE', data.data)
        return data.data
      }).catch(err => {})
  },

  //买了该商品得还买了
  get_product_other({
    commit
  }, params = {}) {
    return https.fetchPost('get-product-other', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //查询商品收藏状态
  select_favorites_status({
    commit
  }, params = {}) {
    return https.fetchGet('select-favorites-status', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //查询购物车列表
  get_cart_list({
    commit
  }, params = {}) {
    return https.fetchGet('get-cart-list', params)
      .then(data => {
        commit('GET_CART_LENGTH', data.data)
        return data.data
      }).catch(err => {})
  },
  //查询购物车列表
  get_cart_list2({
    commit
  }, params = {}) {
    return https.fetchGet('get-cart-list', params)
      .then(data => {
        commit('GET_CART_LIST', data.data)
        return data.data
      }).catch(err => {})
  },

  //删除购物车列表
  del_cart_ids({
    commit
  }, params = {}) {
    return https.fetchGet('del-cart-ids', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取购物车header
  get_cart_header({
    commit
  }, params = {}) {
    return https.fetchGet('get-cart-header', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //测试
  ck_set({
    commit
  }, params = {}) {
    return https.fetchPost('ck-set', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //更新个人中心发票
  update_invoice({
    commit
  }, params = {}) {
    return https.fetchPost('update-invoice', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //新增个人中心发票
  save_invoice({
    commit
  }, params = {}) {
    return https.fetchPost('save-invoice', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取个人中心发票
  get_invoice({
    commit
  }, params = {}) {
    return https.fetchGet('get-invoice', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //跳转到确订单
  link_confirm_order({
    commit
  }, params = {}) {
    return https.fetchPost('link-confirm-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取配送快递
  get_kd({
    commit
  }, params = {}) {
    return https.fetchGet('get-kd', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取门店地址
  get_md({
    commit
  }, params = {}) {
    return https.fetchGet('get-md', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //更新购物车状态
  update_cart_status({
    commit
  }, params = {}) {
    return https.fetchPost('update-cart-status', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //提交订单
  save_order({
    commit
  }, params = {}) {
    return https.fetchPost('save-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取用户联系人信息
  get_user_contacts({
    commit
  }, params = {}) {
    return https.fetchGet('get-user-contacts', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //新增用户联系人信息
  save_user_contacts({
    commit
  }, params = {}) {
    return https.fetchPost('save-user-contacts', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //删除用户联系人信息
  del_user_contacts({
    commit
  }, params = {}) {
    return https.fetchGet('del-user-contacts', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //获取用户未支付的订单
  get_pay_order({
    commit
  }, params = {}) {
    return https.fetchPost('get-pay-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //微信支付
  wx_pay({
    commit
  }, params = {}) {
    return https.fetchPost('wxpayqrcode', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //支付宝支付
  alipay_pay({
    commit
  }, params = {}) {
    return https.fetchGet('alipay-pay', params)
      .then(data => {

      }).catch(err => {})
  },

  //订单列表
  get_order_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-order-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //订单详情
  get_order_detail({
    commit
  }, params = {}) {
    return https.fetchGet('get-order-detail', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //订单购买历史
  get_order_history({
    commit
  }, params = {}) {
    return https.fetchPost('get-order-history', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //订单详情订单发票
  get_order_invoice({
    commit
  }, params = {}) {
    return https.fetchGet('get-order-invoice', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //根据ID查询门店信息
  get_id_md({
    commit
  }, params = {}) {
    return https.fetchGet('get-id-md', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //根据品牌ID查品牌分类子分类
  get_brand_category({
    commit
  }, params = {}) {
    return https.fetchPost('get-brand-category', params)
      .then(data => {
        commit('GET_BRAND_CATEGORY', data.data)
        return data.data
      }).catch(err => {})
  },

  //获取消息列表
  get_message_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-message-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //更新消息状态
  update_message({
    commit
  }, params = {}) {
    return https.fetchGet('update-message', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //删除消息
  del_message({
    commit
  }, params = {}) {
    return https.fetchGet('del-message', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //首页订单统计
  get_index_order({
    commit
  }, params = {}) {
    return https.fetchGet('get-index-order', params)
      .then(data => {
        commit('GET_INDEX_ORDER', data.data)
        return data.data
      }).catch(err => {})
  },

  //删除用户订单
  del_order({
    commit
  }, params = {}) {
    return https.fetchGet('del-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //取消用户订单
  cancel_order({
    commit
  }, params = {}) {
    return https.fetchPost('cancel-order', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  //购物车猜你喜欢
  get_guesgs_list({
    commit
  }, params = {}) {
    return https.fetchPost('get-guesgs-list', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },


  fetchBar2({
    commit
  }, params = {}) {
    return https.fetchPost('http2')
      .then(data => {
        commit('setItem1', data)
      }).catch(err => {})
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}