import axios from 'axios'
import qs from 'qs'
import API from './apiUrl'
import {
  createApp
} from './main'
import {
  MessageBox,
} from 'element-ui'
axios.defaults.timeout = 60000 // 响应时间
// axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json' // 配置请求头
// axios.defaults.baseURL = '' 
console.log(process.env.NODE_ENV)
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://api.hotking.com' : 'https://api.hotking.com'; // 配置接口地址
// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use((config) => {
  // 在发送请求之前做某件事
  // if (config.method === 'post') {
  //   config.data = qs.stringify(config.data)
  // }
  // if (sessionStorage) {
  //   let token = sessionStorage.getItem('access-token');
  //   if (token) {
  //     config.headerer.access-token = token
  //   }
  // }
  if (config.url.indexOf('/shop/bom/matchBom') < 0 && config.url.indexOf('/shop/bom/importBomExcel') < 0) {
    config.data = {
      ...config.data,
      language: 1,
    }
  }

  if (typeof window !== 'undefined') {
    let token = localStorage.getItem('access-token');
    if (token) {
      config.headers['access-token'] = token
    }
    let saveCart = config.url.indexOf('/cart/saveCart')
    let getCartList = config.url.indexOf('/cart/getCartList')
    let deleteCartByGoodId = config.url.indexOf('/cart/deleteCartByGoodId')
    let allCart = config.url.indexOf('/cart/batchCart')
    if (saveCart >= 0 || getCartList >= 0 || deleteCartByGoodId >= 0 || allCart >= 0) {
      let cartHeader = localStorage.getItem('cartHeader');
      config.headers['hkt-cart'] = cartHeader
    }
  }
  return config
}, (error) => {
  console.log('错误的传参')
  return Promise.reject(error)
})
// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use((res) => {
  if (typeof window !== 'undefined') { //判断是服务端还是客服端
    const {
      app,
      router,
      store
    } = createApp()
    if (typeof (res.data) == "string") {
      let num = res.data.indexOf(':424')
      if (num >= 0) {
        localStorage.removeItem('access-token');
        if (router.currentRoute.fullPath.indexOf('login') < 0) {
          router.push({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
        }
        location.reload();
      }
    } else {
      if (res.data.httpCode == 425 || res.data.httpCode == 424) {
        localStorage.removeItem('access-token');
        if (router.currentRoute.fullPath.indexOf('login') < 0) {
          console.log(router.currentRoute.fullPath)
          if (router.currentRoute.fullPath == '/cart/order') {
            router.push({
              path: '/login',
              query: {
                redirect: '/cart'
              }
            })
          } else {
            router.push({
              path: '/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }
        }
        location.reload();
        // MessageBox.confirm('token信息错误或失效，请重新登录！', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'info'
        // }).then(() => {
        //   router.push({
        //     path: '/login',
        //     // query: {
        //     //   redirect: router.currentRoute.fullPath
        //     // }
        //   })
        //   location.reload();
        // }).catch(() => {

        // });

      }
    }
  }

  if (res.headers['access-token']) {
    localStorage.setItem('access-token', res.headers['access-token']);
  }
  // 对响应数据做些事
  if (!res.data.success) {
    return Promise.resolve(res)
  }
  return res
}, (error) => {
  console.log('网络异常')
  return Promise.reject(error)
})

// 返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  let _url = API[url] || url
  return new Promise((resolve, reject) => {
    axios.post(_url, params)
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/// /返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  let _url = API[url] || url
  return new Promise((resolve, reject) => {
    axios.get(_url, {
        params: param
      })
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export default {
  fetchPost,
  fetchGet
}