<template>
  <div class="newMain bgf5f5f5">
    <div class="tool-wrap">
      <div class="calc-wrap">
        <h3>
          <i>
            <img :src="toolNav[toolNavIdx].icon">
          </i>
          <span>{{toolNav[toolNavIdx].title}}</span>
        </h3>
        <div class="calc-inp clearfix">
          <div class="inp-item fl">
            <p>数值</p>
            <el-input placeholder="请输入数值" id="olTool_num" v-model.trim="curNum" onkeyup="value=value.replace(/[^\d]/ig,'')"></el-input>
          </div>
          <div class="inp-item fr">
            <p>数值</p>
            <el-select v-model="curDigit" placeholder="请选择" @change="change">
              <el-option v-for="item in toolNav[toolNavIdx].options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="calc-res olTool_res">
          <h2>
            换算结果
          </h2>
          <table>
            <tbody v-if="isQh">
              <template v-if="toolNavIdx==2">
                <tr>
                  <td colspan="2" style="padding-left: 150px;font-size: 13px;color: #999;">---公制单位---</td>
                </tr>
                <tr v-for="(item,index) in toolNav[toolNavIdx].options" :key="index" v-show="index<6">
                  <td>{{item.label}}</td>
                  <td :class="'olTool_res_'+digit_arr[index]"></td>
                </tr>
                <tr>
                  <td colspan="2" style="padding-left: 150px;font-size: 13px;color: #999;">---英制单位---</td>
                </tr>
                <tr v-for="(item,index) in toolNav[toolNavIdx].options" :key="index+'y'" v-show="index>5">
                  <td>{{item.label}}</td>
                  <td :class="'olTool_res_'+digit_arr[index]"></td>
                </tr>
              </template>
              <template v-else-if="toolNavIdx==5">
                <tr>
                  <td colspan="2" style="padding-left: 150px;font-size: 13px;color: #999;">---公制单位---</td>
                </tr>
                <tr v-for="(item,index) in toolNav[toolNavIdx].options" :key="index" v-show="index<8">
                  <td>{{item.label}}</td>
                  <td :class="'olTool_res_'+digit_arr[index]"></td>
                </tr>
                <tr>
                  <td colspan="2" style="padding-left: 150px;font-size: 13px;color: #999;">---美制单位---</td>
                </tr>
                <tr v-for="(item,index) in toolNav[toolNavIdx].options" :key="index+'m'" v-show="index>7&&index<15">
                  <td>{{item.label}}</td>
                  <td :class="'olTool_res_'+digit_arr[index]"></td>
                </tr>
                <tr>
                  <td colspan="2" style="padding-left: 150px;font-size: 13px;color: #999;">---英制单位---</td>
                </tr>
                <tr v-for="(item,index) in toolNav[toolNavIdx].options" :key="index+'yy'" v-show="index>14">
                  <td>{{item.label}}</td>
                  <td :class="'olTool_res_'+digit_arr[index]"></td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(item,index) in toolNav[toolNavIdx].options" :key="index">
                  <td>{{item.label}}</td>
                  <td :class="'olTool_res_'+digit_arr[index]"></td>
                </tr>
              </template>
            </tbody>
          </table>
          <p class="tip">提示：换算结果仅供参考！</p>
        </div>
      </div>
      <div class="tip-wrap">
        <h3>关于</h3>
        <p>{{toolNav[toolNavIdx].about}}</p>
        <h3>推荐工具</h3>
        <ul class="clearfix">
          <li v-for="(item,index3) in toolNav" :key="index3" :class="{current:index3==toolNavIdx}" @click="toolNavFun(index3)">{{item.title}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
const Big = require('big.js')
export default {
  metaInfo () {
    return {
      title: '换算工具',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  data () {
    return {
      number: 1,
      value: '皮法（pF）',
      toolNavIdx: 0,
      toolNav: [
        {
          about: '使用在线电容换算器可进行皮法（pF）、纳法（nF）、微法（μF）、毫法（mF）和法拉（F）单位换算。',
          title: '电容换算器',
          icon: require('@/assets/images/tool/tool_icn_dr.png'),
          options: [
            {
              value: 'pF',
              label: '皮法（pF）',
            },
            {
              value: 'nF',
              label: '纳法（nF）',
            },
            {
              value: 'μF',
              label: '微法（μF）',
            },
            {
              value: 'mF',
              label: '毫法（mF）',
            },
            {
              value: 'F',
              label: '法拉（F）',
            }
          ],
        },
        {
          about: '使用在线电感换算器可进行皮亨（pH）、纳亨（nH）、微亨（μH）、毫亨（mH）、亨利（H）和千亨（kH）单位换算。',
          title: '电感换算器',
          icon: require('@/assets/images/tool/tool_icn_dg.png'),
          options: [
            {
              value: 'pH',
              label: '皮亨（pH）',
            },
            {
              value: 'nH',
              label: '纳亨（nH）',
            },
            {
              value: 'μH',
              label: '微亨（μH）',
            },
            {
              value: 'mH',
              label: '毫亨（mH）',
            },
            {
              value: 'H',
              label: '亨利（H）',
            },
            {
              value: 'kH',
              label: '千亨（kH）',
            }
          ],
        },
        {
          about: '使用在线长度换算器可进行公制：公里（km）、米（m）、厘米（cm）、毫米（mm）、微米（μm）、纳米（nm）；英制：英里（mi）、码（yd）、英尺（ft）、英寸（in）、海里（nmi）的单位换算。',
          title: '长度换算器',
          icon: require('@/assets/images/tool/tool_icn_cd.png'),
          options: [
            {
              value: 'nm',
              label: '纳米（nm）',
            }, {
              value: 'μm',
              label: '微米（μm）',
            }, {
              value: 'mm',
              label: '毫米（mm）',
            },
            {
              value: 'cm',
              label: '厘米（cm）',
            },
            {
              value: 'm',
              label: '米（m）',
            },
            {
              value: 'km',
              label: '公里（km）',
            },
            {
              value: 'mi',
              label: '英里（mi）',
            },
            {
              value: 'yd',
              label: '码（yd）',
            },
            {
              value: 'ft',
              label: '英尺（ft）',
            },
            {
              value: 'in',
              label: '英寸（in）',
            },
            {
              value: 'nmi',
              label: '海里（nmi）',
            }
          ],
        }, {
          about: '使用在线压力换算器可进行帕（Pa N/m2）、千帕（kPa）、毫巴（mb）、巴（bar）、千巴（kbar）、大气压力（atm）、千克/平方厘米（kg/cm2）、磅/平方英寸（psi）、磅/平方英尺（psf）等的单位换算。',
          title: '压力换算器',
          icon: require('@/assets/images/tool/tool_icn_yl.png'),
          options: [
            {
              value: 'Pa',
              label: '帕（Pa N/m2）'
            }, {
              value: 'kPa',
              label: '千帕（kPa）'
            }, {
              value: 'mb',
              label: '毫巴（mb）'
            }, {
              value: 'bar',
              label: '巴（bar）'
            }, {
              value: 'kbar',
              label: '千巴（kbar）'
            }, {
              value: 'atm',
              label: '大气压力（atm）'
            }, {
              value: 'kgcm',
              label: '千克/平方厘米（kg/cm2）'
            }, {
              value: 'psi',
              label: '磅/平方英寸（psi）'
            }, {
              value: 'psf',
              label: '磅/平方英尺（psf）'
            }, {
              value: 'mm4',
              label: '4°C时的毫米水柱（mm H2O）'
            }, {
              value: 'in4',
              label: '4°C时的英寸水柱（in H2O）'
            }, {
              value: 'mm0',
              label: '0°C时的毫米水柱（mm H2O）'
            }, {
              value: 'in0',
              label: '0°C时的英寸水柱（in H2O）'
            }
          ],
        }, {
          about: '使用在线温度换算器可进行摄氏度（°C）、华氏度（°F）、开氏度（°K）、列氏度（°R）、兰氏度（°Ra）和牛顿（°N）的单位换算。',
          title: '温度换算器',
          icon: require('@/assets/images/tool/tool_icn_wd.png'),
          options: [
            {
              value: 'C',
              label: '摄氏度（°C）'
            }, {
              value: 'F',
              label: '华氏度（°F）'
            }, {
              value: 'K',
              label: '开氏度（°K）'
            }, {
              value: 'R',
              label: '列氏度（°R）'
            }, {
              value: 'Ra',
              label: '兰氏度（°Ra）'
            }, {
              value: 'N',
              label: '牛顿（°N）'
            }
          ],
        }, {
          about: '使用在线体积换算器可进行毫升（mL）、升（L）、立方毫米（mm3）、立方厘米（cm3）、立方英寸（in3）、立方英尺（ft3）、立方米（m3）、立方码（yd3）等单位换算。',
          title: '体积换算器',
          icon: require('@/assets/images/tool/tool_icn_tj.png'),
          options: [
            {
              value: 'mL',
              label: '毫升（mL）'
            }, {
              value: 'L',
              label: '升（L）'
            }, {
              value: 'mm3',
              label: '立方毫米（mm³）'
            }, {
              value: 'cm3',
              label: '立方厘米（cm³）'
            }, {
              value: 'in3',
              label: '立方英寸（in³）'
            }, {
              value: 'ft3',
              label: '立方英尺（ft³）'
            }, {
              value: 'm3',
              label: '立方米（m³）'
            }, {
              value: 'yd3',
              label: '立方码（yd³）'
            }, {
              value: 'floz_1',
              label: '美制液盎司（floz）'
            }, {
              value: 'pt_1',
              label: '美制品脱（pt）'
            }, {
              value: 'cup_1',
              label: '美制杯（cup）'
            }, {
              value: 'qt_1',
              label: '美制夸脱（qt）'
            }, {
              value: 'gal_1',
              label: '美制加仑（gal）'
            }, {
              value: 'tbsp_1',
              label: '美制大匙（tbsp）'
            }, {
              value: 'tsp_1',
              label: '美制茶匙（tsp）'
            }, {
              value: 'floz_2',
              label: '英制液盎司（floz）'
            }, {
              value: 'pt_2',
              label: '英制品脱（pt）'
            }, {
              value: 'cup_2',
              label: '英制杯（cup）'
            }, {
              value: 'qt_2',
              label: '英制夸脱（qt）'
            }, {
              value: 'gal_2',
              label: '英制加仑（gal）'
            }, {
              value: 'tbsp_2',
              label: '英制大匙（tbsp）'
            }, {
              value: 'tsp_2',
              label: '英制茶匙（tsp）'
            }
          ],
        }, {
          about: '使用在线重量换算器可进行千克（kg）、微克（µg）、毫克（mg）、磅（lb）、克（g）、盎司（oz）、公吨（t）、美吨（ST）和英吨（LT）的单位换算。',
          title: '重量换算器',
          icon: require('@/assets/images/tool/tool_icn_zl.png'),
          options: [
            {
              value: 'kg',
              label: '千克（kg）',
            },
            {
              value: 'µg',
              label: '微克（µg）',
            },
            {
              value: 'mg',
              label: '毫克（mg）',
            },
            {
              value: 'lb',
              label: '磅（lb）',
            },
            {
              value: 'g',
              label: '克（g）',
            },
            {
              value: 'oz',
              label: '盎司（oz）',
            },
            {
              value: 't',
              label: '公吨（t）',
            },
            {
              value: 'ST',
              label: '美吨（ST）',
            },
            {
              value: 'LT',
              label: '英吨（LT）',
            }
          ],
        }, {
          about: '使用在线BTU/焦耳换算器可进行BTU/焦耳换算。',
          title: 'BTU/焦耳换算器',
          icon: require('@/assets/images/tool/tool_icn_je.png'),
          options: [
            {
              value: 'btu',
              label: '英制热量单位（BTU）'
            }, {
              value: 'j',
              label: '焦耳（J）'
            }
          ],
        }
      ],
      results: [],
      isExpChk: false,
      curNum: '1',
      curDigit: 'F',
      digit_arr: ['pF', 'nF', 'μF', 'mF', 'F'],
      to_h_map: null,
      h_to_map: {
        'pF': new Big('1e+12'),
        'nF': new Big('1e+9'),
        'μF': new Big('1e+6'),
        'mF': new Big('1e+3'),
        'F': new Big('1'),
      },
      isQh: true,
    }
  },
  created () {
  },
  mounted () {
    // this.drChange(this.number, this.idx)
    Big.DP = 30;
    Big.NE = -12;
    Big.PE = 15;
    setTimeout(() => {
      this.compute();
    }, 200);
  },
  methods: {
    toolNavFun (index) {
      this.toolNavIdx = index
      if (index == 0) {
        this.curNum = '1'
        this.curDigit = 'F'
        this.digit_arr = ['pF', 'nF', 'μF', 'mF', 'F']
        this.h_to_map = {
          'pF': new Big('1e+12'),
          'nF': new Big('1e+9'),
          'μF': new Big('1e+6'),
          'mF': new Big('1e+3'),
          'F': new Big('1'),
        }
      } else if (index == 1) {
        this.curNum = '1'
        this.curDigit = 'H'
        this.digit_arr = ['pH', 'nH', 'μH', 'mH', 'H', 'kH']
        this.h_to_map = {
          'kH': new Big('1e-3'),
          'H': new Big('1'),
          'mH': new Big('1e+3'),
          'μH': new Big('1e+6'),
          'nH': new Big('1e+9'),
          'pH': new Big('1e+12')
        }
      } else if (index == 2) {
        this.curNum = '1'
        this.curDigit = 'm'
        this.digit_arr = ['nm', 'μm', 'mm', 'cm', 'm', 'km', 'mi', 'yd', 'ft', 'in', 'nmi']
        this.h_to_map = {
          'km': new Big('1e-3'),
          'm': new Big('1'),
          'cm': new Big('1e+2'),
          'mm': new Big('1e+3'),
          'μm': new Big('1e+6'),
          'nm': new Big('1e+9'),
          'mi': new Big('1').div('1609.344'),
          'yd': new Big('1').div('0.9144'),
          'ft': new Big('1').div('0.3048'),
          'in': new Big('1').div('0.0254'),
          'nmi': new Big('1').div('1852')
        }
      } else if (index == 3) {
        this.curNum = '1'
        this.curDigit = 'Pa'
        this.digit_arr = ['Pa', 'kPa', 'mb', 'bar', 'kbar', 'atm', 'kgcm', 'psi', 'psf', 'mm4', 'in4', 'mm0', 'in0']
        this.h_to_map = {
          'Pa': new Big('1'),
          'kPa': new Big('0.001'),
          'mb': new Big('0.01'),
          'bar': new Big('0.00001'),
          'kbar': new Big('0.00000001'),
          'atm': new Big("1").div("1.013250e+5"),
          'kgcm': new Big("1").div("9.80665e+4"),
          'psi': new Big("1").div("6894.76"),
          'psf': new Big("1").div("47.8803"),
          'mm4': new Big("1").div("9.80638"),
          'in4': new Big("1").div("249.0819508572"),
          'mm0': new Big("1").div("133.322368"),
          'in0': new Big("1").div("3386.388157894736")
        }
      } else if (index == 4) {
        this.curNum = '1'
        this.curDigit = 'C'
        this.digit_arr = ['C', 'F', 'K', 'R', 'Ra', 'N']
        this.to_h_map = {
          'C': function (x) {
            return new Big(x);
          },
          'F': function (x) {
            return new Big(new Big(new Big(x).minus(32)).div(1.8));
          },
          'K': function (x) {
            return new Big(new Big(x).minus(273.15));
          },
          'R': function (x) {
            return new Big(new Big(x).div(0.8));
          },
          'Ra': function (x) {
            return new Big(new Big(new Big(x).div(1.8)).minus(273.15));
          },
          'N': function (x) {
            return new Big(new Big(x).div(0.33));
          }
        }
        this.h_to_map = {
          'C': function (x) {
            return new Big(x);
          },
          'F': function (x) {
            return new Big(new Big(new Big(x).times(1.8)).plus(32));
          },
          'K': function (x) {
            return new Big(new Big(x).plus(273.15));
          },
          'R': function (x) {
            return new Big(new Big(x).times(0.8));
          },
          'Ra': function (x) {
            return new Big(new Big(new Big(x).plus(273.15)).times(1.8));
          },
          'N': function (x) { //c=n/0.33
            return new Big(new Big(x).times(0.33));
          }
        }
      } else if (index == 5) {
        this.curNum = '1'
        this.curDigit = 'L'
        this.digit_arr = ['mL', 'L', 'mm3', 'cm3', 'in3', 'ft3', 'm3', 'yd3', 'floz_1', 'pt_1', 'cup_1', 'qt_1', 'gal_1', 'tbsp_1', 'tsp_1', 'floz_2', 'pt_2', 'cup_2', 'qt_2', 'gal_2', 'tbsp_2', 'tsp_2']
        this.h_to_map = {
          'mL': new Big('1e+3'),
          'L': new Big('1'),
          'mm3': new Big('1000000'),
          'cm3': new Big('1e+3'),
          'in3': new Big('61.02374409'),
          'ft3': new Big('0.0353146667'),
          'm3': new Big('0.001'),
          'yd3': new Big('0.00130795'),

          'floz_1': new Big('33.8140227'),
          'pt_1': new Big('2.1133764189'),
          'cup_1': new Big('4.22675284'),
          'qt_1': new Big('1.05668821'),
          'gal_1': new Big('0.264172052'),
          'tbsp_1': new Big('67.6280454'),
          'tsp_1': new Big('202.884136'),

          'floz_2': new Big('35.1950652'),
          'pt_2': new Big('1.7597539864'),
          'cup_2': new Big('3.51950652'),
          'qt_2': new Big('0.87987699'),
          'gal_2': new Big('0.21996923465436'),
          'tbsp_2': new Big('56.3121043'),
          'tsp_2': new Big('168.936313')
        }
      } else if (index == 6) {
        this.curNum = '1'
        this.curDigit = 'kg'
        this.digit_arr = ['kg', 'µg', 'mg', 'lb', 'g', 'oz', 't', 'ST', 'LT']
        this.h_to_map = {
          'kg': new Big('1'),
          'µg': new Big('1000000000'),
          'mg': new Big('1000000'),
          'lb': new Big('2.2046226218'),
          'g': new Big('1000'),
          'oz': new Big('35.27396195'),
          't': new Big('0.001'),
          'ST': new Big(new Big(1).div(907.18474)),
          'LT': new Big(new Big(1).div(1016.0469088))
        }
      } else if (index == 7) {
        this.curNum = '1'
        this.curDigit = 'btu'
        this.digit_arr = ['btu', 'j']
        this.h_to_map = {
          'btu': new Big('1'),
          'j': new Big('1055.05585262')
        }
      }
      setTimeout(() => {
        this.compute()
      }, 200);
    },
    compute () {
      try {
        if ($("#olTool_num").val() == "") {
          this.curNum = 0;
        } else {
          new Big($("#olTool_num").val().trim());
          this.curNum = $("#olTool_num").val();
          //$("#olTool_err").html("");
        }
      } catch (error) {
        console.log(error)
        //$("#olTool_err").html("请输入正确数字！");
        return false;
      }
      let curRes_m
      if (this.toolNavIdx == 4) {
        curRes_m = this.to_h_map[this.curDigit](this.curNum)
      } else {
        curRes_m = new Big(this.curNum).div(this.h_to_map[this.curDigit])
      }
      for (var i = 0; i < this.digit_arr.length; i++) {
        if (this.toolNavIdx == 4) {
          var tmp = this.h_to_map[this.digit_arr[i]](curRes_m);
        } else {
          var tmp = curRes_m.times(this.h_to_map[this.digit_arr[i]]);
        }
        var output = "";
        if (this.isExpChk) {
          output = new Big(tmp.toExponential(9)).toExponential();
        } else {
          if (tmp.abs().gte("1e+15")) {
            output = new Big(tmp.toExponential(15)).toString();
          } else if (tmp.abs().lt("1")) {
            output = new Big(tmp.toExponential(9)).toString();
          } else {
            output = new Big(tmp.toFixed(10)).toString();
          }
        }

        $(".olTool_res_" + this.digit_arr[i]).html(output);
        this.digit_arr.forEach(item => {
          $(".olTool_res_" + item).attr('style', '');
          if (item == this.curDigit) {
            $(".olTool_res_" + this.curDigit).attr('style', 'color:#0067ed;font-weight:bold');
          }
        });

      }
    },
    change (e) {
      this.compute()
    },
  },
  watch: {
    'toolNavIdx' () {
    },
    'curNum' () {
      this.compute()
    }
  }

}
</script>
<style lang="scss" scoped>
</style>