<template>
  <div class="newMain">
    <div class="bomBox">
      <div class="link-s">
        <router-link to="/">商城首页</router-link>><a href="/bom">BOM查询</a>><span>查询结果</span>
      </div>
      <div class="bom-bg"><span>秒配BOM表，一键下单</span></div>

      <div class="bom-content" v-loading='!bomListObj'>
        <template v-if="bomListObj">
          <h3 class="bomc-title clear">
            <p class="bomc-match">共匹配到<i>{{bomListObj.list.length}}</i>个型号</p>
            <p class="bomc-t">
              <template v-if="editTitle">
                <input type="text" v-model="saveBomList.name"><i class="save" @click="bomUpdataName">保存</i>
              </template>
              <template v-else>
                {{saveBomList.name}}<i class="edit" @click="editTitle=!editTitle"></i>
              </template>

            </p>
            <div class="operation fr">
              <a href="javascript:;" @click="backBom">重新导入BOM</a>
              <a href="javascript:;" @click="downBom">导出BOM</a>
              <!-- <a href="javascript:;">导出结果</a> -->
              <!-- <a href="javascript:;" @click="saveBomOrder">保存BOM</a> -->
              <router-link to="/home/order/bom">我的BOM</router-link>
              <a href="javascript:;" @click="clickPopBox">联系客服</a>
            </div>
          </h3>
          <div class="bomc-nav" v-if="bomListObj">
            <a href="javascript:;" :class="{current:navIdx==0}" @click="navIdx=0">全部商品({{bomListObj.allList.length}})</a>
            <a href="javascript:;" :class="{current:navIdx==1}" @click="navIdx=1">匹配商品({{bomListObj.list.length}})</a>
            <a href="javascript:;" :class="{current:navIdx==2}" @click="navIdx=2">无法匹配({{bomListObj.unList.length}})</a>
            <a href="javascript:;" @click="addFun">添加物料</a>
          </div>
          <div class="bomc-tabcs" v-if="bomListObj">
            <table class="bomc-tables clear">
              <thead>
                <tr>
                  <td class="bomc-table-checkbox" width="40">
                    <input type="checkbox" class="checkoutAll" v-model="checkedAll" @click="checkAll()">
                  </td>
                  <td width="170">需求规格</td>
                  <td width="150">参数</td>
                  <td width="140">品牌</td>
                  <td width="210">匹配商品</td>
                  <td width="210">产品参数/手册</td>
                  <td width="120">购买数量</td>
                  <!-- <td width="80">手册</td> -->
                  <td width="90">单价</td>
                  <td width="60">操作</td>
                </tr>
              </thead>
              <tbody>
                <tr class="bom_tr" v-for="(item,index) in bomList" :key="index">
                  <td class="bomc-table-checkbox table-checkbox">
                    <input type="checkbox" class="check" :value="JSON.stringify(item)" @click="singeCheck($event,item)" :disabled="!item.count || item.dealPrice=='0.0'" :checked="item.count && item.dealPrice!='0.0'" name="">
                  </td>
                  <td class="box">
                    {{item.materialSn || '--'}} <i class="edit" @click="editParamFun(index,2,item)"></i>
                    <div class="editParams" v-if="editParam && editParamIdx==index">
                      <input class="editInp i1" type="text" v-model="edirP.materialSn" placeholder="需求规格">
                      <input class="editInp i2" type="text" v-model="edirP.param" placeholder="参数">
                      <input class="editInp i3" type="text" v-model="edirP.brandName" placeholder="品牌">
                      <a href="javascript:;" @click="bomUpdata">确定</a><a class="cancel" href="javascript:;" @click="editParam=false">取消</a>
                    </div>
                  </td>
                  <td>
                    {{item.param || '--'}}
                  </td>
                  <td>{{item.brandName || '--'}}</td>
                  <template v-if="item.count!=0">
                    <td>
                      型号:<span class="sn" v-html="item.matchMaterialSn"></span><br>
                      品牌:{{item.matchBrandName}}<br>
                      <a v-if="item.count>1" class="tiH" href="javascript:;" @click="thFun(item)">可替换商品</a>
                    </td>
                    <td>
                      <span v-html="item.matchParam"></span><br>
                      产品手册:<a :href="item.matchPdf" target="_blank" class="pdf"></a>
                    </td>
                    <td>
                      <template v-if="editNum && editNumIdx==index">
                        <input class="editInp matchNum" type="text" v-model="item.matchNum" onkeyup="value=value.replace(/[^\d]/ig,'')" @blur="blurFun($event,item.packageCount,index)"><i class="save" @click="editNumFun(index,1,$event,item)">确定</i>
                      </template>
                      <template v-else>
                        {{item.matchNum ? item.matchNum : 0}}<i class="edit" @click="editNumFun(index)"></i>
                      </template>
                    </td>
                    <!-- <td>
                    <a :href="item.matchPdf" target="_blank" class="pdf"></a>
                  </td> -->

                    <td class="td_price">￥{{item.dealPrice ? item.dealPrice : 0}}</td>
                  </template>
                  <template v-else>
                    <td colspan="4" class="none">
                      无法匹配，请修改需求型号/参数
                    </td>

                  </template>

                  <td><img class="del" src="@/assets/images/cart/del.png" @click="del(index,item)"></td>
                  <!-- </template> -->
                  <!-- <template v-else>
                  <td colspan="6">
                    匹配不到型号
                  </td>
                  <td><img class="del" src="@/assets/images/cart/del.png" @click="del(index)"></td>
                </template> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bomc-bottom clear">
            <div class="bomc-change">
              <input type="checkbox" class="checkoutAll" v-model="checkedAll" @click="checkAll()">
              全选&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              已选<i>{{num}}</i>种商品
            </div>
            <div class="bomc-total">
              <!-- 预计总价(未含运费):<i>￥{{total.toFixed(3)}}</i> -->
              <button type="button" id="shopBtn" @click="shopBtn">{{btnText}}</button>
            </div>
          </div>
        </template>
      </div>

      <!-- 添加物料  start -->
      <transition name="el-fade-in">
        <div class="mask_layout" id="bomAddGoods" v-if="bomAddGoods">
          <div class="layout_box">
            <div class="layout_title">
              {{text}}
              <i class="layout_close" @click="bomAddGoods=false"></i>
            </div>
            <!-- 内容区域 -->
            <div class="layout_con">
              <div class="search-wrap">
                <input class="i1" type="text" v-model="edirP2.materialSn" placeholder="需求规格">
                <input class="i2" type="text" v-model="edirP2.param" placeholder="参数">
                <input class="i3" type="text" v-model="edirP2.brandName" placeholder="品牌">
                <input class="i3" type="text" v-model="edirP2.num" placeholder="数量">
                <i @click="addBom"></i>
              </div>
              <div class="search-result" v-loading="isloading">
                <template v-if="addList">
                  <table>
                    <tbody>
                      <tr>
                        <th>匹配商品</th>
                        <th>产品参数</th>
                        <th>品牌</th>
                        <th>购买数量</th>
                        <th>手册</th>
                        <th>操作</th>
                      </tr>
                      <template v-if="addList && addList.length>0">
                        <tr v-for="(item,index) in addList" :key="index">
                          <td>{{item.matchMaterialSn}}</td>
                          <td>{{item.matchParam}}</td>
                          <td>{{item.matchBrandName}}</td>
                          <td>{{item.num || 0}}</td>
                          <td><a :href="item.matchPdf" target="_blank" class="pdf"></a></td>
                          <td>
                            <a v-if="!thFalg" href="javascript:;" class="pBtn" @click="add(item)">添加商品</a>
                            <a v-else href="javascript:;" class="pBtn" @click="thAddFun(item,index)">切换商品</a>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="6">未能匹配到商品</td>
                        </tr>
                      </template>

                    </tbody>
                  </table>
                </template>

              </div>
              <div class="page-wrap">
                <el-pagination background layout="prev, pager, next" :total="pageTotal" @current-change="change" :hide-on-single-page="true" :current-page="edirP2.pageNum" :page-size="edirP2.pageSize">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { exportMethod2 } from '@/plugins/ibox/utils'
export default {
  data () {
    return {
      btnText: '一键加入购物车',
      text: '添加商品',
      navIdx: 0,
      editXh: false,
      editXhIdx: -1,
      editTitle: false,
      editNum: false,
      editNumIdx: -1,
      bomListObj: null,
      bomList: [],
      shopList: [],
      type: '',
      total: 0, // 总价
      pageTotal: 0,
      num: 0, // 数量
      checkedAll: true,//选中全部
      isCheck: 0,
      isCh: 0,
      editFalg: false,
      bomAddGoods: false,
      saveBomList: {
        name: "物料清单-" + (new Date()).valueOf(),
        list: []
      },
      downParams: {
        id: '',
        name: '',
        uuid: '',
        createTime: '',
        list: []
      },
      edirP: {
        id: '',
        materialSn: '',
        num: '',
        param: '',
        brandName: '',
      },
      edirP2: {
        bomId: '',
        materialSn: '',
        num: '',
        param: '',
        brandName: '',
        pageSize: 10,
        pageNum: 1,
      },
      materialSn: '',
      addList: null,
      editParam: false,
      editParamIdx: -1,
      isloading: false,
      thFalg: false,
    }
  },
  computed: {
  },
  created () {
    this.type = this.$route.query.type
  },
  mounted () {
    if (this.type == 1) {
      this.bomListObj = JSON.parse(localStorage.getItem('excelList'))
      this.edirP2.bomId = this.bomListObj.uuid
      this.bomList = this.bomListObj.allList
      this.downParams = {
        id: this.bomListObj.id,
        name: this.bomListObj.name,
        uuid: this.bomListObj.uuid,
        createTime: this.bomListObj.createTime,
        list: []
      }
      this.count()
    } else if (this.type == 2) {
      this.bomListObj = JSON.parse(localStorage.getItem('excelList'))
      this.edirP2.bomId = this.bomListObj.uuid
      this.bomList = this.bomListObj.allList
      this.downParams = {
        id: this.bomListObj.id,
        name: this.bomListObj.name,
        uuid: this.bomListObj.uuid,
        createTime: this.bomListObj.createTime,
        list: []
      }
      this.count()
    } else {
      this.getBomDetail(this.$route.query.id)
    }
  },
  methods: {
    ...mapActions('lw', ['bomQuery', 'batchCart']),
    ...mapActions('lxr', ['save_bom_order', 'get_bom_detail', 'add_bom', 'bom_updata', 'bom_add_list', 'bom_del', 'bom_updata_name']),
    async bomUpdataName () {
      let res = await this.bom_updata_name({ id: this.bomListObj.id, name: this.saveBomList.name });
      if (res.httpCode == 200) {
        this.editTitle = false
      }
    },
    // 导出结果
    async downBom () {
      this.downParams.list = this.bomListObj.list
      let obj = {
        method: 'post',
        url: '/bom/exportBomExcel',
        fileName: `bom查询结果导出`,
        params: this.downParams
      }
      exportMethod2(obj) // 模板导出
    },
    clickPopBox () { window.open("https://hm.baidu.com/hm.js?30d5f0cd8e30265492389905f69cf896", "", "height=600px,width=800px") },
    change (val) {
      this.edirP2.pageNum = val
      this.addBom()
    },
    blurFun (e, packageCount, idx) {
    },
    addFun () {
      this.text = '添加商品'
      this.pageTotal = 0
      this.bomAddGoods = true; this.thFalg = false
      this.addList = null
      this.edirP2 = {
        bomId: this.bomListObj.uuid,
        materialSn: '',
        num: '',
        param: '',
        brandName: '',
        pageSize: 10,
        pageNum: 1,
      }
    },
    thFun (item) {
      this.text = '替换商品'
      this.addList = null
      this.thFalg = true
      this.edirP2 = {
        bomId: this.bomListObj.uuid,
        materialSn: item.materialSn,
        num: item.num,
        param: item.param,
        brandName: item.brandName,
        pageSize: 10,
        pageNum: 1,
        rowId: item.id
      }
      this.bomAddGoods = true
      this.addBom()
    },
    editParamFun (idx, type, item) {
      this.editParam = true
      if (type == 1) {
        this.editParam = false
      } else {
        this.edirP = {
          id: item.id,
          materialSn: item.materialSn,
          num: item.num,
          param: item.param,
          brandName: item.brandName,
        }
      }
      this.editParamIdx = idx
    },
    editNumFun (idx, type, e, obj) {
      this.editNum = true
      if (type == 1) {
        this.editNum = false
        if (obj.packageCount) {
          let num = parseInt(e.target.parentElement.children[0].value)
          let a = num
          let b = a % parseInt(obj.packageCount)

          if (a > parseInt(obj.packageCount)) {
            if (b != 0) {
              a += parseInt(obj.packageCount) - b
            }
            if (a > 1000000) {
              let c = 1000000 % parseInt(obj.packageCount)
              if (c != 0) {
                a = 1000000 - c
              } else {
                a = 1000000
              }
            }
          } else {
            a = parseInt(obj.packageCount)
          }
          this.bomListObj.allList.forEach((item, index) => {
            if (item.id == obj.id) {
              this.bomListObj.allList[index].matchNum = a;
            }
          });
          this.bomListObj.list.forEach((item, index) => {
            if (item.id == obj.id) {
              this.bomListObj.list[index].matchNum = a;
            }
          });
          this.bomListObj.unList.forEach((item, index) => {
            if (item.id == obj.id) {
              this.bomListObj.unList[index].matchNum = a;
            }
          });
        }
      }
      this.editNumIdx = idx
    },
    editXhFun (idx, type) {
      this.editXh = true
      if (type == 1) {
        this.editXh = false
      }
      this.editXhIdx = idx
    },
    thAddFun (obj) {
      this.bomListObj.allList.forEach((item, index) => {
        if (item.id == obj.rowId) {
          this.bomListObj.allList[index] = obj;
        }
      });
      this.bomListObj.list.forEach((item, index) => {
        if (item.id == obj.rowId) {
          this.bomListObj.list[index] = obj;
        }
      });
      this.bomListObj.unList.forEach((item, index) => {
        if (item.id == obj.rowId) {
          this.bomListObj.unList[index] = obj;
        }
      });
      localStorage.setItem('excelList', JSON.stringify(this.bomListObj))
      this.$message.success('替换成功')
      this.bomAddGoods = false

    },
    async add (item) {
      let res = await this.bom_updata({
        bomId: this.bomListObj.uuid,
        materialSn: item.materialSn,
        num: item.num,
        param: item.param,
        brandName: item.brandName,
      });
      if (res.httpCode == 200) {
        item.goodsId = item.id
        this.bomListObj.allList.unshift(item)
        if (item.count > 0) {
          this.bomListObj.list.unshift(item)
        } else {
          this.bomListObj.unList.unshift(item)
        }
        localStorage.setItem('excelList', JSON.stringify(this.bomListObj))
        this.bomAddGoods = false
      } else {
        return this.$message.error(res.message)
      }
    },
    async bomUpdata () {
      if (this.edirP.materialSn == '') {
        this.edirP.materialSn = null
      }
      if (this.edirP.param == '') {
        this.edirP.param = null
      }
      if (this.edirP.brandName == '') {
        this.edirP.brandName = null
      }
      let res = await this.bom_updata(this.edirP);
      if (res.httpCode == 200) {
        this.editParam = false
        this.bomListObj.allList.forEach((item, index) => {
          if (item.id == res.data.id) {
            this.bomListObj.allList[index] = res.data
          }
        });
        let a = 1
        let b = 1
        this.bomListObj.list.forEach((item, index) => {
          if (item.id == res.data.id) {
            if (res.data.matchMaterialSn) {
              this.bomListObj.list[index] = res.data
            } else {
              this.bomListObj.list.splice(index, 1);
              this.bomListObj.unList.push(res.data);
            }
          }
        });
        this.bomListObj.unList.forEach((item, index) => {
          if (item.id == res.data.id) {
            if (res.data.matchMaterialSn) {
              this.bomListObj.unList.splice(index, 1);
              this.bomListObj.list.push(res.data);
            } else {
              this.bomListObj.unList[index] = res.data
            }
          }
        });

        // if(this.editParamIdx)
        if (this.navIdx == 0) {
          this.bomList = this.bomListObj.allList
        } else if (this.navIdx == 1) {
          this.bomList = this.bomListObj.list
        } else {
          this.bomList = this.bomListObj.unList
        }
        localStorage.setItem('excelList', JSON.stringify(this.bomListObj))
      } else {
        return this.$message.error(res.message)
      }
    },
    async addBom () {
      this.isloading = true
      let res = await this.bom_add_list(this.edirP2);
      if (res.httpCode == 200) {
        this.isloading = false
        this.addList = res.data.records
        this.pageTotal = res.data.total
        this.addList.forEach(item => {
          if (!item.count) {
            item.count = 1
          }
        });
      } else {
        return this.$message.error(res.message)
      }
    },
    async getBomDetail (id) {
      let res = await this.get_bom_detail({ id: id });
      if (res.httpCode == 200) {
        this.saveBomList.name = res.data.name
        this.bomListObj = res.data
        this.edirP2.bomId = this.bomListObj.uuid
        this.bomList = this.bomListObj.allList
        this.downParams = {
          id: this.bomListObj.id,
          name: this.bomListObj.name,
          uuid: this.bomListObj.uuid,
          createTime: this.bomListObj.createTime,
          list: []
        }
        //this.bomCopyAjax(res.data.list)
      } else {
        return this.$message.error(res.message)
      }
    },

    async saveBomOrder () {
      for (var i = 0; i < $('.table-checkbox input').length; i++) {
        if ($('.table-checkbox input')[i].checked) {
          let li = $('.table-checkbox input')[i].value
          this.saveBomList.list.push({ "materialSn": JSON.parse(li).matchType, "bomNum": JSON.parse(li).num })
        }
      }
      let res = await this.save_bom_order(this.saveBomList);
      if (res.httpCode == 200) {
        this.$message.success('保存成功')
        localStorage.removeItem('excelList')
        this.$router.push({ path: '/bom' })
      } else {
        return this.$message.error(res.message)
      }
    },
    del (idx, obj) {
      this.$confirm('确定要删除这个商品吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.bomList.splice(idx, 1);
        this.bomDel(obj)
      }).catch(() => {
      });
    },
    async bomDel (obj) {
      let res = await this.bom_del({ id: obj.id });
      if (res.httpCode == 200) {
        this.bomListObj.allList.forEach((item, index) => {
          if (item.id == obj.id) {
            this.bomListObj.allList.splice(index, 1);
          }
        });
        this.bomListObj.list.forEach((item, index) => {
          if (item.id == obj.id) {
            this.bomListObj.list.splice(index, 1);
          }
        });
        this.bomListObj.unList.forEach((item, index) => {
          if (item.id == obj.id) {
            this.bomListObj.unList.splice(index, 1);
          }
        });
        localStorage.setItem('excelList', JSON.stringify(this.bomListObj))
      } else {
        return this.$message.error(res.message)
      }
    },
    shopBtn () {
      this.shopList = []
      if (this.btnText != '一键加入购物车') {
        return false
      }
      for (var i = 0; i < $('.table-checkbox input').length; i++) {
        if ($('.table-checkbox input')[i].checked) {
          let li = $('.table-checkbox input')[i].value
          this.shopList.push(JSON.parse(li))
        }
      }
      if (this.shopList.length > 0) {
        this.btnText = '加入购物车中...'
        this.batchCart({
          goodsInfo: this.shopList,
          language: '1'
        }).then(res => {
          if (res.httpCode == 200) {
            this.btnText = '一键加入购物车'
            this.$message.success('您选中的商品已成功加入购物车!')
            // this.$router.push('/cart')
          }
        })
          .catch(err => {
            //  console.log(err)  
            this.btnText = '一键加入购物车'
          })
      } else {
        return this.$message.error('请勾选商品在加入购物车')
      }
    },
    backBom () {
      this.$router.push('/bom')
    },
    // 多型号提交
    async bomCopyAjax (data) {
      let arr = []
      if (data.length > 0) {
        data.forEach(item => {
          arr.push({ materialSn: item.materialSn, num: item.bomNum })
        });
      }
      let res = await this.bomQuery(arr);
      if (res.httpCode == 200) {
        this.bomList = res.data
        this.count()
      }
    },
    // 全选/反选
    checkAll () {
      this.checkedAll = !this.checkedAll
      if (this.checkedAll == true) {
        for (var i = 0; i < $('.bomc-table-checkbox input').length; i++) {
          console.log($('.bomc-table-checkbox input')[i].disabled)
          if (!$('.bomc-table-checkbox input')[i].disabled) {
            $('.bomc-table-checkbox input')[i].checked = true
          }
        }
        this.isCheck = $('.bomc-table-checkbox input').length
        this.count()
      } else {
        for (var i = 0; i < $('.bomc-table-checkbox input').length; i++) {
          if (!$('.bomc-table-checkbox input')[i].disabled) {
            $('.bomc-table-checkbox input')[i].checked = false
          }
        }
        this.isCheck = 0
        this.total = 0
        this.num = 0
        this.shopList = []
      }
    },
    singeCheck (e, item) {
      if (!e.target.checked) {
        this.total = parseFloat(this.total) - parseFloat(item.totalMoney)
        this.num = this.num - 1
      } else {
        this.total = parseFloat(this.total) + parseFloat(item.totalMoney)
        this.num = this.num + 1
      }
      if (this.total < 0) {
        this.total = 0
      }
    },
    count () {
      for (let i = 0; i < this.bomList.length; i++) {
        if (this.bomList[i].count > 0) {
          this.total += parseFloat(this.bomList[i].totalMoney)
          this.num += 1
        }
      }
    }
  },
  filters: {

  },
  watch: {
    'navIdx' (val) {
      if (val == 0) {
        this.bomList = this.bomListObj.allList
      } else if (val == 1) {
        this.bomList = this.bomListObj.list
      } else {
        this.bomList = this.bomListObj.unList
      }
    }
  }
}
</script>
<style lang="scss">
</style>