<template>
  <div class="login_center_bg">
    <div class="login_center_box" v-if="params.openId1">
      <div class="login_contain clear">
        <div class="register_box l">
          <div class="reg_phone">
            <em></em>
            <input id="phone" type="text" status="0" name="mobile" maxlength="11" placeholder="请输入手机号码" v-model.trim="params.mobile" :class="{misstake:params.mobile.length!=11&&params.mobile.length!=0}">
            <i class="empty_input"></i>
            <p class="error"></p>
          </div>
          <div class="reg_message clear">
            <em></em>
            <input class="l" type="text" name="smscode" maxlength="6" placeholder="请输入短信验证码" v-model.trim="params.code" :class="{misstake:params.code.length!=6&&params.code.length!=0}">
            <span class="r" id="send" v-show="!sendFalg" @click="sendSms">发送验证码</span>
            <span class="r" id="send" v-show="sendFalg">重新发送(<i ref="timeNum">60</i>)</span>
            <i class="empty_input smscode"></i>
            <p class="error"></p>
          </div>
          <!-- <template v-if="!id">
            <div class="reg_password">
              <em></em>
              <input name="password" maxlength="20" type="password" placeholder="设置设置6至20位登录密码" v-model.trim="password1" :class="{misstake:(password1.length<6 || password1.length>20) && password1.length!=0}"><br />
              <i class="empty_input"></i>
              <p class="error"></p>
            </div>
            <div class="reg_password2">
              <em></em>
              <input name="repassword" maxlength="20" type="password" placeholder="请再次输入登录密码" v-model.trim="password2" :class="{misstake:(password2.length<6 || password2.length>20) && password2.length!=0}"><br />
              <i class="empty_input"></i>
              <p class="error"></p>
            </div>
          </template> -->
          <div>
            <p class="registerNow_tip error"></p>
            <button id="registerNow" class="d" v-if="!status">立即绑定</button>
            <button id="registerNow" v-else @click="wxBindPhone">立即绑定</button>
          </div>
        </div>
      </div>
      <div id="error" class="login_tips "></div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '注册',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二极管,晶体管,电阻,电容,集成电路,电子元器件网上商城'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内厂家直营二极管、晶体管、电阻、电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  components: {
  },
  data () {
    return {
      password1: '',
      password2: '',
      params: {
        "fromType": 1,
        "mobile": "",
        openId1: '',
        code: '',
        nickname: ''
      },
      vxCode: '',
      mobileStatus: false,
      passwordStatus: false,
      codeStatus: false,
      status: false,
      sendFalg: false,
      time: '',
      id: '',
    }
  },

  created () {
  },
  computed: {
  },
  methods: {
    //  
    ...mapActions('lxr', ['register_user', 'send_sms', 'wx_callBack', 'wx_bindPhone']),
    ...mapMutations('lxr', ['SET_TOKEN']),

    //回调微信用户信息
    async wxCallBack () {
      //code是回调后面网址上的code
      let res = await this.wx_callBack({ code: this.vxCode })
      if (res.httpCode == 200) {
        this.params.openId1 = res.data.openId1
        this.params.nickname = res.data.nickname
        this.id = res.data.id
        sessionStorage.setItem("code", res.data.openId1)
        if (res.data.id) {
          window.localStorage.setItem("userInfo", JSON.stringify(res.data))
          this.SET_TOKEN(localStorage.getItem("access-token"))
          this.$router.push('/home/user');
        }
      } else {
        this.$message.error(res.message)
      }
    },

    //发送短信
    async sendSms () {
      let sendCode = 60
      if (!this.mobileStatus) {
        return this.$message.error('手机号码填写错误')
      }
      let res = await this.send_sms({ mobile: this.params.mobile })
      if (res.httpCode == 200) {
        this.sendFalg = true
        this.time = setInterval(() => {
          sendCode--
          this.$refs.timeNum.innerText = sendCode
          if (sendCode == 0) {
            clearInterval(this.time)
            this.sendFalg = false
            this.$refs.timeNum.innerText = '60'
          }
        }, 1000);
      }
    },

    //注册
    async wxBindPhone () {
      let res = await this.wx_bindPhone(this.params)
      if (res.httpCode == 200) {
        window.localStorage.setItem("userInfo", JSON.stringify(res.data))
        this.SET_TOKEN(localStorage.getItem("access-token"))
        this.$router.push('/home/user');
      } else {
        this.$message.error(res.message)
      }
    },
    check () {
      if (this.mobileStatus && this.codeStatus) {
        this.status = true
      } else {
        this.status = false
      }
    }
  },
  mounted () {
    this.vxCode = this.$router.currentRoute.query.code
    // if (this.vxCode) {
    //   this.wxCallBack()
    // } else {
    //   this.$message.error('请重新扫码登录')
    //   this.$router.push({ path: '/login' })
    // }
    let code = sessionStorage.getItem("code")
    if (!code) {
      this.wxCallBack()
    } else {
      this.params.openId1 = code
    }
  },
  watch: {
    'params.mobile' (val) {
      if (val.length == 11) {
        this.mobileStatus = true
      } else {
        this.mobileStatus = false
      }
      this.check()
    },
    // 'password1' (val) {
    //   if (val.length >= 6 && val.length <= 20) {
    //     this.params.password = val
    //     if (val == this.password2) {
    //       this.passwordStatus = true
    //       this.params.password = val
    //     }
    //   } else {
    //     this.params.password = ''
    //     this.passwordStatus = false
    //   }
    //   this.check()
    // },
    // 'password2' (val) {
    //   if (val.length >= 6 && val.length <= 20) {
    //     if (val == this.password1) {
    //       this.passwordStatus = true
    //       this.params.password = val
    //     } else {
    //       this.passwordStatus = false
    //     }
    //   } else {
    //     this.params.password = ''
    //     this.passwordStatus = false
    //   }
    //   this.check()
    // },
    'params.code' (val) {
      if (val.length == 6) {
        this.codeStatus = true
      } else {
        this.codeStatus = false
      }
      this.check()
    }
  },
  beforeDestroy () {
    clearInterval(this.time)
  }
}
</script>
<style lang="scss" scoped>
.login_box {
  min-width: 100vw;
  min-height: 100vh;
}
.login_center_bg {
  width: 420px;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
  height: auto;
  min-height: calc(100vh - 291px);
}
.login_center_box {
  height: 240px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.register_box {
  padding-top: 0;
  height: auto;
}
</style>


