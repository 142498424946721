<template>
  <div class="mask_layout1">
    <div class="pop_up_box add_cart_box">
      <div class="pop_up_title clear">
        <h3 class="l">加入购物车 </h3>
        <span class="r" @click="addCartFun"></span>
      </div>
      <!-- 内容区域 -->
      <div class="add_cart_container">
        <div class="clear add_cart_top">
          <img id="cart_goods_img" src="@/assets/images/goods/default_img.png">
          <div class="addCartTop">
            <input name="current_goods_id" type="hidden">
            <h3 class="clear">
              <a href="" target="_blank">
                <span id="cart_goods_name">贴片电阻 0603 340KΩ(3403) ±1%</span>
              </a>
              <i class="zk" id="cart_goods_is_salv"></i>
              <i class="tj" id="cart_goods_is_tj">本月特价</i>
            </h3>
            <div class="addCartTop-con clear">
              <div class="l left">
                <p><span>制造商料号：</span><i id="cart_material_sn">RTT033403FTP</i></p>
                <p><span>参 数 规 格：</span><i id="cart_spec_param">340KΩ(3403) ±1%</i></p>
                <p><span>封 装 规 格：</span><i id="cart_package_param">0603</i></p>
                <p><span>包 装 数 量：</span><i id="cart_package_num" data="5000">5000 pcs</i></p>
                <p><span>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌：</span><i id="cart_brand_name">旺诠 | RALEC</i></p>
              </div>
              <div class="l">
                <p><span>商 品 类 别：</span><i id="cart_series"></i></p>
                <p><span>库 存 数 量：</span><i id="cart_store_count" data="5000">5000 pcs</i></p>
                <p><span>交 货 周 期：</span><i id="cart_deliver_time">7~14 工作日</i></p>
                <p><span>最小起订量：</span><i id="cart_smaller_sales_sum" data="50">50 pcs</i></p>
              </div>
            </div>
          </div>
        </div>

        <div class="addCartPrice clear">
          <div class="l title"><span>阶梯价格</span></div>
          <div class="l ladder ">
            <table>
              <tbody>
                <tr>
                  <th>采购数量</th>
                  <th>单价</th>
                </tr>
                <tr>
                  <td>50+</td>
                  <td>￥0.0046/pcs</td>
                </tr>
                <tr>
                  <td>501+</td>
                  <td>￥0.0045/pcs</td>
                </tr>
                <tr>
                  <td>5001+</td>
                  <td>￥0.0041/pcs</td>
                </tr>
                <tr>
                  <td>25001+</td>
                  <td>￥0.0037/pcs</td>
                </tr>
                <tr>
                  <td>50001+</td>
                  <td>￥0.0036/pcs</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="clear add_cart_btm">
          <div class="l inpu_box first">
            <span>购买数量</span>
          </div>
          <div class="l inpu_box">
            <input type="number" min="1" placeholder="整盘购" id="discNum" data-goods="" style="width: 110px;"><i>盘</i>
          </div>
          <div class="l inpu_box">
            <input type="number" min="1" placeholder="输入散装购买数量" id="pcsNum" data-goods="" value=""><i id="o_pcsNum"></i>
            <div class="remind_box" style="display: none">
              <div>
                <p id="total_pcs">您购买该产品的数量：<span>4✖200pcs=800 pcs<br>数量自动计为包装数量的整数倍</span></p>
                <span class="know">我知道了</span>
              </div>
              <em></em>
            </div>
          </div>
          <div class="l total_box">
            <p id="goods_price_id" data=""><span>× 阶梯单价：</span><span class="jddj">￥0.0046/pcs</span></p>
          </div>
        </div>

        <div class="add_cart_total clear">
          <p class="l">合计金额</p>
          <p class="r">
            <span class="old_total">原价：<i></i></span>
            <span id="o_data" class="total">总价：<i></i></span>
            <span id="add_cart" class="submit r">加入购物车</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  props: {
  },
  data () {
    return {

    }
  },

  created () {

  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    // //...mapActions('demo', ['increment'])
    addCartFun () {
      this.$emit('childByValue', false)
    }
  },
  mounted () {

  },
}
</script>
<style lang="scss" scoped>
/* 添加购物车 */
.add_cart_box {
  width: 680px;
  margin-left: -340px;
  margin-top: -315px;
}

.add_cart_container {
  font-size: 12px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

.add_cart_top {
  width: 680px;
  height: 186px;
  background: #f0f5f9;
  position: relative;
}

.add_cart_top > img {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 20px;
  left: 20px;
}

.add_cart_top > div {
  width: 100%;
  height: 170px;
  padding-left: 96px;
  padding-top: 20px;
}

.add_cart_top .addCartTop-con .l {
  width: 290px;
}

.add_cart_top p {
  color: #9fafbc;
  margin-bottom: 10px;
}

.add_cart_top p i {
  color: #5c5c5c;
}

.add_cart_top a {
  color: #1e1f30;
  font-size: 12px;
}

.add_cart_top h3 {
  margin-bottom: 10px;
}

.add_cart_top a:hover {
  color: #0067ed;
}

.add_cart_total {
  width: 640px;
  height: 90px;
  line-height: 90px;
  margin: 0 auto;
}

.add_cart_total p.l {
  color: #9fafbc;
  font-weight: bold;
}

.add_cart_total p.r > span.total {
  color: #1e1f30;
}

.add_cart_total p.r > span.total > i {
  color: #e0212f;
  font-size: 14px;
  font-weight: bold;
}

.add_cart_total p.r > span.old_total {
  margin-right: 30px;
  color: #1e1f30;
}

.add_cart_total p.r > span.old_total > i {
  text-decoration: line-through;
}

.add_cart_total p.r > span.submit {
  width: 120px;
  height: 30px;
  line-height: 30px;
  margin-top: 30px;
  border-radius: 4px;
  margin-left: 30px;
}

.add_cart_btm {
  width: 640px;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 auto;
  padding: 20px 0 20px 0;
}

.addCartPrice {
  padding: 20px 20px 0 20px;
}

.addCartPrice .title {
  color: #9fafbc;
  font-weight: bold;
  margin-right: 30px;
}

.addCartPrice .ladder {
  width: 80%;
}

.addCartPrice .ladder tr th {
  width: 16%;
  text-align: left;
  color: #9fafbc;
  font-weight: normal;
}

.addCartPrice .ladder tr td {
  width: 16%;
  text-align: left;
  color: #e0212f;
  font-weight: normal;
  line-height: 30px;
}

.addCartPrice .ladder tr td.priceOld {
  color: #bbbbbb;
  text-decoration: line-through;
}

.addCartPrice .ladder tr td:first-child {
  color: #1e1f30;
}

.add_cart_top .r {
  position: relative;
}

.seePriceD_box {
  width: 300px;
  padding-top: 10px;
  position: absolute;
  top: 24px;
  left: -6px;
  z-index: 2;
}

.seePriceD_box > em {
  width: 20px;
  height: 12px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: 0;
  background: url(~@/assets/images/userCenter/order_progress.png) no-repeat;
}

.seePriceD_box table {
  font-size: 12px;
  color: #5c5c5c;
  width: 100%;
  background: #f1f1f1;
  border-top: 1px solid #e4e2e2;
  border-left: 1px solid #e4e2e2;
  text-align: center;
}

.seePriceD_box table td,
.seePriceD_box table th {
  border-right: 1px solid #e4e2e2;
  border-bottom: 1px solid #e4e2e2;
}

.seePriceD_box table th {
  line-height: 36px;
}

.seePriceD_box table td {
  line-height: 33px;
}

.add_cart_btm select {
  width: 100px;
  height: 40px;
  padding-right: 20px;
  background: url(~@/assets/images/purchase_select.png) no-repeat 90% center;
  border: 1px solid #c5c5c5;
  text-indent: 5px;
}

.add_cart_btm .inpu_box.first {
  margin-left: 0px;
  color: #9fafbc;
  font-weight: bold;
}

.add_cart_btm .inpu_box {
  position: relative;
  margin: 0 15px;
  line-height: 40px;
}

.add_cart_btm input {
  width: 171px;
  height: 40px;
  border: 1px solid #c5c5c5;
  text-indent: 12px;
  padding-right: 40px;
}

.add_cart_btm .inpu_box i {
  position: absolute;
  width: 40px;
  line-height: 40px;
  color: #5c5c5c;
  top: 0;
  right: 0;
  text-align: center;
}

.add_cart_btm .total_box {
  line-height: 40px;
  font-size: 12px;
  color: #373636;
}

.add_cart_btm .total_box .total {
  color: #c9240b;
  font-weight: bold;
}

.add_cart_btm .total_box p .jddj {
  color: #e0212f;
}

.add_cart_btm .submit {
  display: block;
  float: right;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: url(~@/assets/images/cart.png) no-repeat 28px 11px #0067ed;
  margin-left: 14px;
}
.inpu_box input {
  width: 180px;
  height: 40px;
  border: 1px solid #f0f0f0;
  padding-right: 38px;
  text-indent: 12px;
  border-radius: 4px;
}
</style>


