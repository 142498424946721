<template>
  <div class="r userCenter_container_box">
    <h2>BOM管理</h2>
    <div class="userCenter_container orderM_contain">
      <!-- <div class="order-tatal">符合条件的共<i>{{total}}</i>个订单</div> -->
      <div class="orderM_body_box">
        <div class="bom-order-box" v-loading='isloading'>
          <table class="bom-order">
            <tbody>
              <tr>
                <th>BOM名称</th>
                <th>创建时间</th>
                <th>操作</th>
              </tr>
              <template v-if="list">
                <tr v-for="(item,index) in list" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.createTime | calcTime}}</td>
                  <td>
                    <router-link v-if="item.distribution==1" :to="{path:'/match',query:{id:item.id}}" class="lookDetail">查看详情</router-link>
                    <a href="javascript:;" class="del" @click="del(item.id)">删除</a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="no-order" v-if="list&&list.length==0">
            <img src="@/assets/images/goods/noshop_1.png" alt="">
            <p>暂无订单</p>
          </div>
          <div class="page-wrap">
            <el-pagination background hideOnSinglePage @current-change="currentChange" layout="prev, pager, next" :total="total"></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '个人中心-订单管理',
    }
  },
  data () {
    return {
      isloading: true,
      list: null,
      params: {
        pageSize: 10,
        pageNum: 1
      },
      total: 0,
    }
  },

  created () {
    this.getBomOrder()
  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['get_bom_order', 'del_bom_order']),
    currentChange (e) {
      this.params.pageNum = e
      this.getBomOrder()
    },
    async getBomOrder () {
      this.isloading = true
      let res = await this.get_bom_order(this.params)
      this.isloading = false
      if (res.httpCode == 200) {
        this.list = res.data.records
        this.total = res.data.total
      } else {
        return this.$message.error(res.message)
      }
    },
    del (id) {
      this.$confirm('确定删除这个物料清单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delBomOrder(id)
      }).catch(() => {
      });
    },
    async delBomOrder (id) {
      let res = await this.del_bom_order({ id: id })
      if (res.httpCode == 200) {
        this.getBomOrder()
        return this.$message.success(res.message)
      } else {
        return this.$message.error(res.message)
      }
    }
  },
  mounted () {
  },
  filters: {
    calcTime (val) {
      return val.substr(0, val.length - 2)
    },
  }
}
</script>
<style lang="scss">
// @import "~@/assets/css/member-orderManage.scss";
</style>


