<template>
  <div>
    <!-- 头部组件 -->
    <keepAlive>
      <Psearch></Psearch>
    </keepAlive>
    <Pnav></Pnav>

    <!-- 主体路由 -->
    <router-view />

    <!-- 底部组件 -->
    <keepAlive>
      <Pbottom></Pbottom>
    </keepAlive>
    <transition name="el-fade-in">
      <div class="mask_layout" id="hasOrder" v-if="hasOrderFalg">
        <div class="layout_box" @click.stop>
          <div class="layout_con">
            <div class="info-wrap">
              <img src="@/assets/images/hasOrder.png">
              <h4>等待买家付款</h4>
              <p>您提交的审核订单审核通过啦，快去付款吧！</p>
              <router-link :to="{path:'/home/order/list',query:{pay_status:0}}" class="pBtn">立即去付款</router-link>
              <a href="javascript:;" class="cancel" @click="hasOrderF">容我再想想</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 右侧边栏浮动 -->
    <!-- <keepAlive>
      <rightBar></rightBar>
    </keepAlive> -->
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Psearch from '@/components/public/pSearch.vue'
import Pnav from '@/components/public/pNav.vue'
import Pbottom from '@/components/public/pBottom.vue'
export default {
  name: 'app',
  async asyncData ({ store }) {
    let hotKeyword = await store.dispatch('lxr/hot_keyword', {
      pageNum: 1,
      pageSize: 10,
    })
    let getFriendLink = await store.dispatch('lxr/get_friend_link');
    let getProductMenuIndex = await store.dispatch('lxr/get_product_menu_index');
    return hotKeyword, getFriendLink, getProductMenuIndex
  },
  components: {
    Psearch,
    Pnav,
    Pbottom
  },
  data () {
    return {
      hasOrderFalg: false,
      params: {
        "data": {
          "buyEndTime": "",
          "buyStartTime": "",
          "orderStatus": 0,
          "search": ""
        },
        "pageNum": 1,
        "pageSize": 1
      },
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['token', 'productMenuIndex'])
  },
  methods: {
    ...mapMutations('lxr', ['SET_TOKEN', 'GET_FRIEND_LINK', 'GET_PRODUCT_MENU_INDEX', 'SET_USERINFO']),
    ...mapActions('lxr', ['get_product_menu_index', 'get_order_list', 'get_friend_link']),
    async getFriendLink () {
      let res = await this.get_friend_link()
    },
    async getProductMenuIndex () {
      let res = await this.get_product_menu_index()
    },
    async getOrderList () {
      let res = await this.get_order_list(this.params)
      if (res.httpCode == 200) {
        if (res.data.records.length > 0) {
          this.hasOrderFalg = true
        }
      }
    },
    hasOrderF () {
      sessionStorage.setItem('hasOrderFalg', 'false')
      this.hasOrderFalg = false
    }
  },
  mounted () {
    if (!this.token) {
      this.SET_TOKEN(sessionStorage.getItem('access-token'))
    }
    if (!this.friendLink) {
      this.getFriendLink()
    }
    if (!this.productMenuIndex) {
      this.getProductMenuIndex()
    }
    if (!this.userInfo) {
      let userInfo = window.localStorage.getItem("userInfo")
      this.SET_USERINFO(JSON.parse(userInfo))
    }
  },
  watch: {
    '$route.path' () {
      if (this.token && sessionStorage.getItem('hasOrderFalg') != 'false') {
        this.getOrderList()
      }
    }
  }
}
</script>
<style lang="scss">
</style>


