//兼容到IE9或到10
import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import IboxPlugin from '@/plugins/ibox'
import axios from 'axios'
import VueMeta from 'vue-meta'
import promise from 'es6-promise';
import "@/assets/css/newMain.scss";
import "@/assets/css/login.scss";
promise.polyfill();
//import 'element-ui/lib/theme-chalk/base.css';
import {
  createRouter
} from './router'
import {
  createStore
} from './store'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false
//ElementUI库 
import {
  MessageBox,
  Message,
  DatePicker,
  Pagination,
  Loading,
  Carousel,
  CarouselItem,
  Checkbox,
  Radio,
  RadioGroup,
  Select,
  Option,
  Collapse,
  CollapseItem,
  Input,
  Button,
  InputNumber
} from 'element-ui'
Vue.use(IboxPlugin).use(DatePicker).use(Pagination).use(Carousel).use(Checkbox).use(Radio).use(RadioGroup).use(CarouselItem)
  .use(Loading.directive).use(Select).use(Option).use(Collapse).use(CollapseItem).use(Input).use(Button).use(InputNumber)

// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'


/* element 组件按需加载 */
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.prototype.$axios = axios
Vue.component(CollapseTransition.name, CollapseTransition)
export function createApp() {
  const router = createRouter()
  const store = createStore()
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })
  // router.beforeEach((to, from, next) => {
  //   console.log(to.meta)
  //   next()
  // })
  return {
    app,
    router,
    store
  }
}