<template>
  <div class="newMain bgf5f5f5">
    <div class="news-wrap announcement-wrap">
      <div class="new-list-wrap">
        <h3 class="type-title">
          公司公告
          <div class="search-wrap">
            <input type="text" placeholder="请输入文章关键词">
            <i></i>
          </div>
        </h3>
        <div class="list-wrap" v-if="newList">
          <router-link class="list" v-for="(item, index) in newList.records" :key="index" :to="{path:'/news/detail',query:{id:item.id}}" target="_blank">
            <div class="info clearfix">
              <h4 class="new-title fl">{{item.title}}</h4>
              <div class="time fr">{{item.updateTime.substr(0,10)}}</div>
            </div>
          </router-link>
          <div class="pagination" v-if="newList" style="text-align:right;">
            <el-pagination background hide-on-single-page layout="prev, pager, next" :total="newList.total" :page-size="params.pageSize" :current-page="params.pageNum" @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  metaInfo () {
    return {
      title: '服务中心',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  asyncData ({ store, route }) {
    let getNewsList = store.dispatch('lxr/get_news_list', {
      "cid": 35,
      "pageNum": parseInt(route.query.page) || 1,
      "pageSize": 10
    });
    return getNewsList;
  },
  data () {
    return {
      params: {
        "cid": this.$route.query.cid,
        "pageNum": parseInt(this.$route.query.page) || 1,
        "pageSize": 10
      }
    }
  },
  created () {
    // console.log(this.newList)
  },
  computed: {
    ...mapState('lxr', ['newList']),
  },
  methods: {
    ...mapActions('lxr', ['get_news_list']),
    ...mapMutations('lxr', ['GET_NEWS_LIST']),
    async getNewsList () {
      this.params.cid = this.$route.query.cid
      let res = await this.get_news_list(this.params)
    },
    currentChange (e) {
      this.params.pageNum = e
      this.$router.push({
        path: '/announcement',
        query: { cid: this.params.cid, page: this.params.pageNum }
      })
      this.$router.go(0)
    }
  },
  mounted () {
    if (!this.newList) {
      this.getNewsList()
    }
  },
}
</script>
<style lang="scss">
</style>