<template>
  <div class="indexBox">
    <div class="box_ban clear">
      <div class="banner">
        <div class="ass ass3">
          <ul class="ass_ul" v-if="productMenuIndex">
            <li class="ass_list" v-for="(item, index) in productMenuIndex" :key="index" @mousemove="iconFalg=index" v-show="index < 7">
              <a class="nav_menu_title">
                <!-- {{item.onSaleNum}} -->
                <img class="icon " v-if="iconFalg==index" :src="item.image2">
                <img class="icon" v-else :src="item.image">
                {{ item.nameCh }}
                <i class="ass_list_right"></i>
              </a>
              <div class="nav_menu_box">
                <div class="l">
                  <div class="nav_menu">
                    <div class="nav_menu_item clear">
                      <!-- <router-link :to="{
                          path: '/product/level',
                          query: { cid: item.id, cname: item.nameCh,fcid: item.id, }
                        }">
                        <h3 class="clear">
                          <img :src="item.image" />
                          {{ item.nameCh }}
                        </h3>
                      </router-link> -->
                      <ul class="clear" v-if="item.subCategory">
                        <li v-for="(i2, d2) in item.subCategory" :key="d2">
                          <router-link :to="{
                              path: '/product/level',
                              query: {
                                cid: i2.id, 
                                fcid: item.id,
                                cname: item.nameCh,
                                name: i2.nameCh
                              }
                            }">{{ i2.nameCh }}</router-link>
                          <i></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="banner banner2">
        <div class="userInfo-wrap">

          <img v-if="token&&userInfo&&userInfo.headImg" @click="linkUser()" :src="userInfo.headImg" class="pic" />
          <img v-else-if="token" @click="linkUser()" src="@/assets/images/default_tx.png" class="pic" />
          <img v-else @click="linkLogin()" src="@/assets/images/default_tx_n.png" class="pic" />

          <p class="tip">Hi，欢迎来合晶芯城
            <!-- <i></i> -->
          </p>
          <div class="link" v-if="token">
            <router-link to="/home/user" rel="nofollow">会员中心</router-link>
            <router-link to="/home/order/list" rel="nofollow">我的订单</router-link>
          </div>
          <div class="link" v-else>
            <router-link to="/register" rel="nofollow">注册有礼</router-link>
            <router-link to="/login" rel="nofollow">去登录</router-link>
          </div>
          <div class="cz">
            <router-link :to="{ path: '/home/order/list', query: { pay_status: -1 } }">
              <img src="@/assets/images/index/icon-gglb.png" />
              <p>订单查询</p>
            </router-link>
            <!-- <router-link :to="{ path: '/home/order/list', query: { pay_status: 1 } }">
                <img src="@/assets/images/index/icon-dfh.png" />
                <p>待发货</p>
              </router-link> -->
            <router-link to="/home/user/integral">
              <img src="@/assets/images/index/icon-wdjf.png" />
              <p>我的积分</p>
            </router-link>
            <!-- <router-link to="/home/rules">
                <img src="@/assets/images/index/icon-hyqy.png" />
                <p>会员权益</p>
              </router-link> -->
            <router-link to="/tool">
              <img src="@/assets/images/index/icon-hsgj.png" />
              <p>换算工具</p>
            </router-link>
            <router-link to="/specifica">
              <img src="@/assets/images/index/icon-dsh.png" />
              <p>规格列表</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="carousel_box clearfix top_carousel_box">
        <el-carousel trigger="click">
          <el-carousel-item v-for="(item, index) in indexBanner" :key="index">
            <a :href="item.url" target="_blank" :style="'background: url('+item.img+') no-repeat center center'">
              <!-- <img class="lazy" :src="item.img" /> -->
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="bom-courier clearfix">
      <div class="bom fl">
        <h3 class="title">元器件BOM配单专家</h3>
        <div class="b-top clearfix">
          <ul class="fl">
            <li>
              超过60万种商品
            </li>
            <li>智能秒配单</li>
            <li>人工即时支持</li>
            <li>下单2小时发货</li>
          </ul>
          <div class="fileUpload fr">
            <div class="btn">
              <input type="file" ref='file' id="file" class="fileExcel" name="file" @change="upfiles" accept="" style="visibility: hidden; width: 0px; height: 0px;">
              <label for="file">上传BOM文件</label>
            </div>
            <a class="down" href="javascript:;" @click="download">下载BOM标准模板 提升选型准确率</a>
          </div>
        </div>
      </div>
      <div class="courier fr">
        <h3 class="title">物流订单查询</h3>
        <div class="inp">
          <input type="text" class="search-int" v-model="expNo" placeholder="请填写物流单号">
          <p class="tip"><i>*</i> 仅支持顺丰快递、速尔快递、龙邦快递查询</p>
          <input type="button" name="" :value="wlText" class="search-btn" @click="selectKd">
        </div>
      </div>
    </div>

    <div class="index-plate" v-if="sampleListIndex">
      <h2 class="plate-title p0">免费样品<span>500+原厂提供覆盖各品类的电子元件</span>
        <router-link :to="{path:'/industry',query:{cid:38}}">More</router-link>
      </h2>
      <div class="plate-wrap sample-wrap clearfix">
        <div class="other">
          <router-link :to="{path:'/sample'}"><img class="yp-gg" src="@/assets/images/sampleImg.png" /></router-link>
        </div>
        <ul class="plate-ul">
          <li v-for="(item,index) in sampleListIndex.records" :key="index">
            <img v-if="item.goodsImg" class="yp-logo" :src="item.goodsImg" />
            <img v-else class="yp-logo" src="@/assets/images/goods/default_img.png" />
            <h3 class="yp-name">{{item.categoryName}} {{item.materialSn}}</h3>
            <p class="number">最高可领取：30个</p>
            <router-link class="yp-lq" target='_blank' :to="{path:'/sample/detail',query:{id:item.id}}">申请样品</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="index-plate">
      <h2 class="plate-title p1">授权代理<span>实力渠道、海量库存</span>
        <router-link :to="{path:'/industry',query:{cid:38}}">More</router-link>
      </h2>
      <div class="plate-wrap brand-wrap clearfix">
        <ul class="plate-ul" v-if="brandHotList">
          <!-- <li v-for="(item, index) in brandHotList" :key="index" @click="toBrand(item.fno)" v-if="index<4">
            <img src="@/assets/images/sqlist_bg.png" class="topImg">
            <img class="brand-logo" v-if="item.logo" :src="item.logo" :alt="item.brandDesc_ch.name">
            <img class="brand-logo" v-else src="@/assets/images/brand/default.png">
            <h3 class="brand-name">{{item.brandDesc_ch.name}}</h3>
          </li> -->
          <li>
            <img src="@/assets/images/newindex/brand_1.png" class="topImg">
          </li>
          <!-- <li>
            <img src="@/assets/images/newindex/brand_2.png" class="topImg">
          </li> -->
          <li>
            <img src="@/assets/images/newindex/brand_3.png" class="topImg">
          </li>
          <li>
            <img src="@/assets/images/newindex/brand_4.png" class="topImg">
          </li>
        </ul>
        <div class="other">
          <div class="carousel_box clearfix" v-if="SQlist">
            <el-carousel trigger="click">
              <el-carousel-item v-for="(item, index) in SQlist" :key="index">
                <a href="javascript:;" target="_blank">
                  <img class="sq" :src="item.image" />
                  <h3>{{item.title}}</h3>
                </a>
              </el-carousel-item>
            </el-carousel>
          </div>
          <router-link v-else :to="{path:'/brand'}"><img class="yp-gg" src="@/assets/images/brandImg.png" /></router-link>
        </div>
      </div>
    </div>

    <div class="new-wrap clearfix">
      <div class="industry-wrap fl">
        <h3 class="p2">行业新闻<router-link :to="{path:'/industry',query:{cid:38}}">More</router-link>
        </h3>
        <div class="link-more" v-if="newList2">
          <router-link v-for="(item, index) in newList2.records" :key="index" v-show="index<6" :to="{ path: '/news/detail', query: { id: item.id } }" :title="item.title" target="_blank">{{item.title}}<span class="time">{{item.updateTime.substr(0,10)}}</span></router-link>
        </div>
      </div>
      <div class="plan-wrap fr">
        <h3 class="p3">商城资讯<router-link :to="{path:'/industry',query:{cid:35}}">More</router-link>
        </h3>
        <div class="link-more" v-if="newList">
          <router-link v-for="(item, index) in newList.records" :key="index" v-show="index<6" :to="{ path: '/news/detail', query: { id: item.id } }" :title="item.title" target="_blank">{{item.title}}<span class="time">{{item.updateTime.substr(0,10)}}</span></router-link>
        </div>
      </div>
    </div>

    <transition name="el-fade-in">
      <div class="mask_layout" id="logistics" v-if="logistic_flag" @click="logisticFunC">
        <div class="layout_box" @click.stop>
          <div class="layout_title">
            物流详情
            <i class="layout_close" @click="logisticFunC"></i>
          </div>
          <div class="layout_con">
            <!-- <div class="info-wrap">
              <img src="@/assets/images/member/game-xz.png">
              <div class="info">
                <p>订单号：123456789</p>
                <p>总金额：¥86.59</p>
                <p>预计12-01送达</p>
              </div>
            </div> -->
            <div class="details">
              <ul>
                <li v-for="(item,index) in wlList" :key="index" :class="{current:index==0}">
                  <p>{{item.AcceptStation}}</p>
                  <div class="time">
                    <p>{{item.time1}}</p>
                    <p>{{item.time2}}</p>
                  </div>
                </li>
              </ul>
              <div class="no_product_box" v-if="wlList.length==0">
                <img src="@/assets/images/goods/noshop_1.png">
                <p>很抱歉，暂无相关物流</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- 电阻活动  start -->
    <transition name="el-fade-in">
      <div class="mask_layout" v-if="dz_activity_flag">
        <div class="pop_up_box dzBox" style="display:block;background: transparent;">
          <router-link :to="{path:'/product'}"><img src="@/assets/images/activity/dzBg-1.png" /></router-link>
          <i class="dzClose" @click="dzActivity"></i>
        </div>
      </div>
    </transition>

    <div class="link clear"></div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { exportMethod } from '@/plugins/ibox/utils'
import axios from 'axios'
export default {
  name: 'index',
  metaInfo () {
    return {
      title:
        '合晶芯城 - 厂家直营 - 二极管 - 晶体管 - 电阻电容 - 集成电路 - 电子元器件交易平台',
      meta: [
        {
          name: 'keywords',
          content:
            '合晶芯城,二极管,晶体管,电阻,电容,集成电路,电子元器件网上商城'
        },
        {
          name: 'description',
          content:
            '合晶芯城（www.hotking.com）是国内厂家直营二极管、晶体管、电阻、电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  async asyncData ({ store }) {
    let get_index_order = await store.dispatch('lxr/get_index_order')
    let getNewsList = await store.dispatch('lxr/get_news_list', {
      cid: 35,
      pageNum: 1,
      pageSize: 6
    })
    let getNewsList2 = await store.dispatch('lxr/get_news_list', {
      cid: 38,
      pageNum: 1,
      pageSize: 6
    })
    let getBrandHot = await store.dispatch('lxr/get_brand_hot', {
      pageSize: 10,
      pageNum: 1,
    });

    let getNewsAdv = await store.dispatch('lw/getNewsIndexBanner', {
      aid: 2
    });
    let getSampleList = await store.dispatch('lxr/get_sample_hotList', {
      categoryId: '',
      pageSize: 5,
      pageNum: 1,
    });
    let getCertificateList = await store.dispatch('lxr/certificate_list');

    return get_index_order, getNewsList, getNewsList2, getNewsAdv, getBrandHot, getSampleList, getCertificateList
  },
  components: {},
  data () {
    return {
      // url: process.env.VUE_APP_URL
      wlText: '查询',
      iconFalg: -1,
      logistic_flag: false, //是否打开物流
      params: {
        pageNum: 1,
        pageSize: 10
      },
      purchaseNav: [
        {
          name: '电阻',
          categoryId: 35
        },
        {
          name: '电容',
          categoryId: 38
        },
        {
          name: '二极管',
          categoryId: 43
        },
        {
          name: 'MOS管',
          categoryId: 44
        },
        {
          name: '连接器',
          categoryId: 165
        }
      ],
      purchaseNavIdx: 0,
      isloading: false,
      newParams: {
        cid: 35,
        pageNum: 1,
        pageSize: 10
      },
      newParams2: {
        cid: 38,
        pageNum: 1,
        pageSize: 10
      },
      dz_activity_flag: false,
      file: '',
      expNo: '',
      wlList: [],
    }
  },

  created () { },
  computed: {
    ...mapState('lxr', [
      'productListHot',
      'productListPurchase',
      'newList', 'newList2',
      'productMenuIndex',
      'indexOrderList', 'userInfo', 'brandHotList', 'sampleListIndex', 'token', 'SQlist'
    ]),
    ...mapState('lw', ['indexBanner'])
  },
  methods: {
    ...mapActions('lxr', [
      'get_product_special',
      'get_product_purchase',
      'get_news_list',
      'get_index_order', 'get_brand_hot', 'get_sample_hotList', 'select_kd', 'certificate_list'
    ]),
    ...mapMutations('lxr', [
      'GET_PRODUCT_SPECIAL',
      'GET_PRODUCT_HOT',
      'GET_PRODUCT_PURCHASE',
      'GET_NEWS_LIST',
      'GET_INDEX_ORDER'
    ]),
    ...mapActions('lw', ['getNewsIndexBanner']),
    ...mapMutations('lw', ['GET_NEWS_INDEX_BANNER']),
    linkUser () {
      this.$router.push({ path: '/home/user' })
    },
    linkLogin () {
      this.$router.push({ path: '/login' })
    },
    async certificateList () {
      let res = await this.certificate_list()
    },
    async selectKd () {
      if (!this.expNo) {
        return this.$message.error('请填写物流单号')
      }
      this.wlText = '查询中...'
      let res = await this.select_kd({ expNo: this.expNo })
      if (res.httpCode == 200) {
        this.logistic_flag = true
        this.wlText = '查询'
        let a = res.data.Traces
        if (a.length > 0) {
          a.forEach((item, index) => {
            item.time1 = item.AcceptTime.substr(0, 10)
            item.time2 = item.AcceptTime.substr(11, 16)
            this.wlList.unshift(item)
          });
        }
      } else {
        this.$message.error('搜索出现异常')
        this.wlText = '查询'
      }
    },
    upfiles: function (e) {
      this.file = e.target.files[0]
      console.log(this.file)
      if (this.file == '' || !this.file) {
        return this.$message.error("请上传模板文件");
      } else {
        this.$message({
          showClose: true,
          message: '文件上传解析中...',
          type: 'warning',
          duration: 0
        });
        var formData = new FormData();
        formData.append('file', this.file);
        formData.append('language', 1);
        let config = {
          //添加请求头
          headers: { "Content-Type": "multipart/form-data" },
          // responseType: 'blob',          
        };
        axios.post('/shop/bom/importBomExcel', formData, config).then(res => {
          if (res.data.httpCode == 200) {
            $('.el-message--warning').remove()
            this.$router.push({
              name: 'match',
              query: {
                type: 2
              }
            })
            localStorage.setItem('excelList', JSON.stringify(res.data.data))
          } else {
            $('.el-message--warning').remove()
            this.$message.error(res.data.message);
          }
          this.isloading = false
          this.$refs.file.value = ''
        })
          .catch(function (error) {
          });
      }
    },
    async download () {
      let obj = {
        method: 'get',
        url: '/bom/downloadExcel',
        fileName: `bom查询模板导出`,
        params: ''
      }
      exportMethod(obj) // 模板导出
    },
    //品牌
    toBrand (fno) {
      this.$router.push({ path: '/brand/detail', query: { fno: fno } })
    },
    async getBrandHot () {
      let res = await this.get_brand_hot({
        pageSize: 10,
        pageNum: 1,
      })
    },
    async getSampleList () {
      let res = await this.get_sample_hotList({
        categoryId: '',
        pageSize: 5,
        pageNum: 1,
      })
    },

    //打开电阻活动
    dzActivity () {
      sessionStorage.setItem('dzFalg', 'true')
      this.dz_activity_flag = !this.dz_activity_flag
    },

    //关闭物流
    logisticFunC () {
      this.logistic_flag = false
      this.wlList = []
    },
    updateData () {
      this.$router.go(0)
    },
    async getNewsAdv () {
      let res = await this.getNewsIndexBanner({ aid: 2 })
    },

    //获取首页订单
    async getIndexOrder () {
      let res = await this.get_index_order()
    },
    async getNewsList () {
      let res = await this.get_news_list(this.newParams)
    },
    async getNewsList2 () {
      let res = await this.get_news_list(this.newParams2)
    },
    link (gt) {
      this.$router.push({ path: '/search', query: { gt: gt, type: 1 } })
    }
  },
  mounted () {
    // var result = this.isIE();
    // if (!result) {
    //   //不是IE浏览器
    //   //获得按钮元素
    //   var toDesktopButtonNode = document.getElementById("toDesktopButton");
    //   //隐藏按钮
    //   toDesktopButtonNode.style.display = "none";
    // }
    if (!this.indexOrderList) {
      this.getIndexOrder()
    }
    if (!this.newList) {
      this.getNewsList()
    }
    if (!this.newList2) {
      this.getNewsList2()
    }
    if (!this.indexBanner) {
      this.getNewsAdv()
    }
    if (!this.brandHotList) {
      this.getBrandHot()
    }
    if (!this.sampleListIndex) {
      this.getSampleList()
    }
    if (!this.SQlist) {
      this.certificateList()
    }
  },
  watch: {}
}
</script>
<style lang="scss">
// @import "~@/assets/css/home.scss";
.top_carousel_box {
  .el-carousel__container,
  .el-carousel__item {
    height: 406px;
  }
}
.dzBox {
  background: transparent;
  height: 428px;
  box-shadow: unset;
  text-align: center;
  width: 581px;
  top: -100px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  img {
    width: 581px;
    display: block;
  }
  .dzClose {
    background: url(~@/assets/images/activity/dzBg-Close.png) no-repeat center
      center;
    background-size: 60px 60px;
    width: 60px;
    height: 60px;
    display: inline-block;
    // margin-left: 70px;
    margin-top: 30px;
    cursor: pointer;
  }
}
</style>
