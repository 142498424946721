<template>
  <div class="footer">
    <div class="footer-t">
      <ul class="clearfix">
        <li>
          <img src="@/assets/images/footer/ft_1.png">
          <div class="text">
            <h3>正品保证</h3>
            <p>所有产品原厂质量保证</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/footer/ft_2.png">
          <div class="text">
            <h3>海量现货</h3>
            <p>海量现货库存</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/footer/ft_3.png">
          <div class="text">
            <h3>闪电发货</h3>
            <p>最快两小时发货</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/footer/ft_4.png">
          <div class="text">
            <h3>售后无忧</h3>
            <p>完善的售后体系</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="bottom clear">
      <div class="botbox">
        <div class="botbox-j clear" v-if="friendLink">
          <span>友情链接：</span>

          <span v-for="(item, index) in friendLink" :key="index"><a :href="item.link"
                                                                    target="_blank">{{ item.name }}</a> <i>|</i></span>

          <a href="/link" class="more" target="_blank">更多>></a>
        </div>

        <div class="botbox-t clear">
          <p class="l">
            <!-- <router-link to="/link">友情链接</router-link> -->
            <router-link :to="{path:'/help',query:{id:35}}">帮助中心</router-link>
            <router-link to="/agreement">条款与条件</router-link>
          </p>
          <p class="r">
            <span class="time">周一至周六 9:00-18:00</span>
            <a class="qq" href="http://wpa.qq.com/msgrd?v=3&uin=3002484866&site=qq&menu=yes" target="_blank"
               rel="nofollow"><i></i>3002484866</a>
            <span class="tel"><i></i>0755-21005112</span>
            <span class="kf" @click="clickPopBox()" rel="nofollow"><i></i>在线客服</span>
          </p>
        </div>
        <div class="botbox-c clear">
          <p class="l">
            <a href="javascript:;" class="fl" rel="nofollow">增值电信业务经营许可证：粤B2-20211587</a>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030902001437" target="_blank"
               class="fl" rel="nofollow">
              <img src="@/assets/images/gwanbei.png" style="float:left;">
              <span>粤公网安备 44030902001437号</span>
            </a>
            <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">@Copyright 粤ICP备18108309号-3</a>
          </p>
          <p class="r">
            <a href="javascript:;">@深圳市合晶芯城科技有限公司</a>
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="footer-m">
      <ul class="clearfix">
        <li>
          <h3>购物指南</h3>
          <div class="link">
            <a href="javascript:;">登录注册</a>
            <a href="javascript:;">客户必读</a>
            <router-link :to="{path:'/help/shopping/guide',query:{name:0,id:8}}">购物流程</router-link>
            <a href="javascript:;">其他问题</a>
          </div>
        </li>
        <li>
          <h3>支付&配送</h3>
          <div class="link">
            <a href="javascript:;">支付方式</a>
            <a href="javascript:;">支付安全</a>
            <a href="javascript:;">送货时效</a>
            <a href="javascript:;">快递运输</a>
          </div>
        </li>
        <li>
          <h3>售后体系</h3>
          <div class="link">
            <a href="javascript:;">联系客服</a>
            <a href="javascript:;">服务投诉</a>
            <a href="javascript:;">退换货说明</a>
            <a href="javascript:;">物流跟踪</a>
          </div>
        </li>
        <li>
          <h3>特别说明</h3>
          <div class="link">
            <a href="javascript:;">隐私保护</a>
            <router-link to="/agreement">使用条款</router-link>
            <a href="javascript:;">免责声明</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer-b">
      <div class="fauto">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030902001437" target="_blank" class="" rel="nofollow">
          <img src="@/assets/images/gwanbei.png">
          <span>粤公网安备 44030902001437号</span>
        </a>
        <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">@Copyright 粤ICP备18108309号</a>
        <a href="javascript:;">@深圳市合晶芯城科技有限公司</a>
      </div>
    </div> -->
  </div>
</template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex'

export default {
  name: 'app',
  components: {},
  data() {
    return {}
  },

  created() {

  },
  computed: {
    ...mapState('lxr', ['friendLink'])
  },
  methods: {
    clickPopBox() {
      window.open("\n" +
          "https://affim.baidu.com/cps5/chatIndex?siteToken=30d5f0cd8e30265492389905f69cf896&speedLogId=169321061294023ac_1693210612940_42411&bid=9540981140019360823&eid=35990344&reqParam=%7B%22from%22%3A0,%22sid%22%3A%22-100%22,%22tid%22%3A%22-1%22,%22ttype%22%3A1,%22siteId%22%3A%2219360823%22,%22userId%22%3A%2235990344%22,%22pageId%22%3A0%7D", "", "height=600px,width=800px")
    },
  },
  mounted() {
  },
}
</script>