<template>
  <div class="login-wrap register-wrap">
    <div class="info_top_bg">
      <div class="info_top clearfix">
        <h1 class="fl">
          <router-link to="/">
            <img height="44" src="@/assets/images/login/logo.svg">
          </router-link>
        </h1>
        <div class="fl bt">
          <span class="name" id="top-name">用户注册</span>
        </div>
        <router-link class="r" to="/index">商城首页</router-link>
      </div>
    </div>
    <div class="login-con">
      <img class="login-bg" src="@/assets/images/login/login-bg.png" alt="">
      <div class="con-info">
        <div class="nav-con">
          <div class="inp-wrap">
            <input class="inp-phone" autocomplete="off" comfirm="2" type="text" maxlength="11" placeholder="请输入手机号码" v-model.trim="params.mobile" @blur="phoneBlur()" @focus="phoneErr=''">
            <div class="err">{{phoneErr}}</div>
          </div>
          <div class="inp-wrap">
            <input class="inp-code" type="text" autocomplete="off" placeholder="请输入短信验证码" maxlength="6" v-model.trim="params.code" @keyup.enter="registerUser" @blur="codeBlur()" @focus="codeErr=''">
            <div class="err">{{codeErr}}</div>
            <span id="send" v-show="!sendFalg" @click="sendSms">发送验证码</span>
            <span id="send" v-show="sendFalg">重新发送(<i ref="timeNum">60</i>)</span>
          </div>
          <div class="inp-wrap">
            <input name="password" type="password" class="inp-password" autocomplete="off" placeholder="设置6至20位登录密码" maxlength="20" v-model.trim="params.password" @keyup.enter="registerUser" @blur="passBlur()" @focus="passErr=''">
            <div class="err">{{passErr}}</div>
          </div>
          <div class="inp-wrap" style="margin-top:20px">
            <div class="read">
              <span id="agreeread">注册即代表您已同意</span>
              <a id="userPop" href="javascript:;" @click="agreement=true">《用户服务协议》</a>
            </div>
            <button id="login" @click="registerUser">立即注册</button>
          </div>
          <div class="other-wrap">
            <router-link to="/reset">忘记密码</router-link>
            <router-link to="/login">立即登录</router-link>
          </div>
        </div>
      </div>
    </div>
    <loginFooter></loginFooter>
    <transition name="el-fade-in">
      <div class="mask_layout" id="agreement_layout" v-if="agreement" @click="agreement=false">
        <div class="layout_box" @click.stop>
          <div class="layout_title">
            用户协议
            <i class="layout_close" @click="agreement=false"></i>
          </div>
          <div class="layout_con">
            <div class="user_agreement_content">
              <p>最新修订日期：2018年11月19号</p>
              <p class="welcome">欢迎来到合晶芯城！</p>
              <p class="thanks">感谢您对合晶芯城的信任和支持。为了更好的为您提供服务和明确您在合晶芯城的权利和义务，请您在注册前仔细阅读本协议。<br />
                本协议详述您在{domains prefix='0'/}（"网站"）使用我们的服务所须遵守的条款和条件。如您有任何疑问，请及时联系我们。<br />
                您注册成为合晶芯城用户前，必须仔细阅读本用户协议和隐私声明，一旦您注册成为了合晶芯城用户即表示您已经阅读、同意并接受本用户协议和隐私声明中所含的所有条款和条件。如果我
                们对本声明进行了任何变更，我们会在合晶芯城网站页面上发布有关变更事项的通知，所有协议内容在发布之日后自动生效。如您不同意该修订，您必须终止您与合晶芯城的用户关系。</p>
              <div>
                <h3>一、用户注册：</h3>
                <p><em>1. 用户资格：</em><br />合晶芯城用户必须是适用法律下能够签订具有法律约束力的合同的公司或个人。如您不合资格，请不要使用我们的服务。您的合晶芯城帐户不得向其他方转让或出售。合晶芯城保留根据其意愿中止或终止您的帐户的权利。</p>
                <p><em>2. 资料填写：</em><br />为确保交易的正常进行，用户向合晶芯城供真实、有效的用户（个人及公司）资料，如用户资料有任何变动，必须及时通知合晶芯城更新。</p>
              </div>
              <div>
                <h3>二、隐私：</h3>
                <p><em class="top">合晶芯城设有适用于所有用户并纳入用户协议的隐私声明。您应当在注册时阅读并接受隐私声明以使用合晶芯城网站，且您在作为合晶芯城用户期间将继续受其规定（及合晶芯城对隐私声明作出的任何修订）的约束。</em><br /></p>
                <p>1. 个人数据资料,真实姓名、地址、电子邮箱和联系电话等用户信息。在未经您同意之前，合晶芯城不会将您的资料用于其它目的。</p>
                <p>2. 使用非个人信息。合晶芯城将通过您的IP地址来收集非个人化的信息，包括您的浏览器性质、操作系统种类、给您提供接入服务的ISP的域名等，以优化在您计算机屏幕上显示的页面。通过收集上述信息，我们亦进行客流量统计，从而改进网站的管理和服务。</p>
                <p>3. 个人数据安全。合晶芯城采用安全性的服务器使用控制设备保护您的个人数据。</p>
                <p>4. 个人数据披露和限制利用。当国家机构依照法定程序要求合晶芯城披露个人资料时，合晶芯城将根据执法单位的要求或为公共安全目的提供个人资料。在此情况下披露的任何内容，合晶芯城不承担任何责任。合晶芯城在符合下列条件时，对个人资料进行必要范围以外利用：已取得您的同意；相应的法律及程序要求本站提供用户的个人资料。</p>
              </div>
              <div>
                <h3>三、交易：</h3>
                <p><em>用户在合晶芯城进行物品购买时必须遵守以下条款：</em><br /></p>
                <p>1. 您应就您使用合晶芯城的服务及对物品购买遵守所有适用的中国法律、法规、条例和地方性法律要求。您还必须确保您遵守本用户协议和隐私声明及纳入上述文件的所有其它条款和规则的所有规定。</p>
                <p>2. 如您在合晶芯城购买物品，您有义务完成与合晶芯城的交易，但法律或本用户协议禁止的交易除外。通过对一项物品的下订单，您同意受该物品描述所含的出售条件的约束，只要该等出售条件不违反本协议或不是非法的。</p>
                <p>3. 合晶芯城网站上的广告，价目表和声明并不构成要约。合晶芯城有权在发现了合晶芯城网站上显现的产品及订单的明显错误或缺货的情况下，单方面撤回该信息。合晶芯城保留对产品订购的数量的限制权。在下订单的同时，您也同时承认了您已经达到购买这些产品的法定年龄，并将对您在订单中提供的所有信息的真实性负责。</p>
                <p>4. 产品价格和可获性都在网站上指明。这类信息将随时更改且不发任何通知。送货费将另外结算，费用根据您选择的送货方式的不同而异。如果发生了意外情况，在确认了您的订单后，由于供应商提价，税额变化引起的价格变化，或是由于网站的错误等造成价格变化，合晶芯城会通过email或电话通知您，在合晶芯城没有取消订单的情况下，让您决定是否取消订单。</p>
              </div>
              <div>
                <h3>四、物品质量与售后服务</h3>
                <P>1. 详见售后服务</P>
                <P>2. 物品的质量</P>
                <p><em>有国家标准或专业标准的，按国家标准或专业标准执行；无前述标准的，按生产厂的企业标准执行；无生产厂企业标准的，由用户与合晶芯城协商确定。如果物品质量不符合标准，用户可以要求换货或退货。</em><br /></p>
              </div>
              <div>
                <h3>五、网站安全：</h3>
                <p><em>您同意，未经合晶芯城明示书面准许，您不会为了任何目的使用任何机器人、蜘蛛软件、刷屏软件或其他自动方式进入网站。此外，您同意您将不会：</em><br /></p>
                <P>1. 进行任何对合晶芯城网站造成或可能造成（按合晶芯城自行酌情确定）不合理或不合比例的重大负荷的行为；</P>
                <P>2. 未经合晶芯城事先明示书面准许，对网站的任何内容（除您的个人信息以外）制作拷贝、进行复制、修改、制作衍生作品、分发或公开展示；</P>
                <P>3. 干扰或试图干扰网站的正常工作或网站上进行的任何活动；</P>
                <P>4. 在网站中使用违反适用的法律法规下可能视为被禁止或可能被禁止的任何内容；</P>
                <P>5. 对网站使用包含可能破坏、改变、删除、不利影响、秘密截取、未经授权而接触或征用任何系统、数据或个人资料的任何病毒、特洛依木马、蠕虫、定时炸弹、删除蝇、复活节彩蛋、间谍软件或其他电脑程序。</P>
              </div>
              <div>
                <h3>六、知识产权声明：</h3>
                <p>合晶芯城所提供的网络服务的相关著作权、专利权、商标、商业秘密及其它任何所有权或权利，均属合晶芯城所有。非经合晶芯城的同意，任何人或用户均不得擅自下载、复制、传输、改编、编辑，否则应负所有法律责任。合晶芯城拥有向用户提供网络服务过程中所产生并储存于合晶芯城服务器中的任何数据信息的所有权。</p>
              </div>
              <div>
                <h3>七、免责声明：</h3>
                <p>合晶芯城有义务在技术上确保网站的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交易活动的顺利进行。但如因不可抗力或其他合晶芯城无法控制的原因使合晶芯城网站崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，合晶芯城不承担责任；任何用户向合晶芯城提供错误、不完整、不实信息等造成不能正常使用合晶芯城服务或遭受任何其他损失，概与合晶芯城无关。本协议一经用户同意，则说明用户自愿通过短信、微信、邮件等方式，接收合晶芯城或合晶芯城合作伙伴相关消息推送。如您不再需要此类消息推送，可通过相关推送信息中说明的方式，通知合晶芯城停止推送。</p>
              </div>
              <div>
                <h3>八、其它：</h3>
                <p>本协议在所有方面均受中华人民共和国法律管辖。本网站所有用户均不可撤销地受合晶芯城持有人所在地的法院及上级法院的管辖。本协议的规定是可分割的，如本协议任何规定被裁定为无效或不可执行，该规定可被删除而其余条款应予以执行 </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import loginFooter from '@/views/login/loginFooter.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '注册',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二极管,晶体管,电阻,电容,集成电路,电子元器件网上商城'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内厂家直营二极管、晶体管、电阻、电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  components: {
    loginFooter,
  },
  data () {
    return {
      phoneErr: '',
      codeErr: '',
      passErr: '',
      password1: '',
      password2: '',
      params: {
        "from_type": 0,
        "mobile": "",
        "password": "",
        code: ''
      },
      mobileStatus: false,
      passwordStatus: false,
      codeStatus: false,
      status: false,
      sendFalg: false,
      time: '',
      agreement: false,
    }
  },

  created () {
  },
  computed: {
    //...mapState('demo', ['vuexres'])

  },
  methods: {
    ...mapActions('lxr', ['register_user', 'send_sms', 'wx_login']),
    phoneBlur () {
      if (this.params.mobile.length == 11) {
        this.mobileStatus = true
        this.phoneErr = ''
      } else {
        this.mobileStatus = false
        if (this.params.mobile) {
          this.phoneErr = '手机号码填写错误'
        }
      }
      this.check()
    },
    codeBlur () {
      if (this.params.code.length == 6) {
        this.codeStatus = true
        this.codeErr = ''
      } else {
        this.codeStatus = false
        if (this.params.code) {
          this.codeErr = '短信验证码填写错误'
        }
      }
      this.check()
    },
    passBlur () {
      if (this.params.password.length >= 6 && this.params.password.length <= 20) {
        this.passwordStatus = true
        this.passErr = ''
      } else {
        this.passwordStatus = false
        if (this.params.password) {
          this.passErr = '登录密码填写错误'
        }
      }
      this.check()
    },

    //发送短信
    async sendSms () {
      let sendCode = 60
      if (!this.mobileStatus) {
        return this.phoneErr = '手机号码填写错误'
      }
      let res = await this.send_sms({ mobile: this.params.mobile })
      if (res.httpCode == 200) {
        this.sendFalg = true
        this.time = setInterval(() => {
          sendCode--
          this.$refs.timeNum.innerText = sendCode
          if (sendCode == 0) {
            clearInterval(this.time)
            this.sendFalg = false
            this.$refs.timeNum.innerText = '60'
          }
        }, 1000);
      } else {
        this.$message.error(res.message)
      }
    },

    //注册
    async registerUser () {
      this.phoneBlur()
      this.codeBlur()
      this.passBlur()
      if (!this.status) {
        if (!this.mobileStatus) {
          return this.phoneErr = '手机号码填写错误'
        }
        if (!this.codeStatus) {
          return this.codeErr = '短信验证码填写错误'
        }
        if (!this.passwordStatus) {
          return this.passErr = '登录密码填写错误'
        }
      }
      let res = await this.register_user(this.params)
      if (res.httpCode == 200) {
        this.$message.success('注册成功')
        this.$router.push({ path: '/login' })
      } else {
        this.passErr = res.message
      }
    },
    check () {
      if (this.mobileStatus && this.passwordStatus && this.codeStatus) {
        this.status = true
      } else {
        this.status = false
      }
    }
  },
  mounted () {

  },

  watch: {
  },
  beforeDestroy () {
    clearInterval(this.time)
  },
}
</script>
<style lang="scss">
</style>


