<template>
  <div class="r userCenter_container_box">
    <h2 class="ptitle">积分明细（当前积分:{{jf}}）</h2>
    <div class="filter-wrap">
      <el-select class="mr20" v-model="params.data.alterType" placeholder="请选择" @change="scoreType()">
        <el-option v-for="(item,index) in type" :key="index" :label="item.name" :value="item.type">
        </el-option>
      </el-select>
      <el-date-picker v-model="params.data.startTime" value-format='yyyy-MM-dd' type="date" placeholder="选择日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <span class="ctext">至</span>
      <el-date-picker v-model="params.data.endTime" value-format='yyyy-MM-dd' type="date" placeholder="选择日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <button class="search-btn" @click="searchInput">查询</button>
    </div>
    <div class="userCenter_container">
      <div class="points-box">
        <ul class="clear" v-if="list.length > 0">
          <li class="title clear">
            <p class="one l">时间</p>
            <p class="three l">积分变动</p>
            <p class="five l">备注</p>
          </li>
          <div v-loading="isloading" class="isloading2">
            <li class="item clear" v-for="(item, index) in list" :key="index">
              <p class="one l">{{item.operationTime | setTime}}</p>
              <p class="three l"><span class="zs">{{item.scoreChange}}</span></p>
              <p class="five l" :title="item.remark">
                {{item.remark}}
              </p>
            </li>
          </div>
        </ul>
        <div class="points-nodata" v-if="total == 0 && !isloading">
          <img v-show="list.length == 0" src="~@/assets/images/goods/noshop_1.png">
          <p>暂无积分</p>
        </div>
      </div>
      <!-- 分页 -->
      <div class="page-wrap">
        <el-pagination background hide-on-single-page layout="prev, pager, next" :total="total" :page-size="params.pageSize" @current-change="currentChange">
        </el-pagination>
      </div>

    </div>

    <div class="exchange">
      <h2 class="ptitle">积分兑换</h2>
      <ul class="clearfix">
        <li v-for="(item,index) in card" :key="index">
          <img :src="item.image" alt="">
          <div v-if="item.fictitiousCount < 1" class="wh">已抢完</div>
          <div class="b_con">
            <h3 :title="item.name">{{item.name}}</h3>
            <p class="clearfix"><span class="fl"><i>{{item.exchangePoint}}</i> 积分</span><span class="fr"><i>{{item.amount}}</i> 元</span></p>
            <a v-if="item.exchangePoint<=jf &&  item.fictitiousCount > 0" href="javascript:;" @click="exchangeFun(item)">立即兑换</a>
            <a v-else href="javascript:;" class="no">{{item.exchangePoint > jf && item.fictitiousCount > 0?'积分不足':'立即兑换'}}</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="gz">
      <h2 class="ptitle">兑换规则</h2>
      <div class="con">
        <p>1、积分专属合晶商城，仅限商城内使用；目前积分仅可兑换商城积分商品；</p>
        <p>2、客户通过合晶商城线上平台下单并交易成功后获得积分，若有退款，则扣除相应积分；订单金额1元对应1个积分；</p>
        <p>3、积分可以累积，获得但未使用的积分，将在下一个自然年12月31日自动作废，例如2020年12月31日将清空2019年12月31日前客户获得但未使用的积分；</p>
        <p>4、积分仅可兑换商城积分商品，不可兑现和转让；用户使用积分兑换积分商品时，优先消耗旧积分；</p>
        <p>5、法律允许范围内，未尽事宜和最终解释权归合晶商城所有；</p>
        <p>6、本积分规则于2018年7月1日0时起生效，2018年7月1日之前的交易将不获得积分。</p>
      </div>
    </div>
    <transition name="el-fade-in">
      <div class="mask_layout" id="exchange" v-if="exchange_flag" @click="exchange_flag=false,exchange_flag2=false,successData=null,showDh=null">
        <div class="layout_box" @click.stop>
          <div class="layout_title">
            兑换提示
            <i class="layout_close" @click="exchange_flag=false,exchange_flag2=false,successData=null,showDh=null"></i>
          </div>
          <div class="layout_con">
            <div class="detail" v-if="!exchange_flag2">
              <img :src="showDh.image" alt="">
              <p class="name">{{showDh.name}}</p>
              <div class="bottom-wrap">
                <p>本次兑换将消耗 <span>{{showDh.exchangePoint}}</span> 积分，是否确认兑换</p>
                <div class="btn">
                  <a href="javascript:;" @click="exchange_flag=false,exchange_flag2=false,successData=null,showDh=null">取消</a>
                  <a href="javascript:;" @click="dhCard(showDh.id)">确定</a>
                </div>
              </div>
            </div>
            <div v-else class="detail2">
              <img src="@/assets/images/success.png">
              <p class="tip">兑换成功</p>
              <div class="bottom-wrap">
                <p>卡号：{{successData.cartNo}}</p>
                <p>卡密：{{successData.cartKey}}</p>
                <div class="btn">
                  <a href="javascript:;" @click="exchange_flag=false,exchange_flag2=false,successData=null,showDh=null">继续兑换</a>
                  <router-link :to="{path:'/home/user/dh/detail',query:{id:successData.id}}">查看详情</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  inject: ['reload'],
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-积分明细',
    }
  },
  data () {
    return {
      showDh: null,
      exchange_flag: false,
      exchange_flag2: false,
      isloading: true,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      params: {
        "data": {
          "alterType": 0, // 类型
          "endTime": "", // 结束时间
          "startTime": "" // 开始时间
        },
        "pageNum": 1,
        "pageSize": 5
      },
      type: [
        {
          type: 0,
          name: '全部'
        },
        {
          type: 1,
          name: '积分消费'
        },
        {
          type: 2,
          name: '购物消费'
        },
        {
          type: 3,
          name: '日常签到'
        },
        {
          type: 4,
          name: '完善账户信息'
        }
      ],
      curr: 0, // 默认选中
      list: [], // 积分
      total: 0,
      jf: 0,
      cardParams: {
        "pageNum": 1,
        "pageSize": 100
      },
      card: null,
      successData: null,
    }
  },
  created () {
    this.getScoreAll();
    this.getCard()

  },
  computed: {
    ...mapState('lw', ['scoreList'])
  },
  methods: {
    ...mapActions('lw', ['queryScore']),
    ...mapActions('lxr', ['get_Card', 'dh_Card', 'get_cuser_info']),
    ...mapMutations('lw', ['GET_SCORE_LIST']),
    async getCuserInfo () {
      let res = await this.get_cuser_info()
      if (res.httpCode == 200) {
        window.localStorage.setItem("userInfo", JSON.stringify(res.data))
      }
    },
    exchangeFun (item) {
      this.exchange_flag = true
      this.showDh = item
    },
    async dhCard (id) {
      let res = await this.dh_Card({ id: id })
      if (res.httpCode == 200) {
        this.exchange_flag2 = true
        this.successData = res.data
        this.getCard()
        this.getScoreAll()
        this.getCuserInfo()
      } else {
        this.$message.error(res.message);
      }
    },
    async getCard () {
      let res = await this.get_Card(this.cardParams)
      if (res.httpCode == 200) {
        this.card = res.data.records
      }
    },
    async getScoreAll () {
      this.list = []
      let res = await this.queryScore(this.params)
      if (res.httpCode == 200) {
        this.isloading = false
        this.list = res.data.records
        if (this.list.length > 0) {
          this.jf = res.data.records[0].totalScore
        }
        this.total = res.data.total
        // this.GET_SCORE_LIST(res)
      }
    },
    // type类型
    scoreType () {
      this.curr = this.params.data.alterType
      this.params.pageNum = 1
      // if (type == '0') {
      //   this.reload()
      // } else {
      // this.params.data.alterType = type
      this.getScoreAll()
      // }
    },
    // 分页
    currentChange (e) {
      this.params.pageNum = e
      this.getScoreAll()
    },
    // 时间检索
    searchInput () {
      if (this.params.data.startTime.length == 0) {
        return this.$message.error('请选择开始时间');
      }
      if (this.params.data.endTime.length == 0) {
        return this.$message.error('请选择结束时间');
      }
      this.getScoreAll()
    }
  },
  mounted () {
    // if (!this.scoreList) {
    //   this.getScoreAll()
    // }
    let a = sessionStorage.getItem('userInfo')
  },
  filters: {
    setTime: function (val) {
      val = val.substring(0, val.length - 3)
      return val
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-points.scss";
.current {
  color: #0067ed;
}
</style>


