<template>
  <div class="shoppingCart_container shoppingCart">
    <!-- 物流地址选择 -->
    <div class="selectAddress_box">
      <h3 class="box-title">
        收货人信息
        <span class="custom_click_addr_event r" @click="addressShowFun">
          新增收货地址
          <i></i>
        </span>
      </h3>
      <ul class="address-ul clear">
        <template v-if="addressList.length>0">
          <li class="address-item" v-for="(item, index) in addressList" :key="index" :class="{default:item.isDefault==1}" @click.stop="setDefAddress(item.id)">
            <div class="address-content-box">
              <div class="address-text">
                <span>{{item.province}}</span>&nbsp;<span>{{item.city}}</span>&nbsp;<span>{{item.district}}</span>&nbsp;<span>{{item.address}}</span>
                <span class="link-name">({{item.consignee}} 收)</span>
                <span class="link-phone">{{item.mobile}}</span>
                <span class="edit" @click.stop="editAddressShowFun(item.id,index)">修改本地址</span>
              </div>
            </div>
          </li>
        </template>

        <div class="address-none" v-if="addressFalg&&addressList.length==0">
          <img src="@/assets/images/userCenter/shipping_none.png" />
          <p>暂无收货地址 <a href="javascript:;" @click="addressShowFun">立即添加</a></p>
        </div>
      </ul>

    </div>
    <!-- 选择物流快递 -->
    <div class="deliveryWay_box">
      <h3 class="box-title">选择物流快递 <i v-if="kdList.length>0">当前收货地址需要运费 {{kdList[kdIndex].price}} 元</i></h3>
      <div class="ps-wrap">
        <ul>
          <!-- <li v-for="(item,index) in kdList" :key="index" @click="psSelectFun(index)" v-show="item.expressName!='速尔快递'" :class="{current:kdIndex==index}">{{item.expressName}}</li> -->
          <template v-if="kdList.length==0">
            <li @click="tip">速尔快递</li>
            <li @click="tip">龙邦快递</li>
            <li @click="tip">顺丰快递</li>
          </template>
          <template v-else>
            <li v-for="(item,index) in kdList" :key="index" @click="psSelectFun(index)" :class="{current:kdIndex==index}">{{item.expressName}}</li>
          </template>
        </ul>
      </div>
    </div>

    <div class="invoiceInfo_box">
      <h3 class="box-title">发票信息</h3>
      <ul class="clear" id="invoice_category">
        <template v-if="buyList&&buyList.cartList">
          <li :class="[isAct==1?'active':'']" @click="putong" v-if="buyList.cartList[0].isSample!=1">
            普通发票
            <em></em>
          </li>
          <li :class="[isAct==2?'active':'']" @click="zenghzi" v-if="buyList.cartList[0].isSample!=1">
            增值税发票
            <em></em>
          </li>
          <li class="recommended" :class="[isAct==0?'active':'']" @click="noInvoice">
            <i></i>
            不需要发票
            <em></em>
          </li>
        </template>

      </ul>
      <div class="type_current clear" v-show="selectedInvoice.type">
        <span>
          发票类型：
          <i class="custom_show_invoice_type">{{selectedInvoice.type}}</i>
        </span>
        <span>
          发票抬头：
          <i class="custom_show_invoice_title">{{selectedInvoice.looked}}</i>
        </span>
        <span class="last">
          发票金额：
          <i class="custom_show_invoice_amount">¥{{selectedInvoice.parce}}</i>
        </span>
        <!-- <span class="edit r">修改信息</span> -->
      </div>
      <div class="explain-bottom-box">
        <div class="infoice-explain">
          <div class="explain-caption"><span>注意事项</span></div>
          <div class="explain-text-box">
            <p>开票时间:当月发票次月25日统一开出。</p>
            <p>开票规则:开票时间内开具上个月已确认收货交易完成订单的累计金额（包含运费）。</p>
            <p>按商品明细开票:相同型号和相同价格的同一商品，数量合并开票。</p>
            <p>增值税专用发票开票要求:交易金额累计大于500元开票。</p>
            <p>增值税普通发票开票要求:交易金额累计大于300元开票。</p>
            <p>电子发票开票要求:交易金额累计大于50元开票。</p>
          </div>
        </div>
      </div>
    </div>

    <div class="orderMark_box">
      <h3 class="box-title">备注说明</h3>
      <textarea id="textareas" placeholder="文本框字数限制：500字" maxlength="500" class="custom_data_user_note" v-model.trim="HktOrderInfo.order.userNote"></textarea>
    </div>

    <div class="goodsInfo_box">
      <table>
        <tbody>
          <tr>
            <th class="one">商品信息</th>
            <th class="two">我的料号</th>
            <th class="three">单价（含增值税）</th>
            <th class="four">购买数量</th>
            <th class="five">金额（含增值税）</th>
          </tr>
          <template v-if="buyList&&buyList.cartList">
            <tr v-for="(item, index) in buyList.cartList" :key="index" v-show="index<showLength">
              <td class="one">
                <div class="product_info">
                  <img v-if="item.goodsImage" :src="item.goodsImage" />
                  <img v-else src="@/assets/images/goods/default_img.png" />
                  <p class="name">{{item.goodsName}}</p>
                  <p class="title">
                    型号：{{item.customerSn}}
                  </p>
                  <p class="title">
                    型号：{{item.brandName}}
                  </p>
                </div>
              </td>
              <td class="two">{{item.customerSn}}</td>
              <td class="three">¥{{item.cprice}}</td>
              <td class="four">{{item.isSample==1?item.stepSample:item.goodsNum}}pcs</td>
              <td class="five">￥{{item.isSample==1?'0':item.ctotalPrice}}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <a v-if="buyList&&buyList.cartList&&buyList.cartList.length>3" href="javascript:;" class="lookMore" :class="{down:showLength!=3}" @click="showLengthFun">{{showLengthText}}<i></i></a>
    </div>
    <div class="confirmJsInfo clearfix" v-if="buyList&&buyList.cartList">
      <p>
        <span>共<i>{{buyList.cartList.length}}</i>种商品，商品总金额：</span>
        <span class="val">¥{{buyList.totalPrice}}</span>
      </p>
      <p><span>应付运费：</span><span class="val">+¥{{HktOrderInfo.order.shippingPrice}}</span></p>
      <p><span>优惠减免：</span><span class="val">-¥{{buyList.discountPrice}}</span></p>
      <div class="moneyInfo">
        <div class="money">实付款：<span>¥{{(buyList.realTotalPrice+HktOrderInfo.order.shippingPrice) | calcPriceFun}}</span></div>
        <div class="add">寄送至：{{HktOrderInfo.order.province}} {{HktOrderInfo.order.city}} {{HktOrderInfo.order.district}} {{HktOrderInfo.order.address}}
        </div>
        <div class="name">收货人：{{HktOrderInfo.order.contacts}} {{HktOrderInfo.order.contactsMobile}}</div>
        <a @click="saveOrder" class="pBtn" href="javascript:;">提交订单</a>
      </div>
    </div>
    <!--普通发票-->
    <transition name="el-fade-in">
      <div class="mask_layout1" v-show="isPu">
        <div class="pop_up_box invoiceInfo_detail general_case_invoice_event" style>
          <div class="pop_up_title clear">
            <h3 class="l">普通电子发票</h3>
            <span class="r custom_order_box_cancel" @click="putong"></span>
          </div>
          <div class="invoiceInfo_detail_body">
            <!-- <div class="isPapper clear custom_invoice_type_select_event">
              <span :class="[HktOrderInfo.orderInvoice.type==1?'l first custom_invoice_type active':'l first custom_invoice_type']" @click="invoiceFun">电子发票</span>
              <span :class="[HktOrderInfo.orderInvoice.type==2?'l last custom_invoice_type active':'l last custom_invoice_type']" @click="paper">纸质发票</span>
            </div> -->
            <div class="notice_box">
              <h5>发票须知：</h5>
              <p>1. 选择电子发票的订单付款后纳税人识别号不可修改，请确认信息后付款；</p>
              <p>2. 电子发票可在订单完成后，在订单详情中下载和查看。</p>
              <p>3. 发票金额为实际支付金额，不包含优惠券、礼品卡等。</p>
            </div>
            <div class="isperson clear">
              <span class="isperson_title l">
                <i>*</i>单位/个人
              </span>
              <ul class="isperson_ul l">
                <li :class="[HktOrderInfo.orderInvoice.openObj == 2?'active l':'l']" @click="HktOrderInfo.orderInvoice.openObj = 2">
                  单位
                  <em></em>
                </li>
                <li :class="[HktOrderInfo.orderInvoice.openObj == 1?'active l':'l']" @click="HktOrderInfo.orderInvoice.openObj = 1">
                  个人
                  <em></em>
                </li>
              </ul>
            </div>
            <div class="personInfo_box clear">
              <div id="info_two">
                <div class="clear">
                  <span>
                    <i>*</i>发票抬头
                  </span>
                  <input type="text" placeholder="请输入发票抬头" value maxlength="100" class="custom_e_open_name" v-model.trim="HktOrderInfo.orderInvoice.title" />
                </div>
                <div class="clear" v-show="HktOrderInfo.orderInvoice.openObj==2">
                  <span>
                    <i>*</i>纳税人识别号
                  </span>
                  <input type="text" placeholder="请填写购买方纳税人识别号或统一社会信用代码" value maxlength="20" class="custom_e_open_taxpayer" v-model.trim="HktOrderInfo.orderInvoice.openTaxpayer" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>收票人手机
                  </span>
                  <input class="short custom_e_collect_mobile" placeholder="请填写收票人手机" value type="text" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)" v-model.trim="HktOrderInfo.orderInvoice.collectMobile" />

                  <template v-if="HktOrderInfo.orderInvoice.type==1">
                    <span class="last">
                      <i>*</i>收票人邮箱
                    </span>
                    <input class="short custom_e_collect_email" placeholder="请填写收票人邮箱" value type="text" maxlength="50" v-model.trim="HktOrderInfo.orderInvoice.collectEmail" />
                  </template>

                </div>
              </div>

            </div>
            <div class="btn_box">
              <span class="cancel vat_order_box_cancel" @click="putong">取消</span>
              <span class="submit custom_order_box_submit" @click="saveInvoicePt">确定</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--增值税发票-->
    <transition name="el-fade-in">
      <div class="mask_layout1" v-show="isZeng">
        <div class="pop_up_box invoiceInfo_detail vat_case_code_event" style="margin-top: -328px; display: block;">
          <div class="pop_up_title clear">
            <h3 class="l">增值税发票</h3>
            <span class="r custom_order_box_cancel" @click="zenghzi"></span>
          </div>
          <div class="invoiceInfo_detail_body">
            <div class="notice_box" style="margin: 0px 0 10px 0">
              <h5>发票须知：</h5>
              <p>1. 选择电子发票的订单付款后纳税人识别号不可修改，请确认信息后付款；</p>
              <p>2. 电子发票可在订单完成后，在订单详情中下载和查看。</p>
              <p>3. 发票金额为实际支付金额，不包含优惠券、礼品卡等。</p>
            </div>
            <div class="personInfo_box clear">
              <div v-if="HktOrderInfo.orderInvoice">
                <div class="clear">
                  <span>
                    <i>*</i>开票公司名
                  </span>
                  <input type="text" placeholder="请填写公司名称" maxlength="100" class="vat_name" value v-model.trim="HktOrderInfo.orderInvoice.title" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>纳税人识别号
                  </span>
                  <input type="text" placeholder="请输入" maxlength="20" class="vat_num" value v-model.trim="HktOrderInfo.orderInvoice.openTaxpayer" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>经营地址及电话
                  </span>
                  <input type="text" placeholder="请填写地址及电话" maxlength="150" class="vat_add" value v-model.trim="HktOrderInfo.orderInvoice.operatingAddress" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>开户行所属地
                  </span>
                  <input type="text" placeholder="请填写地址" maxlength="80" class="vat_address" value v-model.trim="HktOrderInfo.orderInvoice.openBankAddress" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>开户行帐号
                  </span>
                  <input type="text" placeholder="请填写账号" maxlength="50" class="vat_code" value v-model.trim="HktOrderInfo.orderInvoice.openBankAccount" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>收票人手机
                  </span>
                  <input class="short custom_e_collect_mobile" placeholder="请填写收票人手机" value type="text" maxlength="11" oninput="if(value.length>11)value=value.slice(0,11)" v-model.trim="HktOrderInfo.orderInvoice.collectMobile" />

                  <template v-if="HktOrderInfo.orderInvoice.type==1">
                    <span class="last">
                      <i>*</i>收票人邮箱
                    </span>
                    <input class="short custom_e_collect_email" placeholder="请填写收票人邮箱" value type="text" maxlength="50" v-model.trim="HktOrderInfo.orderInvoice.collectEmail" />
                  </template>

                </div>
                <!-- <div class="clear">
                  <span>
                    <i>*</i>发票收件人
                  </span>
                  <input type="text" placeholder="请填写收件人姓名" maxlength="120" class="vat_naem1" value v-model.trim="HktOrderInfo.orderInvoice.collectName" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>收件人电话
                  </span>
                  <input type="text" placeholder="请填写收件人电话" maxlength="11" class="vat_iphone" value v-model.trim="HktOrderInfo.orderInvoice.collectMobile" />
                </div>
                <div class="clear">
                  <span>
                    <i>*</i>收票地址
                  </span>
                  <input type="text" placeholder="请填写收票地址" maxlength="120" class="vat_address2" value v-model.trim="HktOrderInfo.orderInvoice.collectAddress" />
                </div> -->
              </div>
            </div>
            <div class="btn_box" style="padding-top: 0">
              <span class="cancel vat_order_box_cancel" @click="zenghzi">取消</span>
              <span class="submit vat_order_box_submit" @click="saveInvoiceZzs">确定</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--新增收获地址-->
    <transition name="el-fade-in">
      <div class="mask_layout1" v-if="addressShow">
        <!-- 新增收货地址  -->
        <div id="new_shipping_address" class="pop_up_box new_shipping_address">
          <div class="pop_up_title clear">
            <h3 class="l">新增/修改收货地址</h3>
            <span class="r" @click="addressShow=false"></span>
          </div>
          <div class="new_shipping_body">
            <table>
              <tbody>
                <tr style="display: none">
                  <input name="address_id" type="hidden" value="0" />
                </tr>
                <tr>
                  <td>
                    <i class="error">*</i>收货人
                  </td>
                  <td>
                    <input name="consignee" maxlength="10" type="text" v-model="params.consignee " />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i>*</i>手机号
                  </td>
                  <td>
                    <input name="mobile" maxlength="11" type="text" v-model="params.mobile" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <i>*</i>所在地区：
                  </td>
                  <td class="clear select_area_box">
                    <div>
                      <span id="province_click" @click="getProvince">
                        <input name="province_id" type="hidden" placeholder="请选择省" value="0" />
                        <input name="province" type="text" placeholder="请选择省" v-model="params.province" readonly />
                      </span>
                      <ul class="province_ul" v-show="cityShow==0">
                        <li v-for="(item, index) in province_ul" :key="index" :code="item.provinceCode">
                          <span @click="provinceSelect(index,item.provinceName,item.provinceCode)" :class="{active:provinceSelectIdx==index}">{{item.provinceName}}</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span id="city_click" @click="getCity">
                        <input name="city_id" type="hidden" placeholder="请选择市" value="0" />
                        <input name="city" type="text" placeholder="请选择市" v-model="params.city" readonly />
                      </span>
                      <ul class="city_ul" v-show="cityShow==1">
                        <li v-for="(item, index) in city_ul" :key="index" :code="item.cityCode">
                          <span @click="citySelect(index,item.cityName,item.cityCode)" :class="{active:citySelectIdx==index}">{{item.cityName}}</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span id="district_click" @click="getArea">
                        <input name="district_id" type="hidden" placeholder="请选择区" value="0" />
                        <input name="district" type="text" placeholder="请选择区" v-model="params.district" readonly />
                      </span>
                      <ul class="district_ul" v-show="cityShow==2">
                        <li v-for="(item, index) in area_ul" :key="index" :code="item.areaCode">
                          <span @click="areaSelect(index,item.areaName,item.areaCode)" :class="{active:areaSelectIdx==index}">{{item.areaName}}</span>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i>*</i>详细地址：
                  </td>
                  <td>
                    <textarea id="address_detail" maxlength="120" placeholder="街道、小区、楼牌号等" v-model="params.address"></textarea>
                  </td>
                </tr>
              </tbody>
            </table>
            <span id="shippingSave" v-if="!params.id" class="submit" @click="saveAddress">保存</span>
            <span id="shippingSave" v-else class="submit" @click="editAddress">保存</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import https from "@/https.js";
export default {
  name: "app",
  metaInfo () {
    return {
      title: '生成订单-合晶芯城',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  components: {},
  data () {
    return {
      showLengthText: '展开全部购买商品',
      showLength: 3,
      delivery: 1,//配送方式标识
      linkParams: { language: 1, invoice: 2, giveType: '' },
      addressShow: false,
      province_ul: [],
      cityShow: -1,
      provinceSelectIdx: -1,
      city_ul: [],
      citySelectIdx: -1,
      area_ul: [],
      areaSelectIdx: -1,
      curProvinceCode: -1,
      curCityCode: -1,
      params: {
        address: "", //详细地址
        city: "", //城市
        district: "", //区
        mobile: "", //手机
        province: "", //省份
        consignee: ""
      },
      addressList: [],
      addressListFlag: false,
      isActive: 0, // 默认选中地址
      isAddContact: false, // 联系人伸缩
      isZeng: false, // 增值税发票
      isAct: 0,
      isPu: false, // 普通发票
      isPao: 1,
      isOr: 1, // 单位/个人
      isTime: 1, // 送货时间
      radio: 3,
      options: [],
      psValue: '',
      optionsa: [],
      valuea: '',
      disabled: false,
      disa: true,
      showAddress: false,
      defaultAddress: '', // 提货地址
      buyList: [],
      kdList: [],
      kdIndex: -1,
      baseInvoice: null,
      HktOrderInfo: {
        order: {
          "address": "", //详细地址 ,
          "addressId": "", //送货地址 ,
          "bom": "",//订单bom标识 ,
          "city": "", //城市
          "consignee": "",//送货人 
          "contacts": "",//下单联系人
          "contactsMobile": "",//下单联系人 电话 ,
          "discount": 0, //折扣
          "district": "", //街道
          "email": "", //收货人邮件
          "giveTime": 1, //配送时间 1不限 2周一到周五 3周六日 ,
          "giveType": 1, //配送类型 1 物流 2 到店自提 3 自主配送 ,
          "goodsPrice": 0, //货物价格-实价 ,
          "mobile": "", //手机号
          "orderAmount": 0, //订单金额 ,
          "province": "", //省会
          "shippingCode": "",//物流编码 ,
          "shippingName": "", //物流名称 
          "shippingPrice": 0.00, //物流费用 ,
          "userNote": "", //用户备注 ,
          language: 1,
          warehouseName: '',//自提门店名称
          warehouseId: '',//门店ID
        },
        orderInvoice: {
          "category": 0,//0 不开票 1 普通票 2 专用票 ,
          "collectAddress": "",//发票人联系地址 ,
          "collectEmail": "",//发票人邮箱 ,
          "collectMobile": "",//发票人联系电话 ,
          "collectName": "",//发票人收件名 ,
          "openBankAccount": "",//开户行账户 ,
          "openBankAddress": "",//开户行所属地 ,
          "openObj": 2,//开票对象(1个人|2单位) ,
          "openTaxpayer": "",//开票纳税人识别号 ,
          "openType": 1,//开票类型(1电子元器件) ,
          "status": 0,//1 已开票 0 未开票 ,
          "title": "",//发票抬头 ,
          "type": 1//发票类型(1电子发票|2纸质发票)
        }
      },

      addContact: {
        mobile: '',
        name: ''
      },
      selectedInvoice: {
        type: '',
        looked: '',
        parce: '',
      },
      addressFalg: false
    };
  },
  created () { },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions("lxr", [
      "get_address", 'get_cart_list',
      "save_address",
      "del_address",
      "edit_address",
      "set_def_address",
      "get_address_info", "get_kd", 'link_confirm_order', 'get_invoice', 'save_order'
    ]),
    showLengthFun () {
      if (this.showLength == 3) {
        this.showLength = this.buyList.cartList.length
        this.showLengthText = '收起部分购买商品'
      } else {
        this.showLength = 3
        this.showLengthText = '展开全部购买商品'
      }
    },
    tip () {
      this.$message.error('请先选择收货地址！');
    },
    deliveryFun () {
      this.delivery = 1
      this.linkParams.giveType = ''
      this.linkConfirmOrder()
      this.HktOrderInfo.order.giveType = 1
      this.showAddress = false
    },
    deliveryFun1 () {
      this.delivery = 2
      this.linkConfirmOrder(2)
      this.HktOrderInfo.order.giveType = 2
      this.HktOrderInfo.order.shippingCode = ''
      this.HktOrderInfo.order.shippingName = ''
      this.HktOrderInfo.order.shippingPrice = 0
      this.showAddress = true
    },
    // pu (e) {
    //   if (e == 6) {
    //     this.disabled = true
    //     this.disa = false
    //   } else {
    //     this.disabled = false
    //     this.disa = true
    //   }
    // },
    paper () {
      this.HktOrderInfo.orderInvoice.type = 2
    },
    invoiceFun () {
      this.HktOrderInfo.orderInvoice.type = 1
    },
    // 普通发票
    putong () {
      if (!this.baseInvoice) {
        this.getInvoice()
      }
      this.isPu = !this.isPu;
    },
    // 增值税
    zenghzi () {
      if (!this.baseInvoice) {
        this.getInvoice()
      }
      this.isZeng = !this.isZeng;
    },
    // 联系人伸缩
    addContactFalg () {
      this.isAddContact = !this.isAddContact;
    },
    addressShowFun () {
      this.opt();
      this.addressShow = !this.addressShow;
    },
    editAddressShowFun (id, idx) {
      if (id) {
        this.params.address = this.addressList[idx].address;
        this.params.city = this.addressList[idx].city;
        this.params.district = this.addressList[idx].district;
        this.params.mobile = this.addressList[idx].mobile;
        this.params.province = this.addressList[idx].province;
        this.params.consignee = this.addressList[idx].consignee;
        this.params.id = id;
      }
      this.addressShow = !this.addressShow;
    },
    async getAddress () {
      var res = await this.get_address();
      if (res.httpCode == 200) {
        this.addressFalg = true
        this.addressList = res.data;
        if (this.addressList.length > 0) {
          this.addressList.forEach(item => {
            if (item.isDefault == 1) {
              this.HktOrderInfo.order.province = item.province
              this.HktOrderInfo.order.city = item.city
              this.HktOrderInfo.order.district = item.district
              this.HktOrderInfo.order.address = item.address
              this.HktOrderInfo.order.contacts = item.consignee
              this.HktOrderInfo.order.contactsMobile = item.mobile
              this.getKd(item.province, item.city)
            }
          });
        }
        this.addressListFlag = true;
      } else {
        //this.$message.error(res.message)
      }
    },

    //获取省份
    async getProvince () {
      let _this = this;
      var res = await this.get_address_info({
        level: 0,
        parentCode: 0
      });
      if (res.httpCode == 200) {
        _this.cityShow = 0;
        _this.province_ul = res.data;
      } else {
        this.$message.error(res.message);
      }
    },

    //选择省份
    provinceSelect (idx, val, code) {
      this.provinceSelectIdx = idx;
      this.params.province = val;
      this.getCity(code);
    },

    //获取城市
    async getCity (code) {
      let _this = this;
      if (code > 0) {
        _this.curProvinceCode = code;
      }
      if (_this.curProvinceCode < 0) {
        return;
      }
      var res = await this.get_address_info({
        level: 1,
        parentCode: _this.curProvinceCode
      });
      if (res.httpCode == 200) {
        _this.cityShow = 1;
        _this.city_ul = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    //选择城市
    citySelect (idx, val, code) {
      this.citySelectIdx = idx;
      this.params.city = val;
      this.getArea(code);
    },

    //获取区
    async getArea (code) {
      let _this = this;
      if (code > 0) {
        _this.curCityCode = code;
      }
      if (_this.curCityCode < 0) {
        return;
      }

      var res = await this.get_address_info({
        level: 2,
        parentCode: _this.curCityCode
      });
      if (res.httpCode == 200) {
        _this.cityShow = 2;
        _this.area_ul = res.data;
      } else {
        _this.$message.error(res.message);
      }
    },
    //选择城市
    areaSelect (idx, val, code) {
      this.cityShow = -1;
      this.areaSelectIdx = idx;
      this.params.district = val;
    },

    //新增用户地址
    async saveAddress () {
      let falg = this.checkAddVal();
      if (!falg) {
        return;
      }
      var res = await this.save_address(this.params);
      if (res.httpCode == 200) {
        this.getAddress();
        this.addressShowFun();
        this.opt();
      } else {
        this.$message.error(res.message);
      }
    },

    //更新用户地址
    async editAddress (id) {
      let falg = this.checkAddVal();
      if (!falg) {
        return;
      }
      var res = await this.edit_address(this.params);
      if (res.httpCode == 200) {
        this.getAddress();
        this.addressShowFun();
        this.opt();
      } else {
        this.$message.error(res.message);
      }
    },

    //设置默认
    async setDefAddress (id) {
      var res = await this.set_def_address({ id: id });
      if (res.httpCode == 200) {
        this.getAddress();
      } else {
        this.$message.error(res.message);
      }
    },

    checkAddVal () {
      let falg;
      if (!this.params.consignee) {
        this.$message.error("请填写收货人");
        falg = false;
      } else if (!this.params.mobile) {
        this.$message.error("请填写手机号");
        falg = false;
      } else if (!this.params.province) {
        this.$message.error("请选择省");
        falg = false;
      } else if (!this.params.city) {
        this.$message.error("请选择市");
        falg = false;
      } else if (!this.params.district) {
        this.$message.error("请选择区");
        falg = false;
      } else if (!this.params.address) {
        this.$message.error("请填写详细地址");
        falg = false;
      } else {
        falg = true;
      }
      return falg;
    },

    opt () {
      this.params = {
        address: "", //详细地址
        city: "", //城市
        district: "", //街道
        mobile: "", //手机
        province: "", //省份
        consignee: ""
      };
      this.provinceSelectIdx = -1;
      this.citySelectIdx = -1;
      this.areaSelectIdx = -1;
    },

    //删除用户地址
    delAddress (id) {
      this.$confirm("确认删除该地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.delFun(id);
        })
        .catch(() => { });
    },

    async delFun (id) {
      var res = await this.del_address({ id: id });
      if (res.httpCode == 200) {
        this.getAddress();
      } else {
        this.$message.error(res.message);
      }
    },

    async linkConfirmOrder (type) {
      if (type == 1) {
        this.linkParams.invoice = 2
      } else if (type == 2) {
        this.linkParams.giveType = 2
      }
      let res = await this.link_confirm_order(this.linkParams)
      if (res.httpCode == 200) {
        // this.SET_CONFIRM_ORDER_LIST(res.data.cartList)
        this.buyList = res.data
        this.buyList.totalPrice = parseFloat(this.buyList.totalPrice)
        this.buyList.realTotalPrice = parseFloat(this.buyList.realTotalPrice)
        this.HktOrderInfo.order.shippingPrice = parseFloat(this.HktOrderInfo.order.shippingPrice)
        if (!this.buyList.cartList) {
          this.$router.push({ path: '/cart' })
        }
      }
    },

    //获取快递配送
    async getKd (province, city) {
      let res = await this.get_kd({
        city: city,
        province: province
      })
      if (res.httpCode == 200) {
        this.kdList = res.data
        this.psSelectFun(2)
      }
    },

    //获取基础发票
    async getInvoice () {
      let res = await this.get_invoice()
      if (res.httpCode == 200) {
        if (res.data) {
          this.baseInvoice = res.data
          this.HktOrderInfo.orderInvoice = {
            "category": 2,//0 不开票 1 普通票 2 专用票 ,
            "collectAddress": res.data.collectAddress,//发票人联系地址 ,
            "collectEmail": res.data.collectEmail,//发票人邮箱 ,
            "collectMobile": res.data.collectMobile,//发票人联系电话 ,
            "collectName": res.data.collectName,//发票人收件名 ,
            "openBankAccount": res.data.openBankAccount,//开户行账户 ,
            "openBankAddress": res.data.openBankAddress,//开户行所属地 ,
            "openObj": 2,//开票对象(1个人|2单位) ,
            "openTaxpayer": res.data.openTaxpayer,//开票纳税人识别号 ,
            "openType": 1,//开票类型(1电子元器件) ,
            "status": 0,//1 已开票 0 未开票 ,
            "title": res.data.openName,//发票抬头 ,
            operatingAddress: res.data.operatingAddress, //经营地址
            "type": 1//发票类型(1电子发票|2纸质发票)
          }
        } else {
          this.HktOrderInfo.orderInvoice = {
            "category": 1,//0 不开票 1 普通票 2 专用票 ,
            "collectAddress": "",//发票人联系地址 ,
            "collectEmail": "",//发票人邮箱 ,
            "collectMobile": "",//发票人联系电话 ,
            "collectName": "",//发票人收件名 ,
            "openBankAccount": "",//开户行账户 ,
            "openBankAddress": "",//开户行所属地 ,
            "openObj": 2,//开票对象(1个人|2单位) ,
            "openTaxpayer": "",//开票纳税人识别号 ,
            "openType": 1,//开票类型(1电子元器件) ,
            "status": 0,//1 已开票 0 未开票 ,
            "title": "",//发票抬头 ,
            "type": 1//发票类型(1电子发票|2纸质发票)
          }
        }
      }
    },
    async getCartList () {
      let res = await this.get_cart_list()
    },
    //提交订单
    async saveOrder () {
      if (this.HktOrderInfo.order.giveType == 1 && !this.HktOrderInfo.order.province) {
        this.$message.error('请选择收货地址')
        return
      }
      if (this.HktOrderInfo.order.giveType == 1 && !this.HktOrderInfo.order.shippingCode) {
        this.$message.error('请选择快递配送')
        return
      }
      // if (this.HktOrderInfo.order.giveType == 2 && !this.HktOrderInfo.order.warehouseId) {
      //   this.$message.error('请选择门店')
      //   return
      // }
      let res = await this.save_order(this.HktOrderInfo)
      if (res.httpCode == 200) {
        this.getCartList()
        this.$router.push({ path: '/order/save-alipay' })
      } else {
        this.$message.error(res.message)
      }
    },

    saveInvoiceZzs () {
      if (!this.HktOrderInfo.orderInvoice.title) {
        this.$message.error("请输入发票抬头")
        return
      }
      if (!this.HktOrderInfo.orderInvoice.openTaxpayer) {
        this.$message.error("请输入纳税人识别号")
        return
      }
      if (!this.HktOrderInfo.orderInvoice.operatingAddress) {
        this.$message.error("请输入经营地址")
        return
      }
      if (!this.HktOrderInfo.orderInvoice.openTaxpayer) {
        this.$message.error("请输入开户行所属地")
        return
      }
      if (!this.HktOrderInfo.orderInvoice.openBankAccount) {
        this.$message.error("请输入开户行帐号")
        return
      }
      if (!this.HktOrderInfo.orderInvoice.collectMobile) {
        this.$message.error("请输入发票收票人手机")
        return
      }
      if (!this.HktOrderInfo.orderInvoice.collectEmail) {
        this.$message.error("请输入发票收票人邮箱")
        return
      }
      // if (!this.HktOrderInfo.orderInvoice.collectMobile) {
      //   this.$message.error("请输入收件人电话")
      //   return
      // }
      // if (!this.HktOrderInfo.orderInvoice.collectAddress) {
      //   this.$message.error("请输入收票地址")
      //   return
      // }
      this.linkParams.invoice = ''
      this.linkConfirmOrder()
      this.isZeng = false
      this.isAct = 2
      this.HktOrderInfo.orderInvoice.category = 2
      this.selectedInvoice = {
        type: '增值税发票',
        looked: this.HktOrderInfo.orderInvoice.title,
        parce: this.buyList.totalPrice,
      }
    },
    saveInvoicePt () {
      if (!this.HktOrderInfo.orderInvoice.title) {
        this.$message.error("请输入发票抬头")
        return
      } if (!this.HktOrderInfo.orderInvoice.openTaxpayer && this.HktOrderInfo.orderInvoice.openObj == 2) {
        this.$message.error("请输入纳税人识别号")
        return
      } if (!this.HktOrderInfo.orderInvoice.collectMobile) {
        this.$message.error("请输入收票人手机")
        return
      } if (this.HktOrderInfo.orderInvoice.type == 1 && !this.HktOrderInfo.orderInvoice.collectEmail) {
        this.$message.error("请输入收票人邮箱")
        return
      }
      this.linkParams.invoice = ''
      this.linkConfirmOrder()
      this.isAct = 1
      this.isPu = false
      if (this.HktOrderInfo.orderInvoice.type == 1 && this.HktOrderInfo.orderInvoice.openObj == 1) {
        this.selectedInvoice.type = '电子发票(个人)'
      } else if (this.HktOrderInfo.orderInvoice.type == 1 && this.HktOrderInfo.orderInvoice.openObj == 2) {
        this.selectedInvoice.type = '电子发票(单位)'
      } else if (this.HktOrderInfo.orderInvoice.type == 2 && this.HktOrderInfo.orderInvoice.openObj == 1) {
        this.selectedInvoice.type = '纸质发票(个人)'
      } else if (this.HktOrderInfo.orderInvoice.type == 2 && this.HktOrderInfo.orderInvoice.openObj == 2) {
        this.selectedInvoice.type = '纸质发票(单位)'
      }
      this.selectedInvoice.looked = this.HktOrderInfo.orderInvoice.title
      this.selectedInvoice.parce = this.buyList.totalPrice
      this.HktOrderInfo.orderInvoice.category = 1
    },

    noInvoice () {
      this.linkConfirmOrder(1)
      this.isAct = 0
      this.selectedInvoice = {
        type: '',
        looked: '',
        parce: '',
      }
    },

    psSelectFun (e) {
      this.kdIndex = e
      this.HktOrderInfo.order.shippingCode = this.kdList[e].id
      this.HktOrderInfo.order.shippingName = this.kdList[e].expressName
      this.HktOrderInfo.order.shippingPrice = parseFloat(this.kdList[e].price)
    },
  },
  mounted () {
    this.getAddress();
    this.linkConfirmOrder()
  }, filters: {
    calcPriceFun (val) {
      return val.toFixed(2);
    },
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/css/cart-fillOrderInfo.scss";
@import "~@/assets/css/member-addrManage.scss";
</style>


