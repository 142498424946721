<template>
  <div class="newMain ">
    <div class="brandD-wrap auto">
      <div class="br-introduce clearfix" v-if="brandDetail">
        <div class="br-introduce-title">
          <img v-if="brandDetail.logo" :src="brandDetail.logo" :alt="brandDetail.brandDesc_ch.name">
          <img v-else src="@/assets/images/brand/default.png" :alt="brandDetail.brandDesc_ch.name">
          <div class="br-introduce-name">
            {{brandDetail.brandDesc_ch.name}}
          </div>
        </div>
        <div class="br-introduce-text" v-html="brandDetail.brandDesc_ch.info">
        </div>
      </div>
      <div class="clearfix">
        <div class="pfilter-wrap clearfix">
          <div class="search_content" v-if="brandCategory&&brandCategory.length>0">
            <ul class="brand_secondnary clear nosearch_loading">
              <li class="l" v-for="(item, index) in brandCategory" :key="index" @click="brandCategoryClick(index,item)">
                <a :class="{active:brandCategoryIdx==index}" href="javascript:;">{{item.name}}({{item.count}})</a>
              </li>
            </ul>
            <ul class="brand_thirdnary clear nosearch_loading" v-if="brandCategoryIdx>=0">
              <li class="l" v-for="(item, index) in brandCategory[brandCategoryIdx].sub" :key="index"><a href="javascript:;" :class="{active:brandCategoryIdIdx==index}" @click="categoryIdClick(index,item.id)">{{item.name}}({{item.count}})</a></li>
              <!-- <template v-else>
                <li class="l" v-for="(item, index) in brandCategory[0].sub" :key="index"><a href="javascript:;" :class="{active:brandCategoryIdIdx==index}" @click="categoryIdClick(index,item.id)">{{item.name}}({{item.count}})</a></li>
              </template> -->
            </ul>
          </div>
          <div class="select-wrap" v-if="productList&&params.categoryId">
            <div class="param_search clear">
              <div class="clearfix">
                <i class="l">参数选型：</i>
                <p class="l" v-for="(item, index) in s_attr" :key="index">
                  <a class="item" href="javascript:;" v-for="(i,d) in item" :key="d"><span>{{i.name}}{{i.val}}</span><i @click="delHtmlItem(i.idx,i.valIdx,index,d)"></i></a>
                </p>
              </div>
            </div>
            <div class="param_select">
              <div class="brand_select_box">
                <template v-if="productList.object.QueryItems&&productList.object.QueryItems.length>0">
                  <div class="brand_select" v-for="(av,aindex) in productList.object.QueryItems" :key="aindex">
                    <h4>{{av.name}}</h4>
                    <ul>
                      <template v-if="av.attrs.length >0&&s_attr_falg[aindex]">
                        <li v-for="(av2,aindex2) in av.attrs" :key="aindex2" @click="attrSelect(av.name,aindex,av2,aindex2,av.attrs.length)" :class="{'attrclass':s_attr_falg[aindex].arr[aindex2]}">
                          {{av2}}</li>
                      </template>
                    </ul>
                  </div>
                </template>

              </div>
            </div>
            <a href="javascript:;" class="pBtn" @click.stop="paramsSearch">立即查询</a>
          </div>
        </div>
        <div class="primary_search_product">
          <div class="search_product_title">

            <div class="product-price" id="selectSort">
              <span @click.stop="priceSortShowFun" v-clickoutside="handleClose">{{sortNavText}}</span>
              <i></i>
              <div class="price-selct" id="price-selct" v-show="priceSortShow">
                <p v-for="(item, index) in sortNav" :key="index" :class="{selcted:sortNavIdx==index}" @click="sortFun(item.sort)">{{item.text}}</p>
              </div>
            </div>
            <div class="product-stock" id="checkbox" @click="stockFun">
              <i :class="{active:stockFalg}"></i>
              <span>有库存的</span>
            </div>
            <div class="padding20" v-if="productList">
              符合条件的商品：<span>{{productList.total}}</span></div>
          </div>
          <div class="s-wbox" v-loading="isloading">
            <template v-if="productList && productList.records.length>0">
              <ul class="new-product-ul" v-if="productList">
                <li class="new-product-li clearfix" v-for="(v,index) in productList.records" :key="index">
                  <div class="pl-col-1 fl">
                    <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" class="ibox">
                      <img v-if="v.goodsImg" :src="v.goodsImg" :alt="v.name+' '+v.packageSpecification">
                      <img v-else src="@/assets/images/goods/default_img.png" :alt="v.name+' '+v.packageSpecification">
                    </router-link>
                  </div>
                  <div class="pl-col-2 fl">
                    <p class="p_name">
                      <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.name"></router-link>
                    </p>
                    <div class="clear">
                      <div class="c1">
                        <p>
                          <span class="tag">编码：</span>
                          <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.ksn"></router-link>
                        </p>
                        <p>
                          <span class="tag">品牌：</span>
                          <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.brandName"></router-link>
                        </p>
                        <p class="ms-wrap">
                          <span class="tag">描述：</span>
                          <router-link class="ms" :title="v.goodRemark" :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.goodRemark"></router-link>
                        </p>
                      </div>
                      <div class="c2">
                        <p>
                          <span class="tag">型号：<i v-html="v.materialSn"></i></span>
                        </p>
                        <p>
                          <span class="tag">封装：<i v-html="v.packageSpecification"></i></span>
                        </p>

                        <p v-if="v.goodsManual">
                          <a :href="v.goodsManual" target="_blank" class="pdf">数据手册</a>
                        </p>
                        <p v-else>
                          <a href="javascript:;" class="pdf">数据手册：暂无</a>
                        </p>
                      </div>
                      <div class="c3">
                        <p><span class="tag">库存：<i>现货</i></span></p>
                        <p><span class="tag">发货时间：<i>2~7 天发货</i></span></p>
                        <p><span class="tag">包装数量：{{v.packCount}} {{v.storeCompany}}</span></p>
                      </div>
                    </div>
                  </div>
                  <div class="pl-col-3 fl">
                    <div class="price_list">
                      <div class="tab">
                        <table v-if="v.priceTemplate&&v.priceTemplate.length >0">
                          <tbody>
                            <tr v-for="(vp,index2) in v.priceTemplate" :key="index2">
                              <td class="num">{{vp.num}}+</td>
                              <td class="price">¥{{vp.price}}/{{v.storeCompany}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="pl-col-4 fr">
                    <div class="opera_box">
                      <div class="number-box">
                        <input class="buy-num" :value="v.packCount" maxlength="7" onkeyup="value=value.replace(/[^\d]/ig,'')" @blur="blurFun($event,v.packCount,index)"><span class="add" @click="add($event,v.packCount,index)">+</span><span class="sub" @click="sub($event,v.packCount,index)">-</span>
                        <div class="tip">
                          <em></em>
                          需整盘数量购买，已为您修正
                        </div>
                      </div>
                      <!-- <el-input-number :value="v.setUp" v-model="num" :min="1" :max="10" label="描述文字"></el-input-number> -->
                      <a class="add_cart J_addToCart" href="javascript:;" @click="addShopCart(v,index)">加入购物车</a>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="page-wrap">
                <el-pagination background hideOnSinglePage layout="prev, pager, next" :total="productList.total" @current-change="currentChange"></el-pagination>
              </div>
            </template>
            <div class="no_product_box" v-if="(productList&&productList.records.length==0&&!isloading) || !productList&&!isloading">
              <img src="@/assets/images/goods/noshop_1.png">
              <p>很抱歉，暂无相关商品，请查询其他的看看</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '合晶芯城_电子元器件产品大全_IC交易网_电子元件采购',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城拥有10000多平方米现代化元器件仓库，现货库存超100000种。提供各品牌的原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  async asyncData ({ store, route }) {
    let getBrandDetail = await store.dispatch('lxr/get_brand_detail', {
      fno: route.query.fno,
    });
    let get_brand_category = await store.dispatch('lxr/get_brand_category', {
      brandId: route.query.fno,
      pageNum: 1, pageSize: 100
    });
    let getProductList = await store.dispatch('lxr/get_product_list', {
      pageNum: 1,
      pageSize: 10,
      brandId: route.query.fno || '',
      categoryId: route.query.mid || route.query.pid || '',
      attrs: [],
      queryValue: '',
    })
    return getBrandDetail, get_brand_category, getProductList
  },
  components: {
  },
  data () {
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
        brandId: this.$route.query.fno || '',
        categoryId: this.$route.query.mid || this.$route.query.pid || '',
        attrs: [],
        queryValue: '',
      },
      brandCategoryIdIdx: -1,
      brandCategoryIdx: -1,
      s_attr: [], //选中属性列表集合 ---  展示
      s_attr_id: [], //选中属性列表集合 --  标识
      s_attr_falg: [],
      isloading: true, sortNavIdx: 0,
      sortNavText: '默认排序',
      stockFalg: false,
      priceSortShow: false,
      sortNav: [
        {
          sort: 0,
          text: '默认排序'
        },
        {
          sort: 1,
          text: '价格升序'
        },
        {
          sort: 2,
          text: '价格降序'
        }
      ],
      pid: this.$route.query.pid || '',
      mid: this.$route.query.mid || ''
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['brandDetail', 'productList', 'brandCategory'])
  },
  methods: {
    ...mapActions('lxr', ['get_brand_detail', 'get_product_list', 'get_brand_category', 'get_cart_list']),
    ...mapMutations('lxr', ['GET_BRAND_DETAIL', 'GET_BRAND_CATEGORY', 'GET_PRODUCT_LIST']),
    ...mapActions("lw", ["addCart"]),
    async getCartList () {
      let res = await this.get_cart_list()
    },
    blurFun (e, packCount, idx) {
      let num = parseInt(e.target.value)
      let a = num
      let b = a % parseInt(packCount)

      if (a > parseInt(packCount)) {
        $('.number-box').eq(idx).find('.sub').attr('style', '')
        if (b != 0) {
          a += parseInt(packCount) - b
          $('.buy-num').eq(idx).next().next().next().show()
          setTimeout(() => {
            $('.buy-num').eq(idx).next().next().next().hide()
          }, 2000);
        }
        if (a > 1000000) {
          let c = 1000000 % parseInt(packCount)
          if (c != 0) {
            a = 1000000 - c
          } else {
            a = 1000000
          }
        }
      } else {
        a = parseInt(packCount)
        $('.number-box').eq(idx).find('.sub').attr('style', 'color:#999')
        $('.buy-num').eq(idx).next().next().next().show()
        setTimeout(() => {
          $('.buy-num').eq(idx).next().next().next().hide()
        }, 2000);
      }
      $('.buy-num').eq(idx).val(a)
    },
    add (e, packCount, idx) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num + parseInt(packCount)
      if (a > 1000000) {
        let b = 1000000 % parseInt(packCount)
        if (b != 0) {
          a = 1000000 - b
        } else {
          a = 1000000
        }
      }
      $('.number-box').eq(idx).find('.sub').attr('style', '')
      $('.buy-num').eq(idx).val(a)
    },
    sub (e, packCount, idx) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num - parseInt(packCount)
      if (a < parseInt(packCount)) {
        a = parseInt(packCount)
        $('.number-box').eq(idx).find('.sub').attr('style', 'color:#999')
      } else {
        $('.number-box').eq(idx).find('.sub').attr('style', '')
      }
      $('.buy-num').eq(idx).val(a)
    },
    async addShopCart (v, index) {
      let params = {
        customerSn: v.materialSn,
        goodsId: v.id,
        goodsName: v.name,
        goodsNum: $('.buy-num').eq(index).val(),
        goodsImage: v.goodsImg,
        goodsCategoryName: v.categoryName,
        price: v.price,
        priceTemplate: JSON.stringify(v.priceTemplate),
        setUp: v.setUp,
        storeCount: v.storeCount,
        categoryId: v.categoryId,
        stepSample: v.stepSample,
        brandName: v.brandName
      };
      let res = await this.addCart(params)
      if (res.httpCode == 200) {
        // this.$router.push({ path: '/cart' })
        this.getCartList()
        this.$message.success('添加成功');
      } else {
        this.$message.error(res.message);
      }
    },
    handleClose () {
      this.priceSortShow = false
    },
    priceSortShowFun () {
      this.priceSortShow = !this.priceSortShow
    },
    currentChange (e) {
      this.params.pageNum = e
      this.getProductList()
    },
    delHtmlItem (idx, valIdx, i, id) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      this.s_attr[i].splice(id, 1)
      this.s_attr_id[i].splice(id, 1)
    },
    //参数属性单击
    attrSelect (name, idx, val, valIdx, length) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      if (this.s_attr_id[idx].indexOf(valIdx) >= 0) {
        let delIdx = this.s_attr_id[idx].indexOf(valIdx)
        this.s_attr_id[idx].splice(delIdx, 1)
        this.s_attr[idx].splice(delIdx, 1)
      } else {
        this.s_attr_id[idx].push(valIdx)
        this.s_attr[idx].push({ name: name, idx: idx, val: val, valIdx: valIdx })
      }
    },
    paramsSearch () {
      this.params.attrs = []
      this.s_attr_id.forEach((item, index) => {
        if (item.length > 0) {
          let val = ''
          item.forEach(i => {
            val += this.productList.object.QueryItems[index].attrs[i] + '&'
          });
          this.params.attrs.push({ attrValue: val, attrId: this.productList.object.QueryItems[index].name })
        }
      });
      this.getProductList()
    },
    stockFun () {
      this.stockFalg = !this.stockFalg
      if (this.stockFalg) {
        this.params.storeCount = 1
      } else {
        delete this.params.storeCount
      }
      this.getProductList()
    },
    sortFun (sort) {
      if (sort == 1) { //升序
        this.params.sort = 1
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else if (sort == 2) { //降序
        this.params.sort = 2
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else {
        delete this.params.sort
      }
      this.getProductList()
    },
    brandCategoryClick (idx, item) {
      this.brandCategoryIdx = idx
      this.brandCategoryIdIdx = -1
      if (item.sub.length >= 1) {
        this.brandCategoryIdIdx = 0
        this.params.categoryId = item.sub[0].id
      } else {
        this.params.categoryId = item.id
      }
      this.s_attr_id = []
      this.s_attr = []
      this.s_attr_falg = []
      this.params.attrs = []
      this.params.pageNum = 1
      this.getProductList()
    },
    categoryIdClick (idx, id) {
      this.brandCategoryIdIdx = idx
      this.params.categoryId = id
      this.s_attr_id = []
      this.s_attr = []
      this.s_attr_falg = []
      this.params.attrs = []
      this.params.pageNum = 1
      this.getProductList()
    },
    async getProductList () {
      this.isloading = true
      let res = await this.get_product_list(this.params)
      if (!res) {
        this.isloading = false
        this.GET_PRODUCT_LIST('')
        return
      }
      if (res.httpCode == 200) {
        this.isloading = false
        if (res.data.object.QueryItems && res.data.object.QueryItems.length > 0) {
          res.data.object.QueryItems.forEach((item, index) => {
            this.s_attr_id.push([])
            this.s_attr.push([])
            this.s_attr_falg.push({ arr: [] })
            item.attrs.forEach((i, d) => {
              this.s_attr_falg[index].arr.push(false)
            })
          });
        }
      }
    },
    async getBrandCategory () {
      let res = await this.get_brand_category({ brandId: this.params.brandId, pageNum: 1, pageSize: 100 })
      if (res.httpCode == 200) {
        this.getIdx()
      }
    },
    videoShow () {
      this.videoFalg = !this.videoFalg
    },
    videoHide () {
      this.videoFalg = !this.videoFalg
    },
    async getBrandDetail () {
      let res = await this.get_brand_detail({
        fno: this.params.brandId
      })
    },
    getIdx () {
      if (this.pid && this.brandCategory.length > 0) {
        this.brandCategory.forEach((item, index) => {
          if (this.pid == item.id) {
            this.brandCategoryIdx = index
            if (item.sub.length > 0) {
              item.sub.forEach((i, d) => {
                if (i.id == this.mid) {
                  this.brandCategoryIdIdx = d
                }
              });
            }

          }
        });
      }
    }
  },
  mounted () {

    if (!this.brandDetail) {
      this.getBrandDetail()
    }
    if (!this.brandCategory) {
      this.getBrandCategory()
    } else {
      this.getIdx()
    }
    if (!this.productList) {
      this.getProductList()
    } else {
      this.isloading = false
      if (this.productList.object.QueryItems && this.productList.object.QueryItems.length > 0) {
        this.productList.object.QueryItems.forEach((item, index) => {
          this.s_attr_id.push([])
          this.s_attr.push([])
          this.s_attr_falg.push({ arr: [] })
          item.attrs.forEach((i, d) => {
            this.s_attr_falg[index].arr.push(false)
          })
        });
      }
    }
  }, beforeDestroy () {
    this.GET_BRAND_DETAIL('')
    this.GET_BRAND_CATEGORY('')
    this.GET_PRODUCT_LIST('')
  }
}
</script>
<style lang="scss">
</style>
