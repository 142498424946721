<template>
  <div class="main clear" id="main">
    <div class="link-s">
      <router-link to="/index">首页</router-link>><span>友情链接</span>
    </div>
    <div class="link-t">
      <h3>友情链接</h3>
      <ul class="link-img" v-if="friendLink">
        <li v-for="(item, index) in friendLink" :key="index">
          <a :href="item.link" target="_blank">
            <img v-if="item.logoUrl" :alt="item.name" :title="item.name" :src="item.logoUrl">
            <div class="divLogo" v-else>{{item.name}}</div>
          </a>
        </li>
      </ul>
      <!-- <ul class="link-href">
        <li><a href="http://www.feipinhs88.com/" target="_blank">上海废品回收</a> </li>
      </ul> -->
    </div>
    <div class="link-email">
      <h3>合作联系方式</h3>
      <p>合作邮箱：<span>hj020@hotking.com</span></p>
      <p>咨询 QQ：<span>3002032078</span></p>
    </div>
    <div class="link-direc">
      <h3>链接要求与说明</h3>
      <p>1、需要友链合作的客户请先咨询客服，明确各项事宜后，把友链相关资料发送至合作邮箱；</p>
      <p>2、邮件格式：友链名称+链接地址+图片(非必填)；</p>
      <p>3、合作链接需要与合晶芯城有一定的相关性，或与电子元器件相关；</p>
      <p>4、链接名称2-8个字之间，链接图片大小为120*40像素大小的png/jpg格式（图片logo内容为横板，显示效果更佳）；</p>
      <p>5、违反我国现行法律的或含有违规违法内容的网站勿扰。</p>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '合晶芯城 - 友情链接',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二三极管,电阻电容,集成电路,元器件商城,电子元件采购平台'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内原厂直营二三极管、电阻电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  components: {
  },
  data () {
    return {
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['friendLink'])
  },
  methods: {
  },
  mounted () {
  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/link.scss";
</style>