<template>
  <div class="user-contet" id="userInfo">
    <div class="info-top clearfix">
      <div class="i-user">
        <div class="name-img-i" v-if="userInfo">
          <img v-if="userInfo.headImg" :src="userInfo.headImg" alt="头像">
          <img v-else src="@/assets/images/default_tx.png">
          <div class="name-i">
            <h3>{{userInfo.nickname }}</h3>
            <p>手机号码：{{userInfo.mobile }}</p>
            <p>邮箱账号：{{userInfo.email }}</p>
          </div>
        </div>
        <div class="vip-cpro" v-if="userInfo">
          <div class="vip-process clearfix">
            <p class="l">
              <i class="rank1">
                <img :src="'http://doc.hotking.com/hkt/ui/img/level/rank_'+userInfo.level+'.png'">
              </i>
            </p>
            <div class="progress l" id="vader">
              <div class="bar_space">
                <span class="bar-pro" :style="'width:'+userInfo.levelMin*100+'%'"></span>
              </div>
            </div>
            <p class="l">
              <i class="rank2" v-if="userInfo.level!=6">
                <img :src="'http://doc.hotking.com/hkt/ui/img/level/rank_'+(userInfo.level+1)+'.png'">
              </i>
            </p>
          </div>
          <div class="vip-rankn clear">
            <p class="l">{{userInfo.level==1?'铜牌会员':userInfo.level==2?'银牌会员':userInfo.level==3?'黄金会员':userInfo.level==4?'白金会员':userInfo.level==5?'钻石会员':'皇冠会员'}}</p>
            <p class="l ct">当前经验值 {{userInfo.empiricalPoint}}</p>
            <p class="l">{{userInfo.level+1==1?'铜牌会员':userInfo.level+1==2?'银牌会员':userInfo.level+1==3?'黄金会员':userInfo.level+1==4?'白金会员':userInfo.level+1==5?'钻石会员':'皇冠会员'}}</p>
          </div>
        </div>
      </div>
      <ul class="i-type" v-if="info">
        <li>
          <router-link :to="{path:'/home/order/list',query:{pay_status:0}}">
            <i class="dfk"></i>
            <p>待付款(<span>{{info.unPayNum}}</span>)</p>
          </router-link>
        </li>
        <li>
          <router-link :to="{path:'/home/order/list',query:{pay_status:1}}">
            <i class="wfh"></i>
            <p>未发货(<span>{{info.unDeliverNum}}</span>)</p>
          </router-link>
        </li>
        <li class="three">
          <router-link :to="{path:'/home/order/list',query:{pay_status:2}}">
            <i class="dsh"></i>
            <p>待收货(<span>{{info.unReceiveNum}}</span>)</p>
          </router-link>
        </li>
        <li>
          <router-link to="/cart">
            <i class="gwc"></i>
            <p>购物车(<span>{{info.cartNum}}</span>)</p>
          </router-link>
        </li>
        <li>
          <router-link to="/home/collection">
            <i class="sc"></i>
            <p>我的收藏(<span>{{info.favoriteNum}}</span>)</p>
          </router-link>
        </li>
        <li class="three">
          <router-link to="/home/message">
            <i class="xx"></i>
            <p>未读消息(<span>{{info.unReadMessage}}</span>)</p>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="order-jf clearfix">
      <div class="order-con">
        <h3 class="title">我的订单<router-link :to="{path:'/home/order/list'}">More</router-link>
        </h3>
        <div class="con">
          <table>
            <tbody>
              <tr>
                <th>订单编号</th>
                <th>下单时间</th>
                <th>订单金额</th>
                <th>订单状态</th>
                <th>操作</th>
              </tr>
              <template v-if="orderList">
                <tr v-for="(item,index) in orderList" :key="index">
                  <td>{{item.orderSn}}</td>
                  <td>{{item.createTime | calcTime}}</td>
                  <td>￥{{item.totalAmount | calcPriceFun}}</td>
                  <td>
                    <template v-if="item.orderStatus==-2">
                      审核不通过
                    </template>
                    <template v-if="item.orderStatus==-1">
                      待审核
                    </template>
                    <template v-if="item.orderStatus==0">
                      待付款
                    </template>
                    <template v-if="item.orderStatus==1">
                      待发货
                    </template>
                    <template v-if="item.orderStatus==2">
                      已发货
                    </template>
                    <template v-if="item.orderStatus==3">
                      <i>已完成</i>
                    </template>
                    <template v-if="item.orderStatus==4">
                      <i>已取消</i>
                    </template>
                  </td>
                  <td>
                    <router-link :to="{path:'/home/order/detail',query:{id:item.orderSn}}">查看</router-link>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="jf-con">
        <h3 class="title">会员积分</h3>
        <div class="con">
          <p v-for="(item, index) in list" :key="index" class="clearfix">
            <span class="fl">{{item.operationTime | setTime}}</span>
            <span class="fl nn">{{item.remark}}</span>
            <span class="fr z-num">{{item.scoreChange}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="r userCenter_container_box" v-if="false">
      <h2>编辑账户信息</h2>
      <div class="userCenter_container index_box" v-if="userInfo">
        <div class="box_tx clear">
          <img v-if="userInfo.headImg" :src="userInfo.headImg" alt="头像">
          <img v-else src="@/assets/images/default_tx.png">
          <div class="box-xx">
            <div class="box-xxt clear">
              <p class="name l">{{userInfo.nickname }}</p>
              <p class="rank1 l">
                <i>
                  <img :src="'http://doc.hotking.com/hkt/ui/img/level/rank_'+userInfo.level+'.png'">
                </i>{{userInfo.level==1?'铜牌会员':userInfo.level==2?'银牌会员':userInfo.level==3?'黄金会员':userInfo.level==4?'白金会员':userInfo.level==5?'钻石会员':'皇冠会员'}}
              </p>
              <!-- <p class="btn l disabled" id="checkIn">已签到</p> -->
            </div>
            <div class="box-xxl clear">
              <div class="box-xxl-list l">
                <p><span>手机号码：</span><span>{{userInfo.mobile}}</span></p>
                <p><span>邮箱账号：</span><span>{{userInfo.email}}</span></p>
              </div>
              <div class="box-xxl-list l">
                <!-- <p v-if="userInfo.last_login!=0"><span>上次登录：</span><span>{{userInfo.last_login}}</span></p> -->
                <p class="edit">
                  <span>编辑资料：</span>
                  <router-link to="/home/user/info"></router-link>
                  <span class="ts" v-if="userInfo.emailValidated==0"><i></i>
                    首次完善帐户信息可获得积分噢！
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <ul class="orders clear">
          <template v-if="info">
            <li>
              <router-link :to="{path:'/home/order/list',query:{pay_status:0}}">
                <p>待付款</p>
                <span>{{info.unPayNum}}</span>
                <i class="dfk"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{path:'/home/order/list',query:{pay_status:1}}">
                <p>未发货</p>
                <span>{{info.unDeliverNum}}</span>
                <i class="wfh"></i>
              </router-link>
            </li>
            <li class="three">
              <router-link :to="{path:'/home/order/list',query:{pay_status:2}}">
                <p>待收货</p>
                <span>{{info.unReceiveNum}}</span>
                <i class="dsh"></i>
              </router-link>
            </li>
            <li>
              <router-link to="/cart">
                <p>购物车</p>
                <span>{{info.cartNum}}</span>
                <i class="gwc"></i>
              </router-link>
            </li>
            <li>
              <router-link to="/home/collection">
                <p>我的收藏</p>
                <span>{{info.favoriteNum}}</span>
                <i class="sc"></i>
              </router-link>
            </li>
            <li class="three">
              <router-link to="/home/message">
                <p>未读消息</p>
                <span>{{info.unReadMessage}}</span>
                <i class="xx"></i>
              </router-link>
            </li>
          </template>
        </ul>
        <div class="vip clear">
          <div class="vip-t clear">
            <span class="l">我的会员成长</span>
            <span class="r">
              <router-link to="/home/rules">会员规则</router-link>
            </span>
          </div>
          <div class="vip-c clear">
            <div class="vip-cpro l">
              <div class="vip-process clear">
                <!-- <span class="sjfs">0/1</span> -->
                <p class="l">
                  <i class="rank1">
                    <img :src="'http://doc.hotking.com/hkt/ui/img/level/rank_'+userInfo.level+'.png'">
                  </i>
                </p>
                <div class="progress l" id="vader">
                  <div class="bar_space">
                    <span class="bar-pro" :style="'width:'+userInfo.levelMin*100+'%'"></span>
                  </div>
                </div>
                <p class="l">
                  <i class="rank2" v-if="userInfo.level!=6">
                    <img :src="'http://doc.hotking.com/hkt/ui/img/level/rank_'+(userInfo.level+1)+'.png'">
                  </i>
                </p>
              </div>
              <div class="vip-rankn clear">
                <p class="l">{{userInfo.level==1?'铜牌会员':userInfo.level==2?'银牌会员':userInfo.level==3?'黄金会员':userInfo.level==4?'白金会员':userInfo.level==5?'钻石会员':'皇冠会员'}}</p>
                <p class="l ct">当前经验值 {{userInfo.empiricalPoint}}</p>
                <p class="l">{{userInfo.level+1==1?'铜牌会员':userInfo.level+1==2?'银牌会员':userInfo.level+1==3?'黄金会员':userInfo.level+1==4?'白金会员':userInfo.level+1==5?'钻石会员':'皇冠会员'}}</p>
              </div>
            </div>
            <div class="vip-cjf r">
              <div class="vip-cjfl clear">
                <p class="l num">{{userInfo.active_point}}</p>
                <router-link to="/home/user/integral" class="mx r"><i></i>明细</router-link>
              </div>
              <p class="clear">会员积分</p>
            </div>
          </div>
          <div class="vip-b clear">
            <div class="vip-bl l">
              <template v-if="userInfo.level==1">
                <p class="tt">会员升级后可获得以下福利</p>
                <p>每月签到≥10天赠送会员积分100分</p>
              </template>
              <template v-else-if="userInfo.level==2">
                <p class="tt">会员升级后可获得以下福利</p>
                <p>每月签到≥10天赠送会员积分100分</p>
                <p>首次升级赠送100积分</p>
              </template>
              <template v-else-if="userInfo.level==3">
                <p class="tt">会员升级后可获得以下福利</p>
                <p>每月签到≥15天赠送会员积分500分</p>
                <p>首次升级赠送200积分</p>
              </template>
              <template v-else-if="userInfo.level==4">
                <p class="tt">会员升级后可获得以下福利</p>
                <p>专属客服</p>
                <p>每月签到≥15天赠送会员积分1000分</p>
                <p>首次升级赠送1000积分，¥15无门槛优惠卷一张</p>
              </template>
              <template v-else-if="userInfo.level==5">
                <p class="tt">会员升级后可获得以下福利</p>
                <p>专属客服，优先发货</p>
                <p>每月签到≥18天赠送会员积分1500分</p>
                <p>首次升级赠送2000积分、¥30无门槛优惠卷一张</p>
              </template>
              <template v-else-if="userInfo.level==6">
                <p class="tt">会员升级后可获得以下福利</p>
                <p>专属客服，优先发货</p>
                <p>每月签到≥20天，赠送会员积分2000分</p>
                <p>首次升级赠送5000积分、¥50无门槛优惠卷一张</p>
              </template>
            </div>
            <div class="vip-br r">
              <p class="tt clear">近期变动</p>
              <p v-for="(item, index) in list" :key="index" class="clear" style="width: 100%" v-show="index<3">
                <span class="l" style="width: 130px">{{item.operationTime | setTime}}</span>
                <span class="l nn">{{item.remark}}</span>
                <span class="r z-num">+{{item.scoreChange}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心',
    }
  },
  data () {
    return {
      list: [],
      params: {
        "data": {
          "alterType": 0, // 类型
          "endTime": "", // 结束时间
          "startTime": "" // 开始时间
        },
        "pageNum": 1,
        "pageSize": 7
      },
      info: null,
      orderList: null,
    }
  },

  created () {
    this.getScoreAll()
    this.getUserInfo()
    this.getOrderList()
  },
  computed: {
    ...mapState('lxr', ['userInfo'])
  },
  methods: {
    ...mapMutations('lxr', ['SET_USERINFO']),
    ...mapActions('lw', ['queryScore', 'getUserInfoCount']),
    ...mapActions('lxr', ['get_order_list']),
    async getOrderList () {
      let res = await this.get_order_list(this.params)
      if (res.httpCode == 200) {
        this.orderList = res.data.records
      }
    },
    // 获取积分
    async getScoreAll () {
      let res = await this.queryScore({
        "data": {
          "alterType": 0, // 类型
          "endTime": "", // 结束时间
          "startTime": "" // 开始时间
        },
        "pageNum": 1,
        "pageSize": 8
      })
      if (res.httpCode == 200) {
        this.list = res.data.records
      }
    },
    // 获取统计数据
    async getUserInfo () {
      let res = await this.getUserInfoCount()
      if (res.httpCode == 200) {
        this.info = res.data
      }
    },
  },
  mounted () {


  },
  filters: {
    setTime: function (val) {
      val = val.substring(0, val.length - 3)
      return val
    },
    calcTime (val) {
      return val.substr(0, val.length - 5)
    },
    calcPriceFun (val) {
      return parseFloat(val).toFixed(2);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-index.scss";
</style>


