<template>
  <div class="mask_layout" id="cart_dialog" @click.stop="setCartFalg">
    <div class="cart-close" @click.stop="setCartFalg"></div>
    <div class="cart-wrap clear" @click.stop>
      <div class="cart-wrap-top clear">
        <p class="l"></p>
        <span class="r delAllCart" @click="delCartIds('')">清除全部</span>
      </div>
      <div class="cart-wrap-list clear">
        <ul id="cart-list" v-loading="isLoading">
          <template v-if="cartListX.length>0">
            <li class="catrList-li clear" v-for="(item, index) in cartListX" :key="index">
              <router-link :to="{path:'/product/detail',query:{id:item.goodsId}}" class="catrList-item clear">
                <img v-if="item.goodsImage" :src="item.goodsImage" />
                <img v-else src="@/assets/images/goods/default_img.png" />
                <p>{{item.goodsName}}</p>
                <p class="clear"><span class="l">￥{{item.calcPrice}}/pcs</span><span class="r">× {{item.goodsNum}}</span></p>
              </router-link>
              <div class="catrList-jr clear">
                <p class="l"><span>合计金额：</span><span class="price">￥{{item.goodsNum*item.calcPrice | calcPriceFun}}</span></p>
                <p class="r delCartList" @click="delCartIds(item.goodsId)"></p>
              </div>
            </li>
          </template>
          <li class="nocart" v-if="!isLoading&&cartListX.length==0">
            <img src="@/assets/images/cart/cartno.png">
            <p>购物车空空如也，<br>赶快把喜欢的商品加入购物车吧</p>
          </li>
        </ul>
      </div>
      <div class="cart-wrap-bottom">
        <p>共<i>{{cartListX.length}}</i>种商品（不含运费）</p>
        <p><span>原价：</span><span class="price">¥{{sumPriceX}}</span></p>
        <p><span>总金额：</span><span class="total">¥{{sumPriceX}}</span></p>
        <a href="javascript:;" @click="goCart">去购物车结算</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  props: {
  },
  data () {
    return {
      cartShow: false,
      isLoading: true,
    }
  },

  created () {
  },
  computed: {
    ...mapState('lxr', ['sumPriceX', 'cartListX', 'cartFlag']),
  },
  methods: {
    ...mapMutations('lxr', ['setCartFalg', 'GET_CART_LIST']),
    ...mapActions("lxr", ["get_cart_list2", 'del_cart_ids']),
    //获取购物车header
    async getCartList () {
      var _this = this
      let res = await this.get_cart_list2()
      if (res.httpCode == 200) {
        this.isLoading = false
      }
    },
    async delCartIds (id) {
      var params = {
        goodId: ''
      }
      if (id) {
        params.goodId = id
      } else {
        this.cartListX.forEach((item, index) => {
          if (item) {
            params.goodId += item.goodsId + ','
          }
        });
        params.goodId = params.goodId.substr(0, params.goodId.length - 1)
      }
      if (!params.goodId) {
        this.$message.error('请选择商品')
        return
      }
      let res = await this.del_cart_ids(params)
      if (res.httpCode == 200) {
        this.selectId = []

        this.getCartList()
      }
    },
    goCart () {
      this.setCartFalg()
      this.$router.push({ path: '/cart' })
    }

  },
  mounted () {
    // var a = setInterval(() => {
    //   let cartHeader = localStorage.getItem('cartHeader');
    //   if (cartHeader) {
    //     this.getCartList()
    //     clearInterval(a)
    //   }
    // }, 200);
    let cartHeader = localStorage.getItem('cartHeader');
    if (cartHeader) {
      this.getCartList()
    }
  },
  filters: {
    calcPriceFun (val) {
      return val.toFixed(2);
    },
    calcGoodsNum (val) {

    }
  }
}
</script>
<style lang="scss" scoped>
</style>


