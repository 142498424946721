<template>
  <div>
    <!-- <div class="reset-pass">
      <div class="reset-wrap">
        <ul class="reset-step clearfix">
          <li class="active">
            <span class="one"></span>
            <p>验证身份</p>
          </li>
          <li :class="{active:idx==2||idx==3}">
            <span class="two"></span>
            <p>重置登录密码</p>
          </li>
          <li :class="{active:idx==3}">
            <span class="three"></span>
            <p>重置完成</p>
          </li>
        </ul>
        <div class="nav-con">
          <template v-if="idx==1">
            <div class="inp-wrap"></div>
          </template> 

        </div>
      </div>
    </div> -->
    <div class="resetP_box">
      <div class="resetP_wrap">
        <ul class="resetP_step clearfix">
          <li class="active">
            <span class="one"></span>
            <p>验证身份</p>
          </li>
          <li :class="{active:idx==2||idx==3}">
            <span class="two"></span>
            <p>重置登录密码</p>
          </li>
          <li :class="{active:idx==3}">
            <span class="three"></span>
            <p>重置完成</p>
          </li>
        </ul>
        <table id="resetP_one" class="resetP_table resetP_one" v-if="idx==1">
          <tbody>
            <tr>
              <td>手机号码：</td>
              <td>
                <input id="phone" name="mobile" type="text" maxlength="11" placeholder="请输入手机号码" v-model.trim="params.mobile"><span></span>
              </td>
            </tr>
            <tr>
              <td>验证码：</td>
              <td class="phone_vertify clear">
                <input class="l" name='smscode' type="text" maxlength="6" placeholder="请输入短信验证码" v-model.trim="code">
                <span class="l" id="send" v-show="!sendFalgPhone" @click="sendSms">发送</span>
                <span class="l" id="send" v-show="sendFalgPhone">重新发送(<i ref="timeNumPhone">60</i>)</span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button id="next-one" @click="checkMobileCode">下一步</button>
              </td>
            </tr>
          </tbody>
        </table>
        <table id="resetP_two" class="resetP_table resetP_two" v-if="idx==2">
          <tbody>
            <tr>
              <td>新的密码：</td>
              <td>
                <input name="password" type="password" maxlength="20" placeholder="设置6至20位登录密码" v-model.trim="pswd1"><span></span>
                <i class="empty_input"></i>
                <i class="verify_right"></i>
                <p class="error"></p>
              </td>
            </tr>
            <tr>
              <td>重复密码：</td>
              <td>
                <input name="repassword" type="password" maxlength="20" placeholder="请再次输入登录密码" v-model.trim="pswd2"><span></span>
                <i class="empty_input"></i>
                <i class="verify_right"></i>
                <p class="error"></p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td><button id="next-two" @click="updatePassword">下一步</button></td>
            </tr>
          </tbody>
        </table>
        <!-- 提示语 -->
        <div id="error" class="login_tips" style="display:none;">请先输入验证码</div>
        <!-- 重置密码成功 -->
        <div id="resetP_three" class="registed_box resetP_three" v-if="idx==3">
          <img src="@/assets/images/login/register_after.png">
          <h3>密码已设置成功！</h3>
          <p></p>
          <router-link to="/login">去登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      mobile: '',
      sendFalgPhone: false,
      phoneTime: '',
      code: '',
      idx: 1,
      params: {
        mobile: '',
        password: ''
      },
      pswd1: '',
      pswd2: '',
    }
  },

  created () {

  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['update_password', 'send_sms', 'check_mobile_code']),
    //发送短信
    async sendSms () {
      let sendCode = 60
      if (!this.params.mobile) {
        this.$message.error('请输入手机号码')
        return
      } else if (this.params.mobile.length != 11) {
        this.$message.error('请输入11位手机号码')
        return
      }
      let res = await this.send_sms({ mobile: this.params.mobile })
      if (res.httpCode == 200) {
        this.sendFalgPhone = true
        this.phoneTime = setInterval(() => {
          sendCode--
          this.$refs.timeNumPhone.innerText = sendCode
          if (sendCode == 0) {
            clearInterval(this.phoneTime)
            this.sendFalgPhone = false
            this.$refs.timeNumPhone.innerText = '60'
          }
        }, 1000);
      }
    },

    //验证短信
    async checkMobileCode () {
      if (!this.params.mobile) {
        this.$message.error('请输入手机号码')
        return
      } else if (this.params.mobile.length != 11) {
        this.$message.error('请输入11位手机号码')
        return
      }
      else if (!this.code) {
        this.$message.error('请输入验证码')
        return
      } else if (this.code.length != 6) {
        this.$message.error('请输入6位数验证码')
        return
      }
      let res = await this.check_mobile_code({
        mobile: this.params.mobile,
        code: this.code
      })
      if (res.httpCode == 200) {
        clearInterval(this.phoneTime)
        this.idx = 2
      } else {
        this.$message.error(res.message)
      }
    },

    async updatePassword () {
      if (!(this.pswd1.length >= 6 && this.pswd1.length <= 20)) {
        this.$message.error('请输入正确的新密码')
        return
      } else if (!(this.pswd2.length >= 6 && this.pswd2.length <= 20)) {
        this.$message.error('请输入正确的重复密码')
        return
      }
      if (this.pswd2 != this.pswd1) {
        this.$message.error('两次密码输入不一样')
        return
      }
      let res = await this.update_password(this.params)
      if (res.httpCode == 200) {
        this.idx = 3
      } else {
        this.$message.error(res.message)
      }
    }
  },
  mounted () {

  },
  watch: {
    'pswd1' (val) {
      if (val.length >= 6 && val.length <= 20) {
        if (val == this.pswd2) {
          this.params.password = val
        }
      } else {
        this.params.password = ''
      }
    },
    'pswd2' (val) {
      if (val.length >= 6 && val.length <= 20) {
        if (val == this.pswd1) {
          this.params.password = val
        }
      } else {
        this.params.password = ''
      }
    },
  }
}
</script>
<style lang="scss" scoped>
//@import "~@/assets/css/member-resetPassword.scss";
</style> 


