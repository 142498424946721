<template>
  <div class="newMain bgf5f5f5">
    <div class="help-wrap clearfix" v-loading="isloading">
      <div class="nav-wrap" v-if="helpInfo">
        <h3 class="title">帮助中心</h3>
        <div class="nav-list">
          <div class="nav-item" v-for="(item,index) in helpInfo" :key="index">
            <h4 :class="{current:curIdx==index}" @click="oneItem(index)">{{item.name}}</h4>
            <ul v-if="curIdx==index">
              <li v-for="(i,d) in item.helps" :key="d" :class="{current:curIdx2==d}" @click="twoItem(d)">{{i.title}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="con-wrap" v-if="helpInfo">
        <h3 class="title">{{helpInfo[curIdx].helps[curIdx2].title}}</h3>
        <div class="info" v-html="helpInfo[curIdx].helps[curIdx2].content">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: '',
  metaInfo () {
    return {
      title: '个人中心-帮助中心',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二三极管,电阻电容,集成电路,元器件商城,电子元件采购平台'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内原厂直营二三极管、电阻电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  async asyncData ({ store, route }) {
    // let getHelpList = await store.dispatch('lw/getHelpList', { languageId: 1 })
    let getHelpInfo = await store.dispatch('lw/getHelpInfo', {
      id: route.query.id, languageId: 1
    });

    return getHelpInfo;
  },
  data () {
    return {
      curIdx: 0,
      curIdx2: 0,
      params: {
        id: '', languageId: 1
      },
      isloading: true
    }
  },
  created () {

  },
  computed: {
    ...mapState('lw', ['helpInfo', 'helpList']),
  },
  mounted () {
    this.PUBLIC_NAME(this.$route.meta.title)
    let id = this.$route.query.id
    this.params.id = id ? this.params.id = id : 8
    // if (!this.helpList) {
    //   this.getHelpListF()
    // }
    if (!this.helpInfo) {
      this.getHelpInfoF()
    } else {
      this.isloading = false
    }
  },
  methods: {
    ...mapActions('lw', ['getHelpInfo']),
    ...mapMutations('cwq', ['PUBLIC_NAME']),
    twoItem (d) {
      this.curIdx2 = d
    },
    oneItem (d) {
      if (this.helpInfo[d].helps.length > 0) {
        this.curIdx = d
        this.curIdx2 = 0
      } else {
        this.$message.warning('暂无内容')
      }
    },
    async getHelpInfoF () {
      this.isloading = true
      let res = await this.getHelpInfo(this.params);
      if (res.httpCode == 200) {
        this.isloading = false
      }
    },
    // async getHelpListF () {
    //   let res = await this.getHelpList({ languageId: 1 });
    // }
  },
}
</script>
<style lang="scss">
</style>