<template>
  <div class="hkt_container clear" id="goods_list">
    <div class="primary_search_title clear">
      <a href="/product/level" class="">全部商品</a>&gt;<i>“{{params.materialSn}}” 的搜索结果</i>
    </div>
    <div class="searchMain" v-loading="isloading">

      <div class="primary_search_product">
        <div class="source">
          <router-link :to="{path:'/search',query:{v:$route.query.v}}">自营</router-link>
          <!-- <a class="active" href="javascript:;">国内现货</a> -->
        </div>
        <div class="search_product_title">
          <div class="padding20">
            符合条件的商品：<span v-if="chinaProductList1">{{chinaProductList1.TotalCount}}</span><span v-else>0</span>
          </div>
        </div>
        <template v-if="chinaProductList1">
          <ul v-if="chinaProductList1.ResultList.length>0" class="new-product-ul">
            <li class="new-product-li clear" v-for="(v,index) in chinaProductList1.ResultList" :key="index">
              <div class="pl-col-1 fl">
                <a href="javascript:;" class="ibox">
                  <img v-if="v.goodsImg" :src="v.goodsImg" :alt="v.PartNo+' '+v.Brand">
                  <img v-else src="@/assets/images/goods/default_img.png" :alt="v.PartNo+' '+v.Brand">
                </a>
              </div>
              <div class="pl-col-2 fl">
                <p class="p_name">
                  <a href="javascript:;" v-html="v.PartNo"></a>
                </p>
                <div class="clear">
                  <div class="c1">
                    <p>
                      <span class="tag">封装：</span>
                      <a href="javascript:;">{{v.Package || '-'}}</a>
                    </p>
                    <p>
                      <span class="tag">品牌：</span>
                      <a href="javascript:;">{{v.Brand || '-'}}</a>
                    </p>
                    <p class="ms-wrap">
                      <span class="tag">说明：</span>
                      <a href="javascript:;">{{v.Description || '-'}}</a>
                    </p>
                  </div>
                  <div class="c2">
                    <p>
                      <span class="tag">年份/批号：{{v.MakeYear || '-'}}</span>
                    </p>
                    <p>
                      <span class="tag">最小包装：</span>{{v.MPQ || '-'}}
                    </p>
                    <p v-if="v.goodsManual">
                      <a :href="v.goodsManual" target="_blank" class="pdf">数据手册</a>
                    </p>
                    <p v-else>
                      <a href="javascript:;" class="pdf">数据手册：暂无</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="pl-col-3 fl">
                <div class="dt_box">
                  <div class="box">
                    <p><span class="c_gray">现货：</span><span class="c-b">{{v.Qty}}</span></p>
                    <p class="plane">{{v.DeliveryTime}}（天,工作日）</p>
                  </div>
                </div>
                <div class="price_list">
                  <div class="tab">
                    <table v-if="v.StepPrices&&v.StepPrices.length >0">
                      <tbody>
                        <tr v-for="(vp,index2) in v.StepPrices" :key="index2">
                          <td class="num">{{vp.Qty}}+</td>
                          <td class="price">¥{{vp.Price}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="pl-col-4 fl">
                <div class="opera_box">
                  <p class="spq">「卷装」</p>
                  <!-- <div class="number-box">
                    <input class="buy-num" value="100"><span class="add">+</span><span class="sub">-</span>
                  </div>
                  <span class="valua">金额：<span class="fc-sred"></span></span> -->
                  <a class="add_cart J_addToCart" @click="clickPopBox">联系客服</a>
                  <!-- <span class="compare" :class="v.is_compare==1?'combtn active':'combtn'" @click="addCompareList(v)">{{v.is_compare==1?'对比中':'+ 对比'}}</span>
                  <span type="button" :class="v.collect==1 ? 'collbtn active': 'collbtn'" @click="getFavorites(v,index)">{{v.collect==1?'已收藏':'收藏'}}</span> -->
                </div>
              </div>
            </li>

          </ul>
          <div class="page-wrap">
            <el-pagination background hideOnSinglePage layout="prev, pager, next" :total="chinaProductList1.TotalCount" @current-change="currentChange"></el-pagination>
          </div>
        </template>
      </div>

      <div class="no_product_box" v-if="!isloading&&!chinaProductList1">
        <img src="@/assets/images/goods/noshop_1.png">
        <p>很抱歉，暂无相关商品，请查询其他的看看</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import https from '@/https.js'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '合晶芯城_电子元器件产品大全_IC交易网_电子元件采购',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城是电子元器件采购首选的一站式电商平台。拥有10000多平方米现代化元器件仓库，自营现货库存超100000种。满足各品牌原装正品电子元器件采购需求，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器等。'
        }
      ]
    }
  },
  components: {
  },
  asyncData ({ store, route }) {
    let getProductList = store.dispatch('lxr/get_china_product_list_1', {
      pageNum: 1,
      pageSize: 10,
      materialSn: route.query.v || '',
    })
    return getProductList
  },
  data () {
    return {
      sortNav: [
        {
          sort: 0,
          text: '默认排序'
        },
        {
          sort: 1,
          text: '价格升序'
        },
        {
          sort: 2,
          text: '价格降序'
        }
      ],
      sortNavIdx: 0,
      sortNavText: '默认排序',
      stockFalg: false,
      priceSortShow: false,
      total: 0, //总数
      params: {
        pageNum: 1,
        pageSize: 10,
        materialSn: this.$route.query.v || '',
      },

      isloading: true,
      productList: null
    }
  },
  created () {
  },
  computed: {
    ...mapState('lxr', ['chinaProductList1']),
  },

  methods: {
    ...mapActions('lxr', ['get_product_list', 'get_china_product_list_1']),
    ...mapMutations('lxr', ['GET_PRODUCT_LIST', 'GET_CHINA_PRODUCT_LIST_1']),
    clickPopBox () { window.open("https://hm.baidu.com/hm.js?30d5f0cd8e30265492389905f69cf896", "", "height=600px,width=800px") },
    priceSortShowFun () {
      this.priceSortShow = !this.priceSortShow
    },
    stockFun () {
      this.stockFalg = !this.stockFalg
      if (this.stockFalg) {
        this.params.storeCount = 1
      } else {
        delete this.params.storeCount
      }
      this.getProductList()
    },
    sortFun (sort) {
      if (sort == 1) { //升序
        this.params.sort = 1
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else if (sort == 2) { //降序
        this.params.sort = 2
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else {
        delete this.params.sort
      }
      this.getProductList()
    },
    currentChange (e) {
      this.params.pageNum = e
      this.getProductList()
    },
    async getProductList () {
      let res = await this.get_china_product_list_1(this.params)
      if (res.httpCode == 200) {
        this.isloading = false
      } else {
        // this.$message.error(res.message)
        this.isloading = false
      }
    },
    handleClose () {
      this.priceSortShow = false
    }
  },
  mounted () {
    if (!this.chinaProductList1) {
      this.getProductList()
    } else {
      this.isloading = false
    }
  },
  filters: {
    macSlice: function (val) {
      if (val) {
        return val.length > 18 ? val.slice(0, 18) + '...' : val;
      }
      return '';
    },
  }, watch: {
    '$route' () {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        queryValue: this.$route.query.v || '',
        categoryId: this.$route.query.cid || '',
        attrs: [],
        brandId: this.$route.query.bid || '',
        goodsType: this.$route.query.gt || '',
        type: this.$route.query.type || '',
        specParam: this.$route.query.spe || '',
      }
      this.getProductList()
    }
  }
  , beforeDestroy () {
    this.GET_PRODUCT_LIST('')
    this.GET_CHINA_PRODUCT_LIST_1('')
  }

}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/home-product-cate.scss";
</style>
