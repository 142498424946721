<template>
  <div class="banxin shoppingCart">
    <template v-if="params.tradeNo&&payStatus&&payStatusNum==0">
      <div class="pay_title">
        <p class="first">您的订单号：<span class="order">{{params.tradeNo}}</span><span style="float:right;" class="total">应付金额:<i>¥{{params.totalFee}}</i></span></p>
        <p class="time">
          <i>剩余支付时间：<span class="custom_pay_timers">{{timeHtml}}</span></i>
          合晶芯城已经为此订单预留库存，请尽快付款，超过时限订单将自动取消
        </p>
      </div>
      <div class="pay_body">
        <p class="pay_select clear ">
          <a class="active" href="javascript:;" data-show="weChat_pay">支付宝/微信</a>
        </p>
        <div class="pay_process">
          <div class="select_payWay custom_pay_case clear">
            <span :class="{active:payType==1}" id="alipay" @click="selectPay(1)"><img class="l" src="@/assets/images/cart/Bitmap.png" /><i>支付宝支付</i><em></em></span>
            <span id="wechat_pay" :class="{active:payType==2}" @click="selectPay(2)"><img src="@/assets/images/cart/weixinzhifu.png" class="l" /><i>微信支付</i><em></em></span>
          </div>
        </div>
      </div>
      <a href="javascript:;" class="next_btn custom_pay_order_submit" @click="payBtn">下一步</a>
    </template>
    <template v-if="params.tradeNo&&!payStatus">
      <div class="order_success">
        <img src="@/assets/images/error_72px_1201052.png">
        <h3>订单[{{params.tradeNo}}]已过期</h3>
        <div class="btn">
          <router-link to="/index" class="submit">返回首页</router-link>
        </div>
      </div>
    </template>
    <template v-if="payStatusNum==2">
      <div class="order_success">
        <img src="@/assets/images/cart/success.png">
        <h3>订单支付成功</h3>
        <p class="one">我们将尽快为您发货，收货期间请保持手机畅通</p>
        <p class="success">
          订单号：<i>{{params.tradeNo}}</i><br>
          支付方式：<i>{{tipText}}</i><br>
          支付金额：<i class="err">¥{{params.totalFee}}</i>
        </p>
        <div class="btn">
          <router-link to="/index" class="submit">返回首页</router-link>
          <router-link to="/home/order/list" class="back">查看订单</router-link>
        </div>
      </div>
    </template>
    <transition name="el-fade-in" v-if="payStatus">
      <div class="mask_layout custom_pay_wechat_box" v-show="payFalg">
        <!-- 微信支付 -->
        <div class="pop_up_box pay_box">
          <div class="pop_up_title clear">
            <h3 class="l"><i></i>微信支付 </h3>
            <span class="r custom_pay_wechat_box_cannel" @click="payFalg=false"></span>
          </div>
          <div class="pay_body" style="border:none;margin-top:2px;">
            <p class="time clear">二维码将于： <i class="custom_pay_timers">{{timeHtml}}</i>后过期，请使用微信扫码完成支付</p>
            <h5>支付金额：<i class="error">{{params.totalFee}}元</i></h5>
            <img v-if="!isqrCode" src="@/assets/images/wechat_pay_loading.png" class="custom_pay_wechat_qr">
            <div class="qrcode" id="qrcode" v-show="isqrCode">
            </div>
            <!-- onerror="this.src='/public/static/images/wechat_pay_loading.png'" -->
            <div class="btn_box">
              <span class="completed submit custom_pay_wechat_box_success" @click="getOrderDetail">已完成支付</span><span class="back custom_pay_wechat_box_cannel" @click="getOrderDetail">返回重新选择支付方式</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'

//import QRCode from 'qrcodejs2'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '订单支付-合晶芯城',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  components: {
  },
  data () {
    return {
      tipText: '微信',
      QRCode: null,
      params: {
        tradeNo: '',//订单编号   
        totalFee: '',//金额  
        goodsDesc: '',  //商品描述
      },
      alipay_params: {
        tradeNo: '',//订单编号   
      },
      payType: 0,
      time: -1,
      timeHtml: '',
      payFalg: false,
      isqrCode: false,
      payStatus: true,
      payStatusNum: 0,
      payId: '',
    }
  },

  created () {
    if (typeof window !== 'undefined') {
      this.payId = this.$route.query.id
      this.QRCode = require('qrcodejs2')
      //let QRCode = require('qrcodejs2')
      this.getPayOrder()
    }
  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['get_pay_order', 'wx_pay', 'alipay_pay', 'get_order_detail']),
    selectPay (type) {
      this.payType = type
    },
    async getOrderDetail () {
      let res = await this.get_order_detail({ orderSn: this.params.tradeNo })
      if (res.httpCode == 200) {
        this.payStatusNum = res.data.payStatus
        this.payFalg = false
      }
    },
    async getPayOrder () {
      let params = {
        id: this.payId
      }
      let res = await this.get_pay_order(params)
      if (res.httpCode == 200) {
        if (res.data) {
          this.params.tradeNo = res.data.orderSn
          this.alipay_params.tradeNo = res.data.orderSn
          this.params.totalFee = res.data.totalAmount
          this.payStatusNum = res.data.payStatus
          this.params.goodsDesc = '订单支付'
          this.time = parseInt(res.data.orderExpired)
          if (this.time > 0) {
            var Interval = setInterval(() => {
              var when = parseInt(this.time / 60 / 60)
              var points = parseInt(this.time / 60) % 60
              var seconds = this.time % 60
              if (when.toString().length == 1) (
                when = '0' + when
              )
              if (points.toString().length == 1) (
                points = '0' + points
              )
              if (seconds.toString().length == 1) {
                seconds = '0' + seconds
              }
              this.timeHtml = when + '时' + points + '分' + seconds + '秒'
              this.time--
              if (this.time == 0) {
                //this.payStatus = false
                clearInterval(Interval)
              }
            }, 1000);
          } else {
            this.payStatus = false
          }
        } else {
          //this.$message.error('订单不存在或已支付')
          this.$router.push({ path: '/home/order/list' })
        }
      }
    },
    async payBtn () {
      let res
      if (this.payType == 2) {
        this.tipText = '微信'
        res = await this.wx_pay(this.params);
        if (res.httpCode == 200) {
          this.payFalg = true
          this.creatQrCode(res.data);
        } else {
          this.$message.error(res.message)
        }
      } else if (this.payType == 1) {
        // let a = document.createElement('a')
        // a.href = "/alipay/pay?tradeNo=" + this.alipay_params.tradeNo
        // a.target = "_blank"
        // a.click()
        // let url = window.location.host
        this.tipText = '支付宝'
        this.$confirm("该订单是否已支付成功", "提示", {
          confirmButtonText: "支付成功",
          cancelButtonText: "支付遇到问题",
          type: "warning"
        }).then(() => {
          this.getOrderDetail()
        }).catch(() => {
          this.$router.push({ path: '/home/order/list', query: { pay_status: 0 } })
        });
        window.open("https://api.hotking.com/alipay/pay?tradeNo=" + this.alipay_params.tradeNo)
        //res = await this.alipay_pay(this.alipay_params);
      } else {
        this.$message.error('请选择支付方式')
        return
      }

    },
    creatQrCode (url) {
      if (!this.isqrCode) {
        var qrcode = new this.QRCode(document.getElementById("qrcode"), {
          text: url,
          width: 190,
          height: 190,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: this.QRCode.CorrectLevel.H
        })
        this.isqrCode = true
      }
    },
  },
  mounted () {
    if (!this.payId) {
      this.$message.error('出现错误，请移步我的订单重新进行操作')
    }
  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/cart-pay.scss";
.shoppingCart {
  padding-top: 1px;
  min-height: calc(100vh - 80px - 312px);
}
#qrcode {
  img {
    width: 208px;
    height: 208px;
    margin-top: 22px;
    margin-bottom: 12px;
  }
}
</style>