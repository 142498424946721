<template>
  <div class="nav clear">
    <div class="nav-box">
      <div class="nav-wrap clearfix">
        <div class="sort" id="productMenu" @mousemove="mousemoveFun" @click.stop="linkToProduct">
          <p class="type-text">
            <span></span>商品分类
          </p>
          <div class="navbox" v-if="falg">
            <div class="ass ass3">
              <ul class="ass_ul" v-if="productMenuIndex" @click.stop>
                <li class="ass_list" v-for="(item, index) in productMenuIndex" :key="index" @mousemove="iconFalg=index" v-show="index<7">
                  <a class="nav_menu_title">
                    <img class="icon " v-if="iconFalg==index" :src="item.image2">
                    <img class="icon" v-else :src="item.image">
                    {{item.nameCh}}
                    <i class="ass_list_right"></i>
                  </a>
                  <div class="nav_menu_box">
                    <div class="l">
                      <div class="nav_menu">
                        <div class="nav_menu_item clear">
                          <!-- <router-link :to="{
                          path: '/product/level',
                          query: { cid: item.id, cname: item.nameCh,fcid: item.id, }
                        }">
                            <h3 class="clear">
                              <img :src="item.image" />
                              {{ item.nameCh }}
                            </h3>
                          </router-link> -->
                          <ul class="clear" v-if="item.subCategory">
                            <li v-for="(i2, d2) in item.subCategory" :key="d2">
                              <router-link :to="{
                              path: '/product/level',
                              query: {
                                cid: i2.id, 
                                fcid: item.id,
                                cname: item.nameCh,
                                name: i2.nameCh
                              }
                            }">{{ i2.nameCh }}</router-link>
                              <i></i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>

        <div class="snav">
          <router-link to="/index" class="first" :class="{hover:hover.indexOf('/index')>=0}">商城首页</router-link>
          <!-- <router-link to="/product" :class="{hover:hover.indexOf('/product')>=0}">商品目录</router-link> -->
          <router-link to="/product/level" :class="{hover:hover.indexOf('/product')>=0}">产品中心</router-link>
          <router-link to="/brand" :class="{hover:hover.indexOf('/brand')>=0}">品牌专区</router-link>
          <router-link to="/bom" :class="{hover:hover.indexOf('/bom')>=0}">BOM配单</router-link>
          <router-link to="/sample" :class="{hover:hover.indexOf('/sample')>=0}">样品中心</router-link>
          <!-- <router-link :to="{path:'/industry',query:{cid:35}}" :class="{hover:hover.indexOf('/industry')>=0}">新闻资讯</router-link> -->
          <router-link to="/service" :class="{hover:hover.indexOf('/service')>=0}">服务中心</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
  name: 'pNav',
  data () {
    return {
      hover: '',
      falg: false,
      iconFalg: -1,
    }
  },
  computed: {
    ...mapState('lxr', ['productMenuIndex'])
  },
  methods: {
    linkToProduct () {
      this.$router.push({
        path: '/product/level'
      })
    },
    mousemoveFun () {
      if (this.hover != '/specifica') {
        this.falg = true
      }
    }
  },
  mounted () {
    this.hover = this.$route.path
  },
  watch: {
    '$route.path' (val) {
      this.hover = val
    },
    '$route' () {
      this.falg = false
    }
  }
}
</script>
<style lang="scss" scoped>
#productMenu {
  &:hover {
    .navbox {
      display: block;
    }
  }
}
</style> 

