<template>
  <div class="r userCenter_container_box favorite_content">
    <h2>我的收藏</h2>
    <div class="userCenter_container" v-loading='isloadings'>
      <div class="favorite_cate clear" v-if="cateList.length>0&&!isloadings">
        <span class="l">收藏分类</span>
        <div class="l">
          <span :class="{active: cateCurrent == 0}" data-id="0" @click="getFavoritesList('',0)">全部({{cataTotal}})</span>

          <span v-for="(item,index) in cateList" :key="index" @click="getFavoritesList(item.name,index+1)" :class="{active: cateCurrent == (index+1)}">{{item.name}}({{item.count}})</span>
        </div>
      </div>
      <div class="order-tatal clear" v-if="!isloadings">
        <span class="l">符合条件的共<i id="total">{{total}}</i>个收藏</span>
      </div>
      <ul>
        <template v-if="favoriteList.length>0">
          <li class="clear search_product_box" v-for="(item,index) in favoriteList" :key="index">
            <div class="s_top clear">
              <span class="l">{{item.createTime}}</span>
              <span class="r" @click="getFavoritesDel(item.id,index)">取消收藏</span>
            </div>
            <div class="s_center clear">
              <img class="l" v-if="item.goodsImg" :src="item.goodsImg" :alt="item.goodsName+' '+item.packageSpecification" id="id_goods_img">

              <img class="l" v-else src="@/assets/images/goods/default_img.png" :alt="item.goodsName+' '+item.packageSpecification" id="id_goods_img">

              <div class="l product_box_center">
                <h3>
                  <router-link :to="{path:'/product/detail',query:{id:item.id}}" target="_blank">{{item.goodsName}}</router-link>
                  <i class="zk" v-if="item.is_promotion == 0 && item.is_sale_v > 0">{{item.is_sale_v}}折</i>
                  <i class="tj" v-if="item.is_special==1">本月特价</i>
                </h3>
                <div class="l">
                  <p><a :href="item.info_url"><em>制造商料号：</em><i>{{item.materialSn | macSlice}}</i></a>
                  </p>
                  <p><em>参 数 规 格：</em><i>{{item.specifications}}</i></p>
                  <p><em>封 装 规 格：</em><i :title="item.packageSpecification">{{item.packageSpecification}}</i></p>

                </div>
                <div class="l">
                  <p><em>商 品 类 别：</em><i>{{item.categoryName}}</i></p>
                  <p><em>品 牌：</em><i>{{item.brandName}}</i></p>
                  <p><em>包 装 数 量：</em><i>{{item.packCount}} pcs</i></p>
                </div>

                <div class="r">
                  <p><em>库 存 数 量：</em><i>{{item.storeCount}} pcs</i></p>
                  <p><em>交 货 周 期：</em><i>{{item.deliveryDate}}</i></p>
                  <p><em>最小起订量：</em><i>{{item.setUp}} pcs</i></p>
                </div>
              </div>
              <div class="r product_box_right">
                <div class="btnr-t">
                  <span class="see"><i class="dj">￥{{item.priceTemplate[0].price}}/pcs</i>起</span>
                  <i class="xl"></i>
                  <div class="seePriceD_box">
                    <em></em>
                    <table>
                      <tbody>
                        <tr>
                          <th>采购数量</th>
                          <th>单价</th>
                          <th v-if="item.compare == 1">原价</th>
                        </tr>
                        <template v-if="item.priceTemplate.length >0">
                          <tr v-for="(v,indexp) in item.priceTemplate" :key="indexp">
                            <td>{{v.num}}+</td>
                            <td>￥{{v.price }}/pcs</td>
                            <td class="pricesOld" v-if="v.compare==1">
                              ¥{{v.oldPrice}}/pcs</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <router-link class="add_cart" :to="{path:'/product/detail',query:{id:item.goodsId}}" target="_blank">查看详情</router-link>
              </div>
            </div>
            <div class="s_bottom clear">
              <span class="l" @click="remarkShowFun(item.remarks,item.id,index)">备注信息：<i></i><em>{{item.remarks?item.remarks:''}}</em></span>
            </div>

          </li>
        </template>
        <div class="nodeta" v-if="favoriteList.length==0&&!isloadings">
          <img src="@/assets/images/nosc.png">
          <p>暂无收藏</p>
        </div>
      </ul>
      <div class="page-wrap" v-if="total>10">
        <el-pagination background hideOnSinglePage layout="prev, pager, next" :total="total" @current-change="currentChange" :page-size="params.pageSize" :current-page="params.pageNum"></el-pagination>
      </div>
    </div>

    <transition name="el-fade-in">
      <div class="mask-layout" v-if="remarkShow">
        <div class="pop_up_box remark_up_box">
          <div class="pop_up_title clear">
            <h3 class="l"> 备注信息 </h3> <span class="r" @click="cancel"></span>
          </div>
          <div class="remark_box">
            <textarea maxlength="60" placeholder="请输入60个字以内的备注信息" v-model.trim="saveRemarks.remarks"></textarea>
            <div class="button_box"><span class="cancel" @click="cancel">取消</span><span class="submit" @click="setFavoritesRemarks()">确定</span></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-我的收藏',
    }
  },
  data () {
    return {
      total: 0,
      isloadings: true, //loading
      cate_id: 0, //默认全部
      currentPage: 1, //当前页面
      favoriteList: [], //收藏列表
      cateList: [],
      cataTotal: 0,
      cateCurrent: 0,
      price_items: [], //价格阶梯
      priceShow: false,
      current: 0, //阶梯显示
      remarkShow: false, //备注弹框
      editG_id: "",
      isCanselTip: false, //取消弹框
      currentGoods_id: "", //当前要取消收藏的商品id
      addCartShow: false,
      addCartList: '',
      discNum: '',
      pcsNum: '',
      goods_price: '',
      formatPrice: '',
      sale_total: '',
      saveRemarks: {
        remarks: '',
        id: '',
      },
      setId: Number,
      params: {
        "categoryName": '',
        "pageNum": 1,
        "pageSize": 10,
      }
    }
  },

  created () {

  },
  computed: {
    //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['get_favorites_type', 'get_favorites_list', 'get_favorites_del', 'set_favorites_remarks']),
    //加载收藏分类
    async getFavoritesType () {
      this.cataTotal = 0
      let res = await this.get_favorites_type()
      if (res.httpCode == 200) {
        this.cateList = res.data;
        this.cateList.forEach(item => {
          this.cataTotal += parseInt(item.count)
        });
      }
    },
    // 关闭弹窗
    cancel () {
      this.remarkShow = false
    },
    //获取收藏列表
    async getFavoritesList (name, index) {
      this.cateCurrent = index
      if (name != this.params.categoryName) {
        this.params.pageNum = 1
      }
      this.params.categoryName = name
      let res = await this.get_favorites_list(this.params)
      if (res.httpCode == 200) {
        this.isloadings = false //loading
        this.total = res.data.total
        this.favoriteList = res.data.records
        this.favoriteList.forEach(item => {
          item.createTime = item.createTime.substr(0, 16)
          if (item.priceTemplate) {
            item.priceTemplate = JSON.parse(item.priceTemplate)
          }
        });
      }
    },

    //删除收藏列表
    async getFavoritesDel (id, idx) {
      let params = {
        id: id
      }
      let res = await this.get_favorites_del(params)
      if (res.httpCode == 200) {
        this.getFavoritesType()
        this.total -= 1
        this.$message.success('取消成功')
        this.favoriteList.splice(idx, 1)
      }
    },

    //保存
    async setFavoritesRemarks () {
      if (this.saveRemarks.remarks.length == 0) {
        return this.$message.error('请输入备注信息')
      }
      let res = await this.set_favorites_remarks(this.saveRemarks)
      if (res.httpCode == 200) {
        this.$message.success('备注成功')
        this.favoriteList[this.setId].remarks = this.saveRemarks.remarks
        this.remarkShow = false
      } else {
        this.$message.error(res.message)
      }
    },

    //打开编辑
    remarkShowFun (remarks, id, idx) {
      this.saveRemarks.remarks = remarks
      this.saveRemarks.id = id
      this.setId = idx
      this.remarkShow = true
    },
    currentChange (e) {
      this.params.pageNum = e
      this.getFavoritesList(this.params.categoryName, this.cateCurrent)
    },


  },
  //过滤器格式化
  filters: {
    macSlice: function (val) {
      if (val) {
        return val.length > 18 ? val.slice(0, 18) + '...' : val;
      }
      return '';
    },
    Format: function (val) {
      if (val) {
        return priceFormat(val);
      }
    }
  },
  mounted () {
    this.getFavoritesType()
    this.getFavoritesList('', 0)
  },
}
</script>
<style lang="scss">
// @import "~@/assets/css/member-myFavorite.scss";
</style>


