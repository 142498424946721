<template>
  <div class="r userCenter_container_box">
    <h2>系统消息</h2>
    <div class="userCenter_container">
      <div class="systemM_title_bg">
        <div class="systemM_title">
          <span>消息状态</span>
          <a v-for="(item, index) in nav" :key="index" :class="{active:navIdx==index}" href="javascript:;" @click="navClick(item.val,index)">{{item.text}}</a>
        </div>
      </div>

      <div class="order-tatal clear">
        <span class="l">符合条件的共<i id="total">{{total}}</i>条数据</span>
      </div>

      <div class="systemM_btn">
        <div class="all_selected l">
          <i class="checkbox" :class="{active:flagAll}" @click="flagAllClick"></i>全选
        </div>
        <span class="r del" id="all_delete" @click="operationMessage(0)"><i></i>删除</span>
        <span class="r all" id="all_read" @click="operationMessage(1)"><i></i>标为已读</span>
      </div>
      <ul class="systemM_body" v-loading="isloading">
        <template v-if="messageList&&messageList.length>0">
          <li class="clear" v-for="(item, index) in messageList" :key="index">
            <span class="one">
              <i class="checkbox" :class="{active:messageFalg[index]}" @click="falgClick(index)"></i>
              <em @click="messageDetail(item,index)">{{item.title}}<i title="未读" v-if="item.status==0"></i></em>
            </span>
            <span class="two">{{item.sendTime | calcTime}}</span>
          </li>
        </template>
        <div class="nodata" v-if="messageList&&messageList.length==0">
          <img src="~@/assets/images/nomessage.png" alt="">
          <p>暂无消息</p>
        </div>
      </ul>
      <!-- 分页 -->
      <div class="page-wrap">
        <el-pagination background hideOnSinglePage @current-change="currentChange" layout="prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>
    <transition name="el-fade-in">
      <div class="mask_layout1" v-if="messageDetailFalg">
        <!-- 查看系统消息 -->
        <div class="pop_up_box see_systemM_pop">
          <input name="mid" type="hidden" value="725">
          <div class="pop_up_title clear">
            <h3 class="l">系统消息</h3> 
            <span class="r" @click="messageDetailFalg=false"></span>
          </div>
          <!-- 内容区域 -->
          <div class="see_systemM">
            <h4>消息标题 ：{{detail.title}}</h4>
            <div class="message_contain">
              <span>{{detail.message}}</span>
              <p class="message_time">消息时间 ：{{detail.sendTime | calcTime}}</p>
            </div>
            <div class="see_systemM_btm">
              <!-- <span class="submit delete">删除此消息</span> -->
              <span class="submit cancel" @click="messageDetailFalg=false">关闭</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-系统消息',
    }
  },
  data () {
    return {
      navIdx: 0,
      nav: [
        {
          text: '全部消息',
          val: ''
        }, {
          text: '未读消息',
          val: 0
        }, {
          text: '已读消息',
          val: 1
        }
      ],
      params: {
        "pageNum": 1,
        "pageSize": 10,
        "status": "",//查看状态(0未查看|1已查看) ,
      },
      total: 0,
      messageList: null,
      messageDetailFalg: false,
      detail: null,
      isloading: true,
      messageFalg: [],
      flagAll: false,
    }
  },

  created () {

  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['get_message_list', 'update_message', 'del_message']),
    falgClick (index) {
      this.$set(this.messageFalg, index, !this.messageFalg[index])
    },
    flagAllClick () {
      if (this.flagAll) {
        this.flagAll = false
        this.messageFalg.forEach((item, index) => {
          this.$set(this.messageFalg, index, false)
        });
      } else {
        this.flagAll = true
        this.messageFalg.forEach((item, index) => {
          this.$set(this.messageFalg, index, true)
        });
      }
    },
    navClick (val, idx) {
      this.navIdx = idx
      this.params.status = val
      this.getMessageList()
    },
    messageDetail (item, index) {
      this.detail = item
      this.messageDetailFalg = !this.messageDetailFalg
      if (item.status == 0) {
        this.updateMessage(item.id, index)
      }
    },
    async getMessageList () {
      let res = await this.get_message_list(this.params)
      if (res.httpCode == 200) {
        if (res.data) {
          this.messageList = res.data.records
          if (res.data && this.messageList.length > 0) {
            this.messageList.forEach((item, index) => {
              this.messageFalg.push(false)
            });
          }
          this.isloading = false
          this.total = res.data.total
        }
      }
    },
    //更新单个消息
    async updateMessage (id, index) {
      let res = await this.update_message({ ids: id })
      if (res.httpCode == 200) {
        this.messageList[index].status = 1
      }
    },
    //更新全部消息
    async operationMessage (type) {
      let ids = ''
      this.messageFalg.forEach((item, index) => {
        if (item) {
          ids += this.messageList[index].id + ','
        }
      });
      ids = ids.substr(0, ids.length - 1)
      let res
      if (type == 1) {
        res = await this.update_message({ ids: ids })
      } else {
        res = await this.del_message({ ids: ids })
      }
      if (res.httpCode == 200) {
        this.$message.success('更新成功')
        this.flagAll = false
        this.messageFalg = []
        this.getMessageList()
      }
    },
    currentChange (e) {
      this.params.pageNum = e
      this.getMessageList()
    },
  },
  mounted () {
    this.getMessageList()
  },
  filters: {
    calcTime (val) {
      return val.substr(0, val.length - 5)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-systemMessage.scss";
</style>


