var calUtil = {
  showYear: 2016,
  showMonth: 1,
  showDays: 1,
  eventName: "load",
  times: "",
  init: function (signList) {
    calUtil.setMonthAndDay();
    calUtil.draw(signList);
    // calUtil.bindEnvent(calUtil.showYear, calUtil.showMonth)
  },
  draw: function (signList) {
    var str = calUtil.drawCal(calUtil.showYear, calUtil.showMonth, signList);
    $("#calendar").html(str);
    var calendarName = calUtil.showYear + "年" + calUtil.showMonth + "月";
    $(".calendar_month_span").html(calendarName)
  },
  // bindEnvent: function (year, month) {
  //   $(".calendar_month_prev").click(function () {
  //     calUtil.eventName = "prev";
  //     var btnMonth = parseInt(month) - 1;
  //     if (btnMonth == 0) {
  //       btnMonth = 12;
  //       year -= 1
  //     }
  //     calenderTimeAjax(year + "-" + btnMonth)
  //   });
  //   $(".calendar_month_next").click(function () {
  //     if ($(this).hasClass("disabled")) {
  //       return
  //     } else {
  //       calUtil.eventName = "next";
  //       var btnMonth = parseInt(month) + 1;
  //       if (btnMonth == 13) {
  //         btnMonth = 1;
  //         year += 1
  //       }
  //       calenderTimeAjax(year + "-" + btnMonth)
  //     }
  //   })
  // },
  setMonthAndDay: function () {
    switch (calUtil.eventName) {
      case "load":
        var current = new Date();
        calUtil.showYear = current.getFullYear();
        calUtil.showMonth = current.getMonth() + 1;
        break;
      case "prev":
        var nowMonth = $(".calendar_month_span").html().split("年")[1].split("月")[0];
        calUtil.showMonth = parseInt(nowMonth) - 1;
        if (calUtil.showMonth == 0) {
          calUtil.showMonth = 12;
          calUtil.showYear -= 1
        }
        break;
      case "next":
        var nowMonth = $(".calendar_month_span").html().split("年")[1].split("月")[0];
        calUtil.showMonth = parseInt(nowMonth) + 1;
        if (calUtil.showMonth == 13) {
          calUtil.showMonth = 1;
          calUtil.showYear += 1
        }
        break
    }
  },
  getDaysInmonth: function (iMonth, iYear) {
    var dPrevDate = new Date(iYear, iMonth, 0);
    return dPrevDate.getDate()
  },
  bulidCal: function (iYear, iMonth) {
    var aMonth = new Array();
    aMonth[0] = new Array(7);
    aMonth[1] = new Array(7);
    aMonth[2] = new Array(7);
    aMonth[3] = new Array(7);
    aMonth[4] = new Array(7);
    aMonth[5] = new Array(7);
    aMonth[6] = new Array(7);
    var dCalDate = new Date(iYear, iMonth - 1, 1);
    var iDayOfFirst = dCalDate.getDay();
    var iDaysInMonth = calUtil.getDaysInmonth(iMonth, iYear);
    var iVarDate = 1;
    var d, w;
    aMonth[0][0] = "日";
    aMonth[0][1] = "一";
    aMonth[0][2] = "二";
    aMonth[0][3] = "三";
    aMonth[0][4] = "四";
    aMonth[0][5] = "五";
    aMonth[0][6] = "六";
    for (d = iDayOfFirst; d < 7; d++) {
      aMonth[1][d] = iVarDate;
      iVarDate++
    }
    for (w = 2; w < 7; w++) {
      for (d = 0; d < 7; d++) {
        if (iVarDate <= iDaysInMonth) {
          aMonth[w][d] = iVarDate;
          iVarDate++
        }
      }
    }
    return aMonth
  },
  ifHasSigned: function (signList, day) {
    var signed = false;
    $.each(signList, function (index, item) {
      if (item.date == day) {
        signed = true;
        return false
      }
    });
    return signed
  },
  drawCal: function (iYear, iMonth, signList) {
    var newDate = new Date();
    var curr_year = newDate.getFullYear();
    var curr_month = newDate.getMonth() + 1;
    var curr_day = newDate.getDate();
    var myMonth = calUtil.bulidCal(iYear, iMonth);
    var htmls = new Array();
    htmls.push("<div class='sign_main' id='sign_layer'>");
    htmls.push("<div class='sign_succ_calendar_title clear'>");
    if (curr_year == iYear && iMonth >= curr_month) {
      htmls.push("<div class='calendar_month_next disabled fr' title='下月'></div>")
    } else {
      htmls.push("<div class='calendar_month_next fr' title='下月'></div>")
    }
    htmls.push("<div class='calendar_month_prev fr' title='上月'></div>");
    htmls.push("<div class='calendar_month_span fl' data-year='" + iYear + "' data-month='" + iMonth +
      "'></div>");
    htmls.push("</div>");
    htmls.push("<div class='sign' id='sign_cal'>");
    htmls.push(
      "<table><tr class='thead'><td>日</td><td>一</td><td>二</td><td>三</td><td>四</td><td>五</td><td>六</td></tr>"
    );
    var d, w;
    for (w = 1; w < 7; w++) {
      htmls.push("<tr>");
      for (d = 0; d < 7; d++) {
        var ifHasSigned = calUtil.ifHasSigned(signList, myMonth[w][d]);
        if (ifHasSigned) {
          htmls.push("<td class='on'><span>" + (!isNaN(myMonth[w][d]) ? myMonth[w][d] : " ") +
            "</span></td>")
        } else {
          if (curr_year == iYear && curr_month == iMonth && curr_day == (!isNaN(myMonth[w][d]) ?
              myMonth[w][d] : " ")) {
            htmls.push("<td class='today'><span>" + (!isNaN(myMonth[w][d]) ? myMonth[w][d] : " ") +
              "</span></td>")
          } else {
            htmls.push("<td>" + (!isNaN(myMonth[w][d]) ? myMonth[w][d] : " ") + "</td>")
          }
        }
      }
      htmls.push("</tr>")
    }
    htmls.push("</table>");
    htmls.push("</div>");
    htmls.push("</div>");
    return htmls.join("")
  }
};


export {
  calUtil
}