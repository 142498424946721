<template>
  <div class="r userCenter_container_box">
    <h2 class="text_r">
      <span class="l">会员规则</span>
      <router-link class="r" to="/home/user">返回</router-link>
    </h2>
    <div class="rule_main">
      <div class="rule_present rule_div">
        <h3>会员介绍</h3>
        <p>
          合晶芯城会员共分6级，具体为：铜牌会员、银牌会员、黄金会员、白金会员、钻石会员、皇冠会员。级别由系统根据“经验值”自动的积累自动升级，无需另行申请升级。
          “经验值”根据您的消费额度、参与商城活动、小游戏、等进行累加，一般情况下，每消费1元即可获得1点“经验值”，在订单确认时经验值通过系统自动发放，如有其它问题请及时与商城客服进行联系。
        </p>
      </div>

      <div class="rule_rank rule_div">
        <h3>等级与福利</h3>
        <div class="rule_table">
          <p class="clear rule_tbale_th">
            <span class="one l">会员等级</span>
            <span class="two l">勋章</span>
            <span class="three l">升级条件</span>
            <span class="l four">会员福利</span>
          </p>
          <p class="clear rule_table_tr" v-for="(item,index) in list" :key="index">
            <span class="one l">{{item.levelTitle}}</span>
            <span class="two l"><img :src="item.levelIcon"></span>
            <span class="three l">注册用户未在商城消费过的用户，经验值为{{item.upgradingConditions}}的用户</span>
            <span class="l four last">会员打 {{item.piontCoefficient}} 折</span>
          </p>
          <!--           
          <p class="clear rule_table_tr">
            <span class="one l">银牌会员</span>
            <span class="two l"><img src="@/assets/images/member/rank_2.png"></span>
            <span class="three l">经验值≥1的用户</span>
            <span class="l four last">每月签到≥10天，赠送会员积分100分；首次升级赠送100积分</span>
          </p>
          <p class="clear rule_table_tr">
            <span class="one l">黄金会员</span>
            <span class="two l"><img src="@/assets/images/member/rank_3.png"></span>
            <span class="three l">经验值≥2000的用户</span>
            <span class="l four last">每月签到≥15天，赠送会员积分500分、首次升级赠送200积分</span>
          </p>
          <p class="clear rule_table_tr">
            <span class="one l">白金会员</span>
            <span class="two l"><img src="@/assets/images/member/rank_4.png"></span>
            <span class="three l">经验值≥8000的用户</span>
            <span class="l four">专属客服；每月签到≥15天，赠送会员积分1000分； ¥15无门槛优惠卷一张、首次升级赠送1000积分</span>
          </p>
          <p class="clear rule_table_tr">
            <span class="one l">钻石会员</span>
            <span class="two l"><img src="@/assets/images/member/rank_5.png"></span>
            <span class="three l">经验值≥20000的用户</span>
            <span class="l four">专属客服；优先发货；每月签到≥18天，赠送会员积分1500分； ¥30无门槛优惠卷一张；首次升级赠送2000积分</span>
          </p>
          <p class="clear rule_table_tr">
            <span class="one l">皇冠会员</span>
            <span class="two l"><img src="@/assets/images/member/rank_6.png"></span>
            <span class="three l">经验值≥100000的用户</span>
            <span class="l four">专属客服；优先发货；每月签到≥20天，赠送会员积分2000分； ¥50无门槛优惠卷一张、首次升级赠送5000积分</span>
          </p> -->
        </div>
      </div>

      <div class="rule_integral rule_div">
        <h3>会员积分</h3>
        <p>1.会员积分：用户通过在使用“合晶芯城”平台服务同时，商城为感谢您对我们服务的认可而发放的会员积分，一般情况下会通过以下形式进行发放，用户下单购买商品、签到、参与商城活动、小游戏，等形式进行发放。</p>
        <p>2.会员积分使用：用户可到积分商城选择心仪礼品、优惠卷、购物卷、等丰富的奖品进行兑换。</p>
        <p>3.获得积分条件：</p>
        <p>3.1）购物获得；</p>
        <p>3.2）通过参加商城活动获得；</p>
        <p>3.3）签到、小游戏获得；</p>
        <p>积分=订单金额*积分系数 ，积分获取基准系数1，不同的会员等级积分系数不同。</p>
        <p>4.积分不可兑现和转让；</p>
        <p>5.涉嫌刷积分、违规操作，导致积分发生异常，合晶芯城有权对帐户的积分进行清零处理。</p>
        <p>6.法律允许范围内，未尽事宜和最终解释权归合晶芯城所有。</p>
      </div>

      <div class="rule_courtesy rule_div">
        <h3>会员等级礼遇</h3>
        <p>1.会员等级专属勋章；</p>
        <p>2.会员等级积分奖励；</p>
        <p>3.会员升级获赠大额积分；</p>
        <p>4.会员等级的积分系数；</p>
        <p>5.每月赠送无限制品牌优惠卷；</p>
        <p>6.会员等级专属客服服务；</p>
        <p>7.会员等级专属优先发货服务；</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-会员规则',
    }
  },
  data () {
    return {
      list: null,
    }
  },

  created () {
    this.levelList()
  },
  computed: {
    //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['level_list']),
    async levelList () {
      let res = await this.level_list()
      if (res.httpCode == 200) {
        this.list = res.data
      }
    },
  },
  mounted () {

  },
}
</script>
<style lang="scss" scoped>
.rule_main {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px 30px 40px 30px;
}

.rule_div {
  h3 {
    color: #FF8A14;
    font-size: 14px;
    font-weight: bold;
    margin: 30px 0 20px 0;
  }
  p {
    color: #1e1f30;
    font-size: 12px;
    line-height: 26px;
  }
}

.rule_table {
  p {
    border-bottom: 1px solid #e8e8e8;
    color: #1e1f30;
    .one {
      width: 100px;
    }
    .two {
      width: 100px;
    }
    .three {
      width: 450px;
    }
    .four {
      width: 200px;
      line-height: 28px;
    }
    .last {
      line-height: 60px;
    }
  }
  .rule_tbale_th {
    color: #9fafbc;
    padding-bottom: 10px;
  }
  .rule_table_tr {
    height: 60px;
    line-height: 60px;
    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }
    .four {
      color: #FF8A14;
    }
  }
}
</style>


