<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
    <transition name="el-fade-in">
      <cartDialog v-if="cartFlag"></cartDialog>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import cartDialog from '@/components/public/cart-dialog.vue'
export default {
  name: 'app',
  components: {
    cartDialog
  },
  data () {
    return {
      show: true,
      isRouterAlive: true
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['cartFlag'])
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  methods: {
    ...mapActions("lxr", ["get_cart_header", "get_cart_list"]),

    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    //获取购物车header
    async getCartHeader () {
      let res = await this.get_cart_header();
      if (res.httpCode == 200) {
        localStorage.setItem('cartHeader', res.data)
        localStorage.setItem("timestamp", new Date().getTime());
        this.getCartList()
      }
    },
    async getCartList () {
      let res = await this.get_cart_list()
    },
  },
  mounted () {
    let time = 1000 * 60 * 60 * 24 * 0.5;
    let oldTimestamp = localStorage.getItem('timestamp');//以前存的时间戳
    let nowTimestamp = new Date().getTime();//当前时间戳
    let sumTimestamp = oldTimestamp + time;
    if (nowTimestamp > sumTimestamp) {
      localStorage.removeItem('cartHeader');
    }
    let cartHeader = localStorage.getItem('cartHeader');
    if (!cartHeader) {
      this.getCartHeader()
    } else {
      this.getCartList()
    }
  },
}
</script>
<style lang="scss">
@import "~@/assets/common/css/common-all.css";
@import "~@/assets/css/login-index.scss";
</style>
