<template>
  <div class="main clear">
    <template v-if="newDetail">
      <!-- <div class="box-pos">
        <router-link :to="{path:'/industry',query:{cid:newDetail.cid}}">返回列表</router-link>
        <span>&gt;</span>
        <a href="javascript:;">文章详情</a>
      </div> -->
      <div class="box clear">
        <div class="box-left">
          <h1 class="box-title" id="title">{{newDetail.title}}</h1>
          <div class="box-cen">
            <span class="box-autor" id="autor">HOTKING</span>
            <span class="box-time" id="time">{{newDetail.updateTime.substr(0,19)}}</span>
            <a v-for="(item, index) in newDetail.keywords" :key="index" :href="item.tagLink">
              <span v-if="index==0" class="I">{{item.tag}}</span>
              <span v-else-if="index==1" class="C">{{item.tag}}</span>
              <span v-else-if="index==2" class="J">{{item.tag}}</span>
              <span v-else-if="index==3" class="K">{{item.tag}}</span>
              <span v-else-if="index==4" class="E">{{item.tag}}</span>
              <span v-else class="F">{{item.tag}}</span>
            </a>
          </div>
          <div class="box-desc" id="desc">
            <p>{{newDetail.description}}</p>
          </div>
          <div id="desc" v-html="newDetail.content">
          </div>
          <div class="box-content" id="content">
            <!-- 编辑器内容 -->
          </div>
          <div class="box-page clear">
            <div class="box-prev-page" id="prev-page">
              <router-link :to="{path:'/news/detail',query:{id:next.id}}" class="box-prev-page-t">上一篇</router-link>
              <router-link :to="{path:'/news/detail',query:{id:next.id}}" class="box-prev-page-b">{{next.title}}</router-link>
            </div>
            <div class="box-next-page" id="next-page">
              <router-link :to="{path:'/news/detail',query:{id:next.id}}" class="box-prev-page-t">下一篇</router-link>
              <router-link :to="{path:'/news/detail',query:{id:last.id}}" class="box-prev-page-b">{{last.title}}</router-link>
            </div>
          </div>
          <div class="box-ad clear">
          </div>
        </div>
        <div class="box-right">
          <div class="box-list" id="hot-time" v-if="dayNews&&dayNews.length>0">
            <div class="box-list-title">
              <i></i>
              <span>每日行情</span>
            </div>
            <ul class="box-list-li">
              <li v-for="(item, index) in dayNews" :key="index" v-show="index<10">
                <router-link :to="{path:'/product/detail',query:{id:item.id}}" class="box-prev-page-t">
                  <span>{{item.title}}</span>
                  <span class="r"><i class="warning">￥{{item.price}}</i>起</span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="box-list" id="hot-ready" v-if="hot&&hot.length>0">
            <div class="box-list-title">
              <i></i>
              <span>相关阅读</span>
            </div>
            <ul class="box-list-li">
              <li v-for="(item, index) in hot" :key="index" v-show="index<10">
                <router-link :to="{path:'/news/detail',query:{id:item.id}}"><i class="sort">{{index+1}}</i>{{item.title | filterFun}}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  inject: ['reload'],
  metaInfo () {
    return {
      title: this.newDetail ? this.newDetail.title : '合晶芯城 - 新闻详情',
      meta: [
        {
          name: 'keywords',
          content: this.newKeyword ? this.newKeyword : '合晶芯城,二三极管,电阻电容,集成电路,元器件商城,电子元件采购平台'
        },
        {
          name: 'description',
          content: this.newDetail ? this.newDetail.description : '合晶芯城（www.hotking.com）是国内原厂直营二三极管、电阻电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  asyncData ({ store, route }) {
    let get_news_detail = store.dispatch('lxr/get_news_detail', {
      "id": route.query.id,
    });
    return get_news_detail;
  },
  components: {
  },
  data () {
    return {
    }
  },
  created () {

  },
  computed: {
    ...mapState('lxr', ['newDetail', 'next', 'last', 'hot', 'dayNews', 'newsNavIdx', 'newType', 'newTypeName', 'newKeyword'])
  },
  methods: {
    ...mapActions('lxr', ['get_news_detail']),
    ...mapMutations('lxr', ['GET_NEWS_DETAIL']),
    async getNewsDetail (id) {
      let res = await this.get_news_detail({
        id: id
      })
    },

  },
  mounted () {
    if (!this.newDetail) {
      this.getNewsDetail(this.$route.query.id)
    }
  },
  filters: {
    filterFun: function (value) {
      if (value && value.length > 20) {
        value = value.substring(0, 20) + '...';
      }
      return value;
    }
  },
  watch: {
    $route () {
      this.getNewsDetail(this.$route.query.id)
    }
  },
  beforeDestroy () {
    this.GET_NEWS_DETAIL('')
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/news-detail.scss";
</style>