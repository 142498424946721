<template>
  <div class="banxin shoppingCart clear" v-loading="isloading" style="min-height: 700px">
    <!-- 购物车为空 -->
    <div class="cart_box_null" v-if="!isloading&&cartList.length==0">
      <div>
        <img src="@/assets/images/cart/cart_null.png" width="162">
        <p>购物车内暂时没有商品，<router-link to="/product/level">继续逛逛</router-link>
        </p>
      </div>
    </div>
    <div class="cart_box" v-if="cartList.length>0">
      <div class="tTitle">
        全部商品({{cartList.length}})
        <span>温馨提示：产品是否购买成功，以最终下单为准哦，请尽快结算</span>
        <router-link class="fr" to="/product/level">继续购物 >></router-link>
      </div>
      <table>
        <tbody>
          <tr :class="{active:selectAll}">
            <th class="select">
              <em id="custom_select_all_event" @click="allSelect"></em>
            </th>
            <th class="three">
              商品信息
            </th>
            <th class="last">我的编号</th>
            <th>库存</th>
            <th>单价（含增值税）</th>
            <th>购买数量</th>
            <th class="last">金额（含增值税）</th>
            <th class="operation">操作</th>
          </tr>
          <tr class="hkt_event_selected" v-for="(item, index) in cartList" :key="index" :class="{active:selectId[index]}">
            <td class="select hkt_event_selected_items"><em @click="selectGoods(item.id,item.goodsId,index,2)"></em></td>
            <td class="three">
              <div class="tip" v-if="item.isSample==1"></div>
              <img v-if="item.goodsImage" :src="item.goodsImage" />
              <img v-else src="@/assets/images/goods/default_img.png" />
              <p>
                <router-link :to="{path:'/product/detail',query:{id:item.goodsId}}" target="_blank">{{item.goodsName}}</router-link>
              </p>
              <p>型号：{{item.customerSn}}</p>
              <p>品牌：{{item.brandName}}</p>
            </td>
            <td class="last">
              <input type="text" maxlength="20" value="" class="custom_edit_customer_sn" placeholder="输入10位字母或数字" v-model="item.customerSnEdit" @blur="updateNum(item,index)">
              <!-- {{item.customerSn}} -->
            </td>
            <td>有货</td>
            <td>
              <div class="price">
                <span>￥{{item.calcPrice}}<i></i></span>
                <div class="priceTemplate">
                  <em></em>
                  <table>
                    <tbody>
                      <tr>
                        <th>采购数量</th>
                        <th>单价</th>
                      </tr>
                      <template v-if="item.priceTemplate.length >0">
                        <tr v-for="(v,indexp) in item.priceTemplate" :key="indexp">
                          <td>{{v.num}}+</td>
                          <td :class="{current:item.calcPrice==v.price}">￥{{v.price }}/pcs</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
            <td class="five">
              <template v-if="item.isSample==1">
                {{item.stepSample}} pcs
              </template>
              <!-- <template >
                <input type="text" value="" maxlength="7" :min="item.setUp" class="custom_edit_goods_num" v-model.trim="item.goodsNum" :id="item.goodsNum" ref="dataInfo" oninput="value=value.replace(/[^\d]/g,'')" @blur="calcSum(item,index)">pcs
              </template> -->
              <div class="number-box" v-else>
                <input class="buy-num" :min="item.packCount" v-model.trim="item.goodsNum" :id="item.goodsNum" maxlength="7" onkeyup="value=value.replace(/[^\d]/ig,'')" @blur="calcSum(item,index)"><span class="add" @click="add($event,item,index)">+</span><span class="sub" @click="sub($event,item,index)">-</span>
              </div>
            </td>
            <!-- <td data-event="amount">￥<span ref="goodPrice" v-if="item.isSample==1">0</span><span ref="goodPrice" v-else>{{item.dPrice}}</span></td> -->
            <td data-event="amount">￥<span ref="goodPrice">{{item.dPrice}}</span></td>
            <td class="operation">
              <a href="javascript:;" @click="getFavorites(item)">收藏</a>
              <a href="javascript:;" @click="del(item.goodsId)">删除</a>
            </td>
          </tr>

        </tbody>
      </table>
      <div class="cart_oprate clear">
        <div class="l">
          <em @click="allSelect" :class="{active:selectAll}"></em>全选
          <span id="custom_delete_event" @click="delCartIds">删除选中的商品</span>
          <span id="custom_export_event" @click="download">导出至Excel</span>
        </div>
        <div class="r">
          <span class="discount l">已选中<i class="custom_select_total">{{selectTotal}}</i>种商品&nbsp;&nbsp;&nbsp;共：(未含运费)：</span><i class="custom_cart_total l" ref="sumPrice">￥{{sumPrice}}</i>
          <span class="generateOrder" @click="linkConfirmOrder">去结算</span>

        </div>
      </div>
    </div>

    <div class="guessYouL_box" v-if="guesgsList">
      <h2>猜你喜欢
        <div class="page" v-if="total>=4">
          <a class="pre" href="javascript:;" v-show="params.pageNum>1" @click="getGuesgsList(1)"></a>
          <a class="next" href="javascript:;" v-show="params.pageNum<pageTotal" @click="getGuesgsList(2)"></a>
        </div>
      </h2>
      <div class="guessYouL_contain">
        <div class="guessYouL_div">
          <ul class="clear">
            <li v-for="(item, index) in guesgsList" :key="index">
              <img v-if="item.goodsImg" :src="item.goodsImg">
              <img v-else src="@/assets/images/goods/default_img.png">
              <router-link :to="{path:'/product/detail',query:{id:item.id}}">
                <h3>{{item.name}}</h3>
              </router-link>
              <h4 class="mouseFilke">
                <span>￥{{item.price}}</span>起<i></i>
                <div class="flike" v-if="item.priceTemplate.length>0">
                  <p class="clear"><span class="l">采购数量</span><i class="r">单价(含13%增值税)</i></p>
                  <p class="clear" v-for="(i, idx) in item.priceTemplate" :key="idx">
                    <span class="l">{{i.num}}+</span><i class="error r">￥{{i.price}}</i>
                  </p>
                </div>
              </h4>
              <p class="clear"><span class="l">品牌</span><span class="r">{{item.brandName}}</span></p>
              <p class="clear"><span class="l">规格</span><span class="r">{{item.specParam}}</span></p>
              <p class="clear"><span class="l">制造商料号</span><span class="r">{{item.materialSn}}</span></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <div class="showlog" v-if="tools">
        <div class="log-title"> 限购提示 <span class="close-log" @click="toolsLog">X</span></div>
        <div class="log-middle">
          因近期平台订单激增，为保证发货效率，合晶芯城分品类限制每个账户可购买的数量。
          <p class="too">电阻类产品每项限购50000pcs,同一个产品订单数不超过3个</p>
          <dl class="dl-bg" v-if="numb.length>0">
            <dt></dt>
            <dd>限购品类</dd>
            <dd>限购数量</dd>
            <dd>采购总量</dd>
            <dd>还可采购</dd>
            <dd>订单数</dd>
          </dl>
          <template v-if="numb.length>0">
            <dl class="dl-border" v-for="(item,index) in numb" :key="index">
              <dt></dt>
              <dd>电阻{{item.Name}}</dd>
              <dd>{{item.less}}pcs</dd>
              <dd>{{item.All}}pcs</dd>
              <dd>{{item.hasBuy}}pcs</dd>
              <dd>{{item.Orders == 0 ? '' : item.Orders }}</dd>
            </dl>
          </template>

          <p v-if="numb.length==0" style="color:red;padding:12px 0 0;">电阻下单时间为10:00-15:00,请在这个时间段下单</p>

          <p class="log-tools">如急需更多发货，请联系您的专属销售代表寻求帮助 <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=3002484866&site=qq&menu=yes" class="log-contact">联系QQ</a></p>
          <span class="log-p">我们会在物流缓解后重新开放，感谢您的谅解与支持，<em>(采购数量以您提交订单的商品数量计算)</em></span>
          <div class="log-clo" @click="toolsLog">知道了</div>
        </div>
      </div>
    </transition>

    <!-- 疫情期间无法下单 -->
    <transition name="el-fade-in">
      <div class="showlog" v-if="ycTools">
        <div class="log-title"> 尊敬的用户您好，温馨提示您 <span class="close-log" @click="toolsLog">X</span></div>
        <div class="log-middle">

          <div class="log-clo" @click="toolsLog">知道了</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { exportMethod } from '@/plugins/ibox/utils'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '我的购物车-合晶芯城',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  components: {
  },
  data () {
    return {
      cartList: [],
      isloading: true,
      selectId: [],
      price: 0,
      sumPrice: 0,
      selectTotal: 0,
      selectAll: false,
      params: {
        "categoryId": "",
        "language": 1,
        "pageNum": 1,
        "pageSize": 4,
        "id": ""
      },
      total: 0,
      pageTotal: 0,
      guesgsList: null,
      tools: false,
      numb: [],
      ycTools: false
    }
  },
  created () {

  },
  computed: {
  },
  methods: {
    ...mapActions('lxr', ['get_cart_list', 'del_cart_ids', 'ck_set', 'update_cart_status', 'get_guesgs_list', 'check_cart', 'get_favorites']),
    async getFavorites (item) {
      let params = {
        goodsImg: item.goodsImg,
        brandName: item.brandName,
        categoryName: item.categoryName,
        deliveryDate: item.deliveryNo,
        favoriteType: 1,
        goodsId: item.id,
        goodsName: item.name,
        materialSn: item.materialSn,
        packCount: item.packCount,
        packageSpecification: item.packageSpecification,
        setUp: item.setUp,
        specifications: item.specParam,
        status: 1, //状态(1已收藏|2已取消) ,
        storeCount: item.storeCount,
        priceTemplate: JSON.stringify(item.priceTemplate),
      };
      let res = await this.get_favorites(params);
      if (res.httpCode == 200) {
        this.$message.success("收藏成功");
      } else {
        this.$message.error("操作出现异常");
      }
    },
    add (e, item, index) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num + parseInt(item.packCount)
      if (a > 1000000) {
        let b = 1000000 % parseInt(item.packCount)
        if (b != 0) {
          a = 1000000 - b
        } else {
          a = 1000000
        }
      }
      $('.number-box').eq(index).find('.sub').attr('style', '')
      $('.buy-num').eq(index).val(a)
      item.goodsNum = a
      this.calcSumP(item, index)
    },
    sub (e, item, index) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num - parseInt(item.packCount)
      if (a < parseInt(item.packCount)) {
        a = parseInt(item.packCount)
        $('.number-box').eq(index).find('.sub').attr('style', 'color:#999')
      } else {
        $('.number-box').eq(index).find('.sub').attr('style', '')
      }
      $('.buy-num').eq(index).val(a)
      item.goodsNum = a
      this.calcSumP(item, index)
    },
    async checkCart () {
      // let result = await this.check_user_order_status()
      // console.log(result.data.tList)
      // if (result.httpCode == 200) {
      //   if (result.data.statu) {
      //     if (result.data.msg) {
      //       this.$message.error(result.data.msg)
      //     } else {
      //       this.numb = result.data.tList
      //       this.tools = true
      //     }
      //   } else {
      //     this.$router.push({ path: '/cart/order' })
      //   }
      // }
      // let res = await this.check_cart()
      // if (res.httpCode == 200) {
      //   this.$router.push({ path: '/cart/order' })
      // } else {
      //   this.$message.error(res.message)
      // }
      this.$router.push({ path: '/cart/order' })
    },
    toolsLog () {
      this.tools = false
    },
    download () {
      let _self = this
      let ids = ''
      this.selectId.forEach((item, index) => {
        if (item) {
          ids += this.cartList[index].id + ','
        }
      });
      if (!ids) {
        this.$message.error('请选择商品')
        return
      }
      ids = ids.substr(0, ids.length - 1)
      let obj = {
        method: 'get',
        url: '/orderGoods/export',
        fileName: '购物车商品导出',
        params: 'ids=' + ids
      }
      exportMethod(obj) // 模板导出
    },
    // 去结算
    async linkConfirmOrder () {
      let params = {
        goodsIds: '',
        language: 1,
      }
      this.selectId.forEach((item, index) => {
        if (item) {
          params.goodsIds += this.cartList[index].goodsId + ','
        }
      });
      if (!params.goodsIds) {
        this.$message.error('请选择商品')
        return
      } else {
        this.checkCart()
      }
    },

    //更新购物车数量
    async updateNum (item, index) {
      let res = await this.update_cart_status({
        goodsId: item.goodsId,
        goodsNum: item.goodsNum,
        customerSn: item.customerSnEdit,
        id: item.id
      })
    },

    async selectGoods (id, gid, idx, type) {
      let params = {
        goodsId: gid,
        selected: !this.selectId[idx] ? 1 : 0,
        id: id
      }
      if (type == 1) {
        params.selected = this.selectId[idx] ? 1 : 0
      }
      let res = await this.update_cart_status(params)
      if (res.httpCode == 200) {
        if (type == 2) {
          this.$set(this.selectId, idx, !this.selectId[idx])
          this.cartList[idx].selected = this.selectId[idx] ? 1 : 0
        }
        this.selectAll = true
        this.selectId.forEach((item, index) => {
          if (!item) {
            this.selectAll = false
          }
        });
        setTimeout(() => {
          this.sumPrice = 0
          this.selectId.forEach((i, d) => {
            if (i) {
              this.sumPrice += parseFloat(this.cartList[d].dPrice)
            }
          });
          this.sumPrice = this.sumPrice.toFixed(3)
        }, 100);
      }
    },
    allSelect () {
      this.selectAll = !this.selectAll
      this.selectId.forEach((item, index) => {
        if (this.selectAll) {
          this.$set(this.selectId, index, true)
        } else {
          this.$set(this.selectId, index, false)
        }
        this.selectGoods(this.cartList[index].id, this.cartList[index].goodsId, index, 1)
      });
    },
    async getCartList () {
      var _this = this
      let res = await this.get_cart_list()
      if (res.httpCode == 200) {
        this.isloading = false
        this.cartList = res.data
        if (this.cartList) {
          if (this.cartList.length > 0) {
            this.selectAll = true
            this.cartList.forEach(item => {
              item.customerSnEdit = item.customerSn
              this.params.categoryId += item.categoryId + ','
              this.params.id += item.goodsId + ','
              this.selectId.push(item.selected == 1 ? true : false)
              if (item.selected != 1) {
                this.selectAll = false
              }
              var length
              if (item.priceTemplate && item.priceTemplate.length > 0) {
                if (typeof (item.priceTemplate) == 'string') {
                  item.priceTemplate = JSON.parse(item.priceTemplate)
                }
                length = item.priceTemplate.length
                item.goodsNum = parseInt(item.goodsNum)
                if (item.goodsNum < item.priceTemplate[0].num) {
                  item.calcPrice = item.price
                } else if (length >= 2 && ((item.goodsNum >= item.priceTemplate[0].num) && (item.goodsNum < item.priceTemplate[1].num))) {
                  item.calcPrice = item.priceTemplate[0].price
                } else if (length >= 3 && ((item.goodsNum >= item.priceTemplate[1].num) && (item.goodsNum < item.priceTemplate[2].num))) {
                  item.calcPrice = item.priceTemplate[1].price
                } else if (length >= 4 && ((item.goodsNum >= item.priceTemplate[2].num) && (item.goodsNum < item.priceTemplate[3].num))) {
                  item.calcPrice = item.priceTemplate[2].price
                } else if (length >= 5 && ((item.goodsNum >= item.priceTemplate[3].num) && (item.goodsNum < item.priceTemplate[4].num))) {
                  item.calcPrice = item.priceTemplate[3].price
                } else if (length >= 6 && ((item.goodsNum >= item.priceTemplate[4].num) && (item.goodsNum < item.priceTemplate[5].num))) {
                  item.calcPrice = item.priceTemplate[4].price
                } else if (length >= 7 && ((item.goodsNum >= item.priceTemplate[5].num) && (item.goodsNum < item.priceTemplate[6].num))) {
                  item.calcPrice = item.priceTemplate[5].price
                } else if (length >= 7 && ((item.goodsNum >= item.priceTemplate[6].num) && (item.goodsNum < item.priceTemplate[7].num))) {
                  item.calcPrice = item.priceTemplate[6].price
                } else {
                  item.calcPrice = item.priceTemplate[item.priceTemplate.length - 1].price
                }
              } else {
                item.calcPrice = item.price
              }
              item.dPrice = parseFloat((item.calcPrice * item.goodsNum).toFixed(6))
              if (item.selected == 1) {
                this.sumPrice += parseFloat((item.goodsNum * item.calcPrice).toFixed(3))
              }
            });
            this.sumPrice = this.sumPrice.toFixed(3)
            if (this.params.id) {
              this.getGuesgsList()
            }
          }
        } else {
          this.cartList = []
        }
      }
    },

    //猜你喜欢
    async getGuesgsList (page) {
      if (page == 1) {
        this.params.pageNum -= 1
      }
      if (page == 2) {
        this.params.pageNum += 1
      }
      let res = await this.get_guesgs_list(this.params)
      if (res.httpCode == 200) {
        if (res.data && res.data.records) {
          this.guesgsList = res.data.records
          this.total = res.data.total
          let num = this.total % 4
          if (num != 0) {
            this.pageTotal = parseInt(this.total / 4) + 1
          } else {
            this.pageTotal = parseInt(this.total / 4)
          }
          if (this.guesgsList.length > 0) {
            this.guesgsList.forEach(item => {
              if (typeof (item.priceTemplate) == 'string') {
                item.priceTemplate = JSON.parse(item.priceTemplate)
              }
            });
          }
        }
      }
    },
    del (goodsId) {
      this.$confirm("确定从购物车删除此商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.delFun(goodsId);
        })
        .catch(() => { });
    },
    async delFun (goodsId) {
      let res = await this.del_cart_ids({ goodId: goodsId })
      if (res.httpCode == 200) {
        this.selectId = []
        this.getCartList()
      }
    },
    async delCartIds () {
      var params = {
        goodId: ''
      }
      this.selectId.forEach((item, index) => {
        if (item) {
          params.goodId += this.cartList[index].goodsId + ','
        }
      });
      if (!params.goodId) {
        this.$message.error('请选择商品')
        return
      }
      params.goodId = params.goodId.substr(0, params.goodId.length - 1)
      let res = await this.del_cart_ids(params)
      if (res.httpCode == 200) {
        this.selectId = []
        this.getCartList()
      }
    },
    calcSumP (item, index) {
      this.updateNum(item, index)

      if (item.priceTemplate && item.priceTemplate.length) {
        var length = item.priceTemplate.length
        if (item.goodsNum < item.priceTemplate[0].num) {
          item.calcPrice = item.price
        } else if (length >= 2 && ((item.goodsNum >= item.priceTemplate[0].num) && (item.goodsNum < item.priceTemplate[1].num))) {
          item.calcPrice = item.priceTemplate[0].price
        } else if (length >= 3 && ((item.goodsNum >= item.priceTemplate[1].num) && (item.goodsNum < item.priceTemplate[2].num))) {
          item.calcPrice = item.priceTemplate[1].price
        } else if (length >= 4 && ((item.goodsNum >= item.priceTemplate[2].num) && (item.goodsNum < item.priceTemplate[3].num))) {
          item.calcPrice = item.priceTemplate[2].price
        } else if (length >= 5 && ((item.goodsNum >= item.priceTemplate[3].num) && (item.goodsNum < item.priceTemplate[4].num))) {
          item.calcPrice = item.priceTemplate[3].price
        } else if (length >= 6 && ((item.goodsNum >= item.priceTemplate[4].num) && (item.goodsNum < item.priceTemplate[5].num))) {
          item.calcPrice = item.priceTemplate[4].price
        } else if (length >= 7 && ((item.goodsNum >= item.priceTemplate[5].num) && (item.goodsNum < item.priceTemplate[6].num))) {
          item.calcPrice = item.priceTemplate[5].price
        } else if (length >= 7 && ((item.goodsNum >= item.priceTemplate[6].num) && (item.goodsNum < item.priceTemplate[7].num))) {
          item.calcPrice = item.priceTemplate[6].price
        } else {
          item.calcPrice = item.priceTemplate[item.priceTemplate.length - 1].price
        }
      } else {
        item.calcPrice = item.price
      }

      this.cartList[index].dPrice = parseFloat((item.calcPrice * item.goodsNum).toFixed(6))
      if (item) {
        if (parseInt(item.goodsNum) < parseInt(item.setUp)) {
          this.cartList[index].goodsNum = parseInt(item.setUp)
        }
      }
      setTimeout(() => {
        this.sumPrice = 0
        this.selectId.forEach((i, d) => {
          if (i) {
            this.sumPrice += parseFloat(this.cartList[d].dPrice)
          }
        });
        this.sumPrice = this.sumPrice.toFixed(3)
      }, 100);
    },
    calcSum (item, index) {
      let a = parseInt(item.goodsNum)
      item.storeCount = parseInt(item.storeCount)
      item.setUp = parseInt(item.setUp)
      let packCount = parseInt(item.packCount)
      let b = a % packCount
      if (a > packCount) {
        if (b != 0) {
          a += packCount - b
        }
        if (a > 1000000) {
          let c = 1000000 % packCount
          if (c != 0) {
            a = 1000000 - c
          } else {
            a = 1000000
          }
        }
      } else {
        a = packCount
      }
      item.goodsNum = a
      this.calcSumP(item, index)


      // setTimeout(() => {
      //   this.sumPrice = 0
      //   this.selectId.forEach((i, d) => {
      //     if (i) {
      //       this.sumPrice += parseFloat(this.cartList[index].dPrice)
      //     }
      //   });
      //   this.sumPrice = this.sumPrice.toFixed(6)
      // }, 100);
    }
  },
  mounted () {
    // 获取数量
    // console.log(this.$refs.getValue.value)
    var a = setInterval(() => {
      let cartHeader = localStorage.getItem('cartHeader');
      if (cartHeader) {
        this.getCartList()
        clearInterval(a)
      }
    }, 200);
  },
  watch: {
    'selectId' (val) {
      let s = 0
      if (this.selectId.length > 0) {
        this.selectId.forEach(item => {
          if (item) {
            s += 1
          }
        });
      }
      this.selectTotal = s
    },
  },
  filters: {
    calcPriceFun (val) {
      return val.toFixed(6);
    },
    calcGoodsNum (val) {

    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/cart-shoppingCart.scss";
.showlog {
  .log-clo {
    width: 120px;
    background: #0067ed;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    margin: 15px auto 0;
    cursor: pointer;
  }
  .log-p {
    font-size: 13px;
    color: #333;
    em {
      font-size: 12px;
      color: #666;
    }
  }
  .log-tools {
    color: #999;
    font-size: 12px;
    margin-top: 8px;
    .log-contact {
      color: #0067ed;
      margin-left: 5px;
    }
  }
  .log-middle {
    padding: 15px;
    font-size: 14px;
    .too {
      color: #f00;
      font-size: 13px;
    }
    .dl-bg {
      height: 30px;
      border: 1px solid #eee;
      margin-top: 10px;
      dd {
        height: 30px;
        background: #f6f6f6;
      }
    }
    .dl-border {
      height: 34px;
      border: 1px solid #eee;
      border-top: none;
    }
    dd {
      width: 20%;
      float: left;
      line-height: 30px;
      font-size: 13px;
      text-align: center;
    }
  }
  width: 640px;
  padding-bottom: 20px;
  z-index: 999;
  position: fixed;
  left: 50%;
  top: 42%;
  margin-left: -290px;
  // margin-top: -130px;
  background: #fff;
  box-shadow: 0 0 3px #0067ed;
  border-radius: 5px;
  .log-title {
    background: #f8f8f8;
    font-size: 14px;
    line-height: 30px;
    padding-left: 15px;
    color: #0067ed;
    .close-log {
      float: right;
      cursor: pointer;
      padding: 0 15px;
      color: #0067ed;
    }
  }
}
</style>


