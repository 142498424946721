<template>
  <div class="r userCenter_container_box">
    <h2 class="text_r">
      <span class="l">订单详情</span>
      <router-link class="r" to="/home/order/list">返回</router-link>
    </h2>
    <div class="userCenter_container orderDetail" v-loading="isloading">
      <template v-if="info">
        <table class="base_info">
          <tbody>
            <tr>
              <th colspan="5">基本信息</th>
            </tr>
            <tr class="first">
              <td><i>订单号</i><br><span id="order_sn">{{info.invoiceNo!=''?info.invoiceNo:'-'}}</span></td>
              <td><i>用户名</i><br>{{userInfo.nickname}}</td>
              <td><i>订单状态</i><br>
                <template v-if="info.status==0">
                  待审批
                </template>
                <template v-if="info.status==1">
                  审批通过
                </template>
                <template v-if="info.status==2">
                  审批不通过
                </template>
                <template v-if="info.status==3">
                  待发货
                </template>
                <template v-if="info.status==4">
                  待收货
                </template>
                <template v-if="info.status==5">
                  已完成
                </template>
              </td>
              <td><i>下单时间：</i><br>{{info.createTime | calcTime}}</td>
              <td><i>配送方式</i><br>
                邮寄到付
              </td>
            </tr>
          </tbody>
        </table>

        <div class="product-item">
          <div class="orderM_title clear">
            <span class="one">样品信息</span>
            <span class="two">品牌</span>
            <!-- <span class="three">单价(含增值税)</span> -->
            <span class="three">数量(pcs)</span>
            <span class="three">收货人</span>
            <span class="four">金额(含增值税)</span>
          </div>

          <div class="order_body clear">
            <ul class="clear">
              <li class="clear">
                <img v-if="info.image" :src="info.image">
                <img v-else src="@/assets/images/goods/default_img.png">
                <div class="order-list l">
                  <p class="title">{{info.goodsName}}</p>
                  <p class="lh">规格：{{info.packageSpecification}}</p>
                  <div class="xx">
                    <span>型号：{{info.materialSn}}</span>
                  </div>
                </div>
                <p class="order-wl l"><i></i>{{info.brandName}}</p>
                <p class="order-num l">
                  <span>×{{info.num}} pcs</span>
                </p>
                <p class="order-num l">
                  <span>{{info.consignee}}</span>
                </p>
                <p class="order-litotal l">
                  <span>￥0</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <th colspan="4">收货人信息</th>
            </tr>
            <tr class="first">
              <td><i>收货人</i><br>{{info.consignee}}</td>
              <td><i>手机号码</i><br>{{info.mobile}}</td>
              <td colspan="2"><i>收货地址</i><br>{{info.province}}{{info.city}}{{info.district}}{{info.address}}</td>
            </tr>
          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <th>备注信息</th>
            </tr>
            <tr class="remark">
              <td>{{info.purpose}}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '订单详情',
    }
  },
  data () {
    return {
      info: null,
      isloading: true,
      params: {
        orderSn: ''
      },
      md: null,
      invoice: null,
    }
  },

  created () {
    this.getSampleOrderDetail()
  },
  computed: {
    ...mapState('lxr', ['userInfo'])
  },
  methods: {
    ...mapActions('lxr', ['get_order_detail', 'get_id_md', 'get_order_invoice', 'cancel_order', 'get_sample_order_detail']),
    async getSampleOrderDetail () {
      let res = await this.get_sample_order_detail({ id: this.$route.query.id })
      if (res.httpCode == 200) {
        this.info = res.data
        this.isloading = false
      } else {
        this.$message.error(res.message)
      }
    },
    cancelOrder (id) {
      this.$confirm("确定取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.cancel(id)
        })
        .catch(() => { });
    },

    async cancel (id) {
      let res = await this.cancel_order({ id: id })
      if (res.httpCode == 200) {
        this.getOrderDetail()
      }
    },
  },
  mounted () {
  },
  filters: {
    calcTime (val) {
      return val.substr(0, val.length - 5)
    },
    calcPriceFun (val) {
      return parseFloat(val).toFixed(2);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-orderDetail2.scss";
.brand_details > em {
  left: 105px;
}
</style>


