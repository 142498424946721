<template>
  <div class="r userCenter_container_box">
    <h2 class="ptitle">购买历史</h2>
    <div class="filter-wrap">
      <el-date-picker v-model="params.data.startTime" value-format='yyyy-MM-dd' type="date" placeholder="选择下单日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <span class="ctext">至</span>
      <el-date-picker v-model="params.data.endTime" value-format='yyyy-MM-dd' type="date" placeholder="选择下单日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <div class="fr f-search">
        <input name="keyword" class="search-input" type="text" placeholder="请输入订单号/BOM标识/关键字" v-model.trim="params.data.query"><em id="search" @click="getOrderHistory"></em>
      </div>
    </div>

    <div class="userCenter_container purchase_history">
      <!-- <div class="order-tatal">符合条件的共<i>{{total}}</i>条数据</div> -->
      <div class="order-tatal clear"><span class="l">符合条件的共<i id="total">{{total}}</i>条数据</span><span id="export" class="r">导出对账单</span></div>

      <div class="orderM_body_box" v-loading="isloading">
        <template v-if="history&&history.length>0">
          <ul class="purchase_history_table clear">
            <li class="clear history-item" v-for="(i, idx) in history" :key="idx">
              <img v-if="i.goodsImg" :src="i.goodsImg">
              <img v-else src="@/assets/images/goods/default_img.png">
              <div class="l info">
                <h3>
                  <router-link :to="{path:'/product/detail',query:{id:i.goodsId}}" target="_blank">{{i.goodsName}}</router-link>
                </h3>
                <div class="clear">
                  <div class="l">
                    <p><span class="tt">商 品 品 牌：</span><span class="brand_name">{{i.brandName}}</span></p>
                    <p><span class="tt">商 品 类 别：</span><span>{{i.goodsCategoryName}}</span></p>
                  </div>
                  <div class="r">
                    <p><span class="tt">制造商料号：</span><span class="first">{{i.materialSn}}</span></p>
                    <p><span class="tt">参 数 规 格：</span><span>{{i.specParam}}</span></p>
                  </div>
                </div>
              </div>
              <div class="r btnr">
                <p class="btnr-b">
                  <span class="look" @click="buyJlFun(i.materialSn)">查看购买记录</span>
                  <router-link class="buy" :to="{path:'/product/detail',query:{id:i.goodsId}}">再次购买</router-link>
                </p>
              </div>

            </li>
          </ul>
        </template>
        <div class="no-order" v-if="history&&history.length==0">
          <img src="~@/assets/images/goods/noshop_1.png" alt="">
          <p>暂无历史</p>
        </div>
        <div class="page-wrap" v-if="total>10">
          <el-pagination background hideOnSinglePage @current-change="currentChange" layout="prev, pager, next" :total="total" :page-size="params.pageSize"></el-pagination>
        </div>
      </div>
    </div>
    <!-- <transition name="el-fade-in">
      <againBuy v-if="addCart" :addCart="addCart" v-on:childByValue="addCartFun"></againBuy>
    </transition> -->

    <transition name="el-fade-in">
      <div class="mask_layout2" v-if="buyJl">
        <div class="pop_up_box see_purchaseH_box">
          <div class="pop_up_title clear">
            <h3 class="l">商品&nbsp;&nbsp;<i id="material_sn"></i>&nbsp;的所有购买记录</h3>
            <span class="r" @click="buyJl=false"></span>
          </div>
          <!-- 内容区域 -->
          <div class="see_purchaseH" v-if="buyJlInfo&&buyJlInfo.length>0">
            <div class="clear see_purchaseH_detail">
              <img id="goods_img" v-if="buyJlInfo[0].goodsImg" :src="buyJlInfo[0].goodsImg">
              <img id="goods_img" v-else src="@/assets/images/goods/default_img.png">
              <div class="l">
                <h3>{{buyJlInfo[0].goodsName}}</h3>
                <div class="clear">
                  <div class="l">
                    <p><span class="tt">商 品 品 牌：</span><span class="brand_name">{{buyJlInfo[0].brandName}}</span></p>
                    <p><span class="tt">商 品 类 别：</span><span>{{buyJlInfo[0].goodsCategoryName}}</span></p>
                  </div>
                  <div class="r">
                    <p><span class="tt">制造商料号：</span><span class="first">{{buyJlInfo[0].materialSn}}</span></p>
                    <p><span class="tt">封 装 规 格：</span><span>{{buyJlInfo[0].specParam}}</span></p>
                  </div>
                </div>
              </div>
              <!-- <div class="r btnr">
                <div class="btnr-t">
                  <span class="dj">￥{{buyJlInfo[0].goodsPrice}}/pcs</span>起
                </div>
              </div> -->
            </div>
            <table id="list">
              <tbody>
                <tr>
                  <th class="one">购买时间</th>
                  <th>单价</th>
                  <th>数量</th>
                  <th>总金额</th>
                  <th>订单编号</th>
                </tr>
                <tr v-for="(item, index) in buyJlInfo" :key="index">
                  <td class="one">{{item.createTime}}</td>
                  <td>￥{{item.goodsPrice}}/ pcs</td>
                  <td>{{item.goodsNum}}pcs</td>
                  <td class="four">￥{{item.goodsAmount}}</td>
                  <td class="five">
                    <router-link :to="{path:'/product/detail',query:{id:item.goodsId}}">{{item.orderSn}}</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '个人中心-购买历史',
    }
  },
  data () {
    return {
      buyJl: false,
      buyJlInfo: null,
      addCart: false,
      isloading: true,
      history: null,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      total: 0,
      params: {
        data: {
          startTime: '',
          endTime: '',
          query: '',
          isSummary: 1,
        },
        pageNum: 1,
        pageSize: 10
      },
      paramsAll: {
        data: {
          query: '',
          isSummary: 0,
        },
        pageNum: 1,
        pageSize: 10
      }
    }
  },

  created () {

  },
  computed: {
    //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['get_order_history']),
    buyJlFun (materialSn) {
      this.paramsAll.data.query = materialSn
      this.buyJl = !this.buyJl
      this.getHistoryType()
    },
    currentChange (e) {
      this.params.pageNum = e
      this.getOrderHistory()
    },
    async getHistoryType () {
      let res = await this.get_order_history(this.paramsAll)
      if (res.httpCode == 200) {
        if (res.data) {
          this.buyJlInfo = res.data.records
        }
      }
    },
    async getOrderHistory () {
      let res = await this.get_order_history(this.params)
      if (res.httpCode == 200) {
        this.isloading = false
        if (res.data) {
          this.history = res.data.records
          this.total = res.data.total
        }
      }
    },
    addCartFun (addCart) {
      this.addCart = !this.addCart
    },
    childByValue (addCart) {
      this.addCart = addCart
    }
  },
  mounted () {
    this.getOrderHistory()
  },
  filters: {
    setTime: function (val) {
      val = val.substring(0, val.length - 0)
      return val
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-buyHistory.scss";
.orderM_top /deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
.orderM_top /deep/.el-input__icon {
  line-height: 36px;
}
.orderM_body_box {
  min-height: 326px;
  padding-bottom: 1px;
}
</style>