<template>
  <div class="newMain bgf5f5f5">
    <div class="sample-wrap auto clearfix" style="margin-top: 10px;">
      <div class="pfilter-wrap clearfix">
        <div class="ass ass2 ass3">
          <div class="atitle">规格分类</div>
          <ul class="ass_ul" v-if="productMenuIndex">
            <li class="ass_list" v-for="(item, index) in productMenuIndex" :key="index" v-show="index < 5">
              <a class="nav_menu_title">
                <img class="icon" :src="item.image">
                {{ item.nameCh }}
                <i class="ass_list_right"></i>
              </a>
              <div class="nav_menu_box">
                <div class="l">
                  <div class="nav_menu">
                    <div class="nav_menu_item clear">
                      <!-- <a href="javascript:;">
                        <h3 class="clear">
                          <img :src="item.image" />
                          {{ item.nameCh }}
                        </h3>
                      </a> -->
                      <ul class="clear" v-if="item.subCategory">
                        <li v-for="(i2, d2) in item.subCategory" :key="d2">
                          <a href="javascript:;" @click="getProductList(i2.id)">{{ i2.nameCh }}</a>
                          <i></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="select-wrap" v-if="productList">
          <div class="param_search clear">
            <div class="clearfix">
              <i class="l">参数选型：</i>
              <p class="l" v-for="(item, index) in s_attr" :key="index">
                <a class="item" href="javascript:;" v-for="(i,d) in item" :key="d"><span>{{i.name}}{{i.val}}</span><i @click="delHtmlItem(i.idx,i.valIdx,index,d)"></i></a>
              </p>
            </div>
          </div>
          <div class="param_select">
            <div class="brand_select_box">
              <template v-if="productList.object.QueryItems&&productList.object.QueryItems.length>0">
                <div class="brand_select" v-for="(av,aindex) in productList.object.QueryItems" :key="aindex">
                  <h4>{{av.name}}</h4>
                  <ul>
                    <template v-if="av.attrs.length >0&&s_attr_falg[aindex]">
                      <li v-for="(av2,aindex2) in av.attrs" :key="aindex2" @click="attrSelect(av.name,aindex,av2,aindex2,av.attrs.length)" :class="{'attrclass':s_attr_falg[aindex].arr[aindex2]}">
                        {{av2}}</li>
                    </template>
                  </ul>
                </div>
              </template>

            </div>
          </div>
          <a href="javascript:;" class="pBtn" @click="paramsSearch">立即查询</a>
        </div>
      </div>
      <div class="primary_search_product w960 fr">
        <div class="search_product_title">
          <div class="padding20" v-if="productList">
            符合条件的商品：<span>{{productList.total}}</span></div>
        </div>
        <div class="s-wbox" v-loading="isloading">
          <template v-if="productList && productList.records.length>0">
            <ul class="new-product-ul">
              <template>
                <li class="new-product-li clearfix" v-for="(v,index) in productList.records" :key="index">
                  <div class="pl-col-1 fl">
                    <a href="javascript:;" class="ibox">
                      <img v-if="v.goodsImg" :src="v.goodsImg" :alt="v.name+' '+v.packageSpecification">
                      <img v-else src="@/assets/images/goods/default_img.png" :alt="v.name+' '+v.packageSpecification">
                    </a>
                  </div>
                  <div class="pl-col-2 fl">
                    <p class="p_name">
                      <a href="javascript:;" v-html="v.name"></a>
                    </p>
                    <div class="clear">
                      <div class="c1">
                        <p>
                          <span class="tag">编码：</span>
                          <a class="detail" href="javascript:;" v-html="v.ksn"></a>
                        </p>
                        <p>
                          <span class="tag">品牌：</span>
                          <a href="javascript:;" v-html="v.brandName"></a>
                        </p>
                        <p class="ms-wrap">
                          <span class="tag">描述：</span>
                          <a class="ms" href="javascript:;" v-html="v.goodRemark"></a>
                        </p>
                      </div>
                      <div class="c4">
                        <template v-if="v.attrs">
                          <p v-for="(a,d) in v.attrs" :key="d">
                            <span class="tag">{{a.attrId}}：<i>{{a.attrValue}}</i></span>
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="pl-col-4 fr">
                    <div class="opera_box" v-if="v.goodsManual">
                      <a :href="v.goodsManual || 'javascript:;'" target="_blank" class="pdf">
                        点击查看
                      </a>

                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div class="page-wrap" v-if="productList">
              <el-pagination background hideOnSinglePage layout="prev, pager, next" :total="productList.total" @current-change="currentChange"></el-pagination>
            </div>
          </template>
          <div class="no_product_box" v-if="productList&&productList.records.length==0">
            <img src="@/assets/images/goods/noshop_1.png">
            <p>很抱歉，暂无相关商品，请查询其他的看看</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '合晶芯城 - 厂家直营 - 二极管 - 晶体管 - 电阻电容 - 集成电路 - 电子元器件交易平台',
      meta: [
        {
          name: 'keywords',
          content: '合晶芯城,二极管,晶体管,电阻,电容,集成电路,电子元器件网上商城'
        },
        {
          name: 'description',
          content: '合晶芯城（www.hotking.com）是国内厂家直营二极管、晶体管、电阻、电容、集成电路等电子元器件的一站式采购交易平台，直营包含合科泰电子HKT、合科泰半导体HOTTECH、厚声、乐山LRC、长电、村田、NXP、JST、旺诠、丽智等品牌，并为您提供快捷、优质、满意的电子元器件采购服务！'
        }
      ]
    }
  },
  data () {
    return {
      isloading: false,
      params: {
        categoryId: '',
        pageSize: 10,
        pageNum: 1,
        attrs: [],
      },
      params2: {
        pageNum: 1,
        pageSize: 10,
        queryValue: '',
        categoryId: '',
        attrs: [],
        seoName: this.$route.query.name || ''
      },

      s_attr: [], //选中属性列表集合 ---  展示
      s_attr_id: [], //选中属性列表集合 --  标识
      s_attr_falg: [],
      currentId: '',
    }
  },
  async asyncData ({ store, route }) {
    let getProductList = store.dispatch('lxr/get_product_list', {
      pageNum: route.query.page || 1,
      pageSize: 10,
      categoryId: route.query.cid || '36',
      attrs: [],
      seoName: route.query.name || route.query.cname || ''
    })
    return getProductList
  },
  created () {
  },
  mounted () {
    //初始化商品列表
    if (!this.productList) {
      this.getProductList('')
    } else {
      this.isloading = false
      if (this.productList.object.QueryItems && this.productList.object.QueryItems.length > 0) {
        this.productList.object.QueryItems.forEach((item, index) => {
          this.s_attr_id.push([])
          this.s_attr.push([])
          this.s_attr_falg.push({ arr: [] })
          item.attrs.forEach((i, d) => {
            this.s_attr_falg[index].arr.push(false)
          })
        });
      }
    }
  },
  computed: {
    ...mapState('lxr', ['productList', 'sampleCagetory', 'productMenuIndex'
    ]),
  },
  methods: {
    ...mapActions('lxr', ['get_product_list']),
    ...mapMutations('lxr', ['GET_PRODUCT_LIST']),
    currentChange (e) {
      this.params.pageNum = e
      this.getProductList('')
    },
    paramsSearch () {
      this.params.attrs = []
      this.s_attr_id.forEach((item, index) => {
        if (item.length > 0) {
          let val = ''
          item.forEach(i => {
            val += this.productList.object.QueryItems[index].attrs[i] + '&'
          });
          this.params.attrs.push({ attrValue: val, attrId: this.productList.object.QueryItems[index].name })
        }
      });
      this.params.pageNum = 1
      this.getProductList('')
    },
    async getProductList (id) {
      this.isloading = true
      if (id) {
        this.currentId = id
        this.s_attr_id = []
        this.s_attr = []
        this.s_attr_falg = []
        this.params.attrs = []
        this.params.pageNum = 1
        this.params.categoryId = id
      }
      let res = await this.get_product_list(this.params)
      if (!res) {
        this.isloading = false
        this.GET_PRODUCT_LIST('')
        return
      }
      if (res.httpCode == 200) {
        this.isloading = false
        if (res.data.object.QueryItems && res.data.object.QueryItems.length > 0) {
          res.data.object.QueryItems.forEach((item, index) => {
            this.s_attr_id.push([])
            this.s_attr.push([])
            this.s_attr_falg.push({ arr: [] })
            item.attrs.forEach((i, d) => {
              this.s_attr_falg[index].arr.push(false)
            })
          });
        }
      }
    },
    delHtmlItem (idx, valIdx, i, id) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      this.s_attr[i].splice(id, 1)
      this.s_attr_id[i].splice(id, 1)
    },
    //参数属性单击
    attrSelect (name, idx, val, valIdx, length) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      if (this.s_attr_id[idx].indexOf(valIdx) >= 0) {
        let delIdx = this.s_attr_id[idx].indexOf(valIdx)
        this.s_attr_id[idx].splice(delIdx, 1)
        this.s_attr[idx].splice(delIdx, 1)
      } else {
        this.s_attr_id[idx].push(valIdx)
        this.s_attr[idx].push({ name: name, idx: idx, val: val, valIdx: valIdx })
      }
    },
  },
}
</script>
<style lang="scss">
</style>