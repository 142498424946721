import Vue from 'vue'
import Router from 'vue-router'
import login from '@/views/login/login.vue'
import reset from '@/views/login/reset.vue'
import wxRegister from '@/views/login/wx-register.vue'
import register from '@/views/login/register.vue'
import mainCon from '@/views/mainCon.vue'
import nologinCon from '@/views/nologinCon.vue'
import homeCon from '@/views/homeCon.vue'
import index from '@/views/index/index.vue'
import search from '@/views/search/index.vue'
import chinaSearch from '@/views/search/chinaSearch.vue'
import bom from '@/views/index/bom.vue'
import bomMatch from '@/views/index/match.vue'
import product from '@/views/product/product.vue'
import productLevel from '@/views/product/level.vue'
import productDetail from '@/views/product/product-detail.vue'
import brand from '@/views/brand/brand.vue'
import brandDetail from '@/views/brand/brand-detail.vue'
import newsDetail from '@/views/news/new-detail.vue'
import newIndustry from '@/views/news/industry.vue'
import newAnnouncement from '@/views/news/announcement.vue'
import user from '@/views/home/user/user.vue'
import userInfo from '@/views/home/user/info.vue'
import userAddress from '@/views/home/user/address.vue'
import userInvoice from '@/views/home/user/invoice.vue'
import ueseEditPassword from '@/views/home/user/edit-password.vue'
import userIntegral from '@/views/home/user/integral.vue'
import userIntegralDh from '@/views/home/user/integral-dh.vue'
import userIntegralDhD from '@/views/home/user/integral-dh-d.vue'
import order from '@/views/home/order/order.vue'
import sampleOrder from '@/views/home/order/sample.vue'
import orderDetail from '@/views/home/order/order-detail.vue'
import sorder from '@/views/home/order/sorder.vue'
import bill from '@/views/home/order/bill.vue'
import bomOrder from '@/views/home/order/bom-order.vue'
import buyHistory from '@/views/home/order/buy-history.vue'
import message from '@/views/home/message/message.vue'
import collection from '@/views/home/collection/collection.vue'
import rules from '@/views/home/user/rules.vue'
import help from '@/views/help/help.vue'
import link from '@/views/link/link.vue'
import agreement from '@/views/agreement/agreement.vue'
import cart from '@/views/cart/cart.vue'
import confirmOrder from '@/views/cart/confirm-order.vue'
import orderPay from '@/views/cart/order-pay.vue'
import orderPayAlipay from '@/views/cart/order-pay-alipay.vue'
import orderSaveAlipay from '@/views/cart/order-save-alipay.vue'
import specifica from '@/views/specifica/specifica.vue'
import sample from '@/views/sample/sample.vue'
import sampleDetail from '@/views/sample/detail.vue'
import sampleSuccess from '@/views/sample/success.vue'
import tool from '@/views/index/tool.vue'


import service from '@/views/service/service.vue'

Vue.use(Router)

export function createRouter() {
  return new Router({
    // mode: 'history',
    mode: 'history',
    routes: [{
        path: '/login',
        name: 'login',
        component: login,
        meta: {
          title: '登录'
        }
      }, {
        path: '/register',
        name: 'register',
        component: register,
        meta: {
          title: '注册'
        }
      }, {
        path: '/',
        name: 'mainCon',
        component: mainCon,
        redirect: '/index',
        children: [{
            path: '/index',
            name: 'index',
            component: index,
            //component: () => import('@/views/index/index.vue'),
            meta: {
              title: '首页'
            }
          }, {
            path: '/search',
            name: 'search',
            component: search,
            meta: {
              title: '搜索结果页'
            }
          }, {
            path: '/search/china-1',
            name: 'chinaSearch',
            component: chinaSearch,
            meta: {
              title: '搜索结果页'
            }
          }, {
            path: '/bom',
            name: 'bom',
            component: bom,
            meta: {
              title: '秒配BOM表，一键下单',
            }
          }, {
            path: '/match',
            name: 'match',
            component: bomMatch,
            meta: {
              title: '秒配BOM表，一键下单'
            }
          }, {
            path: '/tool',
            name: 'tool',
            component: tool,
            meta: {
              title: '换算工具'
            }
          }, {
            path: '/product',
            name: 'product',
            component: product,
            meta: {
              title: '商品目录'
            }
          }, {
            path: '/product/detail',
            name: 'productDetail',
            component: productDetail,
            meta: {
              title: '商品详情'
            }
          },
          {
            path: '/product/level',
            name: 'productLevel',
            component: productLevel,
            // component: resolve => require(['@/views/product/level.vue'], resolve),
            //component: () => import('@/views/product/level.vue'),
            meta: {
              title: '商品分类（二三级共用）'
            }
          }, {
            path: '/brand',
            name: 'brand',
            component: brand,
            meta: {
              title: '品牌专区'
            }
          }, {
            path: '/brand/detail',
            name: 'brandDetail',
            component: brandDetail,
            meta: {
              title: '品牌详情'
            }
          },
          {
            path: '/industry',
            name: 'newIndustry',
            component: newIndustry,
            meta: {
              title: '行业新闻'
            }
          }, {
            path: '/announcement',
            name: 'newAnnouncement',
            component: newAnnouncement,
            meta: {
              title: '商城资讯'
            }
          },
          {
            path: '/news/detail',
            name: 'newsDetail',
            component: newsDetail,
            meta: {
              title: '新闻资讯详情'
            }
          },
          {
            path: '/link',
            name: 'link',
            component: link,
            meta: {
              title: '友情链接'
            }
          },
          {
            path: '/specifica',
            name: 'specifica',
            component: specifica,
            meta: {
              title: '规格'
            }
          },
          {
            path: '/sample',
            name: 'sample',
            component: sample,
            meta: {
              title: '样品'
            }
          },
          {
            path: '/sample/detail',
            name: 'sampleDetail',
            component: sampleDetail,
            meta: {
              title: '样品详情'
            }
          },

          {
            path: '/service',
            name: 'service',
            component: service,
            meta: {
              title: '服务中心'
            }
          },
        ]
      }, {
        path: '/agreement',
        name: 'nologinCon',
        component: nologinCon,
        redirect: '/agreement',
        children: [{
            path: '/agreement',
            name: 'agreement',
            component: agreement,
            meta: {
              title: '条款与条件'
            }
          }, {
            path: '/help',
            name: 'help',
            component: help,
            meta: {
              title: '帮助中心'
            }
          }, {
            path: '/reset',
            name: 'reset',
            component: reset,
            meta: {
              title: '找回密码'
            }
          }, {
            path: '/wxRegister',
            name: 'wxRegister',
            component: wxRegister,
            meta: {
              title: '绑定手机号码'
            }
          }, {
            path: '/cart',
            name: 'cart',
            component: cart,
            meta: {
              title: '购物车'
            }
          },
          {
            path: '/cart/order',
            name: 'confirmOrder',
            component: confirmOrder,
            meta: {
              title: '确认订单'
            }
          },
          {
            path: '/order/pay',
            name: 'orderPay',
            component: orderPay,
            meta: {
              title: '订单支付'
            }
          },
          {
            path: '/order/pay-alipay',
            name: 'orderPayAlipay',
            component: orderPayAlipay,
            meta: {
              title: '订单支付'
            }
          },
          {
            path: '/order/save-alipay',
            name: 'orderSaveAlipay',
            component: orderSaveAlipay,
            meta: {
              title: '库存锁仓'
            }
          }, {
            path: '/sample/success',
            name: 'sampleSuccess',
            component: sampleSuccess,
            meta: {
              title: '申请样品'
            }
          },
        ]
      },
      {
        path: '/home',
        name: 'homeCon',
        component: homeCon,
        redirect: '/home/user',
        children: [{
          path: '/home/user',
          name: 'user',
          component: user,
          meta: {
            title: '个人中心',

          }
        }, {
          path: '/home/user/info',
          name: 'userInfo',
          component: userInfo,
          meta: {
            title: '编辑账户信息'
          }
        }, {
          path: '/home/user/address',
          name: 'userAddress',
          component: userAddress,
          meta: {
            title: '收货地址'
          }
        }, {
          path: '/home/user/invoice',
          name: 'userInvoice',
          component: userInvoice,
          meta: {
            title: '增值税开票信息'
          }
        }, {
          path: '/home/user/edit-ps',
          name: 'ueseEditPassword',
          component: ueseEditPassword,
          meta: {
            title: '修改密码'
          }
        }, {
          path: '/home/user/integral',
          name: 'userIntegral',
          component: userIntegral,
          meta: {
            title: '积分明细'
          }
        }, {
          path: '/home/user/dh',
          name: 'userIntegralDh',
          component: userIntegralDh,
          meta: {
            title: '我的兑换'
          }
        }, {
          path: '/home/user/dh/detail',
          name: 'userIntegralDhD',
          component: userIntegralDhD,
          meta: {
            title: '我的兑换'
          }
        }, {
          path: '/home/order/list',
          name: 'order',
          component: order,
          meta: {
            title: '订单管理'
          }
        }, {
          path: '/home/sample/order',
          name: 'sampleOrder',
          component: sampleOrder,
          meta: {
            title: '样品订单'
          }
        }, {
          path: '/home/sample/sorder',
          name: 'sampleSorder',
          component: sorder,
          meta: {
            title: '样品订单详情'
          }
        }, {
          path: '/home/order/detail',
          name: 'orderDetail',
          component: orderDetail,
          meta: {
            title: '订单详情'
          }
        }, {
          path: '/home/order/bom',
          name: 'bomOrder',
          component: bomOrder,
          meta: {
            title: 'BOM订单'
          }
        }, {
          path: '/home/order/bill',
          name: 'bill',
          component: bill,
          meta: {
            title: '电子对账单'
          }
        }, {
          path: '/home/order/buy-history',
          name: 'buyHistory',
          component: buyHistory,
          meta: {
            title: '购买历史'
          }
        }, {
          path: '/home/message',
          name: 'message',
          component: message,
          meta: {
            title: '系统消息'
          }
        }, {
          path: '/home/collection',
          name: 'collection',
          component: collection,
          meta: {
            title: '我的收藏'
          }
        }, {
          path: '/home/rules',
          name: 'rules',
          component: rules,
          meta: {
            title: '会员规则'
          }
        }]
      },
      // {
      //   path: '*', // 页面不存在的情况下会跳到404页面
      //   redirect: '/index',
      //   name: 'notFound',
      //   hidden: true
      // }
    ],
    scrollBehavior(to, from, savedPosition) {
      if (to.fullPath != from.fullPath) {
        return {
          x: 0,
          y: 0
        }
      }
    }
  })
}
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}