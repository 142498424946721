<template>
  <div>
    <div id="login">
      <router-link to="/home/user">
        <img v-if="userInfo.headImg" :src="userInfo.headImg" class="tx">
        <img v-else src="@/assets/common/images/index/tx.png" class="tx">
        <!-- <span class="dian"></span> -->
      </router-link>
      <div class="login-items" v-if="userInfo">
        <em></em>
        <div class="logins-top clear">
          <img v-if="userInfo.headImg" :src="userInfo.headImg">
          <img v-else src="@/assets/common/images/index/tx.png">
          <div class="logins-topxx">
            <router-link to="/home/user">{{userInfo.nickname}}</router-link>
            <p class="rank clear">
              <img class="l" :src="'http://doc.hotking.com/hkt/ui/img/level/rank_'+userInfo.level+'.png'" alt="">
              <span class="l">{{userInfo.level==1?'铜牌会员':userInfo.level==2?'银牌会员':userInfo.level==3?'黄金会员':userInfo.level==4?'白金会员':userInfo.level==5?'钻石会员':'皇冠会员'}}</span>
            </p>
          </div>
        </div>
        <ul class="logins-center clear">
          <li class="order l">
            <router-link to="/home/order/list">
              <p></p>
              <span>订单管理</span>
            </router-link>
          </li>
          <li class="sc l">
            <router-link to="/home/collection">
              <p></p>
              <span>我的收藏</span>
            </router-link>
          </li>
          <li class="ls l">
            <router-link to="/home/order/buy-history">
              <p></p>
              <span>购买历史</span>
            </router-link>
          </li>
          <li class="address l">
            <router-link to="/home/user/address">
              <p></p>
              <span>收货地址</span>
            </router-link>
          </li>
        </ul>
        <div class="logins-bottom clear">
          <router-link to="/home/user/info" class="l">账户资料</router-link>
          <a href="javascript:;" class="r loginout" @click="userLogout"><i></i>退出登录</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      signData: ''
    }
  },

  created () {
    // this.sginCenterAjax()
  },
  computed: {
    ...mapState('lxr', ['token', 'userInfo'])
  },
  methods: {
    ...mapActions('lxr', ['user_logout']),
    ...mapMutations('lxr', ['SET_TOKEN']),
    async userLogout () {
      let res = await this.user_logout()
      if (res.httpCode == 200) {
        localStorage.removeItem('access-token');
        localStorage.removeItem('userInfo');
        this.SET_TOKEN('')
        this.$router.push({ path: '/index' })
      }
    },

  },
  mounted () {
    //this.sginCenterAjax()
  },
}
</script>
<style lang="scss" scoped>
</style>


