<template>
  <div class="login_foo">
    <p>@深圳市合晶芯城科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：&nbsp;0755-21005112</p>
    <p><a href="javascript:;"><img src="@/assets/images/gwanbei.png" />粤公网安备 44030902001437号</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@Copyright <a href="http://www.miitbeian.gov.cn" target="_blank">粤ICP备18108309号-3</a></p>
  </div>
</template>
<script>
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
    }
  },

  created () {
  },
  computed: {
  },
  methods: {
  },
  mounted () {

  },
}
</script>
<style lang="scss" scoped>
.login_foo {
  height: 60px;
  text-align: center;
  font-size: 12px;
  color: #676b76;
  padding-top: 20px;
  line-height: 17px;
  p {
    line-height: 22px;
    a {
      color: #676b76;
      &:hover {
        color: #0067ed;
      }
    }
    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin: -1px 6px 0 0;
    }
  }
}
</style>


