<template>
  <div class="newMain">
    <div class="product-wrap auto">
      <div class="product-type">
        <ul class="clearfix">
          <!-- <li class="current">电阻</li> -->
          <!-- <li>所有分类 >></li> -->
          <template v-if="productMenu">
            <li v-for="(item, index) in productMenu" :key="index" :class="{current:cname==item.nameCh}" @click.stop="categorySearch(item,1,index)" :ref="'one'+index" @mousemove="mousemove(index)">
              {{item.nameCh}}
              <div class="two-type">
                <div class="two-box">
                  <i></i>
                  <i class="i2"></i>
                  <a href="javascript:;" v-for="(item2, index2) in item.subCategory" :key="index2" :class="{current:name==item2.nameCh}" @click.stop="categorySearch(item2,3,index,item)">
                    <span class="nav-t">{{item2.nameCh}}<span v-if="item2.hot==1" class="nav-tag">Hot<i></i></span></span>
                  </a>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="product-type-item" v-if="productMenu&&params.categoryId">
        <!-- <h3 class="type-title">{{cname}}</h3> -->
        <div class="item-list" v-if="productMenuIdx>=0">
          <a href="javascript:;" v-for="(item, index) in productMenu[productMenuIdx].subCategory" :key="index" :class="{current:name==item.nameCh}" @click="categorySearch(item,2)">
            <span class="nav-t">{{item.nameCh}}<span v-if="item.hot==1" class="nav-tag">Hot<i></i></span></span>
          </a>
        </div>
      </div>

      <div class="pfilter-wrap clearfix" v-if="filterData">
        <div class="select-wrap">
          <div class="param_search clear">
            <div class="clearfix">
              <i class="l">参数选型：</i>
              <p class="l" v-for="(item, index) in s_attr" :key="index">
                <a class="item" href="javascript:;" v-for="(i,d) in item" :key="d"><span>{{i.name}}{{i.val}}</span><i @click="delHtmlItem(i.idx,i.valIdx,index,d)"></i></a>
              </p>
            </div>
          </div>
          <div class="param_select">
            <div class="brand_select_box" v-loading="filterLoading">
              <template v-if="filterData&&filterData.length>0">
                <div class="brand_select" v-for="(av,aindex) in filterData" :key="aindex">
                  <h4>{{av.name}}</h4>
                  <ul>
                    <template v-if="av.attrs.length >0&&s_attr_falg[aindex]">
                      <li v-for="(av2,aindex2) in av.attrs" :key="aindex2" @click="attrSelect(av.name,aindex,av2,aindex2,av.attrs.length)" :class="{'attrclass':s_attr_falg[aindex].arr[aindex2],'disable':av2.type==0}">
                        {{av2.values}}</li>
                    </template>
                  </ul>
                </div>
              </template>

            </div>
          </div>
          <a href="javascript:;" class="pBtn" @click.stop="paramsSearch">立即查询</a>
        </div>
      </div>

      <div class="primary_search_product">
        <div class="source">
          <a href="" class="active">自营</a>
          <!-- <a href="/search/china-1?v=%E7%94%B5%E5%AE%B9" class="">国内现货</a> -->
        </div>

        <div class="search_product_title">

          <div class="product-price" id="selectSort">
            <span @click.stop="priceSortShowFun" v-clickoutside="handleClose">{{sortNavText}}</span>
            <i></i>
            <div class="price-selct" id="price-selct" v-show="priceSortShow">
              <p v-for="(item, index) in sortNav" :key="index" :class="{selcted:sortNavIdx==index}" @click="sortFun(item.sort)">{{item.text}}</p>
            </div>
          </div>
          <div class="product-stock" id="checkbox" @click="stockFun">
            <i :class="{active:stockFalg}"></i>
            <span>有库存的</span>
          </div>
          <div class="padding20" v-if="productList">
            符合条件的商品：<span>{{productList.total}}</span></div>
        </div>
        <div class="s-wbox" v-loading="isloading">
          <template v-if="productList && productList.records.length>0">
            <ul class="new-product-ul" v-if="productList">
              <li class="new-product-li clearfix" v-for="(v,index) in productList.records" :key="index">
                <div class="pl-col-1 fl" v-if="v.storeCount==99999">
                  <a href="javascript:;" class="ibox">
                    <img v-if="v.goodsImg" :src="v.goodsImg" :alt="v.name+' '+v.packageSpecification">
                    <img v-else src="@/assets/images/goods/default_img.png" :alt="v.name+' '+v.packageSpecification">
                    <i class="xp" v-if="v.goodsType==1"></i>
                    <i class="yz" v-else-if="v.goodsType==2"></i>
                    <i class="tj" v-else-if="v.goodsType==3"></i>
                    <i class="zk" v-else-if="v.goodsType==4"></i>
                    <i class="hd" v-else-if="v.goodsType==5"></i>
                  </a>
                </div>
                <div class="pl-col-1 fl" v-else>
                  <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" class="ibox">
                    <img v-if="v.goodsImg" :src="v.goodsImg" :alt="v.name+' '+v.packageSpecification">
                    <img v-else src="@/assets/images/goods/default_img.png" :alt="v.name+' '+v.packageSpecification">
                    <i class="xp" v-if="v.goodsType==1"></i>
                    <i class="yz" v-else-if="v.goodsType==2"></i>
                    <i class="tj" v-else-if="v.goodsType==3"></i>
                    <i class="zk" v-else-if="v.goodsType==4"></i>
                    <i class="hd" v-else-if="v.goodsType==5"></i>
                  </router-link>
                </div>
                <div class="pl-col-2 fl">
                  <p class="p_name">
                    <a href="javascript:;" v-html="v.name" v-if="v.storeCount==99999"></a>
                    <router-link :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.name" v-else></router-link>
                  </p>
                  <div class="clear">
                    <div class="c1">
                      <p>
                        <span class="tag">编码：</span>
                        <a class="detail" v-if="v.storeCount==99999" href="javascript:;" v-html="v.ksn"></a>
                        <router-link class="detail" v-else :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.ksn"></router-link>
                      </p>
                      <p>
                        <span class="tag">品牌：</span>
                        <a v-if="v.storeCount==99999" href="javascript:;" v-html="v.brandName"></a>
                        <router-link v-else :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.brandName"></router-link>
                      </p>
                      <p class="ms-wrap">
                        <span class="tag">描述：</span>
                        <a v-if="v.storeCount==99999" class="ms" :title="v.goodRemark" v-html="v.goodRemark"></a>
                        <router-link v-else class="ms" :title="v.goodRemark" :to="{path:'/product/detail',query:{id:v.id}}" target="_blank" v-html="v.goodRemark"></router-link>
                      </p>
                    </div>
                    <div class="c2">
                      <p>
                        <span class="tag">型号：<i v-html="v.materialSn"></i></span>
                      </p>
                      <p>
                        <span class="tag">封装：<i v-html="v.packageSpecification"></i></span>
                      </p>

                      <p v-if="v.goodsManual">
                        <a :href="v.goodsManual" target="_blank" class="pdf">数据手册</a>
                      </p>
                      <p v-else>
                        <a href="javascript:;" class="pdf">数据手册：暂无</a>
                      </p>
                    </div>
                    <div class="c3">
                      <p><span class="tag">库存：<i>现货</i></span></p>
                      <p><span class="tag">发货时间：<i>2~7 天发货</i></span></p>
                      <p><span class="tag">包装数量：{{v.packCount}} {{v.storeCompany}}</span></p>
                    </div>
                  </div>
                </div>
                <div class="pl-col-3 fl">
                  <div class="price_list">
                    <div class="tab">
                      <table v-if="v.priceTemplate&&v.priceTemplate.length >0">
                        <tbody>
                          <tr v-for="(vp,index2) in v.priceTemplate" :key="index2">
                            <td class="num">{{vp.num}}+</td>
                            <td class="price">¥{{vp.price}}/{{v.storeCompany}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="pl-col-4 fr">
                  <div class="opera_box">
                    <div class="number-box">
                      <input class="buy-num" :value="v.packCount" maxlength="7" onkeyup="value=value.replace(/[^\d]/ig,'')" @blur="blurFun($event,v.packCount,index)"><span class="add" @click="add($event,v.packCount,index)">+</span><span class="sub" @click="sub($event,v.packCount,index)">-</span>
                      <div class="tip">
                        <em></em>
                        需整盘数量购买，已为您修正
                      </div>
                    </div>
                    <!-- <el-input-number :value="v.setUp" v-model="num" :min="1" :max="10" label="描述文字"></el-input-number> -->
                    <span class="add_cart" v-if="v.storeCount==99999" @click="clickPopBox">立即询价</span>
                    <span class="add_cart" v-else @click="addShopCart(v,index)">加入购物车</span>
                  </div>
                </div>
              </li>
            </ul>
            <div class="page-wrap">
              <el-pagination background hideOnSinglePage layout="prev, pager, next" :total="productList.total" @current-change="currentChange" :current-page="params.pageNum"></el-pagination>
            </div>
          </template>
          <div class="no_product_box" v-if="(productList&&productList.records.length==0&&!isloading) || !productList&&!isloading">
            <img src="@/assets/images/goods/noshop_1.png">
            <p>很抱歉，暂无相关商品，请查询其他的看看</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: this.seoName + '品牌/厂商-现货-价格-参数-封装-规格型号-数据手册-库存-图片查询-合晶芯城',
      meta: [
        {
          name: 'keywords',
          content: '原厂直营' + this.seoName + '现货采购报价，提供相关参数、型号、封装规格、图片、库存查询及数据手册下载，合晶芯城10000多平方米直营现货仓库，提供优质服务，当日发货，价格实惠！'
        },
        {
          name: 'description',
          content: this.seoName + '参数 ' + this.seoName + '型号 ' + this.seoName + '封装规格 ' + this.seoName + '价格 ' + this.seoName + '数据手册'
        }
      ]
    }
  },
  async asyncData ({ route, store }) {
    let getProductMenu = await store.dispatch('lxr/get_product_menu');
    let getProductList = await store.dispatch('lxr/get_product_list', {
      pageNum: route.query.page || 1,
      pageSize: 10,
      categoryId: route.query.cid || '',
      attrs: [],
      seoName: route.query.name || route.query.cname || ''
    })
    return getProductMenu, getProductList
  },
  components: {
  },
  data () {
    return {
      filterLoading: false,
      cname: this.$route.query.cname,
      name: this.$route.query.name,
      productMenuIdx: -1,
      params: {
        pageNum: parseInt(this.$route.query.page) || 1,
        pageSize: 10,
        categoryId: this.$route.query.cid || '',
        attrs: [],
        seoName: this.$route.query.name || this.$route.query.cname || '',
      },
      s_attr: [], //选中属性列表集合 ---  展示
      s_attr_id: [], //选中属性列表集合 --  标识
      s_attr_falg: [],
      isloading: true, sortNavIdx: 0,
      sortNavText: '默认排序',
      stockFalg: false,
      priceSortShow: false,
      sortNav: [
        {
          sort: 0,
          text: '默认排序'
        },
        {
          sort: 1,
          text: '价格升序'
        },
        {
          sort: 2,
          text: '价格降序'
        }
      ],
      shopNumber: Number,
      filterParams: {
        pageNum: parseInt(this.$route.query.page) || 1,
        pageSize: 10,
        categoryId: this.$route.query.cid || '',
        attrs: [],
        seoName: this.$route.query.name || this.$route.query.cname || '',
      },
      filterData: null,
    }
  },
  created () {

  },
  computed: {
    ...mapState('lxr', ['productMenu', 'productList', 'seoName'])
  },

  methods: {
    ...mapActions('lxr', ['get_product_menu', 'get_product_list', 'get_cart_list', 'filter_list']),
    ...mapMutations('lxr', ['GET_PRODUCT_LIST']),
    ...mapActions("lw", ["addCart"]),
    clickPopBox () { window.open("https://hm.baidu.com/hm.js?30d5f0cd8e30265492389905f69cf896", "", "height=600px,width=800px") },
    blurFun (e, packCount, idx) {
      let num = parseInt(e.target.value)
      let a = num
      let b = a % parseInt(packCount)
      if (a > parseInt(packCount)) {
        $('.number-box').eq(idx).find('.sub').attr('style', '')
        if (b != 0) {
          a += parseInt(packCount) - b
          $('.buy-num').eq(idx).next().next().next().show()
          setTimeout(() => {
            $('.buy-num').eq(idx).next().next().next().hide()
          }, 2000);
        }
        if (a > 1000000) {
          let c = 1000000 % parseInt(packCount)
          if (c != 0) {
            a = 1000000 - c
          } else {
            a = 1000000
          }
        }
      } else {
        a = parseInt(packCount)
        $('.number-box').eq(idx).find('.sub').attr('style', 'color:#999')
        $('.buy-num').eq(idx).next().next().next().show()
        setTimeout(() => {
          $('.buy-num').eq(idx).next().next().next().hide()
        }, 2000);
      }
      $('.buy-num').eq(idx).val(a)
    },
    add (e, packCount, idx) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num + parseInt(packCount)
      if (a > 1000000) {
        let b = 1000000 % parseInt(packCount)
        if (b != 0) {
          a = 1000000 - b
        } else {
          a = 1000000
        }
      }
      $('.number-box').eq(idx).find('.sub').attr('style', '')
      $('.buy-num').eq(idx).val(a)
    },
    sub (e, packCount, idx) {
      let num = parseInt(e.target.parentElement.children[0].value)
      let a = num - parseInt(packCount)
      if (a < parseInt(packCount)) {
        a = parseInt(packCount)
        $('.number-box').eq(idx).find('.sub').attr('style', 'color:#999')
      } else {
        $('.number-box').eq(idx).find('.sub').attr('style', '')
      }
      $('.buy-num').eq(idx).val(a)
    },
    async filterList () {
      this.filterLoading = true
      this.filterParams.attrs = []
      this.s_attr_id.forEach((item, index) => {
        if (item.length > 0) {
          let val = ''
          item.forEach(i => {
            val += this.filterData[index].attrs[i].values + '&'
          });
          this.filterParams.attrs.push({ attrValue: val, attrId: this.filterData[index].name })
        }
      });
      let res = await this.filter_list(this.filterParams)
      this.filterLoading = false
      if (res.httpCode == 200) {
        let data = res.data
        if (data && data.length > 0) {
          this.filterData = data
          data.forEach((item, index) => {
            this.s_attr_id.push([])
            this.s_attr.push([])
            this.s_attr_falg.push({ arr: [] })
            item.attrs.forEach((i, d) => {
              this.s_attr_falg[index].arr.push(false)
            })
          });
        }
      }
    },
    async getCartList () {
      let res = await this.get_cart_list()
    },
    async addShopCart (v, index) {
      let params = {
        customerSn: v.materialSn,
        goodsId: v.id,
        goodsName: v.name,
        goodsNum: $('.buy-num').eq(index).val(),
        goodsImage: v.goodsImg,
        goodsCategoryName: v.categoryName,
        price: v.price,
        priceTemplate: JSON.stringify(v.priceTemplate),
        setUp: v.setUp,
        storeCount: v.storeCount,
        categoryId: v.categoryId,
        stepSample: v.stepSample,
        packCount: v.packCount,
        brandName: v.brandName
      };
      let res = await this.addCart(params)
      if (res.httpCode == 200) {
        // this.$router.push({ path: '/cart' })
        this.getCartList()
        this.$message.success('添加成功');
      } else {
        this.$message.error(res.message);
      }
    },
    handleClose () {
      this.priceSortShow = false
    },
    priceSortShowFun () {
      this.priceSortShow = !this.priceSortShow
    },
    currentChange (e) {
      this.params.pageNum = e
      //this.getProductList()
    },
    delHtmlItem (idx, valIdx, i, id) {
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      this.s_attr[i].splice(id, 1)
      this.s_attr_id[i].splice(id, 1)
      this.filterList()
    },
    //参数属性单击
    attrSelect (name, idx, val, valIdx, length) {
      if (val.type != 1) {
        return
      }
      this.s_attr_falg[idx].arr[valIdx] = !this.s_attr_falg[idx].arr[valIdx]
      if (this.s_attr_id[idx].indexOf(valIdx) >= 0) {
        let delIdx = this.s_attr_id[idx].indexOf(valIdx)
        this.s_attr_id[idx].splice(delIdx, 1)
        this.s_attr[idx].splice(delIdx, 1)
      } else {
        this.s_attr_id[idx].push(valIdx)
        this.s_attr[idx].push({ name: name, idx: idx, val: val.values, valIdx: valIdx })
      }

      this.filterList()
    },
    paramsSearch () {
      this.params.attrs = []
      this.s_attr_id.forEach((item, index) => {
        if (item.length > 0) {
          let val = ''
          item.forEach(i => {
            val += this.filterData[index].attrs[i].values + '&'
          });
          this.params.attrs.push({ attrValue: val, attrId: this.filterData[index].name })
        }
      });
      this.getProductList()
    },
    stockFun () {
      this.stockFalg = !this.stockFalg
      if (this.stockFalg) {
        this.params.storeCount = 1
      } else {
        delete this.params.storeCount
      }
      this.getProductList()
    },
    sortFun (sort) {
      if (sort == 1) { //升序
        this.params.sort = 1
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else if (sort == 2) { //降序
        this.params.sort = 2
        this.sortNavIdx = sort
        this.sortNavText = this.sortNav[sort].text
      } else {
        delete this.params.sort
      }
      this.getProductList()
    },
    categorySearch (item, type, index, i) {
      if (type == 1) {
        if (this.cname == item.nameCh) {
          return false
        }
      }
      if (type == 2) {
        if (this.name == item.nameCh) {
          return false
        }
      }
      this.s_attr_id = []
      this.s_attr = []
      this.s_attr_falg = []
      this.params.attrs = []
      this.params.pageNum = 1
      this.params.categoryId = item.id
      if (type == 1) {
        this.productMenuIdx = index
        this.cname = item.nameCh;
        this.$router.push({ path: '/product/level', query: { cid: this.params.categoryId, cname: this.cname, page: this.params.pageNum } })
      } else if (type == 2) {
        this.name = item.nameCh;
        this.$router.push({ path: '/product/level', query: { cid: this.params.categoryId, cname: this.cname, name: this.name, page: this.params.pageNum } })
      } else {
        this.productMenuIdx = index
        this.cname = i.nameCh;
        this.name = item.nameCh;
        this.$router.push({ path: '/product/level', query: { cid: this.params.categoryId, cname: this.cname, name: this.name, page: this.params.pageNum } })
      }
      this.filterParams = {
        pageNum: 1,
        pageSize: 10,
        categoryId: item.id,
        attrs: [],
        seoName: '',
      }
      this.filterList()
    },
    async getProductMenu () {
      let res = await this.get_product_menu()
      if (res.httpCode == 200) {
        this.getProductMenuIdx()
      }
    },
    async getProductList () {
      this.isloading = true
      let res = await this.get_product_list(this.params)
      if (!res) {
        this.isloading = false
        this.GET_PRODUCT_LIST('')
        return
      }
      if (res.httpCode == 200) {
        this.isloading = false
        // if (res.data.object.QueryItems && res.data.object.QueryItems.length > 0) {
        //   res.data.object.QueryItems.forEach((item, index) => {
        //     this.s_attr_id.push([])
        //     this.s_attr.push([])
        //     this.s_attr_falg.push({ arr: [] })
        //     item.attrs.forEach((i, d) => {
        //       this.s_attr_falg[index].arr.push(false)
        //     })
        //   });
        // }
      }
    },
    getProductMenuIdx () {
      this.productMenu.forEach((item, index) => {
        if (item.nameCh == this.cname) {
          this.productMenuIdx = index
        }
      });

    },
    mousemove (index) {
      let offsetWidth = this.$refs['one' + index][0].offsetWidth
      let offsetLeft = this.$refs['one' + index][0].offsetLeft
      console.log(this.$refs['one' + index])
      this.$refs['one' + index][0].children[0].children[0].firstChild.style.left = (offsetLeft + offsetWidth / 2 - 12) + 'px'
      this.$refs['one' + index][0].children[0].children[0].children[1].style.left = (offsetLeft + offsetWidth / 2 - 11) + 'px'
    }
  },
  mounted () {
    if (!this.productMenu) {
      this.getProductMenu()
    } else {
      this.getProductMenuIdx()
    }


    //初始化商品列表
    if (!this.productList) {
      this.getProductList()
    } else {
      this.isloading = false
      // if (this.productList.object.QueryItems && this.productList.object.QueryItems.length > 0) {
      //   this.productList.object.QueryItems.forEach((item, index) => {
      //     this.s_attr_id.push([])
      //     this.s_attr.push([])
      //     this.s_attr_falg.push({ arr: [] })
      //     item.attrs.forEach((i, d) => {
      //       this.s_attr_falg[index].arr.push(false)
      //     })
      //   });
      // }
    }
    if (this.$route.query.cid) {
      this.filterList()
    }
    // this.filterList()
  },
  filters: {
  },
  watch: {
    // 'params.categoryId' () {
    //   this.$router.push({ path: '/product/level', query: { cid: this.params.categoryId, cname: this.cname, name: this.name, page: this.params.pageNum } })
    // },
    'params.pageNum' () {
      this.$router.push({ path: '/product/level', query: { cid: this.params.categoryId, cname: this.cname, name: this.name, page: this.params.pageNum } })
    },
    '$route' () {
      this.params.categoryId = this.$route.query.cid
      this.params.seoName = this.$route.query.name || this.$route.query.cname
      this.cname = this.$route.query.cname
      this.name = this.$route.query.name
      // this.$router.push({ path: '/product/level', query: { cid: this.params.categoryId, cname: this.cname, name: this.name, page: this.params.pageNum } })
      this.getProductMenuIdx()
      this.getProductList()
    },
  },
  beforeDestroy () {
    this.GET_PRODUCT_LIST('')
  }

}
</script>
<style lang="scss">
</style>
