var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav clear"},[_c('div',{staticClass:"nav-box"},[_c('div',{staticClass:"nav-wrap clearfix"},[_c('div',{staticClass:"sort",attrs:{"id":"productMenu"},on:{"mousemove":_vm.mousemoveFun,"click":function($event){$event.stopPropagation();return _vm.linkToProduct($event)}}},[_vm._m(0),(_vm.falg)?_c('div',{staticClass:"navbox"},[_c('div',{staticClass:"ass ass3"},[(_vm.productMenuIndex)?_c('ul',{staticClass:"ass_ul",on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.productMenuIndex),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(index<7),expression:"index<7"}],key:index,staticClass:"ass_list",on:{"mousemove":function($event){_vm.iconFalg=index}}},[_c('a',{staticClass:"nav_menu_title"},[(_vm.iconFalg==index)?_c('img',{staticClass:"icon ",attrs:{"src":item.image2}}):_c('img',{staticClass:"icon",attrs:{"src":item.image}}),_vm._v("\n                  "+_vm._s(item.nameCh)+"\n                  "),_c('i',{staticClass:"ass_list_right"})]),_c('div',{staticClass:"nav_menu_box"},[_c('div',{staticClass:"l"},[_c('div',{staticClass:"nav_menu"},[_c('div',{staticClass:"nav_menu_item clear"},[(item.subCategory)?_c('ul',{staticClass:"clear"},_vm._l((item.subCategory),function(i2,d2){return _c('li',{key:d2},[_c('router-link',{attrs:{"to":{
                            path: '/product/level',
                            query: {
                              cid: i2.id, 
                              fcid: item.id,
                              cname: item.nameCh,
                              name: i2.nameCh
                            }
                          }}},[_vm._v(_vm._s(i2.nameCh))]),_c('i')],1)}),0):_vm._e()])])])])])}),0):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"snav"},[_c('router-link',{staticClass:"first",class:{hover:_vm.hover.indexOf('/index')>=0},attrs:{"to":"/index"}},[_vm._v("商城首页")]),_c('router-link',{class:{hover:_vm.hover.indexOf('/product')>=0},attrs:{"to":"/product/level"}},[_vm._v("产品中心")]),_c('router-link',{class:{hover:_vm.hover.indexOf('/brand')>=0},attrs:{"to":"/brand"}},[_vm._v("品牌专区")]),_c('router-link',{class:{hover:_vm.hover.indexOf('/bom')>=0},attrs:{"to":"/bom"}},[_vm._v("BOM配单")]),_c('router-link',{class:{hover:_vm.hover.indexOf('/sample')>=0},attrs:{"to":"/sample"}},[_vm._v("样品中心")]),_c('router-link',{class:{hover:_vm.hover.indexOf('/service')>=0},attrs:{"to":"/service"}},[_vm._v("服务中心")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"type-text"},[_c('span'),_vm._v("商品分类\n        ")])}]

export { render, staticRenderFns }