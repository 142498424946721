import Vue from 'vue'
import https from '@/https.js'
const initState = {
  /* enterpriseSerialNumber:'', */
}

const state = {
  checkDisplay: false, //签到 
  scoreList: null, // 积分列表
  newsBanner: null, // 新闻广告
  indexBanner: null, // 首页banner
  helpInfo: null, // 帮助中心信息
  helpList: null,
}

const mutations = {
  SET_CHECK_DISPLAY(state, data) {
    state.checkDisplay = data
  },
  // 获取积分列表
  GET_SCORE_LIST(state, data) {
    state.scoreList = data.data.records
  },
  // 新闻广告
  GET_NEWS_BANNER(state, data) {
    state.newsBanner = data.data
  },
  // 首页广告
  GET_NEWS_INDEX_BANNER(state, data) {
    state.indexBanner = data.data
  },
  // 帮助中心信息
  GET_HELP_LIST(state, data) {
    state.helpList = data.data
  },
  // 帮助中心信息
  GET_HELP_INFO(state, data) {
    state.helpInfo = data.data
  },

  //商品列表
  GET_PRODUCT_LIST(state, data) {
    if (data) {
      if (data.data.records.length > 0) {
        data.data.records.forEach((item, index) => {
          if (typeof (item.priceTemplate) == 'string') {
            if (item.priceTemplate.length > 1) {
              item.priceTemplate = JSON.parse(item.priceTemplate)
            }
          }
        });
      }
      state.productList = data.data
    } else {
      state.productList = null
    }
  },
}
const actions = {

  //添加积分
  addScore({
    commit
  }, params = {}) {
    return https.fetchPost('addScore', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  //查询积分 
  queryScore({
    commit
  }, params = {}) {
    return https.fetchPost('queryScore', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  // 用户签到列表  
  querySignInPage({
    commit
  }, params = {}) {
    return https.fetchPost('querySignInPage', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  // 获取签到信息
  querySignInfo({
    commit
  }, params = {}) {
    return https.fetchGet('querySignInfo', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },

  // 新增签到  
  addSignInfo({
    commit
  }, params = {}) {
    return https.fetchPost('addSignInfo', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  // 签到统计 
  getSign({
    commit
  }, params = {}) {
    return https.fetchGet('getSign', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  // 新闻广告
  getNewsBanner({
    commit
  }, params = {}) {
    return https.fetchPost('getNewsBanner', params)
      .then(data => {
        commit('GET_NEWS_BANNER', data.data)
        return data.data
      }).catch(err => {})
  },
  // 首页广告
  getNewsIndexBanner({
    commit
  }, params = {}) {
    return https.fetchPost('getNewsBanner', params)
      .then(data => {
        commit('GET_NEWS_INDEX_BANNER', data.data)
        return data.data
      }).catch(err => {})
  },
  // 帮助中心信息
  getHelpList({
    commit
  }, params = {}) {
    return https.fetchPost('getHelpInfo', params)
      .then(data => {
        commit('GET_HELP_LIST', data.data)
        return data.data
      }).catch(err => {})
  },

  // 帮助中心信息
  getHelpInfo({
    commit
  }, params = {}) {
    return https.fetchPost('getHelpInfo', params)
      .then(data => {
        commit('GET_HELP_INFO', data.data)
        return data.data
      }).catch(err => {})
  },


  // bom匹配查询 
  bomQuery({
    commit
  }, params = {}) {
    return https.fetchPost('bomQuery', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  // 模板下载 
  downloadTpl({
    commit
  }, params = {}) {
    return https.fetchGet('downloadTpl', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  // 用户统计 
  getUserInfoCount({
    commit
  }, params = {}) {
    return https.fetchGet('getUserInfoCount', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  // 加入购物车 
  addCart({
    commit
  }, params = {}) {
    return https.fetchPost('addCart', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
  // 批量加入购物车 
  batchCart({
    commit
  }, params = {}) {
    return https.fetchPost('batchCart', params)
      .then(data => {
        return data.data
      }).catch(err => {})
  },
}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}