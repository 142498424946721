<template>
  <div class="header-wrap clearfix">
    <div class="t-header-bg">
      <div class="t-header">
        <!-- <div class="announcement fl">
          <a href="javascript:;">【放假通知】尊敬的用户：合晶芯城将于2021年5月1日-5日放假，期间将暂停人工服务。祝您节日快乐！</a>
        </div> -->
        <div class="other fr">
          <template v-if="token&&userInfo">
            <router-link to="/home/user" class="name">{{userInfo.nickname}}</router-link>
            <a href="javascript:;" class="exit" @click="userLogout">退出</a>
            <router-link to="/home/order">我的订单</router-link>
            <router-link class="messageWrap" to="/home/message">消息<i v-if="message>0"></i></router-link>
          </template>
          <template v-else>
            <router-link to="/login" rel="nofollow">登录</router-link>
            <router-link to="/register" rel="nofollow" class="reg">注册有礼</router-link>
          </template>
          <a href="javascript:;">客服电话：18823438533(微信同号)</a>
        </div>
      </div>
    </div>
    <div class="b-header clearfix">
      <div class="b-header-width clearfix">
        <router-link to="/" class="logos">
          <img src="@/assets/images/login/logo.svg">
        </router-link>
        <div class="search-wrap">
          <div class="search-bd">
            <input type="text" class="search-int" autocomplete="off" @keyup.enter="getProductList(params.queryValue)" id="search-input" @input="getSearchKey" placeholder="请输入型号或参数，多个关键词用空格隔开" value="" v-model.trim="params.queryValue">
            <input type="button" name="" class="search-btn" value="搜索" @click="getProductList(params.queryValue)" v-clickoutside="handleClose">
            <div class="search-list" v-if="searchKey.length>0">
              <ul>
                <li v-for="(item, index) in searchKey" :key="index" @click="getProductList(item.name)">
                  <span>{{item.name}}</span>
                  <span class="right">约<i>{{item.totalCount}}</i>条结果</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="search-hot" v-if="logoActive">热搜:
            <a v-for="(item, index) in hotKeywordList" :key="index" href="javascript:;" @click="getProductList(item.name)">{{item.name}}</a>
          </div>
        </div>
        <div class="shopCart" @click="setCartFalg">
          <span class="cart"><span v-if="cartLength!=0" class="num">{{cartLength}}</span></span>
          <span class="text">我的购物车</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { calUtil } from '@/assets/calendar'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'pSearch',
  components: {
    // userItem,
    // Check
  },
  data () {
    return {
      notice: false,
      logoActive: true,
      scrollTop: 0,
      queryValue: '',
      params: {
        attrs: [],
        categoryId: "",
        language: 1,
        pageNum: 1,
        pageSize: 10,
        queryValue: this.$route.query.v,
      },
      signData: '',
      jilu: false,
      shuoming: false,
      curr_month: '', // 月
      curr_year: '', // 年
      curr_date: '', // 日
      isact: false,
      sign_flag: 0, // 是否签到
      signData: '',
      signInfo: '',
      par: {
        "pageNum": 1,
        "pageSize": 10,
        "status": "",//查看状态(0未查看|1已查看) ,
      },
      total: 0,
      searchKey: [],
      message: 0,
    }
  },

  created () {
  },
  computed: {
    ...mapState('lxr', ['token', 'hotKeywordList', 'cartLength', 'userInfo', 'productList']),
    ...mapState('lw', ['checkDisplay']),
  },
  methods: {
    ...mapMutations('lxr', ['setCartFalg', 'GET_PRODUCT_LIST', 'SET_TOKEN']),
    ...mapActions('lxr', ['get_product_list', 'hot_keyword', 'get_message_list', 'get_search_key']),
    ...mapActions('lxr', ['user_logout', 'get_cart_list', 'get_message_num']),
    async getMessageNum () {
      let res = await this.get_message_num()
      if (res.httpCode == 200) {
        this.message = res.data
      }
    },
    handleClose () {
      this.searchKey = []
    },
    async getCartList () {
      let res = await this.get_cart_list()
    },
    async getSearchKey () {
      if (this.params.queryValue) {
        let res = await this.get_search_key({
          name: this.params.queryValue,
          language: 1,
          pageNum: 1,
          pageSize: 8,
        })
        if (res.httpCode == 200) {
          this.searchKey = res.data.records
        }
      } else {
        this.searchKey = []
      }

    },
    async getMessageList () {
      let res = await this.get_message_list(this.par)
      if (res.httpCode == 200) {
        if (res.data) {
          this.total = res.data.total
        }
      }
    },
    async userLogout () {
      let res = await this.user_logout()
      if (res.httpCode == 200) {
        localStorage.removeItem('access-token');
        localStorage.removeItem('userInfo');
        this.SET_TOKEN('')
        this.$router.push({ path: '/index' })
        this.getCartList()
      }
    },
    handleScroll () {
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // 设备/屏幕高度
      let scrollTop = $(window).scrollTop(); // div 到头部的距离
      if (scrollTop > 131) {
        $('.b-header').css("top", "-131px").addClass("active").animate({ top: 0 })
        this.logoActive = false
      } else {
        $(".b-header").removeClass("active");
        this.logoActive = true
      }
    },
    async getProductList (val) {
      this.GET_PRODUCT_LIST('')
      this.params.queryValue = val
      if (!this.params.queryValue) {
        return
      }
      // this.GET_PRODUCT_LIST('')
      this.$router.push({ path: '/search', query: { v: val } })
      this.searchKey = []
      // let res = await this.get_product_list(this.params)
      // if (res.httpCode == 200) {
      //   this.GET_PRODUCT_LIST(res)
      //   this.$router.push({ path: '/search', query: { v: val } })
      //   this.searchKey = []
      // }
    },
    async hotKeyword () {
      let res = await this.hot_keyword({
        pageNum: '1',
        pageSize: '10',
      })
      if (res.httpCode == 200) {
      }
    },
  },
  mounted () {

    var _this = this
    _this.handleScroll()
    window.addEventListener('scroll', _this.handleScroll, true);
    if (!this.hotKeywordList) {
      this.hotKeyword()
    }
    if (!this.token) {
      this.SET_TOKEN(localStorage.getItem('access-token'))
      this.getMessageNum()
    } else {
      this.getMessageNum()
    }
  },
  watch: {
    '$route' () {
      this.params.queryValue = this.$route.query.v;
      if (this.token) {
        this.getMessageNum()
      }
    }
  },
  beforeDestroy () {
    //calUtil.eventName = "load";
  }
}
</script>