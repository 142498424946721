<template>
  <div class="r userCenter_container_box">
    <h2 class="ptitle">样品订单管理</h2>
    <div class="user-nav">
      <div class="wrap">
        <a href="javascript:;" :class="{active:params.data.orderStatus===''&&params.data.orderStatus!==0}" @click="statusNavFun('')">全部订单</a>
        <a href="javascript:;" v-for="(item, index) in statusNav" :key="index" :class="{active:params.data.orderStatus==item.status && params.data.orderStatus!==''}" @click="statusNavFun(item.status)">{{item.text}}</a>
      </div>
    </div>
    <div class="filter-wrap">
      <el-date-picker v-model="params.data.buyStartTime" value-format='yyyy-MM-dd' type="date" placeholder="选择下单日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <span class="ctext">至</span>
      <el-date-picker v-model="params.data.buyEndTime" value-format='yyyy-MM-dd' type="date" placeholder="选择下单日期" id="begin_time" :picker-options="pickerOptions">
      </el-date-picker>
      <div class="fr f-search">
        <input name="keyword" class="search-input" type="text" placeholder="请输入订单号/BOM标识/关键字" v-model.trim="params.data.search"><em id="search" @click="getOrderList"></em>
      </div>
    </div>
    <div class="userCenter_container orderM_contain">
      <!-- <div class="orderM_top clear">
        <div class="advanced_filter clear">
          <span>订单状态</span>
          <a href="javascript:;" :class="{active:params.data.orderStatus===''&&params.data.orderStatus!==0}" @click="statusNavFun('')">全部</a>
          <a href="javascript:;" v-for="(item, index) in statusNav" :key="index" :class="{active:params.data.orderStatus==item.status && params.data.orderStatus!==''}" @click="statusNavFun(item.status)">{{item.text}}</a>
        </div>
      </div>

      <div class="order-tatal">符合条件的共<i>{{total}}</i>个订单</div> -->

      <div class="orderM_title clear">
        <span class="one">商品信息</span>
        <span class="two">我的料号</span>
        <span class="three">单价(含增值税)</span>
        <span class="three">数量(pcs)</span>
        <span class="four">金额(含增值税)</span>
      </div>
      <div class="orderM_body_box" v-loading="isloading">
        <template v-if="info">
          <div class="orderM_body" v-for="(item, index) in info" :key="index">
            <div class="orderM_body_head clear">
              <div class="one l">
                <em>{{item.createTime | calcTime}}</em>
                <b>订单编号：<i><a href="javascript:;"><span class="order_sn">{{item.orderSn}}</span></a></i><span class="Invoice" v-if="item.status&&item.orderStatus!=0&&item.orderStatus!=4&&item.orderStatus!=5" :class="{yes:item.status==1}">（{{item.status==1?'已开票':'未开票'}}）</span></b>
                <span class="wfh">
                  <template v-if="item.orderStatus==-2">
                    审核不通过
                    <!-- <i class="time-out">（3000秒后自动取消）</i> -->
                  </template>
                  <!-- <template v-if="item.orderStatus==-1">
                    待审核
                  </template>
                  <template v-if="item.orderStatus==0">
                    待付款
                  </template> -->
                  <template v-if="item.orderStatus==-1 || item.orderStatus==0 || item.orderStatus==1">
                    待发货
                  </template>
                  <template v-if="item.orderStatus==2">
                    已发货
                  </template>
                  <template v-if="item.orderStatus==3">
                    <i>已完成</i>
                  </template>
                  <template v-if="item.orderStatus==4">
                    <i>已取消</i>
                  </template>
                </span>
                <!-- <i class="add-bom true">BOM标识：<em v-if="!info.bom"></em><i v-else>{{info.bom}}</i></i> -->
                <input name="bom" class="addBom_inp" type="text" maxlength="30" style="display:none">
              </div>
              <div class="two l"></div>
              <div class="three r">
                <div class="deleted" @click="delOrder(item.id)">删除订单</div>
                <div class="cancel" v-if="item.orderStatus!=4&&item.orderStatus!=5&&item.orderStatus<=1" @click="cancelOrder(item.id)">取消订单</div>
                <!-- cancelOrder -->
              </div>
            </div>

            <div class="order_body clear">
              <ul class="clear" v-if="item.hktOrderGoods&&item.hktOrderGoods.length>0">
                <li class="clear" v-for="(i, idx) in item.hktOrderGoods" :key="idx">
                  <img v-if="i.goodsImg" :src="i.goodsImg">
                  <img v-else src="@/assets/images/goods/default_img.png">
                  <div class="order-list l">
                    <p class="title">
                      <router-link :to="{path:'/product/detail',query:{id:i.goodsId}}">{{i.goodsName}}</router-link>
                    </p>
                    <p class="lh">制造商料号：{{i.materialSn}}</p>
                    <div class="xx">
                      <span>{{i.goodsCategoryName}}</span>
                      <i class="brand_name_u">更多信息</i>
                      <div class="brand_details">
                        <em></em>
                        <div>品牌: {{i.brandName}} &nbsp;&nbsp;参数规格: {{i.specParam}}</div>
                      </div>
                    </div>
                  </div>
                  <p class="order-wl l"><i></i>{{i.customerSn}}</p>
                  <p class="order-dj l">￥{{i.goodsPrice}}</p>
                  <p class="order-num l">
                    <span>×{{i.goodsNum}} pcs</span>
                    <router-link :to="{path:'/sample/detail',query:{id:i.goodsId}}" class="getonce confirmReceipt">再次领取</router-link>
                  </p>
                  <p class="order-litotal l">
                    <span>￥{{i.goodsAmount}}</span>
                  </p>
                </li>
              </ul>
              <div class="order-body-total clear">
                <div class="l">
                  <span class="">原价:<em>￥{{item.goodsPrice | calcPriceFun}}</em>订单总金额（运费：<i>¥{{item.shippingPrice}}</i>&nbsp;&nbsp;&nbsp;折扣：<i>¥{{item.discount | calcPriceFun}}）</i>：</span>
                  <span class="order-total-price">￥{{item.totalAmount | calcPriceFun}}</span>
                </div>
                <div class="r">
                  <router-link :to="{path:'/home/order/detail',query:{id:item.orderSn}}">订单详情</router-link>
                  <router-link v-if="item.orderStatus==0" class="btn gopay" :to="{path:'/order/pay',query:{id:item.id}}">去付款</router-link>
                </div>
              </div>
            </div>

          </div>
        </template>
        <div class="no-order" v-if="info&&info.length==0">
          <img src="@/assets/images/goods/noshop_1.png" alt="">
          <p>暂无订单</p>
        </div>
        <div class="page-wrap" v-if="total>10">
          <el-pagination background hideOnSinglePage @current-change="currentChange" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '个人中心-订单管理',
    }
  },
  data () {
    return {
      timeHtml: '',
      value1: '',
      value2: '',
      addCart: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      isAll: true,
      statusNav: [
        {
          status: -2,
          text: '无货'
        },
        // {
        //   status: -1,
        //   text: '待审核'
        // },
        // {
        //   status: 0,
        //   text: '待付款'
        // },
        {
          status: 1,
          text: '待发货'
        },
        {
          status: 2,
          text: '已发货'
        },
        {
          status: 3,
          text: '已完成'
        }, {
          status: 4,
          text: '已取消'
        },
      ],
      params: {
        "data": {
          "buyEndTime": "",
          "buyStartTime": "",
          "orderStatus": "",
          "search": "",
          isSample: 1,
        },
        "pageNum": 1,
        "pageSize": 10
      },
      info: null,
      total: 0,
      isloading: true,
      h: '',
      m: '',
      s: ''
    }
  },

  created () {
    this.params.data.orderStatus = this.$route.query.pay_status != undefined ? this.$route.query.pay_status : ''
  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['get_order_list', 'del_order', 'cancel_order']),
    statusNavFun (status) {
      this.params.data.orderStatus = status
      this.getOrderList()
    },
    async getOrderList () {
      let res = await this.get_order_list(this.params)
      if (res.httpCode == 200) {
        this.info = res.data.records
        this.total = res.data.total
        this.isloading = false
        // this.countTime()
      }
    },
    delOrder (id) {
      this.$confirm("确定删除该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.del(id)
        })
        .catch(() => { });
    },

    async del (id) {
      let res = await this.del_order({ id: id })
      if (res.httpCode == 200) {
        this.getOrderList()
      }
    },

    cancelOrder (id) {
      this.$confirm("确定取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.cancel(id)
        })
        .catch(() => { });
    },

    async cancel (id) {
      let res = await this.cancel_order({ id: id })
      if (res.httpCode == 200) {
        this.getOrderList()
      }
    },

    currentChange (e) {
      this.params.pageNum = e
      this.getOrderList()
    },

  },
  mounted () {
    this.getOrderList()
  },
  filters: {
    calcTime (val) {
      return val.substr(0, val.length - 5)
    },
    calcPriceFun (val) {
      return parseFloat(val).toFixed(2);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-orderManage.scss";
.orderM_top /deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
.orderM_top /deep/.el-input__icon {
  line-height: 36px;
}
</style>


