<template>
  <div class="newMain">
    <div class="service-con">
      <div class="auto">
        <h3 class="service-title">完善的客服体系</h3>
        <p class="service-tip">我们建立了完善的客服体系，配备了专业的客服人员，能满足您的极致要求！</p>
        <ul class="service-wrap clearfix">
          <li>
            <h3>产品技术问题</h3>
            <div class="contact-info">
              <div class="qq"><span>QQ：3002484866 </span><i onclick="window.open('tencent://message/?uin=3002484866','_self')">立即咨询</i>
              </div>
              <div class="phone"><span>电话：18823438533(微信同号)</span></div>
              <div class="email"><span>邮箱：hkic@hotking.com</span></div>
            </div>
          </li>
          <li>
            <h3>商务采购问题</h3>
            <div class="contact-info">
              <div class="qq"><span>QQ：3002484866 </span><i onclick="window.open('tencent://message/?uin=3002484866','_self')">立即咨询</i>
              </div>
              <div class="phone"><span>电话：18823438533(微信同号)</span></div>
              <div class="email"><span>邮箱：hkic@hotking.com</span></div>
            </div>
          </li>
          <li>
            <h3>支付中遇到的问题</h3>
            <div class="contact-info">
              <div class="qq"><span>QQ：3002484866 </span><i onclick="window.open('tencent://message/?uin=3002484866','_self')">立即咨询</i>
              </div>
              <div class="phone"><span>电话：18823438533(微信同号)</span></div>
              <div class="email"><span>邮箱：hkic@hotking.com</span></div>
            </div>
          </li>
          <li>
            <h3>关于送货的问题</h3>
            <div class="contact-info">
              <div class="qq"><span>QQ：3002484866 </span><i onclick="window.open('tencent://message/?uin=3002484866','_self')">立即咨询</i>
              </div>
              <div class="phone"><span>电话：18823438533(微信同号)</span></div>
              <div class="email"><span>邮箱：hkic@hotking.com</span></div>
            </div>
          </li>
          <li>
            <h3>关于售后的问题</h3>
            <div class="contact-info">
              <div class="qq"><span>QQ：3002484866 </span><i onclick="window.open('tencent://message/?uin=3002484866','_self')">立即咨询</i>
              </div>
              <div class="phone"><span>电话：18823438533(微信同号)</span></div>
              <div class="email"><span>邮箱：hkic@hotking.com</span></div>
            </div>
          </li>
          <li>
            <h3>投诉与建议</h3>
            <div class="contact-info">
              <div class="qq"><span>QQ：3002484866 </span><i onclick="window.open('tencent://message/?uin=3002484866','_self')">立即咨询</i>
              </div>
              <div class="phone"><span>电话：18823438533(微信同号)</span></div>
              <div class="email"><span>邮箱：hkic@hotking.com</span></div>
            </div>
          </li>
        </ul>
        <div class="questions-wrap">
          <h3 class="title">常见问题</h3>
          <ul>
            <li>
              <h3>如何订购商品？</h3>
              <p>您可以首先浏览我们的网站了解商品。看到您满意的商品您可以直接在我们的网站上添加到购物车，然后下订单即可。</p>
            </li>
            <li>
              <h3>有哪些快递公司可以选择？</h3>
              <p>合晶芯城目前有顺丰速递、速尔快递、龙邦快递，您在下单时，请根据需要选择快递公司即可。</p>
            </li>
            <li>
              <h3>合晶芯城的发票如何开具？</h3>
              <p>合晶芯城可以为所有订单开具增值税发票，所有产品均按国家要求进行正规的纳税。客户可以在提交订单时选择“我需要开发票”中选择电子发票或者纸质发票中提交，并且在发票信息栏完善开票信息，最后提交开发票申请即可。
                备注：纸质发票需订单金额累计超过500元，电子发票无订单金额要求！</p>
            </li>
            <li>
              <h3>合晶芯城接受电话询价吗？</h3>
              <p>可以。客户有需求均可以致电客服热线0755-21005112，我们会为您进行人工查询。但由于网上订单涉及到个人信息，我们推荐客户具体下订单在网上操作完成。</p>
            </li>
            <li>
              <h3>为什么要注册会员？</h3>
              <p>只有注册用户才可以在网上进行订购，享受优惠的价格。<br>
                只有注册用户才可以登录"会员中心"，使用更多的会员购物功能,管理自己的资料。<br>只有注册用户才有可能获取我们的赠品或者其它礼品。</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  metaInfo () {
    return {
      title: '服务中心',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城原装正品电子元器件，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器'
        }
      ]
    }
  },
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>
<style lang="scss">
</style>