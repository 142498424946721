<template>
  <div class="hkt_container clear">
    <div class="product-all">
      <span>全部商品</span>
      <span class="total">{{productCount}}</span>
    </div>
    <div class="product-search ">
      <div class="commodity_search_inp clear">
        <div class="brand-search l" data-id="0">
          <div class="brand-current-img" v-clickoutside="handleClose" @click.stop="moreClick">
            <template v-if="brandLogo">
              <img :src="brandLogo" alt="">
            </template>
            <template v-else>
              搜索全部品牌
            </template>
          </div>
          <i></i>
          <template v-if="brandList">
            <ul class="brand-img" v-show="more">
              <li v-for="(item, index) in brandList" :key="index" :brandId='item.brandId' @click="selectBrand(item.brandId,item.logo)">
                <img class="l" v-if="item.logo" :src="item.logo">
                <img class="l" v-else src="@/assets/images/brand/default.png" :alt="item.name">
                <p>{{item.name}}</p>
              </li>
            </ul>
          </template>
        </div>

        <div class="r">
          <input type="text" class="searchProduct" placeholder="输入制造商料号、参数规格、品牌等关键词" data-event="brandsearch" maxlength="25" v-model.trim="queryValue">
          <i v-if="btnFalg" class="focus" @click="linkSearch()"></i>
          <i v-else></i>
        </div>

      </div>
    </div>

    <div class="commodity_contain">
      <div class="primary_classify_box">
        <h3></h3>
        <div class="secondary_classify_box clear" v-if="productMenu">
          <div class="filter-second clear" v-for="(item, index) in productMenu" :key="index">
            <h4>
              <img :src="item.image">
              <router-link :to="{path:'/product/level',query:{cid:item.id,cname:item.nameCh}}">{{item.nameCh}}({{item.onSaleNum?item.onSaleNum:0}})</router-link>
            </h4>
            <div class="threeLevel_classify_box" v-if="item.subCategory.length>0">
              <router-link v-for="(i, idx) in item.subCategory" :key="idx" class="six" :to="{path:'/product/level',query:{cid:i.id,cname:item.nameCh,fcid:item.id,name:i.nameCh}}">{{i.nameCh}}({{i.onSaleNum?i.onSaleNum:0}})</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  metaInfo () {
    return {
      title: '电子元器件产品大全 - IC交易网 - 电子元器件采购 - 合晶芯城',
      meta: [
        {
          name: 'keywords',
          content: '电子元器件,IC交易网,电子元件采购,电子元件'
        },
        {
          name: 'description',
          content: '合晶芯城是电子元器件采购首选的一站式电商平台。拥有10000多平方米现代化元器件仓库，自营现货库存超100000种。满足各品牌原装正品电子元器件采购需求，涵盖电容器、IC芯片、电阻器、晶体管、传感器、晶振、二极管、传感器等。'
        }
      ]
    }
  },
  asyncData ({ store }) {
    let getBrand = store.dispatch('lxr/get_brand');
    let getProductMenu = store.dispatch('lxr/get_product_menu');
    let getProductCount = store.dispatch('lxr/get_product_count');
    return getProductCount, getBrand, getProductMenu
  },
  components: {
  },
  data () {
    return {
      more: false,
      brandId: '',
      brandLogo: '',
      queryValue: '',
      btnFalg: '',
    }
  },

  created () {

  },
  computed: {
    ...mapState('lxr', ['brandList', 'productMenu', 'productCount'])
  },
  methods: {
    ...mapActions('lxr', ['get_brand', 'get_product_menu', 'get_product_count']),
    ...mapMutations('lxr', ['GET_BRAND', 'GET_PRODUCT_MENU', 'GET_PRODUCT_COUNT']),
    selectBrand (brandId, logo) {
      this.brandId = brandId
      this.brandLogo = logo
    },
    linkSearch () {
      let params = {
        v: this.queryValue
      }
      if (this.brandId) {
        params.bid = this.brandId
      }
      this.$router.push({ path: '/search', query: params })
    },
    moreClick () {
      this.more = !this.more
    },
    async getBrand () {
      let res = await this.get_brand()
      if (res.httpCode == 200) {
        //this.GET_BRAND(res)
      }
    },
    async getProductMenu () {
      let res = await this.get_product_menu()
      if (res.httpCode == 200) {
        //this.GET_PRODUCT_MENU(res)
      }
    },
    async getProductCount () {
      let res = await this.get_product_count()
      if (res.httpCode == 200) {
        //this.GET_PRODUCT_COUNT(res)
      }
    },
    handleClose () {
      this.more = false
    }
  },
  mounted () {
    if (!this.brandList) {
      this.getBrand()
    }
    if (!this.productMenu) {
      this.getProductMenu()
    }
    if (!this.productCount) {
      this.getProductCount() 
    }
  },
  watch: {
    'queryValue' (val) {
      if (val) {
        this.btnFalg = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/home-product-index.scss";
</style>



