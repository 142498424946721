<template>
  <div>
    <div class="info_top_bg clear">
      <div class="info_top">
        <h1 class="l">
          <router-link to="/index"><img height="44" src="@/assets/images/login/logo.svg"></router-link>
        </h1>
        <div class="l bt">
          <span class="name" id="top-name">个人中心</span>
        </div>

        <div class="login">
          <router-link class="l" to="/index">商城首页</router-link>
          <userItem></userItem>
          <div>
            <router-link to="/home/message">
              <span class="message"></span>
              <span v-if="message>0" class="dian"></span>
            </router-link>
          </div>
          <div class="shop" @click="setCartFalg">
            <a href="javascript:;" class="openCart">
              <span class="cart" id="custom_cart_effects_event"></span>
              <span v-if="cartLength!=0" class="num custom_cart_quantity_e">{{cartLength}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="mask_layout" id="cart_dialog" style="display: none">
      <div class="cart-close"></div>
      <div class="cart-wrap clear">
        <div class="cart-wrap-top clear">
          <p class="l"></p>
          <span class="r delAllCart">清除全部</span>
        </div>
        <div class="cart-wrap-list clear">
          <ul id="cart-list">
          </ul>
        </div>
        <div class="cart-wrap-bottom">
          <p>共<i>0</i>种商品（不含运费）</p>
          <p><span>原价：</span><span class="price">¥0.00</span></p>
          <p><span>总金额：</span><span class="total">¥0.00</span></p>
          <router-link to="/cart">去购物车结算</router-link>
        </div>
      </div>
    </div>
    <!--签到-->
    <!-- <check /> -->
  </div>
</template>
<script>
import userItem from '@/components/public/user-item.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
    userItem,
    //Check
  },
  data () {
    return {
      show: false,
      par: {
        "pageNum": 1,
        "pageSize": 10,
        "status": "",//查看状态(0未查看|1已查看) ,
      },
      total: 0,
      message: 0,
    }
  },

  created () {
  },
  computed: {
    ...mapState('cwq', ['checkDisplay']),
    ...mapState('lxr', ['cartLength']),
  },
  methods: {
    ...mapMutations('lxr', ['setCartFalg']),
    ...mapActions('lxr', ['get_message_list', 'get_message_num']),
    async getMessageNum () {
      let res = await this.get_message_num()
      if (res.httpCode == 200) {
        this.message = res.data
      }
    },
    async getMessageList () {
      let res = await this.get_message_list(this.par)
      if (res.httpCode == 200) {
        if (res.data) {
          this.total = res.data.total
        }
      }
    },
  },
  mounted () {
    this.getMessageNum()
    if (localStorage.getItem("access-token")) {
      this.getMessageList()
    }
  },
  watch: {
    '$route' () {
      this.getMessageNum()
    }
  }
}
</script>
<style lang="scss" scoped>
.login {
  margin-top: 24px;
}
</style>


