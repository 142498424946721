<template>
  <div class="r userCenter_container_box">
    <h2>增值税开票信息</h2>
    <div class="userCenter_container vat_container" v-loading="isloading">
      <div>
        <table v-if="!isloading">
          <tbody>
            <tr>
              <td>
                <h5>增值税开票资料：</h5>
              </td>
              <td class="left"><i>*</i>开票公司名称：</td>
              <td><input name="open_name" type="text" placeholder="请输入公司名称" v-model.trim="params.openName" /></td>
            </tr>
            <tr>
              <td></td>
              <td class="left"><i>*</i>纳税人识别号：</td>
              <td><input name="open_taxpayer" type="text" placeholder="请输入" v-model.trim="params.openTaxpayer" /></td>
            </tr>
            <tr>
              <td></td>
              <td class="left"><i>*</i>经营地址及电话：</td>
              <td><input name="operating_address" type="text" placeholder="XXXXX(180xxxxxx)" v-model.trim="params.operatingAddress" /></td>
            </tr>
            <tr>
              <td></td>
              <td class="left"><i>*</i>开户行所属地：</td>
              <td><input name="open_bank_address" type="text" placeholder="请输入地址" v-model.trim="params.openBankAddress" /></td>
            </tr>
            <tr>
              <td></td>
              <td class="left"><i>*</i>开户行帐号：</td>
              <td><input name="open_bank_account" type="text" placeholder="请输入账号" v-model.trim="params.openBankAccount" /></td>
            </tr>
            <tr>
              <td></td>
              <td class="left file_l"><i>*</i><span>营业执照电子文件：</span></td>
              <td>
                <div class="clear e_file">
                  <!-- <input name="open_business_license" class="l" type="text" v-model="fileName" readonly> -->
                  <label class="l" for="fileImg">点击选择图片</label>
                  <input type="file" id="fileImg" name="file" ref="inputer" accept="image/jpeg,image/jpg,image/png" style="visibility: hidden;width:0;height: 0;" @change="uploadImg($event)" />
                </div>
                <div class="fileName">
                  <!-- <p><i id="fileImgLocalName">图片名称</i><em></em></p> -->
                  <div class="img_see">
                    <img id="fileImgSee" :src="imgSrc" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="empty" colspan="2"></td>
            </tr>
            <tr>
              <td>
                <h5>增值税收票信息：</h5>
              </td>
              <td class="left"><i>*</i>发票收件人：</td>
              <td><input name="collect_name" maxlength="10" type="text" placeholder="请输入姓名" v-model.trim="params.collectName" /></td>
            </tr>
            <tr>
              <td></td>
              <td class="left"><i>*</i>发票收件人手机：</td>
              <td><input name="collect_mobile" maxlength="11" placeholder="请输入手机号码" type="text" v-model.trim="params.collectMobile" /></td>
            </tr>
            <tr>
              <td></td>
              <td class="left"><i>*</i>发票收件地址：</td>
              <td><input name="collect_address" maxlength="100" placeholder="请输入地址" type="text" v-model.trim="params.collectAddress" /></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>
                <span class="submit" id="changeNow" v-if="!invoiceInfo" @click="saveInvoice">保存</span>
                <span class="submit" id="changeNow" v-else @click="updateInvoice">保存</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { ajaxUpload } from '@/plugins/ibox/utils'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-增值税开票信息',
    }
  },
  data () {
    return {
      params: {
        "collectAddress": "",
        "collectEmail": "",
        "collectMobile": "",
        "collectName": "",
        "openBankAccount": "",
        "openBankAddress": "",
        "openBusinessLicense": "",
        "openName": "",
        "openTaxpayer": "",
        "operatingAddress": "",
      },
      invoiceInfo: {},
      isloading: true,
      imgSrc: '',
      fileName: '',
    }
  },

  created () {

  },
  computed: {
    // //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['update_invoice', 'get_invoice', 'save_invoice']),
    async getInvoice () {
      let res = await this.get_invoice()
      if (res.httpCode == 200) {
        this.isloading = false
        if (res.data) {
          this.params = res.data
          this.imgSrc = res.data.openBusinessLicense
        } else {
          this.invoiceInfo = res.data
        }
      }
    },
    async saveInvoice () {
      if (this.params.openName.length == 0) {
        return this.$message.error('请输入开票公司名称')
      }
      let res = await this.save_invoice(this.params)
      if (res.httpCode == 200) {
        this.getInvoice()
        // this.invoiceInfo = res.data
      }
    },
    async updateInvoice () {
      if (this.params.openName.length == 0) {
        return this.$message.error('请输入开票公司名称')
      }
      let res = await this.update_invoice(this.params)
      if (res.httpCode == 200) {
        // this.getInvoice()
        this.$message.success('保存成功')
        // this.invoiceInfo = res.data
      }
    },

    uploadImg (e) {
      let _this = this
      let file = e.target.files[0]
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        _this.$message.error('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
        return false
      }
      this.fileName = file.name
      let reader = new FileReader();
      reader.onload = (e) => {
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要 
          _this.imgSrc = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          _this.imgSrc = e.target.result;
        }
      }
      reader.readAsDataURL(file);
      let formData = new FormData();
      formData.append('file', file);
      formData.append('path', 'user');
      ajaxUpload(formData, response => {
        if (response.httpCode === 200) {
          _this.params.openBusinessLicense = response.data
        } else {
          _this.$message.error("图片上传失败,请稍后再试！");
        }
      })
    }
  },
  mounted () {
    this.getInvoice()
  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-valueAddedTax.scss";
</style>


