<template>

  <div class="r userCenter_container_box">
    <h2>编辑账户信息</h2>
    <div class="userCenter_container clear" v-if="info.mobile">
      <div class="tx clear">
        <img id="tx_img" v-if="info.headImg" :src="info.headImg" @click="changePicFun ()">
        <img id="tx_img" v-else src="~@/assets/images/tx.png" @click="changePicFun ()">
        <p @click="changePicFun ()">修改头像</p>
        <!-- <label class="btn-orange" for="uploads">选择Logo</label>
        <input ref="uploads" type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg($event, 1)"> -->
      </div>

      <table class="editAccount_box clear">
        <tbody>
          <tr>
            <td>昵称</td>
            <td class="clear">
              <input name="nickname" maxlength="10" type="text" v-model.trim="info.nickname" placeholder="请输入名称">
              <i class="reason">10个字以内</i>
            </td>
          </tr>
          <tr>
            <td>手机号码</td>
            <td class="clear">
              <input class="deColor l" maxlength="11" type="text" v-model.trim="info.mobile" readonly>
              <em class="modify l" id="changePhone" @click="changeMobileFun">修改</em>
            </td>
          </tr>
          <tr>
            <td>邮箱账号</td>
            <td>
              <template v-if="!info.email">
                <input class="deColor l" id="email_show" type="text" value="未绑定" readonly>
                <em class="modify" id="bindEmail" @click="changeEmailFun">绑定</em>
              </template>

              <template v-else>
                <input class="deColor l" id="email_show" v-model.trim="info.email" type="text" readonly>
                <em class="modify" id="changeEmail" @click="changeEmailFun">修改</em>
              </template>

            </td>
          </tr>
          <!-- info.regType==1 -->
          <!-- <template v-if="info.regType==1"> -->
          <input name="reg_type" type="hidden" value="1">
          <tr>
            <td>姓名</td>
            <td>
              <input name="name" maxlength="10" type="text" v-model.trim="info.name">
            </td>
          </tr>
          <tr>
            <td>性别</td>
            <td class="clear sex_select">
              <span class="l" v-for="(item,index) in sex" :key="index"><i class="checkbox" :class="{active:info.sex==item.code}" :sex="item.code" @click="selectSex(item.code)">{{item.val}}<em></em></i></span>
            </td>
          </tr>
          <tr>
            <td>公司名称</td>
            <td>
              <input type="text" name="company_name" v-model.trim="info.company"><i class="reason">用于增值税发票抬头</i>
            </td>
          </tr>
          <tr>
            <td>行业类型</td>
            <td>
              <input type="text" name="company_name" v-model.trim="info.industry">
            </td>
          </tr>
          <tr>
            <td>职业类型</td>
            <td>
              <input type="text" name="company_name" v-model.trim="info.occupation">
            </td>
          </tr>
          <!-- </template> -->

          <!-- info.regType==2 -->
          <!-- <template v-if="info.regType==2">
            <input name="reg_type" type="hidden" value="2">
            <tr>
              <td>企业名称</td>
              <td>
                <input name="name" type="text">
              </td>
            </tr>
            <tr>
              <td>公司名称</td>
              <td>
                <input type="text" maxlength="50" name="company_name" v-model.trim="info.company"><i class="reason">用于增值税发票抬头</i>
              </td>
            </tr>
          </template> -->
          <tr>
            <td></td>
            <td class="editAccount_btn clear">
              <span id="saveChange" class="submit l" @click="saveUserInfo">保存修改</span>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <!-- <vueCropper :imgNav="imgNav" v-if="vueCropperShow" v-on:returnCropper="returnCropper" v-on:returnCropperOssVal="returnCropperOssVal"></vueCropper> -->
    <transition name="el-fade-in">
      <div class="mask_layout1" v-if="changeEmail">
        <div id="bind_email_box" class="pop_up_box bind_email_box">
          <div class="pop_up_title clear">
            <h3 class="l">{{info.email?'修改邮箱':'绑定邮箱'}}</h3>
            <span class="r box_close" @click="changeEmail=false"></span>
          </div>
          <!-- 内容区域 -->
          <div class="modify_phone">
            <div class="tr_first clear" v-if="info.email">
              <p class="last_phone">
                <i>当前绑定邮箱账号：</i><em>{{info.email}}</em>
              </p>
            </div>
            <table>
              <tbody>
                <tr v-if="!email">
                  <td><i class="error">*</i>输入您的邮箱：</td>
                  <td>
                    <input style="float:inherit" class="emilVerify" name="email" type="text" v-model.trim="editEmail.to">
                    <i class="empty_input"></i>
                    <i class="verify_right"></i>
                    <p class="error_span"></p>
                  </td>
                </tr>
                <tr>
                  <td><i class="error">*</i>输入邮箱验证码：</td>
                  <td class="clear">
                    <input class="l emailSmsCode" maxlength="6" type="text" v-model.trim="editEmail.code">
                    <span class="vertify_code email l bindEmail" v-show="!sendFalg" @click="sendEmail">发送验证码</span>
                    <span class="vertify_code email l bindEmail" v-show="sendFalg">重新发送(<i ref="timeNum">60</i>)</span>
                    <br />

                    <i class="empty_input" style="right:120px"></i>
                    <p class="error_span"></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modify_phone_sub">
              <span id="bindEmail_complete" v-if="email" class="submit isBind" @click="nextEmail">下一步</span>
              <span id="bindEmail_complete" v-if="!email" class="submit isBind" @click="bindEmail">确认绑定</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="el-fade-in">
      <div class="mask_layout1" v-if="changeMobile">
        <!-- 修改手机号 修改 -->
        <div id="change_mobile_box" class="pop_up_box modify_phone_step">
          <div class="pop_up_title clear">
            <h3 class="l">修改手机号</h3>
            <span class="r box_close" @click="changeMobile=false"></span>
          </div>
          <!-- 内容区域 -->
          <div class="modify_phone">
            <div class="tr_first clear">
              <p class="last_phone">
                <i>当前绑定手机号码：</i><em>{{info.mobile}}</em>
              </p>
            </div>
            <table class="clear">
              <tbody>
                <tr v-if="!phone">
                  <td><i class="error">*</i>输入新手机号：</td>
                  <td>
                    <input style="float:inherit" class="emilVerify" name="phone" type="text" v-model.trim="phoneCode.mobile">
                    <i class="empty_input"></i>
                    <i class="verify_right"></i>
                    <p class="error_span"></p>
                  </td>
                </tr>
                <tr>
                  <td><i class="error">*</i>输入手机验证码：</td>
                  <td class="clear">
                    <input class="l phoneOneSmsCode" maxlength="6" type="text" v-model.trim="phoneCode.code">
                    <span class="vertify_code l" v-show="!sendFalgPhone" id="sendMessage" @click="sendSms">发送验证码</span>
                    <span class="vertify_code l" v-show="sendFalgPhone" id="sendMessage">重新发送(<i ref="timeNumPhone">60</i>)</span>
                    <br>
                    <i class="empty_input"></i>
                    <p class="error_span"></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modify_phone_sub">
              <span id="next_step" v-if="phone" class="submit" @click="nextPhone">下一步</span>
              <span id="next_step" v-if="!phone" class="submit isBind" @click="bindPhone">确认绑定</span>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="el-fade-in">
      <div class="mask_layout1" style="display: flex;" v-show="changePic">
        <!-- 头像上传 -->
        <div class="upload_tx pop_up_box" style="display: block;">
          <div class="pop_up_title clear">
            <h3 class="l">修改头像</h3>
            <span class="r box_close" @click="changePicFun"></span>
          </div>
          <div class="modify_tx">
            <div class="tx_box clear">
              <div class="container">
                <div class="imageBox">
                  <div class="thumbBox"></div>
                  <div class="spinner" style="display: none">请上传头像</div>
                </div>
                <div class="action clearfix">
                  <div class="new-contentarea tc">
                    <a href="javascript:void(0)" class="upload-img">
                      <label for="upload-file">上传图像</label>
                    </a>
                    <input type="file" class="" name="upload-file" id="upload-file" @change="uploadImg($event)" />
                  </div>
                  <span id="btnCrop" class="Btnsty_peyton" value="" @click="btnCrop">预览</span>
                  <span id="btnZoomIn" class="Btnsty_peyton" value="+" @click="btnZoomIn">+</span>
                  <span id="btnZoomOut" class="Btnsty_peyton" value="-" @click="btnZoomOut">-</span>
                </div>
                <div class="cropped"></div>
              </div>
            </div>
            <div class="tx_btn clearfix">
              <span class="txBtn_close l" @click="changePicFun">取消</span>
              <span class="txBtn_sub r" @click="chooseImg">确定</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { ajaxUpload } from '@/plugins/ibox/utils'
// import vueCropper from '@/components/cropper/vue-cropper.vue'

export default {
  name: 'app',
  components: {
    // vueCropper,
  },
  metaInfo () {
    return {
      title: '个人中心-账户信息',
    }
  },
  data () {
    return {
      changeMobile: false,
      changeEmail: false,
      changePic: false,
      info: {
        company: "",
        email: "",
        headImg: "",
        mobile: "",
        name: "",
        nickname: "",
        regType: Number,
        sex: Number,
        industry: '',
        occupation: '',
      },
      userId: '',
      sex: [
        {
          val: '男',
          code: 1
        }, {
          val: '女',
          code: 2
        }, {
          val: '保密',
          code: 0
        },
      ],
      email: false,
      phone: true,
      editEmail: {
        to: '',
        code: ''
      },
      phoneCode: {
        mobile: '',
        code: ''
      },
      sendFalg: false,
      sendFalgPhone: false,
      time: '',
      phoneTime: '',
      cropbox: null,
      options: {},
      cropper: null,
      file: null,
      imgUrl: ''
    }
  },

  created () {
    if (typeof window !== 'undefined') {
      require('@/assets/common/js/cropbox.js')
    }
  },
  computed: {
    //...mapState('demo', ['vuexres'])

  },
  methods: {
    ...mapActions('lxr', ['get_userInfo', 'save_userInfo', 'send_email', 'bind_email', 'send_sms', 'check_mobile_code']),
    ...mapMutations('lxr', ['SET_USERINFO']),

    toggleShow () {
      this.show = !this.show;
    },

    async nextPhone () {
      if (!this.phoneCode.code) {
        this.$message.error('请输入验证码')
        return
      } else if (this.phoneCode.code.length != 6) {
        this.$message.error('请输入6位数验证码')
        return
      }
      let res = await this.check_mobile_code({
        mobile: this.info.mobile,
        code: this.phoneCode.code
      })
      if (res.httpCode == 200) {
        clearInterval(this.phoneTime)
        this.sendFalgPhone = false
        this.phoneCode.code = ''
        this.$refs.timeNumPhone.innerText = 60
        this.phone = false
      } else {
        this.$message.error(res.message)
      }
    },

    async bindPhone () {
      if (!this.phoneCode.mobile) {
        this.$message.error('请输入手机号码')
        return
      } else if (this.phoneCode.mobile.length != 11) {
        this.$message.error('请输入11位手机号码')
        return
      }
      else if (!this.phoneCode.code) {
        this.$message.error('请输入验证码')
        return
      } else if (this.phoneCode.code.length != 6) {
        this.$message.error('请输入6位数验证码')
        return
      }
      let res = await this.check_mobile_code({
        mobile: this.phoneCode.mobile,
        code: this.phoneCode.code
      })
      if (res.httpCode == 200) {
        this.savePhone()
      } else {
        this.$message.error(res.message)
      }
    },

    //更新手机
    async savePhone () {
      let res = await this.save_userInfo({
        mobile: this.phoneCode.mobile,
        id: this.userId
      })
      if (res.httpCode == 200) {
        this.$message.success('保存成功')
        clearInterval(this.phoneTime)
        this.sendFalgPhone = false
        this.changeMobile = false
        this.phone = true
        this.info.mobile = this.phoneCode.mobile
        this.phoneCode.code = ''
        this.phoneCode.mobile = ''
        this.$refs.timeNumPhone.innerText = 60
      } else {
        this.$message.error(res.message)
      }
    },

    //发送短信
    async sendSms () {
      let res
      let sendCode = 60
      if (this.phone) {
        res = await this.send_sms({ mobile: this.info.mobile })
      } else {

        if (!this.phoneCode.mobile) {
          return this.$message.error('手机号码错误')
        }
        res = await this.send_sms({ mobile: this.phoneCode.mobile })
      }

      // let res = await this.send_sms({ mobile: this.info.mobile })
      if (res.httpCode == 200) {
        this.sendFalgPhone = true
        this.phoneTime = setInterval(() => {
          sendCode--
          this.$refs.timeNumPhone.innerText = sendCode
          if (sendCode == 0) {
            clearInterval(this.phoneTime)
            this.sendFalgPhone = false
            this.$refs.timeNumPhone.innerText = '60'
          }
        }, 1000);
      }
    },

    async nextEmail () {
      if (!this.editEmail.code) {
        this.$message.error('请输入验证码')
        return
      } else if (this.editEmail.code.length != 6) {
        this.$message.error('请输入6位数验证码')
        return
      }
      let res = await this.bind_email({
        to: this.info.email,
        code: this.editEmail.code.toString()
      })
      if (res.httpCode == 200) {
        clearInterval(this.time)
        this.email = false
        this.sendFalg = false
        this.editEmail.code = ''
        this.$refs.timeNum.innerText = 60
      } else {
        this.$message.error(res.message)
      }
    },

    //发送邮件
    async sendEmail () {
      let res
      let sendCode = 60
      if (this.email) {
        res = await this.send_email({ to: this.info.email })
      } else {
        let falg = this.$iBox.checkEmail(this.editEmail.to)
        if (!falg) {
          this.$message.error('邮箱格式不正确2222')
          return
        }
        res = await this.send_email({ to: this.editEmail.to })
      }

      if (res.httpCode == 200) {
        this.sendFalg = true
        this.time = setInterval(() => {
          sendCode--
          this.$refs.timeNum.innerText = sendCode
          if (sendCode == 0) {
            clearInterval(this.time)
            this.sendFalg = false
            this.$refs.timeNum.innerText = '60'
          }
        }, 1000);
      } else {
        this.$message.error(res.message)
      }
    },

    //绑定邮箱
    async bindEmail () {
      if (!this.editEmail.code) {
        this.$message.error('请输入验证码')
        return
      } else if (this.editEmail.code.length != 6) {
        this.$message.error('请输入6位数验证码')
        return
      }
      let res = await this.bind_email(this.editEmail)
      if (res.httpCode == 200) {
        this.saveEmail()
      } else {
        this.$message.error(res.message)
      }
    },

    //更新邮箱
    async saveEmail () {
      let res = await this.save_userInfo({
        mobile: this.info.mobile,
        email: this.editEmail.to,
        id: this.userId
      })
      if (res.httpCode == 200) {
        this.$message.success('保存成功')
        this.info.email = this.editEmail.to
        this.$refs.timeNum.innerText = 60
        this.changeEmail = false
        this.email = false
        clearInterval(this.time)
      } else {
        this.$message.error(res.message)
      }
    },

    //获取用户信息
    async getUserInfo () {
      let res = await this.get_userInfo()
      if (res.httpCode == 200) {
        this.info.headImg = res.data.headImg
        this.info.nickname = res.data.nickname
        this.info.mobile = res.data.mobile
        this.info.email = res.data.email
        this.info.regType = res.data.regType
        this.info.name = res.data.name
        this.info.sex = res.data.sex
        this.info.company = res.data.company
        this.info.industry = res.data.industry
        this.info.occupation = res.data.occupation
        this.info.sex = res.data.sex
        this.userId = res.data.id
        this.options =
        {
          thumbBox: '.thumbBox',
          spinner: '.spinner',
          imgSrc: ''
        }
        this.cropper = $('.imageBox').cropbox(this.options);
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        this.SET_USERINFO(res.data)
        if (this.info.email) {
          this.email = true
        }
      } else {
        this.$message.error(res.message)
      }
    },

    //更新用户信息
    async saveUserInfo () {
      if (!this.info.nickname) {
        return this.$message.error('用户昵称不能为空')
      }
      let res = await this.save_userInfo(this.info)
      if (res.httpCode == 200) {
        this.$message.success('保存成功')
        this.getUserInfo()
      } else {
        this.$message.error(res.message)
      }
    },

    selectSex (code) {
      this.info.sex = code
    },
    changeMobileFun () {
      this.changeMobile = !this.changeMobile
    },
    changeEmailFun () {
      this.changeEmail = !this.changeEmail
    },
    changePicFun () {
      this.changePic = !this.changePic
    },
    /* 图片上传 */

    chooseImg () {
      var _this = this;
      if (!_this.options.imgSrc) {
        _this.changePic = !_this.changePic
        return
      }
      var formData = new FormData();
      let blob = this.cropper.getBlob()
      formData.append('file', blob, 'pic_' + new Date().getTime() + '.png');
      formData.append('path', 'user');
      ajaxUpload(formData, response => {
        if (response.httpCode === 200) {
          _this.info.headImg = response.data + '?t=' + new Date().getTime()
          _this.changePic = !_this.changePic
        } else {
          _this.$message.error("图片上传失败,请稍后再试！");
        }
      })
    },
    //选择本地图片
    uploadImg (e) {
      var _this = this;
      //上传图片 
      _this.file = e.target.files[0]
      var file = e.target.files[0]
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        _this.$message.error('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
        return false
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要 
          _this.options.imgSrc = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          _this.options.imgSrc = e.target.result;
        }
        _this.cropper = $('.imageBox').cropbox(_this.options);
      }
      reader.readAsDataURL(file);
      setTimeout(() => {
        _this.getPicUrl()
      }, 200);
    },

    getPicUrl () {
      this.imgUrl = this.cropper.getDataURL();
      $('.cropped').html('');
      $('.cropped').append('<img src="' + this.imgUrl + '" align="absmiddle" style="width:64px;margin-top:4px;border-radius:64px;box-shadow:0px 0px 12px #7E7E7E;" ><p>64px*64px</p>');
      $('.cropped').append('<img src="' + this.imgUrl + '" align="absmiddle" style="width:128px;margin-top:4px;border-radius:128px;box-shadow:0px 0px 12px #7E7E7E;"><p>128px*128px</p>');
      $('.cropped').append('<img src="' + this.imgUrl + '" align="absmiddle" style="width:180px;margin-top:4px;border-radius:180px;box-shadow:0px 0px 12px #7E7E7E;"><p>180px*180px</p>');
    },
    btnCrop () {
      this.getPicUrl()
    },
    btnZoomIn () {
      this.cropper.zoomIn();
      this.getPicUrl()
    },
    btnZoomOut () {
      this.cropper.zoomOut();
      this.getPicUrl()
    },
  },
  mounted () {
    this.getUserInfo()
  },
  beforeDestroy () {
    clearInterval(this.time)
  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-editAccountInfo.scss";
.tx_btn {
  margin-bottom: 20px;
  padding-top: 20px;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

*,
:after,
:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.upload_tx {
  margin-top: -350px;
}
.container {
  width: 400px;
  position: relative;
  font-size: 12px;
  p {
    line-height: 12px;
    line-height: 0px;
    height: 0px;
    margin: 10px;
    color: #bbb;
  }
}
.action {
  width: 400px;
  height: 30px;
  margin: 10px 0;
}
.cropped {
  position: absolute;
  right: -238px;
  top: 0;
  width: 200px;
  border: 1px #ddd solid;
  height: 450px;
  padding: 4px;
  box-shadow: 0px 0px 12px #ddd;
  text-align: center;
}
.imageBox {
  position: relative;
  height: 400px;
  width: 400px;
  border: 1px solid #aaa;
  background: #fff;
  overflow: hidden;
  background-repeat: no-repeat;
  cursor: move;
  box-shadow: 4px 4px 12px #b0b0b0;
}
.imageBox .thumbBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  margin-top: -125px;
  margin-left: -125px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid rgb(102, 102, 102);
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background: none repeat scroll 0% 0% transparent;
}
.imageBox .spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  line-height: 400px;
  background: rgba(0, 0, 0, 0.2);
}
.Btnsty_peyton {
  text-align: center;
  float: right;
  width: 66px;
  display: block;
  margin-bottom: 10px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #ffffff;
  margin: 0px 2px;
  background-color: #3d82dc;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 0px 0px 5px #b0b0b0;
  border: 0px #fff solid;
}
/*选择文件上传*/
.new-contentarea {
  width: 165px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  float: left;
}
.new-contentarea label {
  width: 100%;
  height: 100%;
  display: block;
}
.new-contentarea input[type="file"] {
  width: 188px;
  height: 40px;
  background: #333;
  margin: 0 auto;
  position: absolute;
  right: 50%;
  margin-right: -94px;
  top: 0;
  right/*\**/: 0px\9;
  margin-right/*\**/: 0px\9;
  width/*\**/: 10px\9;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 2;
}
a.upload-img {
  width: 165px;
  display: inline-block;
  margin-bottom: 10px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #ffffff;
  background-color: #3d82dc;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
  border: 0px #fff solid;
  box-shadow: 0px 0px 5px #b0b0b0;
}
a.upload-img:hover {
  background-color: #ec7e70;
}

.tc {
  text-align: center;
}
</style>
