<template>
  <div class="r userCenter_container_box">
    <h2>电子对账单</h2>
    <div class="userCenter_container elec_statement">
      <div class="orderM_top clear">
        <div class="orderM_top_r l">
          <span class="t">购买时间：</span>
          <el-date-picker v-model="params.data.startTime" value-format='yyyy-MM-dd' type="date" placeholder="选择日期" id="begin_time" :picker-options="pickerOptions">
          </el-date-picker>
          <span class="zi">至</span>
          <el-date-picker v-model="params.data.endTime" value-format='yyyy-MM-dd' type="date" placeholder="选择日期" id="begin_time" :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div class="orderM_top_l r">
          <input id="material_sn" type="text" value="" placeholder="请输入制造商料号" v-model.trim="params.data.query">
          <em id="search_q" @click="getOrderHistory"></em>
        </div>
      </div>

      <div class="order-tatal clear"><span class="l">符合条件的共<i id="total">{{total}}</i>条数据</span><span id="export" class="r">导出对账单</span></div>
      <div class="orderM_body_box" v-loading="isloading">
        <div class="elecStatement_contain clear">
          <div class="order-table">
            <table>
              <tbody>
                <template v-if="history&&history.length>0">
                  <tr>
                    <th class="one">商品信息</th>
                    <th class="two">制造商料号</th>
                    <th class="two">封装规格</th>
                    <th class="two">参数规格</th>
                    <th>购买数量</th>
                    <th>合计金额</th>
                  </tr>

                  <tr v-for="(i, idx) in history" :key="idx">
                    <td class="one"><span>{{i.goodsName}}</span><br /><span class="brand">{{i.brandName}}</span></td>
                    <td class="two">{{i.materialSn}}</td>
                    <td class="two">{{i.specParam}}</td>
                    <td class="two">{{i.specParam}}</td>
                    <td>{{i.goodsNum}}</td>
                    <td>￥{{i.goodsAmount}}元</td>
                  </tr>
                </template>
                <template v-if="history&&history.length<1">
                  <tr>
                    <td style="text-align: center;border-bottom:none" colspan="6">
                      <div class="no-order">
                        <img src="@/assets/images/goods/noshop_1.png" alt="">
                        <p>暂无数据</p>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="page-wrap">
          <el-pagination background hideOnSinglePage @current-change="currentChange" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'app',
  components: {
  },
  metaInfo () {
    return {
      title: '个人中心-电子对账单',
    }
  },
  data () {
    return {
      value1: '',
      value2: '',
      isloading: true,
      history: null,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        }
      },
      total: 0,
      params: {
        data: {
          startTime: '',
          endTime: '',
          query: ''
        },
        pageNum: 1,
        pageSize: 10
      }
    }
  },

  created () {

  },
  computed: {
    //...mapState('demo', ['vuexres'])
  },
  methods: {
    ...mapActions('lxr', ['get_order_history']),
    currentChange (e) {
      this.params.pageNum = e
      this.getOrderHistory()
    },
    async getOrderHistory () {
      let res = await this.get_order_history(this.params)
      if (res.httpCode == 200) {
        this.isloading = false
        if (res.data) {
          this.history = res.data.records
          this.total = res.data.total
        }
      }
    },
  },
  mounted () {
    this.getOrderHistory()
  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/member-orderManage.scss";
.orderM_top /deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
.orderM_top /deep/.el-input__icon {
  line-height: 36px;
}
.orderM_body_box {
  min-height: 326px;
  padding-bottom: 1px;
}
</style>


